import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const advertisersAPI = {
  list (request) {
    return Client.post('/api/clients/', request);
  },

  get (id) {
    return Client.get(`/api/clients/${id}`);
  },

  saveOrUpdateTemplate (data, config) {
    return Client.post('/api/mediaplans/templates/', data, config);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/clients/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  }
};

export default advertisersAPI;
