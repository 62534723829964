import React, {Component} from 'react';
import isEqual from 'react-fast-compare';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import Tooltip from 'rc-tooltip';
import Sifter from 'sifter';

import LocalizedMessage, {localizeMessage} from '../../../../components/LocalizedMessage';
import Select from '../../../../components/Select';
import Input from './Input';
import OnLoadWrapper from './OnLoadWrapper';
import TargetAudienceSelect from './TargetAudienceSelect';
import TableGenerator from '../Tables/TableGenerator';
// import Placeholder from './Placeholder';
import {removeDuplicates, UUID} from '../../../../helpers/utils';
import Alert from '../../../../helpers/alert';
import API from '../../../../api';

class Media extends Component {
  state = {
    optionsFrequencies: this.props.effectiveFrequenciesList,
    form: this.props.formData || {},
    tvcList: this.props.tvcListData || [{
      type: null,
      length: null
    }],
    resultTvcList: this.props.resultTvcListData || []
  };

  spendsTypeFormMap = {
    'tvc': 'resultTvcList'
  };

  cacheCppDictionaryNames = {};

  frequenciesSifter = new Sifter(this.props.effectiveFrequenciesList || [], {});
  oldFrequencyInputValue = '';

  shouldComponentUpdate (nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }

    return !isEqual(this.props, nextProps);
  }

  isMediaTypeImplemented (mediaType, spendsType) {
    const {
      mediaTypes
    } = this.props;

    return mediaTypes[mediaType.id] &&
      mediaTypes[mediaType.id].meta &&
      Object.keys(mediaTypes[mediaType.id].meta).length &&
      mediaTypes[mediaType.id].meta[spendsType.name];
  }

  isShowRegionality (mediaType, spendsType) {
    const {mediaTypes} = this.props;
    const {form} = this.state;

    return form.typeTable === 'tvc-placement-regionality' || form.typeTable === 'tvc-production-regionality'
      ? true
      : mediaTypes &&
      mediaTypes[mediaType.id] &&
      mediaTypes[mediaType.id].meta &&
      mediaTypes[mediaType.id].meta[spendsType.name] &&
      mediaTypes[mediaType.id].meta[spendsType.name].fields.regionality;
  }

  isShowEffectiveFrequencies (mediaType, spendsType) {
    const {mediaTypes} = this.props;
    const {form} = this.state;

    return form.typeTable === 'tvc-production' || form.typeTable === 'tvc-production-regionality'
      ? false
      : mediaTypes &&
      mediaTypes[mediaType.id] &&
      mediaTypes[mediaType.id].meta &&
      mediaTypes[mediaType.id].meta[spendsType.name] &&
      mediaTypes[mediaType.id].meta[spendsType.name].fields.effectiveFrequencies;
  }

  isShowTable (spendsType, mediaTypeId, tvcList) {
    const {mediaTypes, mediaType} = this.props;
    const {form} = this.state;

    if (tvcList.length && !tvcList.some(tvc => (!tvc.length || tvc.length.length))) {
      return false;
    }

    if (
      !mediaTypes[mediaTypeId] ||
      !mediaTypes[mediaTypeId].meta ||
      !mediaTypes[mediaTypeId].meta[spendsType.name] ||
      !mediaTypes[mediaTypeId].meta[spendsType.name].fields
    ) {
      return false;
    }

    const fieldsKeys = Object.keys(mediaTypes[mediaTypeId].meta[spendsType.name].fields)
      .filter((fieldKey) => mediaTypes[mediaTypeId].meta[spendsType.name].fields[fieldKey]);

    return fieldsKeys.length && fieldsKeys.every((fieldKey) => {
      fieldKey = this.spendsTypeFormMap[fieldKey] || fieldKey;

      if (fieldKey === 'resultTvcList') {
        return !!this.state[fieldKey] && !!this.state[fieldKey].length;
      }

      if (this.isShowEffectiveFrequencies(mediaType, spendsType) && fieldKey === 'effectiveFrequencies') {
        return !!form[fieldKey] && !!form[fieldKey].length;
      }

      return !!form[fieldKey];
    });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.year !== this.props.year || nextProps.brand !== this.props.brand) {
      Object.keys(this.cacheCppDictionaryNames).forEach((targetAudience) => {
        const {mediaTypeId, numberIo} = this.cacheCppDictionaryNames[targetAudience];

        this.loadCppDictionary(targetAudience, mediaTypeId, numberIo, nextProps.year, nextProps.brand);
      });
    }

    if (nextProps.effectiveFrequenciesList !== this.props.effectiveFrequenciesList) {
      this.frequenciesSifter.items = nextProps.effectiveFrequenciesList;
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.year?.value !== this.props.year?.value) {
      this.deleteAllTVC();
    }

    const {resultTvcList} = this.state;

    if (prevState.resultTvcList.length !== resultTvcList.length && resultTvcList.length === 0) {
      this.updateSpendsTypeLocal({tableData: undefined});
    }
  }

  componentWillUnmount () {
    const {onUpdateMediaValidationStatus, category, mediaType, mediaRecord, spendsType} = this.props;
    const title =
    `${this.getCampaignForTitle()}${category.name}_${mediaRecord.numberIO.value}_${mediaType.name}_${spendsType.name}`;

    onUpdateMediaValidationStatus(title, true);
  }

  async loadCppDictionary (targetAudience, mediaTypeId, numberIo, _year, _brand) {
    const {
      mediaTypes,
      updateMediaTypes,
      trackPromiseAll
    } = this.props;

    const year = _year || this.props.year;
    const brand = _brand || this.props.brand;

    this.cacheCppDictionaryNames[targetAudience] = {
      mediaTypeId,
      numberIo
    };

    const values = await trackPromiseAll({
      items: API.cpp.intersect({
        brandId: brand.value,
        mediaTypeId: mediaTypeId,
        targetAudienceId: +targetAudience,
        year: year.value
      })
    });

    Object.keys(mediaTypes).forEach((mediaType) => {
      if ((+mediaTypes[mediaType].id) === (+mediaTypeId)) {
        if (!mediaTypes[mediaType].cpp[numberIo]) {
          mediaTypes[mediaType].cpp[numberIo] = [];
        }

        mediaTypes[mediaType].cpp[numberIo][targetAudience] = Object.keys(values.items).map((i) => values.items[i]);
      }
    });

    updateMediaTypes(mediaTypes);
  }

  handleMediaTypeInputChange = (e) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      isUsingCompanies,
      campaign,
      updateSpendsTypeForm,
      category
    } = this.props;

    const {form} = this.state;

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value
      }
    });

    updateSpendsTypeForm(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        [e.target.name]: e.target.value
      }
    );
  };

  handleTargetAudienceInputChange = (e) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      isUsingCompanies,
      campaign,
      updateSpendsTypeForm,
      category,
      media
    } = this.props;

    const {form} = this.state;

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    if (
      media[campaignId] &&
      media[campaignId][category.id] &&
      media[campaignId][category.id][mediaRecordIndex] &&
      media[campaignId][category.id][mediaRecordIndex].numberIO &&
      media[campaignId][category.id][mediaRecordIndex].numberIO.value
    ) {
      this.loadCppDictionary(
        e.target.value.value,
        mediaType.id,
        media[campaignId][category.id][mediaRecordIndex].numberIO.value
      );
    }

    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value
      }
    });

    updateSpendsTypeForm(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        [e.target.name]: e.target.value
      }
    );
  };

  onChangePlanByRegional = async (type, isLoad) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateMediaData,
      updateSpendsType,
      updateSpendsTypeForm,
      isUsingCompanies,
      campaign,
      category
    } = this.props;

    const {form} = this.state;

    if (
      !spendsType ||
      !form
    ) {
      return;
    }

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    const spendsTypeState = {};

    form['planBy'] = type;
    form.typeTable = type;
    spendsTypeState.tableType = type;

    if (!isLoad) {
      await updateMediaData(
        campaignId,
        category.id,
        mediaRecordIndex,
        mediaType.id,
        spendsType.id,
        {
          tableData: []
        }
      );

      form.effectiveFrequencies = [];
    }

    updateSpendsType(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      spendsTypeState,
      () => {
        this.setState({
          form: {
            ...form
          }
        });

        updateSpendsTypeForm(
          campaignId,
          category.id,
          mediaRecordIndex,
          mediaType.id,
          spendsType.id,
          {
            ...form
          }
        );
      }
    );
  };

  onChangePlanBy = async (type, isLoad) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateMediaData,
      updateSpendsType,
      updateSpendsTypeForm,
      isUsingCompanies,
      campaign,
      category
    } = this.props;

    const {form} = this.state;

    if (
      !spendsType ||
      !form
    ) {
      return;
    }

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    const spendsTypeState = {};

    form['planByNotRegions'] = type;
    form.typeTable = type;
    spendsTypeState.tableType = type;

    if (!isLoad) {
      await updateMediaData(
        campaignId,
        category.id,
        mediaRecordIndex,
        mediaType.id,
        spendsType.id,
        {
          tableData: []
        }
      );

      form.effectiveFrequencies = [];
    }

    updateSpendsType(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      spendsTypeState,
      () => {
        this.setState({
          form: {
            ...form
          }
        });

        updateSpendsTypeForm(
          campaignId,
          category.id,
          mediaRecordIndex,
          mediaType.id,
          spendsType.id,
          {
            ...form
          }
        );
      }
    );
  };

  onChangePlanByWithImpressions = async (type, isLoad) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateSpendsTypeForm,
      updateSpendsType,
      updateMediaData,
      isUsingCompanies,
      campaign,
      category
    } = this.props;

    const {form} = this.state;

    if (!form) {
      return;
    }

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    const spendsTypeState = {};

    form['planByWithImpressions'] = type;
    form.typeTable = type;
    spendsTypeState.tableType = type;

    if (!isLoad) {
      await updateMediaData(
        campaignId,
        category.id,
        mediaRecordIndex,
        mediaType.id,
        spendsType.id,
        {
          tableData: []
        }
      );

      form.effectiveFrequencies = [];
    }

    updateSpendsType(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      spendsTypeState,
      () => {
        this.setState({
          form: {
            ...form
          }
        });

        updateSpendsTypeForm(
          campaignId,
          category.id,
          mediaRecordIndex,
          mediaType.id,
          spendsType.id,
          {
            ...form
          }
        );
      });
  };

  onChangeTvc = (e, tvcIndex) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateSpendsTypeTvc,
      isUsingCompanies,
      campaign,
      category
    } = this.props;

    const {tvcList} = this.state;

    if (!spendsType) {
      return;
    }

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    if (!tvcList[tvcIndex]) {
      return;
    }

    tvcList[tvcIndex].type = e;

    this.setState({
      tvcList
    });

    updateSpendsTypeTvc(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        tvcList
      }
    );
  };

  onChangeTvcLength = (e, tvcIndex) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateSpendsTypeTvc,
      isUsingCompanies,
      campaign,
      category,
    } = this.props;

    const {tvcList} = this.state;

    if (!spendsType) {
      return;
    }

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    if (!tvcList[tvcIndex]) {
      return;
    }

    tvcList[tvcIndex].length = e;

    this.setState({
      tvcList
    });

    updateSpendsTypeTvc(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        tvcList
      }
    );
  };

  addTVC = () => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateSpendsTypeTvc,
      category,
      campaign,
      isUsingCompanies
    } = this.props;

    const {tvcList, resultTvcList} = this.state;

    if (!tvcList.length) {
      return;
    }

    const lastTVC = tvcList[tvcList.length - 1];

    if (!lastTVC.type || !lastTVC.length || !lastTVC.length.length) {
      return;
    }

    const identicalTvcCache = [];

    if (tvcList.length > 1) {
      tvcList.forEach((tvc, tvcIndex) => {
        if (tvcList.length - 1 === tvcIndex) {
          return;
        }

        if (tvc.type.value === lastTVC.type.value) {
          identicalTvcCache.push(lastTVC.length.some(v => tvc.length.findIndex(l => l.value === v.value) !== -1));
        }
      });
    }

    if (identicalTvcCache.some((value) => value)) {
      Alert.error(localizeMessage({id: 'category-list-content.cannot-add-two-identical-video'}));

      return;
    }

    lastTVC.uuid = UUID();

    const newResultTvcList = cloneDeep(resultTvcList);
    newResultTvcList.push(lastTVC);

    tvcList.push({
      type: null,
      length: null
    });

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    this.setState({
      tvcList,
      resultTvcList: newResultTvcList
    });

    updateSpendsTypeTvc(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        tvcList,
        resultTvcList: newResultTvcList
      }
    );
  };

  deleteTVC = (tvcIndex) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateSpendsTypeTvc,
      category,
      campaign,
      isUsingCompanies
    } = this.props;

    const {tvcList, resultTvcList} = this.state;

    const newTvcList = cloneDeep(tvcList);
    newTvcList.splice(tvcIndex, 1);

    const newResultTvcList = cloneDeep(resultTvcList);
    newResultTvcList.splice(tvcIndex, 1);

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    this.setState({
      tvcList: newTvcList,
      resultTvcList: newResultTvcList
    });

    updateSpendsTypeTvc(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        tvcList: newTvcList,
        resultTvcList: newResultTvcList
      }
    );
  };

  deleteAllTVC = () => {
    const tvcList = [{type: null, length: null}];
    const resultTvcList = [];

    this.setState({tvcList, resultTvcList});

    this.props.updateSpendsTypeTvc(
      this.props.isUsingCompanies ? this.props.campaignId : 0,
      this.props.category.id,
      this.props.mediaRecordIndex,
      this.props.mediaType.id,
      this.props.spendsType.id,
      {
        tvcList,
        resultTvcList,
      }
    );
  };

  updateTVC = (tvcIndex) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateSpendsTypeTvc,
      category,
      campaign,
      isUsingCompanies
    } = this.props;

    const {tvcList, resultTvcList} = this.state;

    const tvc = tvcList[tvcIndex];

    if (!tvc.type || !tvc.length || !tvc.length.length) {
      return;
    }

    let identicalTvcLengthCache = [];

    const __cacheTvc__ = {};
    const __cacheTvcLength__ = [];

    resultTvcList.forEach((_tvc) => {
      if (_tvc.type.value === tvc.type.value) {
        const hashKey = _tvc.uuid;
        __cacheTvc__[hashKey] = __cacheTvc__[hashKey] || [];

        each(_tvc.length, (length) => {
          __cacheTvc__[hashKey].push(length.value);
          __cacheTvcLength__.push(length.value);
        });

        identicalTvcLengthCache = tvc.length.filter(l => __cacheTvcLength__.includes(l.value)).map(l => l.value);
      }
    });

    const haveInOtherTvc = [];

    each(__cacheTvc__, (c, ck) => {
      if (tvc.uuid === ck) {
        return;
      }

      haveInOtherTvc.push(identicalTvcLengthCache.some(v => c.includes(v)));
    });

    if (haveInOtherTvc.some(value => value)) {
      Alert.error(localizeMessage({id: 'category-list-content.cannot-add-two-identical-video'}));

      return;
    }

    const newResultTvcList = cloneDeep(resultTvcList);
    newResultTvcList[tvcIndex] = tvc;

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    this.setState({
      resultTvcList: newResultTvcList
    });

    updateSpendsTypeTvc(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      {
        resultTvcList: newResultTvcList
      }
    );
  };

  getCampaignForTitle () {
    const {isUsingCompanies, campaign} = this.props;

    return isUsingCompanies
      ? campaign.name + '_'
      : '';
  }

  onChangeFrequenciesInput = (selectedValues = [], value) => {
    const {
      effectiveFrequenciesList
    } = this.props;

    if (value === this.oldFrequencyInputValue) {
      return;
    }

    this.oldFrequencyInputValue = value;

    const result = this.frequenciesSifter.search(value, {
      fields: ['label']
    });

    const searchResults = result.items.map(item => effectiveFrequenciesList[item.id]);

    const _results = selectedValues.concat(searchResults);

    const results = removeDuplicates([
      ...searchResults,
      ..._results
    ], 'value');

    this.setState({
      optionsFrequencies: results
    });
  };

  updateSpendsTypeLocal = (values, cb) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      updateMediaData,
      category,
      campaign,
      isUsingCompanies
    } = this.props;

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    updateMediaData(
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id,
      values,
      cb
    );
  };

  onCreateDescriptionOption = async (value) => {
    const {
      mediaRecordIndex,
      mediaType,
      spendsType,
      category,
      campaign,
      isUsingCompanies,
      updateSpendsTypeForm,
      onCreateDescriptionOption,
    } = this.props;

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    const newOption = {
      value: value,
      label: value,
    };

    await onCreateDescriptionOption(
      newOption,
      campaignId,
      category.id,
      mediaRecordIndex,
      mediaType.id,
      spendsType.id
    );

    if (this.props.descriptions.find(description => description.value === newOption.value)) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          descriptions: [...(prevState.form.descriptions || []), newOption],
        }
      }), () => {
        updateSpendsTypeForm(
          campaignId,
          category.id,
          mediaRecordIndex,
          mediaType.id,
          spendsType.id,
          {
            descriptions: this.state.form.descriptions
          }
        );
      });
    }
  }

  render () {
    const {
      campaign,
      category,
      mediaRecord,
      mediaRecordIndex,
      mediaTypes,
      mediaType,
      spendsType,
      optionsTargetAudiences,
      descriptions,
      optionsTvc,
      tvcTypes,
      mediaTypesMap,
      cpp,
      year,
      effectiveFrequenciesList,
      regionsList,
      tableData,
      removeMedia,
      trackPromiseAll,
      onUpdateMediaValidationStatus,
      isDisabled = false,
    } = this.props;

    const {
      optionsFrequencies,
      form,
      tvcList,
      resultTvcList
    } = this.state;

    const title =
    `${this.getCampaignForTitle()}${category.name}_${mediaRecord.numberIO.value}_${mediaType.name}_${spendsType.name}`;

    onUpdateMediaValidationStatus(title, this.isShowTable(spendsType, mediaType.id, resultTvcList));

    return (
      <div className='ibox'>
        <div className='ibox-title'>
          {!isDisabled && (
            <div className='flowCharts-delete-button-container'>
              <Tooltip
                placement='top'
                overlay={(
                  <LocalizedMessage id='delete' />
                )}
              >
                <i
                  className='fa fa-remove inline-button-edit-active'
                  onClick={() => removeMedia(category.id, mediaRecordIndex, mediaType.id, spendsType.id)}
                />
              </Tooltip>
            </div>
          )}
          <h5>
            {title}
          </h5>
        </div>

        {
          /*
        <LazyLoad
          once
          height={200}
          offset={[200, 200]}
          scrollContainer='#main_content'
          placeholder={<Placeholder />}
        >
          */
        }
        <div className='ibox-content'>
          {
            !this.isMediaTypeImplemented(mediaType, spendsType) ? (
              <p className='media-type-not-implemented'>Not implemented yet</p>
            ) : (
              <div className='form-horizontal'>
                {
                  this.isShowRegionality(mediaType, spendsType) ? (
                    <Input
                      type='c-select'
                      formName='regionality'
                      labelId='flowchart.regions.label'
                      placeholderId='flowchart.regions.placeholder'
                      value={form.regionality}
                      onChange={this.handleMediaTypeInputChange}
                      options={regionsList}
                      settings={{
                        isMulti: true
                      }}
                      disabled={isDisabled}
                    />
                  ) : null
                }

                {
                  mediaTypes[mediaType.id].meta[spendsType.name].fields.targetAudience && (
                    <TargetAudienceSelect
                      options={optionsTargetAudiences}
                      value={form.targetAudience}
                      onChange={this.handleTargetAudienceInputChange}
                      disabled={isDisabled}
                    />
                  )
                }

                {
                  mediaTypes[mediaType.id].meta[spendsType.name].fields.planBy ? (
                    <div className='row form-group'>
                      <OnLoadWrapper
                        onLoad={() => this.onChangePlanByRegional(
                          form.planBy || 'tvc-placement-regionality',
                          true
                        )}
                      >
                        <div className='col-lg-6 planBy'>
                          <label className='col-lg-5 control-label planBy-label'>
                            <LocalizedMessage id='flowchart.plan-by' />
                          </label>
                          <div className='col-lg-7'>
                            <div className='planBy-item'>
                              <input
                                id={
                                  `planBy_${title}_tvc-placement-regionality`
                                }
                                name={
                                  `planBy_${title}`
                                }
                                type='radio'
                                value='tvc-placement-regionality'
                                checked={form.planBy === 'tvc-placement-regionality'}
                                onChange={() =>
                                  this.onChangePlanByRegional(
                                    'tvc-placement-regionality',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planBy_${title}_tvc-placement-regionality`
                                }
                              >
                                <LocalizedMessage id='flowchart.week-ratings' />
                              </label>
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className='planBy-item'>
                              <input
                                id={
                                  `planBy_${title}_tvc-production-regionality`
                                }
                                name={
                                  `planBy_${title}`
                                }
                                type='radio'
                                value='tvc-production-regionality'
                                checked={form.planBy === 'tvc-production-regionality'}
                                onChange={() =>
                                  this.onChangePlanByRegional(
                                    'tvc-production-regionality',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planBy_${title}_tvc-production-regionality`
                                }
                              >
                                <LocalizedMessage id='flowchart.month-budgets' />
                              </label>
                            </div>
                          </div>
                        </div>
                      </OnLoadWrapper>
                      <div className='col-lg-6' />
                    </div>
                  ) : null
                }

                {
                  mediaTypes[mediaType.id].meta[spendsType.name].fields.planByNotRegions ? (
                    <div className='row form-group'>
                      <OnLoadWrapper
                        onLoad={() => this.onChangePlanBy(
                          form.planByNotRegions || 'tvc-placement',
                          true
                        )}
                      >
                        <div className='col-lg-6 planBy'>
                          <label className='col-lg-5 control-label planBy-label'>
                            <LocalizedMessage id='flowchart.plan-by' />
                          </label>
                          <div className='col-lg-7'>
                            <div className='planBy-item'>
                              <input
                                id={
                                  `planByNotRegions_${title}_tvc-placement`
                                }
                                name={
                                  `planByNotRegions_${title}`
                                }
                                type='radio'
                                value='tvc-placement'
                                checked={form.planByNotRegions === 'tvc-placement'}
                                onChange={() =>
                                  this.onChangePlanBy(
                                    'tvc-placement',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planByNotRegions_${title}_tvc-placement`
                                }
                              >
                                <LocalizedMessage id='flowchart.week-ratings' />
                              </label>
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className='planBy-item'>
                              <input
                                id={
                                  `planByNotRegions_${title}_tvc-production`
                                }
                                name={
                                  `planByNotRegions_${title}`
                                }
                                type='radio'
                                value='tvc-production'
                                checked={form.planByNotRegions === 'tvc-production'}
                                onChange={() =>
                                  this.onChangePlanBy(
                                    'tvc-production',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planByNotRegions_${title}_tvc-production`
                                }
                              >
                                <LocalizedMessage id='flowchart.month-budgets' />
                              </label>
                            </div>
                          </div>
                        </div>
                      </OnLoadWrapper>
                      <div className='col-lg-6' />
                    </div>
                  ) : null
                }

                {
                  mediaTypes[mediaType.id].meta[spendsType.name].fields.planByWithImpressions ? (
                    <div className='row form-group'>
                      <OnLoadWrapper onLoad={() => {
                        this.onChangePlanByWithImpressions(
                          form.planByWithImpressions || 'tvc-placement',
                          true
                        );
                      }}>
                        <div className='col-lg-6 planBy'>
                          <label className='col-lg-5 control-label planBy-label'>Plan by</label>

                          <div className='col-lg-7'>

                            <div className='planBy-item'>
                              <input
                                id={
                                  `planByWithImpressions_${title}_tvc-placement`
                                }
                                name={
                                  `planByWithImpressions_${title}`
                                }
                                type='radio'
                                value='tvc-placement'
                                checked={form.planByWithImpressions === 'tvc-placement'}
                                onChange={() =>
                                  this.onChangePlanByWithImpressions(
                                    'tvc-placement',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planByWithImpressions_${title}_tvc-placement`
                                }
                              >
                                <span>
                                  <LocalizedMessage
                                    id='flowchart.week-ratings'
                                  />
                                </span>
                              </label>
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className='planBy-item'>
                              <input
                                id={
                                  `planByWithImpressions_${title}_tvc-placement-impressions`
                                }
                                name={
                                  `planByWithImpressions_${title}`
                                }
                                type='radio'
                                value='tvc-placement-impressions'
                                checked={form.planByWithImpressions === 'tvc-placement-impressions'}
                                onChange={() =>
                                  this.onChangePlanByWithImpressions(
                                    'tvc-placement-impressions',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planByWithImpressions_${title}_tvc-placement-impressions`
                                }
                              >
                                <span>
                                  <LocalizedMessage
                                    id='flowchart.week-impressions'
                                  />
                                </span>
                              </label>
                              &nbsp;&nbsp;&nbsp;
                            </div>
                            <div className='planBy-item'>
                              <input
                                id={
                                  `planByWithImpressions_${title}_tvc-production`
                                }
                                name={
                                  `planByWithImpressions_${title}`
                                }
                                type='radio'
                                value='tvc-production'
                                checked={form.planByWithImpressions === 'tvc-production'}
                                onChange={() =>
                                  this.onChangePlanByWithImpressions(
                                    'tvc-production',
                                    false
                                  )
                                }
                                disabled={isDisabled}
                              />
                              &nbsp;
                              <label
                                htmlFor={
                                  `planByWithImpressions_${title}_tvc-production`
                                }
                              >
                                <span>
                                  <LocalizedMessage
                                    id='flowchart.month-budgets'
                                  />
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </OnLoadWrapper>
                      <div className='col-lg-6' />
                    </div>
                  ) : null
                }

                {
                  mediaTypes[mediaType.id].meta[spendsType.name].fields.descriptions ? (
                    <Input
                      type='c-select-creatable'
                      formName='descriptions'
                      labelId='flowchart.descriptions.label'
                      placeholderId='flowchart.descriptions.placeholder'
                      value={form.descriptions}
                      onChange={this.handleMediaTypeInputChange}
                      options={descriptions}
                      settings={{
                        isMulti: true,
                        onCreateOption: this.onCreateDescriptionOption,
                      }}
                      disabled={isDisabled}
                    />
                  ) : null
                }

                {
                  this.isShowEffectiveFrequencies(mediaType, spendsType) ? (
                    <Input
                      type='c-select'
                      formName='effectiveFrequencies'
                      labelId='flowchart.effective-frequencies.label'
                      placeholderId='flowchart.effective-frequencies.placeholder'
                      value={form.effectiveFrequencies}
                      onChange={this.handleMediaTypeInputChange}
                      options={optionsFrequencies}
                      settings={{
                        isMulti: true,
                        onInputChange: (...args) => this.onChangeFrequenciesInput(
                          form.effectiveFrequencies,
                          ...args
                        )
                      }}
                      disabled={isDisabled}
                    />
                  ) : null
                }

                {
                  mediaTypes[mediaType.id].meta[spendsType.name].fields.tvc ? (
                    <div>
                      <h5 className='flowCharts-tvc-title'>
                        <span><LocalizedMessage id='flowchart.tvc-name_copy-id' /></span>
                      </h5>

                      {
                        tvcList.map((tvc, tvcIndex) => (
                          <div className='row form-group' key={'tvc_list_item_' + tvcIndex}>
                            <div className='col-lg-4'>
                              <Select
                                value={tvc.type}
                                onChange={(value) => this.onChangeTvc(
                                  value,
                                  tvcIndex,
                                )}
                                placeholder={<LocalizedMessage id='flowchart.select-tvc' />}
                                options={optionsTvc}
                                isDisabled={isDisabled}
                              />
                            </div>
                            <div className='col-lg-3'>
                              <Select
                                isDisabled={
                                  !tvc.type ||
                                  !tvc.type.value ||
                                  !tvcTypes[tvc.type.value] ||
                                  !tvcTypes[tvc.type.value].length ||
                                  isDisabled
                                }
                                value={tvc.length}
                                onChange={(value) => this.onChangeTvcLength(
                                  value,
                                  tvcIndex,
                                )}
                                placeholder={<LocalizedMessage id='flowchart.select-tvc-length' />}
                                options={
                                  (tvc.type && tvc.type.value) ? (tvcTypes[tvc.type.value] || {}).length || [] : []
                                }
                                isMulti
                              />
                            </div>
                            {
                              (tvcIndex < tvcList.length - 1 && !isDisabled) ? (
                                <div className='col-lg-2 flowCharts-confirm-button-container'>
                                  <Tooltip
                                    placement='top'
                                    overlay={(
                                      <span><LocalizedMessage id='confirm' /></span>
                                    )}
                                  >
                                    <i
                                      className='fa fa-check inline-button-edit-active'
                                      onClick={() =>
                                        this.updateTVC(tvcIndex)
                                      }
                                    />
                                  </Tooltip>
                                  &nbsp;
                                  <Tooltip
                                    placement='top'
                                    overlay={(
                                      <span><LocalizedMessage id='delete' /></span>
                                    )}
                                  >
                                    <i
                                      className='fa fa-trash inline-button-delete-active'
                                      onClick={() =>
                                        this.deleteTVC(tvcIndex)
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              ) : null
                            }
                          </div>
                        ))
                      }

                      <div className='row form-group'>
                        <div className='col-lg-6'>
                          <button
                            type='button'
                            onClick={this.addTVC}
                            className='btn btn-success step-button btn-sm'
                            disabled={isDisabled}
                          >
                            <i className='fa fa-plus-circle' />
                            <span className='bold'><LocalizedMessage id='flowchart.add-tvc' /></span>
                          </button>
                        </div>
                        <div className='col-lg-6' />
                      </div>
                    </div>
                  ) : null
                }
              </div>
            )
          }

          {
            this.isShowTable(spendsType, mediaType.id, resultTvcList) ? (
              <TableGenerator
                category={category}
                campaign={campaign}
                mediaTypes={mediaTypes}
                mediaTypesMap={mediaTypesMap}
                mediaType={mediaType}
                spendsType={spendsType}
                cpp={cpp}
                year={year}
                targetAudience={form.targetAudience}
                frequencies={form.effectiveFrequencies}
                frequenciesList={effectiveFrequenciesList}
                tvcTypes={tvcTypes}
                tvcList={resultTvcList}
                descriptions={descriptions}
                descriptionsList={form.descriptions}
                regions={form.regionality}
                regionsList={regionsList}
                tableData={tableData}
                tableType={form.typeTable || mediaTypes[mediaType.id].meta[spendsType.name].typeTable}
                numberIo={mediaRecord.numberIO}
                updateSpendsType={this.updateSpendsTypeLocal}
                trackPromiseAll={trackPromiseAll}
                isDisabled={isDisabled}
              />
            ) : null
          }
        </div>
        {
          /*
          </LazyLoad>
          */
        }
      </div>
    );
  }
}

export default Media;
