import React from 'react';
import {connect} from 'react-redux';
import LocalizedMessage, {localizeMessage} from './LocalizedMessage';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  messages: state.locale.messages
});

export {localizeMessage};

export default process.env.NODE_ENV === 'test'
  ? props => <LocalizedMessage {...props} messages={locales['en']} />
  : connect(mapStateToProps, mapDispatchToProps)(LocalizedMessage);
