import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';

import CategoryListContent from './CategoryListContent';

const CategoryList = props => {
  const {
    isUsingCompanies,
    categories,
    campaigns
  } = props;

  return (
    <Fragment>
      {
        !isUsingCompanies ?
          (
            <Tabs defaultIndex={0} forceRenderTabPanel>
              <TabList>
                {
                  categories.map((category, categoryIndex) => (
                    <Tab
                      key={category.id + '_' + categoryIndex + '_tab'}
                    >
                      {category.name.toUpperCase()}
                    </Tab>
                  ))
                }
              </TabList>

              {
                categories.map((category, categoryIndex) => (
                  <TabPanel
                    key={category.id + '_' + categoryIndex + '_tab_panel'}
                  >
                    <CategoryListContent
                      categories={categories}
                      campaigns={campaigns}
                      isUsingCompanies={false}
                      category={category}
                      {...props}
                    />
                  </TabPanel>
                ))
              }
            </Tabs>
          )
          :
          (
            <Tabs defaultIndex={0} forceRenderTabPanel>
              <TabList>
                {
                  campaigns.map((campaign, campaignIndex) => (
                    <Tab
                      key={campaign.id + '_' + campaignIndex + '_tab'}
                    >
                      {campaign.name.toUpperCase()}
                    </Tab>
                  ))
                }
              </TabList>

              {
                campaigns.map((campaign, campaignIndex) => (
                  <TabPanel
                    key={campaign.id + '_' + campaignIndex + '_tab_panel'}
                  >
                    <Tabs defaultIndex={0} forceRenderTabPanel>
                      <TabList>
                        {
                          campaign.selectedCategories.map((category, categoryIndex) => (
                            <Tab
                              key={category.id + '_' + categoryIndex + '_tab'}
                            >
                              {category.name.toUpperCase()}
                            </Tab>
                          ))
                        }
                      </TabList>

                      {
                        campaign.selectedCategories.map((category, categoryIndex) => (
                          <TabPanel
                            key={category.id + '_' + categoryIndex + '_tab_panel'}
                          >
                            <CategoryListContent
                              categories={categories}
                              campaigns={campaigns}
                              isUsingCompanies={true}
                              category={category}
                              campaign={campaign}
                              {...props}
                            />
                          </TabPanel>
                        ))
                      }
                    </Tabs>
                  </TabPanel>
                ))
              }
            </Tabs>
          )
      }
    </Fragment>
  );
};

CategoryList.propTypes = {
  isUsingCompanies: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  year: PropTypes.object,
  numbersIo: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  targetAudiences: PropTypes.array.isRequired,
  mediaTypes: PropTypes.object.isRequired,
  mediaTypesMap: PropTypes.object.isRequired,
  spendsTypes: PropTypes.object.isRequired,
  effectiveFrequenciesList: PropTypes.array.isRequired,
  brand: PropTypes.object,
  descriptions: PropTypes.array.isRequired,
  tvcTypeList: PropTypes.array.isRequired,
  tvcTypes: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  addMedia: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  updateMediaTypes: PropTypes.func.isRequired,
  updateSpendsTypeForm: PropTypes.func.isRequired,
  updateSpendsTypeTvc: PropTypes.func.isRequired,
  updateSpendsType: PropTypes.func.isRequired,
  updateMediaData: PropTypes.func.isRequired,
  mediaData: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  onCreateDescriptionOption: PropTypes.func.isRequired,
  mediaOrderSorting: PropTypes.object.isRequired,
  trackPromiseAll: PropTypes.func.isRequired
};

export default CategoryList;
