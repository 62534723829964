import {fetchResponse} from './helpers/fakeFetch';

import cppList from './data/cppList.json';
import cppIntersectList from './data/cppIntersectList.json';

const cppAPI = {
  list (request) {
    console.log('api.cpp.list(', request, ')');

    return fetchResponse(1000, cppList);
  },

  intersect (request) {
    console.log('api.cpp.intersect(', request, ')');

    return fetchResponse(
      1000,
      cppIntersectList[request.mediaTypeId] &&
      cppIntersectList[request.mediaTypeId][request.targetAudienceId]
        ? cppIntersectList[request.mediaTypeId][request.targetAudienceId]
        : {}
    );
  },

  upload (year, data) {
    console.log('api.cpp.upload(' + year + ', ', data, ')');

    return fetchResponse(1000, {});
  }
};

export default cppAPI;
