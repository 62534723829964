import React, {Component, Fragment} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Checkbox} from 'react-ui-icheck';
import LocalizedMessage, {localizeMessage} from '../../../../components/LocalizedMessage';

import Select from '../../../../components/Select';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import API from '../../../../api';
import Alert from '../../../../helpers/alert';
import history from '../../../../history';

import classes from './User.module.scss';
import {Helmet} from 'react-helmet';

class User extends Component {
  static propTypes = {};

  state = {
    isEditMode: false,
    isPageLoaded: false,

    user: {
      role: {}
    },
    userDisabled: false,
    userEmail: '',
    userPassword: '',
    userFirstName: '',
    userLastName: '',
    userRole: '',
    userAgency: null,
    userAdvertisers: [],
    userBrands: []
  };

  mounted = false;

  _form = null;

  componentDidMount () {
    this.mounted = true;

    this.loadUser();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadUser () {
    const {
      match: {
        params
      },
      checkRoles
    } = this.props;

    const isSuperAdmin = checkRoles('SUPER_ADMIN');
    const isAdmin = checkRoles('ADMIN');

    const state = {};

    const id = params && params.id;
    const isEditMode = typeof id === 'string' && id.length;

    state.isEditMode = isEditMode;

    const promises = [
      API.users.getRoles(),
      isEditMode ? API.users.get(id) : Promise.resolve(null),
      API.advertisers.list({max: 0}),
      isSuperAdmin ? API.agencies.list({max: 0}) : Promise.resolve(null),
      isSuperAdmin || isAdmin ? API.brands.list({max: 0}) : Promise.resolve(null)
    ];

    try {
      const [roles, user, advertisers, agencies, brands] = await Promise.all(promises);

      if (!this.mounted) {
        return;
      }

      state.user = user || {role: {}};

      state.rolesOptions = roles.map(role => ({
        value: role.name,
        label: role.nameTranslation
      }));

      state.userRole = state.user.role.name || roles[0].name;
      state.userEmail = state.user.mail || '';
      state.userFirstName = state.user.firstName || '';
      state.userLastName = state.user.lastName || '';
      state.userDisabled = state.user.disabled;

      if (agencies) {
        state.agenciesOptions = agencies.items.map(agency => ({
          value: agency.id,
          label: agency.name
        }));

        state.userAgency = user ? state.agenciesOptions.find(agency => (
          agency.value === user.agencyId
        )) : null;

        await this.handleChangeAgencySelect(state.userAgency);
      }

      if (advertisers) {
        state.advertisersOptions = advertisers.items.map(e => ({
          value: e.id,
          label: e.name
        }));

        state.userAdvertisers =
          user
            ? user.advertiserIds.map(e => state.advertisersOptions.find(a => a.value === e))
            : [];
      }

      if (brands) {
        state.brandsOptions = brands.items.map(e => ({
          value: e.id,
          label: e.name
        }));

        state.userBrands =
          user
            ? user.brandIds.map(e => state.brandsOptions.find(a => a.value === e))
            : [];
      }

      state.isPageLoaded = true;

      this.setState(state);
    } catch (error) {
      console.error(error);
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='users' />,
        link: '/app/settings/users'
      },
      {
        title: <LocalizedMessage id='settings.user.user-detail' />
      }
    ];
  }

  setFormRef = (ref) => {
    this._form = ref;
  };

  handleChangeAgencySelect = (userAgency) => {
    this.setState({
      userAgency,
      advertisersOptions: []
    });

    if (!userAgency) {
      return Promise.resolve();
    }

    return new Promise(async resolve => {
      try {
        const advertisers = await API.advertisers.list({
          max: 0,
          agencyId: userAgency.value
        });

        if (!this.mounted) {
          return;
        }

        const advertisersOptions = advertisers.items.map(e => ({
          value: e.id,
          label: e.name
        }));

        this.setState({
          advertisersOptions
        }, resolve);
      } catch (error) {
        console.error(error);
      }
    });
  };

  getSelectAgencyConfig () {
    const {userAgency, agenciesOptions} = this.state;

    return {
      isSearchable: true,
      value: userAgency,
      onChange: this.handleChangeAgencySelect,
      options: agenciesOptions,
      placeholder: <LocalizedMessage id='settings.user.form.agency.placeholder' />
    };
  }

  handleChangeAdvertisersSelect = (userAdvertisers) => {
    this.setState({userAdvertisers});
  };

  getSelectAdvertisersConfig () {
    const {userAdvertisers, advertisersOptions} = this.state;

    return {
      isSearchable: true,
      isMulti: true,
      value: userAdvertisers,
      onChange: this.handleChangeAdvertisersSelect,
      options: advertisersOptions,
      placeholder: <LocalizedMessage id='settings.user.form.advertisers.placeholder' />
    };
  }

  handleChangeBrandsSelect = (userBrands) => {
    this.setState({userBrands});
  };

  getSelectBrandsConfig () {
    const {userBrands, brandsOptions} = this.state;

    return {
      isSearchable: true,
      isMulti: true,
      value: userBrands,
      onChange: this.handleChangeBrandsSelect,
      options: brandsOptions,
      placeholder: <LocalizedMessage id='settings.user.form.brands.placeholder' />
    };
  }

  handleInputChange = (e) => {
    const value = e.target.type === 'file'
      ? e.target.files[0]
      : e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value;

    this.setState({
      [e.target.name]: value
    });
  };

  resetClick = () => {
    this._form.reset();

    this.setState({
      newName: '',
      newExternalId: '',
      aimMacValue: null,
      categoryExtensionsValue: null,
      numberIoValue: null
    });
  };

  onSubmit = async (e) => {
    const {checkRoles} = this.props;

    const {
      user,
      userAdvertisers,
      userBrands,
      userAgency,
      userFirstName,
      userLastName,
      userEmail,
      userPassword,
      userRole
    } = this.state;

    e.preventDefault();

    if (!checkRoles('SUPER_ADMIN,ADMIN')) {
      return;
    }

    try {
      const requestData = {
        firstName: userFirstName,
        lastName: userLastName,
        mail: userEmail,
        password: userPassword,
        role: userRole
      };

      switch (userRole) {
        case 'CLIENT':
          requestData.brandIds = userBrands.map(b => b.value);

          break;
        case 'ADMIN':
          requestData.agencyId = userAgency ? userAgency.value : null;

          break;
        case 'PLANNER':
        case 'BUYER':
          requestData.advertiserIds = userAdvertisers.map(a => a.value);
          requestData.agencyId = userAgency ? userAgency.value : null;

          break;
        default:
          break;
      }

      await API.users.save(user.id, requestData);

      if (!user.id) {
        Alert.success(localizeMessage({id: 'settings.user.save-successfully'}, {email: userEmail}));

        history.push('/app/settings/users');
      } else {
        Alert.success(localizeMessage({id: 'settings.user.update-successfully'}, {email: userEmail}));
      }
    } catch (error) {
      console.error(error);

      Alert.error(error.message || localizeMessage({id: 'settings.user.saving-error'}));
    }
  };

  render () {
    const {checkRoles} = this.props;

    const {
      user,
      isPageLoaded,
      userDisabled,
      userEmail,
      userPassword,
      userFirstName,
      userLastName,
      userRole,
      advertisersOptions,
      rolesOptions
    } = this.state;

    if (!isPageLoaded) {
      return null;
    }

    const isSuperAdmin = checkRoles('SUPER_ADMIN');
    const isUserAdmin = userRole === 'ADMIN';
    const isUserPlannerOrBuyer = (
      userRole === 'PLANNER' ||
      userRole === 'BUYER'
    );
    const isUserClient = userRole === 'CLIENT';

    return (
      <Fragment>
        <LocalizedMessage
          id='site.title.settings.user'
        >
          {localizedMessage => (
            <Fragment>
              <Helmet
                title={(user.id ? `${userFirstName} ${userLastName} - ` : '') + localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </Fragment>
          )}
        </LocalizedMessage>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='wrapper wrapper-content'>
              <div className='ibox'>
                <div className='ibox-content'>
                  <form
                    ref={this.setFormRef}
                    onSubmit={this.onSubmit}
                  >
                    <div className='form-horizontal'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='m-b-md'>
                            <h2>User {user.mail}</h2>
                          </div>
                        </div>
                      </div>

                      {
                        user.id ? (
                          <Fragment>
                            <div className='form-group'>
                              <label className='col-lg-2 control-label'>
                                <LocalizedMessage id='settings.user.form.createdAt' />
                              </label>
                              <div className='col-lg-10'>
                                <div className='form-control-static'>
                                  {moment(user.createdAt).format('DD/MM/YY HH:mm')}
                                </div>
                              </div>
                            </div>

                            <div className='hr-line-dashed' />
                          </Fragment>
                        ) : null
                      }

                      {
                        user.id ? (
                          <Fragment>
                            <div className='form-group'>
                              <label className='col-lg-2 control-label'>
                                <LocalizedMessage id='settings.user.form.lastLogin' />
                              </label>
                              <div className='col-lg-10'>
                                <div className='form-control-static'>
                                  {user.lastLoginAt ? moment(user.lastLoginAt).format('DD/MM/YY HH:mm') : '-'}
                                </div>
                              </div>
                            </div>

                            <div className='hr-line-dashed' />
                          </Fragment>
                        ) : null
                      }

                      <div className='form-group'>
                        <label className='col-lg-2 control-label'>
                          <LocalizedMessage
                            id='settings.user.form.disabled'
                          />
                        </label>
                        <div className='col-lg-10'>
                          <label className='checkbox-inline'>
                            <Checkbox
                              checkboxClass='icheckbox_square-green'
                              name='userDisabled'
                              onChange={this.handleInputChange}
                              checked={userDisabled}
                            />
                          </label>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.email'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='email'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userEmail'
                                onChange={this.handleInputChange}
                                value={userEmail}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.password'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='password'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userPassword'
                                onChange={this.handleInputChange}
                                value={userPassword}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.firstName'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='text'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userFirstName'
                                onChange={this.handleInputChange}
                                value={userFirstName}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <LocalizedMessage
                        id='settings.user.form.lastName'
                      >
                        {localizedMessage => (
                          <div className='form-group'>
                            <label className='col-lg-2 control-label'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-10'>
                              <input
                                type='text'
                                placeholder={localizedMessage}
                                className='form-control'
                                name='userLastName'
                                onChange={this.handleInputChange}
                                value={userLastName}
                              />
                            </div>
                          </div>
                        )}
                      </LocalizedMessage>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <label className='col-lg-2 control-label'>
                          <LocalizedMessage
                            id='settings.user.form.role'
                          />
                        </label>
                        <div className='col-lg-10'>
                          {
                            rolesOptions.map((role, roleIndex) => (
                              <div
                                key={role.value + '_' + roleIndex}
                                className='i-checks'
                              >
                                <label className={classes.RoleLabel}>
                                  <input
                                    type='radio'
                                    name='userRole'
                                    checked={userRole === role.value}
                                    value={role.value}
                                    onChange={this.handleInputChange}
                                  />
                                  {' '}
                                  {role.label}
                                </label>
                              </div>
                            ))
                          }
                        </div>
                      </div>

                      {
                        isUserClient
                          ? (
                            <Fragment>
                              <div className='hr-line-dashed' />

                              <div className='form-group'>
                                <label className='col-lg-2 control-label' htmlFor='brandId'>
                                  <LocalizedMessage
                                    id='settings.user.form.brands'
                                  />
                                </label>

                                <div className='col-lg-10'>
                                  <Select {...this.getSelectBrandsConfig()} />
                                </div>
                              </div>
                            </Fragment>
                          )
                          : null
                      }

                      {
                        (
                          isSuperAdmin &&
                          (
                            isUserPlannerOrBuyer ||
                            isUserAdmin
                          )
                        )
                          ? (
                            <Fragment>
                              <div className='hr-line-dashed' />

                              <div className='form-group'>
                                <label className='col-lg-2 control-label' htmlFor='agencyId'>
                                  <LocalizedMessage
                                    id='settings.user.form.agency'
                                  />
                                </label>

                                <div className='col-lg-10'>
                                  <Select {...this.getSelectAgencyConfig()} />
                                </div>
                              </div>
                            </Fragment>
                          )
                          : null
                      }

                      {
                        (
                          isUserPlannerOrBuyer &&
                          advertisersOptions &&
                          advertisersOptions.length
                        )
                          ? (
                            <Fragment>
                              <div className='hr-line-dashed' />

                              <div className='form-group'>
                                <label className='col-lg-2 control-label' htmlFor='advertiserId'>
                                  <LocalizedMessage id='settings.user.form.advertisers' />
                                </label>

                                <div className='col-lg-10'>
                                  <Select {...this.getSelectAdvertisersConfig()} />
                                </div>
                              </div>
                            </Fragment>
                          )
                          : null
                      }

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <Link
                            to='/app/settings/users'
                            className='btn btn-white'
                          >
                            <span><LocalizedMessage id='cancel' /></span>
                          </Link>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={this.onSubmit}
                          >
                            <i className='fa fa-chevron-down fa-lg' />
                            <span><LocalizedMessage id='save' /></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default User;
