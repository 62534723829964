import {connect} from 'react-redux';
import {checkRoles} from '../../../modules/auth';
import Reports from '../components/Reports';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
