import Client from './HTTPClient';

const cppAPI = {
  list (request) {
    return Client.post('/api/cpp/', request);
  },

  intersect (request) {
    return Client.post('/api/cpp/intersect/', request, true, {}, true, null, {
      withoutLoader: true
    });
  },

  upload (year, data) {
    return Client.post(`/api/cpp/upload/${year}`, data, true, {isJson: false, isMultipart: true});
  }
};

export default cppAPI;
