import React, { Component } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../../components/URLFiltersHoc';
import Select from '../../../../components/Select';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import TableList from '../../../../components/TableList';

import Alert from '../../../../helpers/alert';
import API from '../../../../api';
import { IOption } from 'types';

interface IState {
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;

  yearValue: any;
  yearOptions: any[];
  dictionaryFile: any;
}

class Cbu extends Component<Props, IState> {
  years = {
    startYear: moment().add(1, 'year').year(),
    endYear: moment().add(-5, 'year').year()
  };

  state: IState = {
    dataList: [],
    dataListTotal: 1,
    isLoading: false,

    yearValue: null,
    dictionaryFile: null,
    yearOptions: this.getYearOptions()
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;
  _form = React.createRef<HTMLFormElement>();

  componentDidMount () {
    this.mounted = true;
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  getYearOptions () {
    const years: IOption[] = [];

    for (let year = this.years.startYear; year >= this.years.endYear; year--) {
      years.push({
        value: year,
        label: String(year)
      });
    }

    return years;
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.cbu.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='menu.dictionaries.cbu' />,
        link: '/app/dictionaries/cbu'
      }
    ];
  }

  setFormRef = (ref) => {
    this._form = ref;
  };

  handleChangeYearSelect = (yearValue) => {
    this.setState({ yearValue });
  };

  getSelectYearConfig () {
    const { yearValue, yearOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: yearValue,
      onChange: this.handleChangeYearSelect,
      options: yearOptions,
      placeholder: <LocalizedMessage id='cbu-dictionary.year.placeholder' />
    };
  }

  handleInputChange = (e) => {
    const value = e.target.files[0];

    this.setState({
      dictionaryFile: value
    });
  };

  resetClick = () => {
    if (this._form.current) {
      this._form.current.reset();
    }

    this.setState({
      yearValue: null
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      yearValue,
      dictionaryFile
    } = this.state;

    if (
      (!yearValue || !yearValue.value) ||
      (!dictionaryFile)
    ) {
      return;
    }

    const formData = new FormData();
    formData.append('file', dictionaryFile);

    try {
      await API.cbu.upload(yearValue.value, formData);

      Alert.success(localizeMessage({ id: 'cbu-dictionary.successfullyUploaded' }));

      this.resetClick();

      this.refreshList();
    } catch (error) {
      console.error(error);

      Alert.warn(localizeMessage({ id: 'cbu-dictionary.errorUploading' }));
    }
  };

  render () {
    const {
      onUpdateSort, onUpdatePage, filters,
    } = this.props;
    const {
      yearValue,
      dictionaryFile,
      dataList,
      dataListTotal,
      isLoading
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.cbu'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <form
                    ref={this.setFormRef}
                    onSubmit={this.onSubmit}
                  >
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label className='col-lg-2 control-label' htmlFor='year'><LocalizedMessage id='year' /></label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectYearConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label className='col-lg-2 control-label'>
                          <LocalizedMessage
                            id='cbu-dictionary.xlsx.label'
                          />
                        </label>
                        <div className='col-lg-10'>
                          <input
                            type='file'
                            className='form-control'
                            name='dictionaryFile'
                            onChange={this.handleInputChange}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          />
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <button
                            className='btn btn-white'
                            onClick={this.resetClick}
                          >
                            <span><LocalizedMessage id='reset' /></span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={this.onSubmit}
                            disabled={!yearValue || !dictionaryFile}
                          >
                            <>
                              <i className='fa fa-chevron-down fa-lg' />
                              <span><LocalizedMessage id='save' /></span>
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: localizeMessage({ id: 'id' }),
                          sort: 'id'
                        },
                        {
                          label: localizeMessage({ id: 'year' }),
                          sort: 'year_number'
                        },
                        {
                          label: localizeMessage({ id: 'frequency' })
                        },
                        {
                          label: localizeMessage({ id: 'rating' })
                        },
                        {
                          label: localizeMessage({ id: 'targetAudience' })
                        },
                        {
                          label: localizeMessage({ id: 'cbu_value' }),
                          sort: 'cbu_value'
                        }
                      ]}
                    >
                      {
                        dataList.map((row, rowIndex) => (
                          <tr key={rowIndex + '_cbu'}>
                            <td>{row.id}</td>
                            <td>{row.yearNumber}</td>
                            <td>{row.frequency.frequencyValue}</td>
                            <td>{row.rating.value}</td>
                            <td>{row.targetAudience.name}</td>
                            <td>{row.cbuValue}</td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Cbu, 'cbu_value');
