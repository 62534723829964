import clone from 'lodash/clone';
import find from 'lodash/find';
import each from 'lodash/each';
import moment from 'moment';
import {getMonthShortNames} from '../../../helpers/utils';
import API from '../../../api';

export const budgetting = async (flowcharts, toExport = false) => {
  const resultTable = [];
  let cacheIOs = new Map();

  if (toExport) {
    resultTable.push([
      'Brand4',
      'Brand1',
      'Brand2',
      'Brand3',
      'Spend1',
      'Spend2',
      'Spend3',
      'Description',
      'Period',
      'Country',
      'Comment',
      'Sum',
      'Internal_Order',
      'Customer',
      'Campaign',
      'Campaign internal',
      'MediaType',
      'Brand'
    ]);
  }

  for (const flowchart of flowcharts) {
    let dataJson = {};
    let categoryExtensions = [];

    try {
      const response = await API.flowcharts.get(flowchart.id);
      dataJson = JSON.parse(response.dataJson);
      categoryExtensions = response.categoryExtensions;
    } catch (e) {
      return;
    }

    const isUsingCompanies = dataJson.campaign.isUsingCompanies;

    const brand = flowchart.brand;
    const division = flowchart.division;
    const year = flowchart.year;

    const Brand1 = division.name;

    const Spend1 = 'Advertising Consumer';
    const Spend2 = 'Asset deployment (paid)';
    const Country = 'RU';
    const Comment = '';

    for (const campaignId in dataJson.media) {
      if (dataJson.media.hasOwnProperty(campaignId)) {
        const companies = dataJson.media[campaignId];

        const campaign = isUsingCompanies
          ? find(dataJson.campaign.optionsCampaigns, (option) => option.value === campaignId)
          : null;

        const CampaignName = campaign ? campaign.label : '-';

        for (const categoryExtensionId in companies) {
          if (companies.hasOwnProperty(categoryExtensionId)) {
            const categories = companies[categoryExtensionId];

            const categoryExtension = find(categoryExtensions, categoryExtension => (
              (+categoryExtension.id) === (+categoryExtensionId)
            ));

            if (!categoryExtension) {
              return;
            }

            const Brand2 = categoryExtension.name;
            const Customer = 'W/o clients type';

            for (const media of categories) {
              const internalOrder = media.numberIO.value;

              if (
                internalOrder &&
                media.numberIO.instance &&
                (
                  !media.numberIO.instance.brand3 ||
                  !media.numberIO.instance.brand4
                )
              ) {
                media.numberIO.instance = await getIO(media.numberIO.instance.id, cacheIOs);
              }

              const Brand4 = media.numberIO.instance?.brand4;
              const Brand3 = media.numberIO.instance?.brand3;

              each(media.mediaTypes, (mediaType) => {
                const Spend3 = mediaType.spend3;

                const DescriptionArray = [];

                if (
                  typeof CampaignName === 'string' &&
                  CampaignName.length &&
                  CampaignName !== '-'
                ) {
                  DescriptionArray.push(CampaignName);
                }

                if (
                  typeof mediaType.bigMedia === 'string' &&
                  mediaType.bigMedia.length
                ) {
                  DescriptionArray.push(mediaType.bigMedia);
                }

                if (
                  typeof mediaType.name === 'string' &&
                  mediaType.name.length
                ) {
                  DescriptionArray.push(mediaType.name);
                }

                const Description = DescriptionArray.join(' - ');

                each(mediaType.spendsTypes, (spendsType) => {
                  if (!spendsType.tableData) {
                    return;
                  }

                  const MediaTypeName = mediaType.name;

                  if (
                    Array.isArray(spendsType.tableData) ||
                    !spendsType.tableData.budgets ||
                    !spendsType.tableData.table
                  ) {
                    const tableData = clone(spendsType.tableData);
                    spendsType.tableData = {
                      table: tableData,
                      budgets: spendsType.tableData.budgets || [[]]
                    };
                  }

                  const isPlacementBudgets = Array.isArray(spendsType.tableData.budgets[1]);

                  const budgetsOfMonth = isPlacementBudgets
                    ? spendsType.tableData.budgets.reduce((acc, arr) => arr.map((b, i) => (acc[i] || 0) + b), [])
                    : spendsType.tableData.budgets[0];

                  each(budgetsOfMonth, (budget, monthIndex) => {
                    const _monthIndex = (monthIndex + 1) <= 9 ? '0' + (monthIndex + 1) : (monthIndex + 1);
                    const Period = year.toString() + _monthIndex;

                    const CampaignFullName = `${Brand4 || ''}${Spend3 || ''}${Description || ''}${Period || ''}`;

                    const Sum = budget;

                    if (
                      !Sum ||
                      isNaN(Sum)
                    ) {
                      return;
                    }

                    resultTable.push([
                      Brand4,
                      Brand1,
                      Brand2,
                      Brand3,
                      Spend1,
                      Spend2,
                      Spend3,
                      Description,
                      Period,
                      Country,
                      Comment,
                      Sum,
                      internalOrder,
                      Customer,
                      CampaignFullName,
                      CampaignName,
                      MediaTypeName,
                      brand.name
                    ]);
                  });
                });
              });
            }
          }
        }
      }
    }
  }

  return resultTable;
};

const getDaysInWeek = (month, weekType) =>
  weekType === 'first'
    ? 8 - (moment(month).startOf('month').day() || 7)
    : weekType === 'last'
      ? moment(month).endOf('month').day() || 7
      : 7;

const calcWeekValue = (month, weekType, value = 0) =>
  value / month.daysInMonth() * getDaysInWeek(month, weekType);

const getIO = async (id, cache) => {
  try {
    if (!cache.has(id)) {
      const response = await API.io.get(id);
      cache.set(id, response);
    }

    return cache.get(id);
  } catch (e) {
    console.error(e);
  }
};

export const budgetSummary = async (flowcharts, toExport = false) => {
  const resultTable = [];

  if (toExport) {
    resultTable.push([
      'Brand',
      'Country',
      'Division',
      'Date',
      'Status',
      'Campaign',
      'Category (extension)',
      'Spend Type',
      'Target Audience',
      'Year',
      'Half year',
      'Quarter',
      'Month',
      'Week',
      'Big Media',
      'Media Type',
      'Description',
      'TVC duration',
      'Active week',
      'Budget RUR',
      'Budget EUR',
      'GRPs',
      'Adj.GRP'
    ]);
  }

  const monthNames = getMonthShortNames();

  const currenciesCache = {};

  for (const flowchart of flowcharts) {
    let dataJson = {};
    let categoryExtensions = [];
    const Year = flowchart.year;

    try {
      const response = await API.flowcharts.get(flowchart.id);
      dataJson = JSON.parse(response.dataJson);
      categoryExtensions = response.categoryExtensions;
    } catch (e) {
      return;
    }

    const isUsingCompanies = dataJson.campaign.isUsingCompanies;

    const brand = flowchart.brand;
    const division = flowchart.division;
    const country = flowchart.country;

    if (!currenciesCache[flowchart.year]) {
      const currencyResponse = await API.currencies.get(flowchart.year);
      currenciesCache[flowchart.year] = +currencyResponse.eur;
    }

    const currency = currenciesCache[flowchart.year];

    const Brand = brand.name;
    const Country = country && country['nameEn'] ? country['nameEn'] : '';
    const Division = division.name;
    const ChangesDate = flowchart.dataChanges;
    const Status = flowchart.status && flowchart.status.name ? flowchart.status.name : '';

    each(dataJson.media, (companies, campaignId) => {
      const campaign = !isUsingCompanies
        ? null
        : find(dataJson.campaign.optionsCampaigns, (option) => option.value === campaignId);

      const Campaign = campaign ? campaign.label : '-';

      each(companies, (categories, categoryExtensionId) => {
        const categoryExtension = find(categoryExtensions, categoryExtension => (
          (+categoryExtension.id) === (+categoryExtensionId)
        ));

        if (!categoryExtension) {
          return;
        }

        const CategoryExtension = categoryExtension.name;

        each(categories, media => {
          each(media.mediaTypes, mediaType => {
            const MediaType = mediaType.name;
            const BigMedia = mediaType.bigMedia || '';

            each(mediaType.spendsTypes, (spendsType) => {
              if (!spendsType.tableData) {
                return;
              }

              const SpendType = spendsType.name;

              const TargetAudience =
                spendsType.form.targetAudience &&
                spendsType.form.targetAudience.label
                  ? spendsType.form.targetAudience.label
                  : '';

              if (Array.isArray(spendsType.tableData) || !spendsType.tableData.budgets || !spendsType.tableData.table) {
                const tableData = clone(spendsType.tableData);
                spendsType.tableData = {
                  table: tableData,
                  budgets: spendsType.tableData.budgets || [[]]
                };
              }

              each(spendsType.tableData.table, tableData => {
                const Description = tableData.name;
                const isTvc = typeof tableData.tvcUuid === 'string';

                each(tableData.lens, len => {
                  const TvcDuration = isTvc ? len.name : '';

                  each(tableData.months, (month, monthIndex) => {
                    const Month = toExport
                      ? monthNames[monthIndex]
                      : new Date().setFullYear(Year, monthIndex, 1);
                    const Quarter = moment().month(monthIndex).quarter();
                    const HalfYear = Quarter > 2 ? 2 : 1;

                    const lenMonth = len.months[monthIndex];
                    const currentMonth = moment([Year, monthIndex]);

                    each(lenMonth.weeks, (week, weekIndex) => {
                      const isFirstWeek = weekIndex === 0;
                      const isLastWeek = weekIndex === lenMonth.weeks.length - 1;
                      const weekType = isFirstWeek
                        ? 'first'
                        : isLastWeek
                          ? 'last'
                          : 'middle';

                      const calcActiveWeek = (isMonthBudgets) => {
                        const value = isMonthBudgets ? lenMonth.value : week.value;

                        if (!value) {
                          return 0;
                        }

                        const numDaysOfWeek = getDaysInWeek(currentMonth, weekType);

                        if (numDaysOfWeek !== 7) {
                          const isFirstWeekOfYear = isFirstWeek && monthIndex === 0;
                          const isLastWeekOfYear = isLastWeek && monthIndex === 11;

                          if (isFirstWeekOfYear || isLastWeekOfYear) {
                            return 1;
                          }

                          const currentValue = isMonthBudgets
                            ? calcWeekValue(currentMonth, weekType, value)
                            : value;

                          if (isFirstWeek) {
                            const prevMonth = moment([Year, monthIndex - 1]);
                            const lenPrevMonth = len.months[monthIndex - 1];
                            const prevValue = isMonthBudgets
                              ? calcWeekValue(prevMonth, 'last', lenPrevMonth.value)
                              : lenPrevMonth.weeks[lenPrevMonth.weeks.length - 1].value || 0;

                            if (currentValue > prevValue) {
                              return 1;
                            }
                            if (currentValue < prevValue) {
                              return 0;
                            }
                          }

                          if (isLastWeek) {
                            const nextMonth = moment([Year, monthIndex + 1]);
                            const lenNextMonth = len.months[monthIndex + 1];
                            const nextValue = isMonthBudgets
                              ? calcWeekValue(nextMonth, 'first', lenNextMonth.value)
                              : lenNextMonth.weeks[0].value || 0;

                            if (currentValue > nextValue) {
                              return 1;
                            }
                            if (currentValue < nextValue) {
                              return 0;
                            }
                          }

                          return numDaysOfWeek < 4 ? 0 : 1;
                        }

                        return 1;
                      };

                      const hasSum = typeof lenMonth.sum !== 'undefined' && lenMonth.sum !== null;
                      const hasSum30 = typeof lenMonth.sum30 !== 'undefined';
                      const weekDate = moment(currentMonth).add(7 * weekIndex, 'days');
                      const lastDayOfYear = moment(String(Year)).endOf('year');

                      const Week = weekDate.isAfter(lastDayOfYear)
                        ? weekDate.subtract(7, 'days').isoWeeks() + 1
                        : weekDate.isoWeeks();

                      const Budget = hasSum
                        ? lenMonth.sum !== 0 ? (week.value || 0) / lenMonth.sum * (lenMonth.value || 0) : 0
                        : calcWeekValue(currentMonth, weekType, lenMonth.value);

                      const IsActiveWeek = calcActiveWeek(!hasSum);

                      const oldGRPs = // оставил для старых флоучартов
                        month.results &&
                        month.results.total &&
                        month.results.total.value
                          ? month.results.total.value
                          : 0;
                      const oldGRPs30 = // оставил для старых флоучартов
                        month.results &&
                        month.results.total30 &&
                        month.results.total30.value
                          ? month.results.total30.value
                          : 0;

                      const GRPs = hasSum
                        ? calcWeekValue(currentMonth, weekType, lenMonth.sum)
                        : calcWeekValue(currentMonth, weekType, oldGRPs);

                      const GRPs30 = hasSum30
                        ? calcWeekValue(currentMonth, weekType, lenMonth.sum30)
                        : calcWeekValue(currentMonth, weekType, oldGRPs30);

                      resultTable.push([
                        Brand,
                        Country,
                        Division,
                        ChangesDate,
                        Status,
                        Campaign,
                        CategoryExtension,
                        SpendType,
                        TargetAudience,
                        ...(toExport ?
                          [Year, HalfYear, Quarter] :
                          [Year]
                        ),
                        Month,
                        Week,
                        BigMedia,
                        MediaType,
                        Description,
                        TvcDuration,
                        toExport ? IsActiveWeek : {
                          IsActiveWeek,
                          Month,
                          Week
                        },
                        Budget,
                        Budget * currency,
                        GRPs,
                        GRPs30
                      ]);
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  return resultTable;
};

export default {
  budgetting,
  budgetSummary
};
