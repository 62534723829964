import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import sum from 'lodash/sum';
import debounce from 'lodash/debounce';

import LocalizeMessage from '../../../../components/LocalizedMessage';
import RoundedInput from '../../../../components/RoundedInput';
import { MediaType, BigMediaType, SpendsType, Media, MediaTypeData } from '../../../../types/media';
import { DataCollection, TableResult } from '../../../../types/tableData';
import classes from './TableBudgetsAndReach.module.scss';

type SummaryType = 'National TV' | 'Regional TV' | 'Cable TV' | 'Smart TV' | 'TV SP' | 'OLV' | 'Banners'
  | 'Other Digital' | 'Total Digital' | 'OOH' | 'Print' | 'Radio' | 'Production, tracking, comission' | 'Total';

type MapMediaToReach = Partial<Record<SummaryType, number>>;
type MapMediaToBudget = Record<SummaryType, number>;

type IProps = {
  summaryBudgetsAndReachs: {
    mapCampaignToReachData: IState['mapCampaignToReachData'];
    mapCampaignToBudgetData: Record<string, MapMediaToBudget | undefined>
  }
  media: Media;
  data: DataCollection;
  isUsingCompanies: boolean;
  isDisabled: boolean;
};

interface IState {
  mapCampaignToReachData: Record<string, MapMediaToReach | undefined> // key = campaign name
  mapCampaignToIsCalculatedReach: Record<string, boolean>;  // key = campaign name
}

const nationalMediaTypes: MediaType[] = ['National TV', 'Affinitive TV', 'Orbita TV'];
const tvMediaTypes: MediaType[] = ['TV SP commit', 'TV SP flex'];
const olvMediaTypes: MediaType[] = ['OLV commit', 'OLV flex'];
const bannersMediaTypes: MediaType[] = ['Banners commit', 'Banners flex'];
const otherDigitalMediaTypes: MediaType[] = [
  'SMM commit', 'SMM flex', 'Digital SP commit', 'Digital SP flex', 'Bloggers commit', 'Bloggers flex',
  'Other digital commit', 'Other digital flex', 'E-com', 'Context'
];
const rows: SummaryType[] = [
  'National TV', 'Regional TV', 'Cable TV', 'Smart TV', 'TV SP', 'OLV', 'Banners',
  'Other Digital', 'Total Digital', 'OOH', 'Print', 'Radio', 'Production, tracking, comission', 'Total'
];

const isIncludeMediaType = (mediaType: MediaTypeData, types: MediaType[]) => types.includes(mediaType.name);
const isEqualMediaType = (mediaType: MediaTypeData, type: MediaType) => type === mediaType.name;
const isEqualBigMedia = (mediaType: MediaTypeData, type: BigMediaType) => type === mediaType.bigMedia;

const isNoPlacement = (s: SpendsType) => s.name !== 'Placement';
const isPlacement = (s: SpendsType) => s.name === 'Placement';

const getCrossReach = (vals: number[]) => { // значения в процентах
  return (1 - vals.reduce((acc, val) => (acc * (1 - val / 100)), 1)) * 100;
};

class TableBudgetsAndReach extends Component<IProps> {
  debouncedRecalculate = debounce(() => this.forceUpdate(), 500);
  state: IState = {
    mapCampaignToReachData: this.props.summaryBudgetsAndReachs.mapCampaignToReachData || {},
    mapCampaignToIsCalculatedReach: {},
  };
  mapCampaignToBudgetData: Record<string, MapMediaToBudget> = {};

  static getDerivedStateFromProps (props: IProps, state: IState) {
    const { media, data } = props;
    const names = Object.keys(media);
    const newReachs = names.map(name => {
      const allHashes = flattenDeep(Object.values(media[name]).map(
        ios => Object.values(ios).map(
          io => Object.values(io.mediaTypes)
            .filter(mediaType => nationalMediaTypes.includes(mediaType.name))
            .map(mediaType => Object.values(mediaType.spendsTypes)
              .filter(MediaTypeData => MediaTypeData.name === 'Placement')
              .map(MediaTypeData => MediaTypeData.mediaDataHash)
            )
        )
      ));
      const results: (TableResult | undefined)[] = flatten(allHashes.map(hash =>
        (data[hash] && data[hash].table && data[hash].table.map((tableData) => tableData.results))
      ));

      const reachs = results.map((result: any) => (result && result.reach && result.reach1));
      const isCalculated = reachs.length && reachs.every(reach => reach !== undefined);
      const calculated = isCalculated ? getCrossReach(reachs) : 0;

      return [isCalculated, calculated];
    });

    return names.reduce((acc, name, index) => {
      const prevIsCalculated = state.mapCampaignToIsCalculatedReach[name];
      const nextIsCalculated = newReachs[index][0];
      let updatedState = acc;

      if (nextIsCalculated) { // !prevIsCalculated && nextIsCalculated || prevIsCalculated && nextIsCalculated
        updatedState = {
          ...acc,
          mapCampaignToIsCalculatedReach: { ...acc.mapCampaignToIsCalculatedReach, [name]: true },
          mapCampaignToReachData: {
            ...acc.mapCampaignToReachData,
            [name]: {
              ...(acc.mapCampaignToReachData[name] || {}),
              'National TV': (newReachs[index][1] as number),
            }
          }
        };
      }

      if (prevIsCalculated && !nextIsCalculated) {
        updatedState = {
          ...acc,
          mapCampaignToIsCalculatedReach: { ...acc.mapCampaignToIsCalculatedReach, [name]: false },
          mapCampaignToReachData: {
            ...acc.mapCampaignToReachData,
            [name]: {
              ...(acc.mapCampaignToReachData[name] || {}),
              'National TV': 0
            }
          }
        };
      }
      const totalDigitalReach = (() => {
        const reachs = ['OLV', 'Banners', 'Other Digital']
          .map(type =>
            (updatedState.mapCampaignToReachData[name] && updatedState.mapCampaignToReachData[name]![type]) || 0);

        return getCrossReach(reachs as number[]);
      })();

      const totalReach = (() => {
        const reachs = Object.keys(updatedState.mapCampaignToReachData[name] || {})
          .filter(key => !['Total Digital', 'Total'].includes(key))
          .map(type => updatedState.mapCampaignToReachData[name] && updatedState.mapCampaignToReachData[name]![type]);

        return reachs.some(n => n === undefined)
          ? null : getCrossReach(reachs as number[]);
      })();

      return {
        ...updatedState,
        mapCampaignToReachData: {
          ...updatedState.mapCampaignToReachData,
          [name]: {
            ...(updatedState.mapCampaignToReachData[name] || {}),
            'Total Digital': totalDigitalReach,
            'Total': totalReach,
          }
        }
      };
    }, state);
  }

  handleReachInputChange = (e: React.ChangeEvent<HTMLInputElement>, campaignName: string, media: string) => {
    const { value } = e.target;
    const num = Number(value);

    if (num < 0 || num > 100) {
      return;
    }
    this.setState((prevState: IState) => ({
      ...prevState,
      mapCampaignToReachData: {
        ...prevState.mapCampaignToReachData,
        [campaignName]: {
          ...(prevState.mapCampaignToReachData[campaignName] || {}),
          [media]: num
        }
      }
    }));
  };

  onUpdateBudgets = () => {
    this.debouncedRecalculate();
  }

  getBudget = <T extends MediaType | MediaType[] | BigMediaType>(
    campaignName: string,
    mediaT: T,
    filterMediaType: (m: MediaTypeData, t: T) => boolean,
    filterSpendsType: (s: SpendsType) => boolean = isPlacement,
  ): number => {
    const { media, data } = this.props;
    const allHashes = flattenDeep(Object.values(media[campaignName]).map(
      ios => Object.values(ios).map(
        io => Object.values(io.mediaTypes)
          .filter(mediaType => filterMediaType(mediaType, mediaT))
          .map(mediaType => Object.values(mediaType.spendsTypes)
            .filter(filterSpendsType)
            .map(MediaTypeData => MediaTypeData.mediaDataHash)
          )
      )
    ));
    const results: (TableResult | undefined)[] = flatten(allHashes.map(hash =>
      (data[hash] && data[hash].table && data[hash].table.map((tableData) => tableData.results))
    ));

    return sum(results.map(result => (result && result.budget) || 0));
  }

  updateBudgets = () => {
    const { media, summaryBudgetsAndReachs } = this.props;
    this.mapCampaignToBudgetData = {};
    Object.keys(media).forEach(name => {
      const mapMediaToBudget: MapMediaToBudget = {
        'National TV': this.getBudget(name, nationalMediaTypes, isIncludeMediaType),
        'Regional TV': this.getBudget(name, 'Regional TV', isEqualMediaType),
        'Cable TV': this.getBudget(name, 'Cable TV', isEqualMediaType),
        'Smart TV': this.getBudget(name, 'Smart TV', isEqualMediaType),
        'TV SP': this.getBudget(name, tvMediaTypes, isIncludeMediaType),
        'OLV': this.getBudget(name, olvMediaTypes, isIncludeMediaType),
        'Banners': this.getBudget(name, bannersMediaTypes, isIncludeMediaType),
        'Other Digital': this.getBudget(name, otherDigitalMediaTypes, isIncludeMediaType),
        'Total Digital': this.getBudget(name, 'Digital', isEqualBigMedia),
        'OOH': this.getBudget(name, 'OOH', isEqualBigMedia),
        'Print': this.getBudget(name, 'Print', isEqualBigMedia),
        'Radio': this.getBudget(name, 'Radio', isEqualBigMedia),
        'Production, tracking, comission': this.getBudget(name, 'any' as any, () => true, isNoPlacement),
        'Total': this.getBudget(name, 'any' as any, () => true, () => true),
      };
      this.mapCampaignToBudgetData[name] = mapMediaToBudget;
      summaryBudgetsAndReachs.mapCampaignToBudgetData = this.mapCampaignToBudgetData;
    });
  };

  updateReachs = () => {
    const { summaryBudgetsAndReachs } = this.props;
    const { mapCampaignToReachData } = this.state;
    summaryBudgetsAndReachs.mapCampaignToReachData = mapCampaignToReachData;
  }

  renderRow = (title: SummaryType, index: number) => (
    <tr key={index}>
      <td>{title}</td>
      {Object.keys(this.props.media).map((name, index) => {
        const { mapCampaignToReachData, mapCampaignToIsCalculatedReach } = this.state;
        const budgetSum = Math.round(this.mapCampaignToBudgetData[name][title]);
        const reachValue = (mapCampaignToReachData[name] && mapCampaignToReachData[name]![title]) || 0;
        const isShowInput = title === 'National TV'
          ? !mapCampaignToIsCalculatedReach[name]
          : !['Total', 'Total Digital'].includes(title);
        const isShowReach = title !== 'Production, tracking, comission';

        return (
          <Fragment key={index}>
            <td className='input-cell'>
              {budgetSum}
            </td>
            <td>
              <div>
                {isShowReach
                  ? (isShowInput
                    ? (
                      <RoundedInput
                        type='number'
                        step='any'
                        onChange={(e) => this.handleReachInputChange(
                          e, name, title
                        )}
                        value={reachValue || ''}
                        className={cx(
                          'form-control',
                          'flowCharts-media-months-table-field',
                          'flowCharts-media-months-table-field--full-width',
                        )}
                        disabled={this.props.isDisabled}
                      />
                    )
                    : (Math.round(reachValue))
                  )
                  : '-'
                }
              </div>
            </td>
          </Fragment>
        );
      })}
    </tr>
  );

  render () {
    const { media, isUsingCompanies } = this.props;
    this.updateBudgets();
    this.updateReachs();

    return (
      <div className='ibox'>
        <div className='ibox-title'>
          <h5><LocalizeMessage id='flowchart.budgetAndReachTable' /></h5>
        </div>
        <div className='ibox-content'>
          <table className='table flowCharts-media-months-table'>
            <thead>
              {isUsingCompanies && (
                <tr>
                  <th></th>
                  {Object.keys(media).map((name, i) => (
                    <th colSpan={2} key={i}>{name}</th>
                  ))}
                </tr>
              )}
              <tr>
                <th className={classes.TableCell}>Media</th>
                {Object.keys(media).map((name, i) => (
                  <Fragment key={i}>
                    <th className={classes.TableCell}>Budget</th>
                    <th className={classes.TableCell}>Reach 1+</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map(this.renderRow)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TableBudgetsAndReach;
