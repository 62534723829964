import React, { PureComponent } from 'react';
import { RouteChildrenProps } from 'react-router';
import queryString from 'query-string';

interface IFilters {
  items: string[];
  currentPage: number;
  currentSort: string;
  max: number,
}

export interface IInjectedProps {
  filters: IFilters;
  onUpdateFilters: (newFilters: IFilters) => void;
  onUpdateSort: (newSortName: string) => void;
  onUpdatePage: (newPage: number) => void;
  onChangeFilterItems: (newItems: string[]) => void;
}

export type Props = IInjectedProps & RouteChildrenProps;

const URLFiltersHoc = (Component: React.ComponentType<Props>, defaultSort = 'name') => {
  const parseURLFilters = ({ location: { search } }: RouteChildrenProps): IFilters => {
    const query = queryString.parse(search);

    return {
      items: queryString.parse(search, {
        arrayFormat: 'bracket',
      }).filters as string[] || [],
      currentPage: parseInt((query.page as string) || '1', 10) || 1,
      currentSort: (query.sort as string) || defaultSort,
      max: 10,
    };
  };

  class WrappedComponent extends PureComponent<RouteChildrenProps, IFilters> {
    filters = parseURLFilters(this.props);
    prevSearchValue = this.props.location.search;

    onUpdateURLFilters = ({ currentPage, currentSort, items }: IFilters) => {
      const { history, location } = this.props;
      history.push({
        pathname: location.pathname,
        search: `?${queryString.stringify({
          ...(currentPage > 1 ? { page: currentPage } : null),
          ...(currentSort !== defaultSort ? { sort: currentSort } : null),
          ...(items.length ? { filters: items } : null),
        }, {
          arrayFormat: 'bracket',
        })}`,
      });
    };

    onChangeFilterItems = (filterItems) => {
      const newFilters = {
        ...this.filters,
        items: filterItems,
        currentPage: 1,
      };
      this.onUpdateURLFilters(newFilters);
    };

    onUpdateSort = (currentSort) => {
      const newFilters = {
        ...this.filters,
        currentSort,
        currentPage: 1,
      };

      this.onUpdateURLFilters(newFilters);
    };

    onUpdatePage = (currentPage) => {
      const newFilters = {
        ...this.filters,
        currentPage,
      };
      this.onUpdateURLFilters(newFilters);
    };

    render () {
      const { location } = this.props;

      if (this.prevSearchValue !== location.search) {
        this.prevSearchValue = location.search;
        this.filters = parseURLFilters(this.props);
      }

      return (
        <Component
          {...this.props}
          filters={this.filters}
          onUpdateFilters={this.onUpdateURLFilters}
          onUpdateSort={this.onUpdateSort}
          onUpdatePage={this.onUpdatePage}
          onChangeFilterItems={this.onChangeFilterItems}
        />
      );
    }
  }

  return WrappedComponent;
};

export default URLFiltersHoc;
