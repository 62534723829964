import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import './ScrollTopButton.scss';

const ScrollTopButton = () => {
  const mainContainer = document.getElementById('main_content');

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);

    return () => {
      document.removeEventListener('scroll', onScroll, true);
    };
  });

  const onScroll = (e) => {
    if (e.target !== mainContainer) return;

    setIsVisible(e.target.scrollTop > 100);
  };

  const scrollToTop = () => {
    mainContainer.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cx('scroll-top-button', 'fa', 'fa-arrow-circle-o-up')}
      onClick={scrollToTop}
    />
  );
};

export default ScrollTopButton;
