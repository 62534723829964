import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class LangSwitcher extends Component {
  static propTypes = {
    lang: PropTypes.oneOf(['ru', 'en']).isRequired,
    selectLocale: PropTypes.func.isRequired
  };

  render () {
    const {lang, selectLocale} = this.props;

    return (
      <Fragment>
        {
          ['ru', 'en'].map(cLocale => (
            <span key={cLocale}>
              {' '}
              <button
                className={cx(
                  'btn btn-circle',
                  lang === cLocale ? 'btn-primary' : 'btn-default'
                )}
                onClick={() => selectLocale(cLocale)}
              >
                {cLocale.toUpperCase()}
              </button>
              {' '}
            </span>
          ))
        }
      </Fragment>
    );
  }
}

export default LangSwitcher;
