import Client from './HTTPClient';

const API = {
  list (request) {
    return Client.post('/api/digitals/', request);
  },

  upload (data) {
    return Client.post('/api/digitals/upload', data, true, {isJson: false, isMultipart: true});
  },

  create (request) {
    return Client.post('/api/digitals/new', request);
  }
};

export default API;
