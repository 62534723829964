import {fetchResponse} from './helpers/fakeFetch';

const fakeUserAccessToken = '0BMmGiJKG059sZlaY9MJLPTV2ZxwcEYW';
const fakeUserRefreshToken = 'deYa7S1cmFvrSU4gIkRSayagNOQzlttE';

const AuthAPI = {
  logIn (mail, password) {
    console.log('api.auth.login(', mail, password, ')');

    return fetchResponse(1000, {
      id: 6,
      access_token: fakeUserAccessToken, // eslint-disable-line camelcase
      refresh_token: fakeUserRefreshToken, // eslint-disable-line camelcase
      token_type: 'bearer', // eslint-disable-line camelcase
      email: 'danil@valov.me',
      expires_in: 29472, // eslint-disable-line camelcase
      first_name: 'Danil', // eslint-disable-line camelcase
      last_name: 'Valov', // eslint-disable-line camelcase
      roles: ['ROLE_USER']
    });
  },

  impersonateLogIn (username) {
    console.log('api.auth.impersonateLogIn(', username, ')');

    return fetchResponse(1000, {
      id: 6,
      access_token: fakeUserAccessToken, // eslint-disable-line camelcase
      refresh_token: fakeUserRefreshToken, // eslint-disable-line camelcase
      token_type: 'bearer', // eslint-disable-line camelcase
      email: 'danil@valov.me',
      expires_in: 29472, // eslint-disable-line camelcase
      first_name: 'Danil', // eslint-disable-line camelcase
      last_name: 'Valov', // eslint-disable-line camelcase
      roles: ['ROLE_USER']
    });
  },

  getProfile () {
    console.log('api.auth.getProfile()');

    return fetchResponse(1000, {
      'id': 53,
      'agencyId': 108,
      'mail': 'aleksey.kozlov@umww.ru',
      'firstName': 'Aleksey',
      'lastName': 'Kozlov',
      'role': {'name': 'PLANNER', 'nameTranslation': 'Планер'},
      'createdAt': '2018-04-25T15:48:39.762+03:00',
      'lastLoginAt': '2018-05-15T10:44:54.943+03:00',
      'advertiserIds': [3169],
      'extend': {'agency': {'id': 53, 'name': null}},
      'impersonate': true,
      'disabled': false
    });
  },

  impersonateLogOut () {
    console.log('api.auth.impersonateLogOut()');

    return fetchResponse(1000, {
      status: true
    });
  },

  logOut () {
    console.log('api.auth.logOut()');

    return fetchResponse(1000, {
      status: true
    });
  }
};

export default AuthAPI;
