import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage from '../../components/LocalizedMessage';
import {capitalizeFirstLetter} from '../../helpers/utils';
import classes from './Loader.module.scss';

const Loader = ({position, active, withText, withDescription, withBackground, description, isSmall, className}) => {
  return (
    <div
      className={cx(
        classes.Loader,
        classes[`Loader${capitalizeFirstLetter(position)}`],
        {
          [classes.LoaderActive]: active,
          [classes.LoaderSmall]: isSmall,
          [classes.LoaderWithBackground]: position !== 'inline' && withBackground && !isSmall
        },
        className
      )}
    >
      <div className={classes.LoaderContent}>
        <ul className={classes.LoaderAnimation}>
          {
            new Array(12).fill(null).map((value, index) => (
              <li
                key={index}
                className={classes.LoaderAnimationItem}
              />
            ))
          }
        </ul>
        {
          withText && !isSmall ?
            <p className={classes.LoaderText}>
              <LocalizedMessage
                id='loading'
              />
            </p>
            : null
        }
        {
          withDescription && !isSmall ?
            <p className={classes.LoaderDescription}>
              {
                description ||
                <LocalizedMessage
                  id='loadingDescription'
                />
              }
            </p>
            : null
        }
      </div>
    </div>
  );
};

Loader.defaultProps = {
  position: 'absolute',
  active: true,
  withText: true,
  withDescription: false,
  withBackground: true,
  isSmall: false,
  className: null
};

Loader.propTypes = {
  position: PropTypes.oneOf(['absolute', 'inline']),
  active: PropTypes.bool,
  withText: PropTypes.bool,
  withDescription: PropTypes.bool,
  withBackground: PropTypes.bool,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  isSmall: PropTypes.bool,
  className: PropTypes.string
};

export default Loader;
