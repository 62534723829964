import {connect} from 'react-redux';
import FlowchartView from './components/FlowchartView';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(FlowchartView);
