import React, {Fragment, PureComponent} from 'react';
import moment from 'moment';
import each from 'lodash/each';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import keyBy from 'lodash/keyBy';
import clone from 'lodash/clone';
import findKey from 'lodash/findKey';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import without from 'lodash/without';
import Tooltip from 'rc-tooltip';
import SweetAlert from 'sweetalert';
import Switch from 'react-switchery';
import {Helmet} from 'react-helmet';
import cx from 'classnames';

import LocalizedMessage, {
  localizeMessage,
} from '../../../components/LocalizedMessage';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Loader from '../../../components/Loader';
import SaveModal from '../../../components/SaveModal';
import Input from './Form/Input';
import RevertVersionModal from './Form/RevertVersionModal';
import Budgets from './Form/Budgets';
import BudgetsCampaigns from './Form/BudgetsCampaigns';
import CategoryList from './Form/CategoryList';
import OnLoadWrapper from './Form/OnLoadWrapper';
import {ButtonsGroup} from './Form/ButtonsGroup';
import TableBudgetsAndReach from './Tables/TableBudgetsAndReach';
import {ScrollTopButton} from './ScrollTopButton';

import history from '../../../history';
import Alert from '../../../helpers/alert';
import {statusOptions} from '../../../helpers/statusOptions';
import {
  PromiseAll,
  removeDuplicates,
  safeApiRequest,
} from '../../../helpers/utils';
import {validateFrequency} from '../../../helpers/validateFrequency';
import FormValidator from '../../../helpers/FormValidator';
import API from '../../../api';

import fieldsForTypes from '../data/fieldsForTypes';
import mediaTypes from '../data/mediaTypes';
import sorting from '../data/sorting';

import './Flowchart.scss';

class FlowchartPage extends PureComponent {
  years = {
    startYear: moment().add(1, 'year').year(),
    endYear: moment().add(-5, 'year').year(),
  };

  lockTimer = undefined;

  validator = new FormValidator([
    {
      field: 'flowchartName',
      method: 'isEmpty',
      validWhen: false,
      message: 'Required field',
    },
    {
      field: 'divisionId',
      method: (option) => !!(option && option.value),
      validWhen: true,
      message: 'Required field',
    },
    {
      field: 'brandId',
      method: (option) => !!(option && option.value),
      validWhen: true,
      message: 'Required field',
    },
    {
      field: 'categoriesIds',
      method: (options) => !!(options && options.length),
      validWhen: true,
      message: 'Required field',
    },
    {
      field: 'campaignsIds',
      method: (options) =>
        this.state.isUsingCompanies ? !!(options && options.length) : true,
      validWhen: true,
      message: 'Required field',
    },
    {
      field: 'year',
      method: (option) => !!(option && option.value),
      validWhen: true,
      message: 'Required field',
    },
  ]);

  isAlreadyExistsMessage =
    'For current year and brand pair flowchart already exists';

  state = {
    isMyUserLocking: undefined,

    isEditMode: false,
    isPageLoaded: false,

    optionsYear: this.getYearOptions(),
    optionsCountry: [],
    optionsDivision: [],

    optionsBrands: [],
    optionsCampaigns: [],

    campaignsEnabled: true,
    categoriesEnable: true,
    budgetsLoading: false,
    isLoadingCampaigns: false,

    form: {
      flowchartName: '',
      divisionId: null,
      brandId: null,
      categoriesIds: [],
      campaignsIds: [],
      country: null,
      year: null,
      status: {},
      isDeleted: false,
      validation: this.validator.valid(),
    },

    submitted: false,

    yearDependenciesLoading: false,

    isUsingCompanies: false,
    categoriesData: [],
    campaignsData: [],
    regions: [],
    media: {},

    numbersIOLoading: false,
    numbersIO: [],

    mediaOrderSorting: {},
    targetAudiences: [],
    frequencies: [],
    descriptions: [],
    mediaTypes: {},
    spendsTypes: {},
    mapYearToTvcTypes: {},
    tvcTypeList: [],
    summaryBudgetsAndReachs: {},
    revertModalActive: false,
  };

  mounted = false;

  mediaHashSeparator = '+_+';

  brandsMap = {};
  categoriesIdMap = {};

  flowChart = {};
  status = {};
  invalidMedia = [];

  campaignsIdMap = {};
  lastCampaniesInputValue = '';

  _budgets = null;
  _budgetsCampaigns = null;
  _budgetsAndReachRef = React.createRef();

  budgets = {
    validatedBalance: true,
    validatedBalanceCampaign: true,
    campaignsEnabled: true,
    categoriesEnable: true,
    campaigns: {
      briefed: {
        price: {},
        total: '',
      },
      planned: {
        price: {},
        total: '',
      },
      reserve: {
        price: {},
        total: '',
      },
      balance: {
        price: {},
        total: '',
      },
    },
    categories: {
      briefed: {
        price: {},
        total: '',
      },
      planned: {
        price: {},
        total: '',
      },
      reserve: {
        price: {},
        total: '',
      },
      balance: {
        price: {},
        total: '',
      },
    },
  };

  mediaData = {};
  formData = {};
  countLoadingAdditionalData = 0;
  tvcLengths = [];

  componentDidMount () {
    this.mounted = true;

    this.mountComponent();
  }

  componentWillUnmount () {
    this.mounted = false;
    if (this.state.isMyUserLocking === undefined) {
      API.lock.unlockFlowchart(this.flowChart.id).then().catch();
    }
    if (this.lockTimer) {
      clearInterval(this.lockTimer);
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.mountComponent(nextProps.match.params.id);
    }
  }

  setStateAsync = (updatedState) => {
    return new Promise((resolve) => {
      this.setState(updatedState, resolve);
    });
  };

  setBudgetsCampaignsRef = (ref) => {
    this._budgetsCampaigns = ref;
  };

  setBudgetsRef = (ref) => {
    this._budgets = ref;
  };

  getYearOptions () {
    const years = [];

    for (let year = this.years.startYear; year >= this.years.endYear; year--) {
      years.push({
        value: year,
        label: year,
      });
    }

    return years;
  }

  convertStatusToLocal (status) {
    return status.replace('_', '-').toLowerCase();
  }

  async mountComponent (_id) {
    const {
      user,
      match: {params},
      history,
      checkRoles,
    } = this.props;

    const {form, optionsYear} = this.state;

    if (!checkRoles('ADMIN,PLANNER,BUYER')) {
      // history.push('/app');
    }

    window.scrollTo(0, 0);

    this.setState({
      modalActive: false,
      isPageLoaded: false,
    });

    const id = _id || (params && params.id);

    const isEditMode = typeof id !== 'undefined';

    this.mediaTypesMap = mediaTypes;

    each(this.mediaTypesMap, (mediaType) => {
      each(mediaType, (mediaTypeMeta) => {
        mediaTypeMeta.fields = fieldsForTypes[mediaTypeMeta.typeTable] || {};
      });
    });

    const promises = [
      API.personaTargetAudience.list({max: 0}),
      API.mediaTypes.list({max: 0}),
      API.spendsType.list({max: 0}),
      API.indoor.list({max: 0}),
      API.frequencies.list({max: 0}),
      API.countries.list({max: 0}),
    ];

    const state = {};

    if (isEditMode) {
      state.isEditMode = isEditMode;
      promises.push(
        !params.versionId
          ? API.flowcharts.get(id)
          : API.flowcharts.getVersion(Number(params.versionId))
      );
    }

    try {
      const [
        targetAudiencesResponse,
        mediaTypesResponse,
        spendsTypesResponse,
        descriptionsResponse,
        frequenciesResponse,
        countriesResponse,
        flowChartResponse,
      ] = await Promise.all(promises);

      if (!this.mounted) {
        return;
      }

      state.form = form;

      state.optionsCountry = countriesResponse.items.map((country) => ({
        value: country.id,
        label: country.name,
      }));

      if (state.optionsCountry.length === 1) {
        state.form.country = state.optionsCountry[0];
      }

      state.targetAudiences = (
        targetAudiencesResponse.items || targetAudiencesResponse
      ).map((ta) => {
        return {
          value: ta.id,
          label: ta.name,
        };
      });

      state.descriptions = descriptionsResponse.items.map((descriptionItem) => {
        return {
          value: descriptionItem.name,
          label: descriptionItem.name,
        };
      });

      state.mediaTypes = {};

      each(mediaTypesResponse.items, (mediaType) => {
        if (!this.mediaTypesMap[mediaType.media.trim()]) {
          this.mediaTypesMap[
            mediaType.media.trim()
          ] = this.mediaTypesMap.Default;
        }

        state.mediaTypes[mediaType.id] = {
          id: mediaType.id,
          name: mediaType.media,
          cpp: {},
          bigMedia: mediaType.bigMedia,
          spend3: mediaType.spend3,
          meta: this.mediaTypesMap[mediaType.media.trim()],
          isActive: mediaType.isActive,
        };
      });

      state.spendsTypes = {};

      each(spendsTypesResponse.items, (spendsType) => {
        const mediaTypesMapKey = Object.keys(this.mediaTypesMap)[0];

        if (!this.mediaTypesMap[mediaTypesMapKey][spendsType.name]) {
          each(this.mediaTypesMap, (mediaType) => {
            mediaType[spendsType.name] = mediaType.Default;
          });
        }

        state.spendsTypes[spendsType.id] = {
          id: spendsType.id,
          name: spendsType.name,
        };
      });

      const frequencies = frequenciesResponse.items.filter(validateFrequency);

      let frequenciesRange = frequencies.filter(
        (frequency) =>
          !frequency.frequencyValue.includes('-') &&
          !frequency.frequencyValue.includes('+')
      );

      frequenciesRange = frequenciesRange.sort(
        (a, b) => +a.frequencyValue - +b.frequencyValue
      );

      const mergedFrenquencies = [];

      frequenciesRange.forEach((frequencyOne, frequencyOneIndex) => {
        const _frequenciesRange = cloneDeep(frequenciesRange).slice(
          frequencyOneIndex + 1
        );

        _frequenciesRange.forEach((frequencyTwo) => {
          mergedFrenquencies.push({
            value:
              frequencyOne.frequencyValue + '-' + frequencyTwo.frequencyValue,
            label:
              frequencyOne.frequencyValue + '-' + frequencyTwo.frequencyValue,
          });
        });
      });

      const mainFrequencies = frequencies.map((frequency) => {
        const value =
          !frequency.frequencyValue.includes('-') &&
          !frequency.frequencyValue.includes('+')
            ? (+frequency.frequencyValue).toFixed(1)
            : frequency.frequencyValue;

        return {
          value: value,
          label: frequency.frequencyValue,
        };
      });

      state.frequencies = mainFrequencies.concat(mergedFrenquencies);

      if (isEditMode) {
        if (
          flowChartResponse.status.name.toLowerCase() === 'initial' &&
          user.role.name === 'PLANNER'
        ) {
          history.push('/app/flowcharts');

          return;
        }

        this.flowChart = flowChartResponse;

        state.form.year = optionsYear.find(
          (optionYear) => optionYear.value === flowChartResponse.year
        );

        await this.handleYearInputChange({
          target: {
            value: state.form.year,
            name: 'year',
          },
        });

        state.form.status = statusOptions.find(
          (status) => status.value === flowChartResponse.status.name
        );

        this.status = flowChartResponse.status;

        state.form.flowchartName = flowChartResponse.title;
        state.form.isDeleted = flowChartResponse.isDeleted;

        state.form.country = state.optionsCountry.find(
          (countryOption) =>
            countryOption.value === flowChartResponse.country.id
        );

        await this.loadRegions(state.form.country);

        state.form.divisionId = this.state.optionsDivision.find(
          (divisionOption) =>
            divisionOption.value === flowChartResponse.division.id
        );

        await this.refreshBrands(flowChartResponse.division.id);

        state.form.brandId = {
          value: flowChartResponse.brand.id,
          label: flowChartResponse.brand.name,
        };

        await this.handleBrandChange({
          target: {
            value: state.form.brandId,
            name: 'brandId',
          },
        });

        const categoriesIds = flowChartResponse.categoryExtensions.map(
          (categoryExtension) => ({
            value: categoryExtension.id,
            label: categoryExtension.name,
          })
        );

        state.form.categoriesIds = await this.handleCategoriesChange(
          {
            target: {
              value: removeDuplicates(categoriesIds, 'value'),
              name: 'categoriesIds',
            },
          },
          {}
        );

        const dataJson = this.parseSavingJsonToObject(
          flowChartResponse.dataJson
        );

        if (dataJson && dataJson.campaign) {
          state.isUsingCompanies = dataJson.campaign.isUsingCompanies;

          state.form.campaignsIds =
            dataJson.campaign.selectedCampaignsIds || [];

          state.optionsCampaigns = dataJson.campaign.optionsCampaigns || [];

          await this.handleCampaignsChange(
            {
              target: {
                value: state.form.campaignsIds,
                name: 'campaignsIds',
              },
            },
            {},
            state.optionsCampaigns,
            state.isUsingCompanies
          );
        }

        if (dataJson && dataJson.budgets) {
          this.budgets = dataJson.budgets;

          this.budgets.categoriesEnable =
            typeof this.budgets.categoriesEnable !== 'boolean'
              ? true
              : this.budgets.categoriesEnable;

          this.budgets.campaignsEnabled =
            typeof this.budgets.campaignsEnabled !== 'boolean'
              ? true
              : this.budgets.campaignsEnabled;

          state.categoriesEnable = this.budgets.categoriesEnable;
          state.campaignsEnabled = this.budgets.campaignsEnabled;

          if (typeof this.budgets.validatedBalanceCampaign === 'undefined') {
            this.budgets.validatedBalanceCampaign = true;
          }
        }

        if (dataJson && dataJson.summaryBudgetsAndReachs) {
          state.summaryBudgetsAndReachs = dataJson.summaryBudgetsAndReachs;
        }

        const {mediaData, formData} = this.convertMediaToMediaData(
          dataJson.media
        );
        this.mediaData = mediaData;
        this.formData = formData;
        state.media = this.convertMediaDataToClient(dataJson.media);
        state.mediaOrderSorting = this.generateOrderSorting(state.media);
      }

      state.isPageLoaded = true;

      this.setState(state);

      this.startLockTimer();
    } catch (error) {
      console.error(error);
    }
  }

  handleTimerTick = () => {
    API.lock
      .updateFlowchartLock(this.flowChart.id)
      .then((response) => {
        if (response.lockUser.id !== this.props.user.id) {
          window.location.reload();
        }
      })
      .catch(() => window.location.reload());
  };

  startLockTimer = () => {
    if (!this.flowChart.lock) {
      // flowchart isn't locked, so we lock it and start updating
      API.lock.lockFlowchart(this.flowChart.id).then(() => {
        // Tick timer once so we compensate for slow requests
        this.handleTimerTick();
        this.lockTimer = window.setInterval(this.handleTimerTick, 30_000);
        this.setState({
          isEditMode: true,
          isMyUserLocking: undefined,
        });
      });
    } else {
      this.setState({
        isEditMode: false,
        isMyUserLocking: this.flowChart.lock.lockUser.id === this.props.user.id,
      });
    }
  };

  unlockFlowchart = () => {
    API.lock
      .unlockFlowchart(this.flowChart.id)
      .then(() => {
        this.flowChart.lock = undefined;
        this.startLockTimer();
      })
      .catch(() => {
        window.location.reload();
      });
  };

  trackPromiseAll = async (promises) => {
    this.countLoadingAdditionalData += 1;

    if (!this.state.budgetsLoading) {
      await this.setStateAsync({
        budgetsLoading: true,
      });
    }

    const result = await PromiseAll(promises);

    this.countLoadingAdditionalData -= 1;

    if (this.countLoadingAdditionalData < 1) {
      await this.setStateAsync({
        budgetsLoading: false,
      });
    }

    return result;
  };

  convertMediaDataToClient (media) {
    const convertedMedia = {};

    each(media, (campaignData, campaignKey) => {
      convertedMedia[campaignKey] = campaignData;

      each(campaignData, (catagoryData, categoryKey) => {
        convertedMedia[campaignKey][categoryKey] = {};

        each(catagoryData, (mediaData) => {
          convertedMedia[campaignKey][categoryKey][
            mediaData.numberIO.value
          ] = mediaData;

          each(
            convertedMedia[campaignKey][categoryKey][mediaData.numberIO.value]
              .mediaTypes,
            (mediaTypeData) => {
              each(mediaTypeData.spendsTypes, (spendsTypeData) => {
                const dataHashKey = this.createMediaDataHash(
                  campaignKey,
                  categoryKey,
                  mediaData.numberIO.value,
                  mediaTypeData.id,
                  spendsTypeData.id
                );

                spendsTypeData.mediaDataHash = dataHashKey;
                delete spendsTypeData.tableData;
                delete spendsTypeData.form;
                delete spendsTypeData.tvcList;
                delete spendsTypeData.resultTvcList;
              });
            }
          );
        });
      });
    });

    return convertedMedia;
  }

  convertMediaDataToServer (media) {
    const convertedMedia = {};

    each(cloneDeep(media), (campaignData, campaignKey) => {
      convertedMedia[campaignKey] = campaignData;

      each(convertedMedia[campaignKey], (catagoryData, categoryKey) => {
        each(catagoryData, (mediaData) => {
          each(mediaData.mediaTypes, (mediaTypesData) => {
            each(mediaTypesData.spendsTypes, (spendsTypeData) => {
              spendsTypeData.tableData = this.mediaData[
                spendsTypeData.mediaDataHash
              ];
              const {form, tvcList, resultTvcList} = this.formData[
                spendsTypeData.mediaDataHash
              ];

              spendsTypeData.form = form;
              spendsTypeData.tvcList = tvcList;
              spendsTypeData.resultTvcList = resultTvcList;

              delete spendsTypeData.mediaDataHash;
            });
          });
        });

        convertedMedia[campaignKey][categoryKey] = values(catagoryData);
      });
    });

    return convertedMedia;
  }

  generateOrderSorting (media) {
    const order = {};

    each(media, (campaignData, campaignKey) => {
      order[campaignKey] = {};

      each(campaignData, (catagoryData, categoryKey) => {
        order[campaignKey][categoryKey] = {};

        each(catagoryData, (numberIoData) => {
          order[campaignKey][categoryKey][numberIoData.numberIO.value] = {};

          const mMediaTypes = values(numberIoData.mediaTypes)
            .sort((a, b) => {
              if (!sorting.mediaTypes.includes(a.name)) {
                return 1;
              }

              if (!sorting.mediaTypes.includes(b.name)) {
                return -1;
              }

              return (
                sorting.mediaTypes.indexOf(a.name) -
                sorting.mediaTypes.indexOf(b.name)
              );
            })
            .map((mediaType) => mediaType.id);

          order[campaignKey][categoryKey][
            numberIoData.numberIO.value
          ].mediaTypes = mMediaTypes;
          order[campaignKey][categoryKey][
            numberIoData.numberIO.value
          ].spendsTypes = {};

          each(numberIoData.mediaTypes, (mediaType) => {
            order[campaignKey][categoryKey][
              numberIoData.numberIO.value
            ].spendsTypes[mediaType.id] = [];

            const mSpendsTypes = values(mediaType.spendsTypes)
              .sort((a, b) => {
                if (!sorting.spendsTypes.includes(a.name)) {
                  return 1;
                }

                if (!sorting.spendsTypes.includes(b.name)) {
                  return -1;
                }

                return (
                  sorting.spendsTypes.indexOf(a.name) -
                  sorting.spendsTypes.indexOf(b.name)
                );
              })
              .map((spendsType) => spendsType.id);

            order[campaignKey][categoryKey][
              numberIoData.numberIO.value
            ].spendsTypes[mediaType.id] = mSpendsTypes;
          });
        });
      });
    });

    return order;
  }

  createMediaDataHash (
    campaignKey,
    categoryKey,
    numberIo,
    mediaTypeId,
    spendsTypeId
  ) {
    return [campaignKey, categoryKey, numberIo, mediaTypeId, spendsTypeId].join(
      this.mediaHashSeparator
    );
  }

  convertMediaToMediaData (media) {
    const mediaData = {};
    const formData = {};

    each(cloneDeep(media), (campaignData, campaignKey) => {
      each(campaignData, (catagoryData, categoryKey) => {
        each(catagoryData, (numberIoData) => {
          each(numberIoData.mediaTypes, (mediaType) => {
            each(mediaType.spendsTypes, (spendsType) => {
              const dataHashKey = this.createMediaDataHash(
                campaignKey,
                categoryKey,
                numberIoData.numberIO.value,
                mediaType.id,
                spendsType.id
              );

              this.updateTvcInfo(spendsType);

              mediaData[dataHashKey] = spendsType.tableData;

              if (!mediaData[dataHashKey].unlockedCrossWeeksMonths) {
                mediaData[dataHashKey].unlockedCrossWeeksMonths = [];
              }

              formData[dataHashKey] = {};
              formData[dataHashKey].form = spendsType.form;
              formData[dataHashKey].tvcList = spendsType.tvcList;
              formData[dataHashKey].resultTvcList = spendsType.resultTvcList;
            });
          });
        });
      });
    });

    return {
      mediaData,
      formData,
    };
  }

  updateTvcInfo = (spendsType) => {
    spendsType.tableData.table?.length && spendsType.tableData.table.forEach(row => {
      this.replaceTvcInfo(row.lens, 'name');
    });
    spendsType.tvcList?.length && spendsType.tvcList.forEach(tvc => {
      this.replaceTvcInfo(tvc.length);
    });
    spendsType.resultTvcList?.length && spendsType.resultTvcList.forEach(tvc => {
      this.replaceTvcInfo(tvc.length);
    });
  }

  replaceTvcInfo = (lengths, fieldName = 'value') => {
    (lengths || []).forEach(len => {
      const info = this.state.tvcLengths.find(tvc => tvc.length === len[fieldName]);
      if (info) {
        len.info = info;
      }
    });
  };

  onUpdateMediaValidationStatus = (mediaName, isValid) => {
    if (this.invalidMedia.includes(mediaName)) {
      if (isValid) {
        this.invalidMedia = without(this.invalidMedia, mediaName);
      }
    } else if (!isValid) {
      this.invalidMedia = this.invalidMedia.concat(mediaName);
    }
  };

  onChangeBudgetsEnable (type, toggle) {
    this.budgets[type] = toggle;

    if (type === 'categoriesEnable') {
      this.budgets.validatedBalance = true;
    } else if (type === 'campaignsEnabled') {
      this.budgets.validatedBalanceCampaign = true;
    }

    this.validateBudgetsBriefed();

    this.setState({
      [type]: toggle,
    });
  }

  handleInputChange = (e) => {
    const {form} = this.state;

    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCountryInputChange = (e) => {
    const {form} = this.state;

    this.mediaData = {};
    this.formData = {};

    this.setState(
      {
        form: {
          ...form,
          [e.target.name]: e.target.value,
        },
        media: {},
      },
      () => {
        this.loadRegions();
      }
    );
  };

  async loadRegions (country = this.state.form.country) {
    if (!country) {
      return;
    }

    const regionsResponse = await API.countries.getRegions(country.value);

    return new Promise((resolve) =>
      this.setState(
        {
          regions: regionsResponse.items.map((region) => ({
            value: region.id,
            label: region.name,
          })),
        },
        resolve
      )
    );
  }

  handleYearInputChange = (e) => {
    const {form, mapYearToTvcTypes} = this.state;

    const newYearOption = e.target.value;
    const newYear = newYearOption.value;
    const hasTypesForYear = mapYearToTvcTypes[newYear] !== undefined;

    return new Promise((resolve) => {
      this.setState(
        {
          form: {
            ...form,
            year: newYearOption,
          },
          yearDependenciesLoading: true,
        },
        async () => {
          const promises = [
            API.divisions.year(newYear),
            API.currencies.get(newYear),
            API.tvcInfo.get(newYear),
          ];
          if (!hasTypesForYear) {
            promises.push(API.tvc.get(newYear));
          }

          try {
            const [
              divisionsResponse,
              currenciesResponse,
              tvcLengths,
              tvcResponse,
            ] = await Promise.all(promises);

            const state = {};
            state.tvcLengths = tvcLengths.items;

            if (!hasTypesForYear) {
              const tvcTypes = {};
              state.mapYearToTvcTypes = mapYearToTvcTypes;

              tvcResponse.items.forEach((item) => {
                const tvcLength = item.meta.map((meta) => {
                  const info = tvcLengths.items.find(tvc => tvc.length === meta.tvcInfo.length);

                  return {
                    value: meta.tvcInfo.length,
                    label: meta.tvcInfo.length,
                    preTest: meta.preTest ? meta.preTest.name : null,
                    info,
                  };
                });

                tvcTypes[item.id] = {
                  name: item.name,
                  id: item.id,
                  length: tvcLength,
                };
              });
              state.mapYearToTvcTypes[newYear] = tvcTypes;

              state.tvcTypeList = Object.keys(tvcTypes).map((tvcId) => {
                return {
                  value: tvcId,
                  label: tvcTypes[tvcId].name,
                };
              });
            } else {
              const tvcTypes = mapYearToTvcTypes[newYear];
              state.tvcTypeList = Object.keys(tvcTypes).map((tvcId) => {
                return {
                  value: tvcId,
                  label: tvcTypes[tvcId].name,
                };
              });
            }

            state.optionsDivision = (
              divisionsResponse.items || divisionsResponse
            ).map((division) => ({
              value: division.id,
              label: division.name,
            }));

            state.yearDependenciesLoading = false;
            state.currencyValue = +currenciesResponse.eur;

            this.setState(state, resolve);
          } catch (error) {
            console.error(error);
          }
        }
      );
    });
  };

  handleDivisionChange = (e) => {
    const {form} = this.state;

    const isChanged =
      (form.divisionId && form.divisionId.value) !==
      (e.target.value && e.target.value.value);

    this.mediaData = {};
    this.formData = {};

    this.setState(
      {
        form: {
          ...form,
          [e.target.name]: e.target.value,
          brandId: isChanged ? null : form.brandId,
          categoriesIds: isChanged ? null : form.categoriesIds,
        },
        media: {},
      },
      () => {
        this.refreshBrands();
      }
    );
  };

  handleBrandChange = (e) => {
    const {form, optionsCampaigns} = this.state;

    const selectedBrand = e.target.value;

    const categories = this.serializeCategories(
      this.brandsMap[selectedBrand.value].categoryExtensions
    );
    this.categoriesIdMap = keyBy(categories, 'value');

    if (optionsCampaigns.length) {
      this.serializeCampaigns(form.campaignsIds, false, []);
    }

    return new Promise((resolve) => {
      this.mediaData = {};
      this.formData = {};

      this.setState(
        {
          form: {
            ...form,
            [e.target.name]: selectedBrand,
            categoriesIds: [],
          },
          optionsCategories: categories,
          categoriesData: [],
          media: {},
        },
        () => {
          this.refreshNumberIO();
          resolve();
        }
      );
    });
  };

  handleCategoriesChange = (e, {action, removedValue}) => {
    const {form, optionsCampaigns, media} = this.state;
    const newValue = e.target.value;

    if (action === 'remove-value') {
      each(media, (campaigns, campaignValue) => {
        delete media[campaignValue][removedValue.value];

        const clonedMediaData = cloneDeep(this.mediaData);

        each(clonedMediaData, (mediaData, mediaDataHash) => {
          const [campaignId, categoryId] = mediaDataHash.split(
            this.mediaHashSeparator
          );

          if (
            campaignId === campaignValue &&
            +categoryId === +removedValue.value
          ) {
            delete this.mediaData[mediaDataHash];
            delete this.formData[mediaDataHash];
          }
        });

        if (!Object.keys(media[campaignValue]).length) {
          delete media[campaignValue];

          each(clonedMediaData, (mediaData, mediaDataHash) => {
            const [campaignId] = mediaDataHash.split(this.mediaHashSeparator);
            if (campaignId === campaignValue) {
              delete this.mediaData[mediaDataHash];
              delete this.formData[mediaDataHash];
            }
          });
        }
      });
    } else if (action === 'clear') {
      each(media, (campaigns, campaignValue) => {
        delete media[campaignValue];

        each(cloneDeep(this.mediaData), (mediaData, mediaDataHash) => {
          const [campaignId] = mediaDataHash.split(this.mediaHashSeparator);
          if (campaignId === campaignValue) {
            delete this.mediaData[mediaDataHash];
            delete this.formData[mediaDataHash];
          }
        });
      });
    }

    const categoryExtensionsIds = newValue.map(
      (categoryExtension) => categoryExtension.value
    );
    const categoriesData = this.serializeSelectedCategories(
      categoryExtensionsIds
    );

    const existsCategogies = newValue.filter(
      (category) => !!this.categoriesIdMap[category.value]
    );

    const notExistsCategories = newValue.filter(
      (category) => !this.categoriesIdMap[category.value]
    );

    for (const category of notExistsCategories) {
      each(media, (campaigns, campaignValue) => {
        delete media[campaignValue][category.value];

        const clonedMediaData = cloneDeep(this.mediaData);

        each(clonedMediaData, (mediaData, mediaDataHash) => {
          const [campaignId, categoryId] = mediaDataHash.split(
            this.mediaHashSeparator
          );
          if (campaignId === campaignValue && +categoryId === +category.value) {
            delete this.mediaData[mediaDataHash];
            delete this.formData[mediaDataHash];
          }
        });

        if (!Object.keys(media[campaignValue]).length) {
          delete media[campaignValue];

          each(clonedMediaData, (mediaData, mediaDataHash) => {
            const [campaignId] = mediaDataHash.split(this.mediaHashSeparator);
            if (campaignId === campaignValue) {
              delete this.mediaData[mediaDataHash];
              delete this.formData[mediaDataHash];
            }
          });
        }
      });
    }

    if (optionsCampaigns.length) {
      this.serializeCampaigns(null, null, e.target.value);
    }

    return new Promise((resolve) => {
      this.setState(
        {
          form: {
            ...form,
            categoriesIds: existsCategogies,
          },
          categoriesData,
          media,
        },
        () => {
          this.refreshNumberIO();
          resolve(this.state.form.categoriesIds);
        }
      );
    });
  };

  handleCampaignsChange = (
    e,
    {action, removedValue},
    _optionsCampaigns,
    _isUsingCompanies
  ) => {
    const {form, optionsCampaigns} = this.state;
    let {media} = this.state;
    const newValue = e.target.value;

    if (action === 'remove-value') {
      delete media[removedValue.value];

      const clonedMediaData = cloneDeep(this.mediaData);

      each(clonedMediaData, (mediaData, mediaDataHash) => {
        const [campaignId] = mediaDataHash.split(this.mediaHashSeparator);
        if (campaignId === removedValue.value) {
          delete this.mediaData[mediaDataHash];
          delete this.formData[mediaDataHash];
        }
      });
    } else if (action === 'clear') {
      media = {};
      this.mediaData = {};
      this.formData = {};
    }

    this.campaignsIdMap = keyBy(_optionsCampaigns || optionsCampaigns, 'value');

    this.serializeCampaigns(newValue, _isUsingCompanies);

    return new Promise((resolve) => {
      this.setState(
        {
          form: {
            ...form,
            [e.target.name]: e.target.value,
          },
          media,
        },
        resolve
      );
    });
  };

  handleIsUsingCompanies = (isUsingCompanies) => {
    this.budgets = {
      ...this.budgets,
      campaigns: {
        briefed: {
          price: {},
          total: '',
        },
        planned: {
          price: {},
          total: '',
        },
        reserve: {
          price: {},
          total: '',
        },
        balance: {
          price: {},
          total: '',
        },
      },
    };

    this.mediaData = {};
    this.formData = {};

    this.setState(
      {
        isUsingCompanies,
        media: {},
      },
      () => {
        this.serializeCampaigns();
        this.validateBudgetsBriefed();
      }
    );
  };

  serializeSelectedCategories (categories) {
    return categories
      .filter((value) => !!this.categoriesIdMap[value])
      .map((value) => {
        const cCategory = this.categoriesIdMap[value];

        return {
          id: cCategory.value,
          name: cCategory.label,
          numberIO: null,
          mediaType: null,
          spendsType: null,
        };
      });
  }

  serializeCampaigns (values, _isUsingCompanies, _categoriesIds) {
    const {
      isUsingCompanies,
      form: {categoriesIds, campaignsIds},
    } = this.state;

    if (!_isUsingCompanies && !isUsingCompanies) {
      return;
    }

    const campaignsData = (values || campaignsIds).map((campaign) => {
      const cCampaign = this.campaignsIdMap[campaign.value];
      const selectedCategories = this.serializeSelectedCategories(
        (_categoriesIds || categoriesIds).map((c) => c.value)
      );

      return {
        id: cCampaign.value,
        name: cCampaign.label,
        selectedCategories: clone(selectedCategories),
      };
    });

    this.setState({
      campaignsData,
    });
  }

  serializeCategories (categories) {
    return categories.map((categoryExtension) => {
      return {
        value: categoryExtension.id,
        label: categoryExtension.name,
      };
    });
  }

  async refreshBrands (divisionId) {
    const {form} = this.state;

    this.setState({
      optionsBrands: [],
      optionsBrandsLoading: true,
      form: {
        ...form,
        brandId: null,
      },
    });

    this.brandsMap = {};

    const response = await safeApiRequest(
      API.divisions.get,
      divisionId || form.divisionId.value,
      {max: 0}
    );

    const brandsMap = {};

    const brands = response.brands.map((brand) => {
      brandsMap[brand.id] = brand;

      return {
        value: brand.id,
        label: brand.name,
      };
    });

    if (this.flowChart.brand && !brandsMap[this.flowChart.brand.id]) {
      brandsMap[this.flowChart.brand.id] = {
        id: this.flowChart.brand.id,
        name: this.flowChart.brand.name,
        categoryExtensions: this.flowChart.categoryExtensions,
      };
    }

    this.brandsMap = brandsMap;

    this.setState({
      optionsBrands: brands,
      optionsBrandsLoading: false,
    });
  }

  async refreshNumberIO () {
    const {form} = this.state;

    if (
      !form.brandId ||
      !form.brandId.value ||
      !form.categoriesIds ||
      !form.categoriesIds.length ||
      !(form.year && form.year.value)
    ) {
      return;
    }

    this.setState({
      numbersIOLoading: true,
      numbersIO: [],
    });

    const response = await safeApiRequest(API.io.search, {
      brandId: form.brandId.value,
      categoryExtensionIds: form.categoriesIds.map((c) => parseInt(c.value)),
      year: form.year.value,
    });

    this.numbersIoList = response;

    const numbersIO = response.map((io) => {
      return {
        value: io.numberIo,
        label: io.numberIo,
        instance: io,
      };
    });

    this.setState({
      numbersIOLoading: false,
      numbersIO,
    });
  }

  debouncedCampaignsHandler = debounce(
    (value) => this.companiesLoadHandler(value),
    500
  );

  campaignsInputChange = (value, force = false) => {
    if (force || value !== this.lastCampaniesInputValue) {
      this.lastCampaniesInputValue = value;

      this.debouncedCampaignsHandler(value);
    }
  };

  companiesLoadHandler = async (query) => {
    const {
      optionsCampaigns,
      form: {campaignsIds},
    } = this.state;

    this.setState({
      isLoadingCampaigns: true,
    });

    try {
      const response = await API.digitals.list({
        max: 30,
        pattern: query,
      });

      if (!this.mounted) {
        return;
      }

      const campaignsItems = response.items.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });

      const _optionsCampaigns = removeDuplicates(
        [
          ...optionsCampaigns.filter(
            (o) => campaignsIds.findIndex((_o) => _o.value === o.value) !== -1
          ),
          ...campaignsItems,
        ],
        'value'
      );

      this.campaignsIdMap = keyBy(_optionsCampaigns, 'value');

      this.setState({
        isLoadingCampaigns: false,
        optionsCampaigns: _optionsCampaigns,
      });
    } catch (error) {
      console.error(error);
    }
  };

  onCreateCampaignOption = async (value) => {
    const {optionsCampaigns, form, isUsingCompanies} = this.state;

    this.setState({
      isLoadingCampaigns: true,
    });

    const response = await API.digitals.create({
      name: value,
    });

    if (!this.mounted) {
      return;
    }

    const newOption = {
      value: response.name,
      label: response.name,
    };

    const _optionsCampaigns = [...optionsCampaigns, newOption];

    const campaignsIds = [...form.campaignsIds, newOption];

    this.campaignsIdMap = keyBy(_optionsCampaigns, 'value');

    this.serializeCampaigns(campaignsIds, isUsingCompanies, form.categoriesIds);

    this.setState({
      isLoadingCampaigns: false,
      optionsCampaigns: _optionsCampaigns,
      form: {
        ...form,
        campaignsIds,
      },
    });
  };

  onCreateDescriptionOption = async (
    newOption,
    campaign,
    category,
    mediaIndex,
    mediaTypeId,
    spendsTypeId
  ) => {
    const {descriptions, media} = this.state;

    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const mediaTypeKey = this.getMediaTypeKeyById(mediaTypeId);

    try {
      await API.indoor.create({
        name: newOption.value,
      });

      if (!this.mounted) {
        return;
      }

      const newMedia = media;

      if (
        mediaTypeKey &&
        newMedia[campaign] &&
        newMedia[campaign][category] &&
        newMedia[campaign][category][mediaIndex] &&
        newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey] &&
        newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
          .spendsTypes[spendsTypeId]
      ) {
        const form =
          newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
            .spendsTypes[spendsTypeId].form || {};

        form['descriptions'] = [...(form['descriptions'] || []), newOption];

        newMedia[campaign][category][mediaIndex].mediaTypes[
          mediaTypeKey
        ].spendsTypes[spendsTypeId].form = form;
      }

      this.setState({
        media: newMedia,
        descriptions: [...descriptions, newOption],
      });
    } catch (error) {
      console.error(error);
    }
  };

  getMediaTypeKeyById (id) {
    const {mediaTypes} = this.state;

    return findKey(mediaTypes, (mediaType) => +mediaType.id === +id);
  }

  addMedia = (category, numberIO, _mediaTypes, _spendsTypes, campaign) => {
    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const {media, mediaTypes, spendsTypes} = this.state;

    const newMedia = media;

    if (!newMedia[campaign]) newMedia[campaign] = {};
    if (!newMedia[campaign][category]) newMedia[campaign][category] = {};

    const resultMediaTypes = {};

    _mediaTypes.forEach((mediaType) => {
      const resultSpendsTypes = {};
      const mediaTypeKey = this.getMediaTypeKeyById(mediaType.value);

      _spendsTypes.forEach((spendsType) => {
        if (
          this.validateMediaSpendsTypes(
            campaign,
            category,
            numberIO,
            mediaTypeKey,
            spendsType.value
          )
        ) {
          const tableType =
            mediaTypes[mediaTypeKey].meta[spendsTypes[spendsType.value].name]
              .typeTable;

          const mediaDataHash = this.createMediaDataHash(
            campaign,
            category.id,
            numberIO.value,
            mediaTypeKey,
            spendsType.value
          );

          this.mediaData[mediaDataHash] = {
            table: [],
            budgets: [],
            unlockedCrossWeeksMonths: [],
          };

          this.formData[mediaDataHash] = {
            tvcList: [
              {
                type: null,
                length: null,
              },
            ],
            resultTvcList: [],
            form: {},
          };

          resultSpendsTypes[spendsType.value] = {
            id: spendsType.value,
            name: spendsTypes[spendsType.value].name,
            tableType,
            mediaDataHash,
          };
        }
      });

      if (this.validateMediaType(campaign, category, numberIO, mediaTypeKey)) {
        resultMediaTypes[mediaTypeKey] = {
          id: mediaType.value,
          name: mediaTypes[mediaTypeKey].name,
          spend3: mediaTypes[mediaTypeKey].spend3,
          bigMedia: mediaTypes[mediaTypeKey].bigMedia,
          spendsTypes: resultSpendsTypes,
        };
      } else {
        const media = this.getMediaByNumberIO(campaign, category, numberIO);

        if (media) {
          media.mediaTypes[mediaTypeKey].spendsTypes = Object.assign(
            {},
            media.mediaTypes[mediaTypeKey].spendsTypes,
            resultSpendsTypes
          );
        }
      }
    });

    if (this.validateMedia(campaign, category, numberIO)) {
      newMedia[campaign][category][numberIO.value] = {
        numberIO: numberIO,
        mediaTypes: resultMediaTypes,
      };
    } else {
      const media = this.getMediaByNumberIO(campaign, category, numberIO);
      media.mediaTypes = Object.assign({}, media.mediaTypes, resultMediaTypes);
    }

    this.setState({
      media: newMedia,
      mediaOrderSorting: this.generateOrderSorting(newMedia),
    });
  };

  validateMedia (campaign, category, numberIO) {
    const {media: _media} = this.state;

    return typeof _media[campaign][category][numberIO.value] === 'undefined';
  }

  validateMediaType (campaign, category, numberIO, mediaTypeKey) {
    const {media: _media} = this.state;

    const media = _media[campaign][category][numberIO.value];

    if (!media) {
      return true;
    }

    return typeof media.mediaTypes[mediaTypeKey] === 'undefined';
  }

  validateMediaSpendsTypes (
    campaign,
    category,
    numberIO,
    mediaTypeKey,
    spendsTypeId
  ) {
    const {media: _media} = this.state;

    const media = _media[campaign][category][numberIO.value];

    if (!media) {
      return true;
    }

    return (
      typeof media.mediaTypes[mediaTypeKey] === 'undefined' ||
      typeof media.mediaTypes[mediaTypeKey].spendsTypes[spendsTypeId] ===
        'undefined'
    );
  }

  getMediaByNumberIO (campaign, category, numberIO) {
    const {media} = this.state;
    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    return media[campaign][category][numberIO.value];
  }

  removeMedia = (campaign, category, mediaIndex, mediaTypeId, spendsTypeId) => {
    const {media} = this.state;

    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const mediaTypeKey = this.getMediaTypeKeyById(mediaTypeId);

    const newMedia = media;

    if (
      mediaTypeKey &&
      newMedia[campaign] &&
      newMedia[campaign][category] &&
      newMedia[campaign][category][mediaIndex] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
        .spendsTypes[spendsTypeId]
    ) {
      // eslint-disable-next-line max-len
      delete this.mediaData[
        newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
          .spendsTypes[spendsTypeId].mediaDataHash
      ];
      // eslint-disable-next-line max-len
      delete this.formData[
        newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
          .spendsTypes[spendsTypeId].mediaDataHash
      ];

      delete newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
        .spendsTypes[spendsTypeId];

      if (
        !Object.keys(
          newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
            .spendsTypes
        ).length
      ) {
        delete newMedia[campaign][category][mediaIndex].mediaTypes[
          mediaTypeKey
        ];

        if (
          !Object.keys(newMedia[campaign][category][mediaIndex].mediaTypes)
            .length
        ) {
          delete newMedia[campaign][category][mediaIndex];

          if (!Object.keys(newMedia[campaign][category]).length) {
            delete newMedia[campaign][category];

            if (!Object.keys(newMedia[campaign]).length) {
              delete newMedia[campaign];
            }
          }
        }
      }
    }

    this.setState({
      media: newMedia,
      mediaOrderSorting: this.generateOrderSorting(newMedia),
    });
  };

  updateMediaTypes = (mediaTypes, cb) => {
    this.setState({mediaTypes}, cb);
  };

  updateSpendsTypeTvc = (
    campaign,
    category,
    mediaIndex,
    mediaTypeId,
    spendsTypeId,
    values
  ) => {
    const {media} = this.state;

    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const mediaTypeKey = this.getMediaTypeKeyById(mediaTypeId);

    const newMedia = media;

    if (
      mediaTypeKey &&
      newMedia[campaign] &&
      newMedia[campaign][category] &&
      newMedia[campaign][category][mediaIndex] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
        .spendsTypes[spendsTypeId]
    ) {
      // eslint-disable-next-line max-len
      const formData = this.formData[
        newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
          .spendsTypes[spendsTypeId].mediaDataHash
      ];

      // eslint-disable-next-line max-len
      this.formData[
        newMedia[campaign][category][mediaIndex].mediaTypes[
          mediaTypeKey
        ].spendsTypes[spendsTypeId].mediaDataHash
      ] = {
        ...formData,
        ...values,
      };
    }
  };

  updateSpendsTypeForm = (
    campaign,
    category,
    mediaIndex,
    mediaTypeId,
    spendsTypeId,
    values
  ) => {
    const {media} = this.state;

    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const mediaTypeKey = this.getMediaTypeKeyById(mediaTypeId);

    const newMedia = media;

    if (
      mediaTypeKey &&
      newMedia[campaign] &&
      newMedia[campaign][category] &&
      newMedia[campaign][category][mediaIndex] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
        .spendsTypes[spendsTypeId]
    ) {
      // eslint-disable-next-line max-len
      const formData = this.formData[
        newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
          .spendsTypes[spendsTypeId].mediaDataHash
      ];
      const form = formData.form || {};

      formData.form = {
        ...form,
        ...values,
      };
    }
  };

  updateMediaData = (
    campaign,
    category,
    mediaIndex,
    mediaTypeId,
    spendsTypeId,
    values,
    cb
  ) => {
    const {media} = this.state;

    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const mediaTypeKey = this.getMediaTypeKeyById(mediaTypeId);

    if (
      mediaTypeKey &&
      media[campaign] &&
      media[campaign][category] &&
      media[campaign][category][mediaIndex] &&
      media[campaign][category][mediaIndex].mediaTypes[mediaTypeKey] &&
      media[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
        .spendsTypes[spendsTypeId]
    ) {
      // eslint-disable-next-line max-len
      this.mediaData[
        media[campaign][category][mediaIndex].mediaTypes[
          mediaTypeKey
        ].spendsTypes[spendsTypeId].mediaDataHash
      ] = values.tableData;

      if (this._budgets) {
        this._budgets.onUpdateBudgets(this.mediaData);
      }

      if (this._budgetsCampaigns) {
        this._budgetsCampaigns.onUpdateBudgets(this.mediaData);
      }

      if (this._budgetsAndReachRef.current) {
        this._budgetsAndReachRef.current.onUpdateBudgets(this.mediaData);
      }
    }

    if (typeof cb === 'function') {
      cb();
    }

    return Promise.resolve();
  };

  updateSpendsType = (
    campaign,
    category,
    mediaIndex,
    mediaTypeId,
    spendsTypeId,
    values,
    cb
  ) => {
    const {media} = this.state;

    if (typeof campaign === 'undefined') {
      campaign = 0;
    }

    const mediaTypeKey = this.getMediaTypeKeyById(mediaTypeId);

    const newMedia = media;

    if (
      mediaTypeKey &&
      newMedia[campaign] &&
      newMedia[campaign][category] &&
      newMedia[campaign][category][mediaIndex] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey] &&
      newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
        .spendsTypes[spendsTypeId]
    ) {
      const spendsType = {
        ...newMedia[campaign][category][mediaIndex].mediaTypes[mediaTypeKey]
          .spendsTypes[spendsTypeId],
        ...values,
      };

      newMedia[campaign][category][mediaIndex].mediaTypes[
        mediaTypeKey
      ].spendsTypes[spendsTypeId] = spendsType;
    }

    this.setState(
      {
        media: newMedia,
      },
      cb
    );
  };

  onUpdateBudgetsData = (values, cb) => {
    this.budgets = {
      ...this.budgets,
      ...values,
    };

    this.validateBudgetsBriefed();

    cb();
  };

  validateBudgetsBriefed () {
    const {categoriesEnable, campaignsEnabled} = this.budgets;

    const {isUsingCompanies} = this.state;

    const categoriesTotal = get(this.budgets, 'categories.briefed.total', 0);
    const campaignsTotal = get(this.budgets, 'campaigns.briefed.total', 0);

    const briefedValidated =
      isUsingCompanies && categoriesEnable && campaignsEnabled
        ? categoriesTotal === campaignsTotal
        : true;

    if (this._budgets) {
      this._budgets.onUpdateValidateBriefed(briefedValidated);
    }

    if (this._budgetsCampaigns) {
      this._budgetsCampaigns.onUpdateValidateBriefed(briefedValidated);
    }

    return briefedValidated;
  }

  checkErrors () {
    const {
      form: {
        flowchartName,
        divisionId,
        brandId,
        categoriesIds,
        campaignsIds,
        country,
        year,
      },
      isUsingCompanies,
    } = this.state;
    const errors = [];

    const validate = this.validator.validate(this.state.form);

    if (!validate.isValid) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.base'}));
    }

    if (isNil(flowchartName) || isEmpty(flowchartName)) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.name'}));
    }

    if (isNil(country) || isNil(country.value)) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.country'}));
    }

    if (isNil(divisionId) || isNil(divisionId.value)) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.division'}));
    }

    if (isNil(brandId) || isNil(brandId.value)) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.brand'}));
    }

    if (isNil(categoriesIds) || !categoriesIds.length) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.categories'}));
    }

    if (isUsingCompanies && (isNil(campaignsIds) || !campaignsIds.length)) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.companies'}));
    }

    if (isNil(year)) {
      errors.push(localizeMessage({id: 'flowchart.check-errors.year'}));
    }

    if (this.invalidMedia.length) {
      errors.push(
        localizeMessage({id: 'flowchart.check-errors.empty-media'}),
        this.invalidMedia.join(', ')
      );
    }

    return errors;
  }

  onSaveFlowchart = () => {
    const {validatedBalance, validatedBalanceCampaign} = this.budgets;

    if (!this.validateBudgetsBriefed()) {
      Alert.error(
        localizeMessage({id: 'flowchart.errors.budgets-not-identical'})
      );

      return;
    }

    if (!validatedBalance || !validatedBalanceCampaign) {
      Alert.error(localizeMessage({id: 'flowchart.errors.budgets'}));

      return;
    }

    const errors = this.checkErrors();

    if (!isEmpty(errors)) {
      const errorsComponent = errors
        .map((error, errorIndex) => `<p>${error}</p>`)
        .join('');

      // @TODO: See better html processing solution
      const content = document.createElement('div');
      content.innerHTML = errorsComponent;

      SweetAlert(localizeMessage({id: 'validation-errors.title'}), {
        icon: 'error',
        content: content,
        buttons: {
          confirm: {
            text: 'Ok',
            visible: true,
            closeModal: true,
          },
        },
        className: 'flowchart-errors-alert',
      });

      this.setState({submitted: true});

      return;
    }

    this.setState({modalActive: true, submitted: true});
  };

  onRevertVersion = () => {
    this.setState({revertModalActive: true});
  };

  parseSavingJsonToObject (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  }

  generateJsonForSaving () {
    const {
      media,
      isUsingCompanies,
      form: {campaignsIds},
      optionsCampaigns,
      summaryBudgetsAndReachs,
    } = this.state;

    return JSON.stringify({
      summaryBudgetsAndReachs,
      media: this.convertMediaDataToServer(media),
      campaign: {
        isUsingCompanies: isUsingCompanies,
        optionsCampaigns: optionsCampaigns,
        selectedCampaignsIds: campaignsIds,
      },
      budgets: this.budgets,
    });
  }

  onAcceptSaveModal = async (comment) => {
    const {
      isEditMode,
      form: {
        brandId,
        categoriesIds,
        divisionId,
        status,
        flowchartName,
        country,
        year,
      },
    } = this.state;

    const {history} = this.props;

    const requestData = {
      brandId: brandId.value,
      categoryExtensionIds: categoriesIds.map((category) => category.value),
      dataJson: this.generateJsonForSaving(),
      divisionId: divisionId.value,
      status: status.value,
      title: flowchartName,
      countryId: country.value,
      year: year.value,
      comment,
    };

    this.setState({modalActive: false});

    if (isEditMode) {
      try {
        await API.flowcharts.save(this.flowChart.id, requestData);

        Alert.success(localizeMessage({id: 'flowchart.saving.successfuly'}));
      } catch (error) {
        console.error(error);

        const id =
          error.message === this.isAlreadyExistsMessage
            ? 'flowchart.errors.failed-save.already-exists'
            : 'flowchart.errors.failed-save';

        Alert.error(localizeMessage({id}));
      }

      return;
    }

    try {
      const flowChart = await API.flowcharts.save(null, requestData);

      Alert.success(localizeMessage({id: 'flowchart.saving.successfuly'}));

      history.push(`/app/flowcharts/${flowChart.id}/edit`);
    } catch (error) {
      console.error(error);

      const id =
        error.message === this.isAlreadyExistsMessage
          ? 'flowchart.errors.failed-save.already-exists'
          : 'flowchart.errors.failed-save';

      Alert.error(localizeMessage({id}));
    }
  };

  onAcceptRevertVersionModal = async (comment) => {
    const {
      match: {params},
    } = this.props;

    this.setState({revertModalActive: false});

    try {
      await API.flowcharts.revertToVersion(
        this.flowChart.id,
        Number(params.versionId),
        comment
      );
      Alert.success(
        localizeMessage({id: 'flowchart.reverting-version.successfully'})
      );
      history.push('/app/flowcharts');
    } catch (error) {
      console.error(error);
      Alert.error(
        localizeMessage({id: 'flowchart.errors.failed-revert-version'})
      );
    }
  };

  onCloseRevertVersionModal = () => {
    this.setState({revertModalActive: false});
  };

  getBreadcrumbs () {
    const {isEditMode} = this.state;

    const breadcrumbs = [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='flowcharts' />,
        link: '/app/flowcharts',
      },
    ];

    if (isEditMode) {
      breadcrumbs.push({
        title: <LocalizedMessage id='edit-flowchart' />,
        link: `/app/flowcharts/${this.flowChart.id}/edit`,
      });
    } else {
      breadcrumbs.push({
        title: <LocalizedMessage id='new-flowchart' />,
      });
    }

    return breadcrumbs;
  }

  render () {
    const {
      checkRoles,
      match: {params},
    } = this.props;

    const {
      isMyUserLocking,
      isEditMode,
      isPageLoaded,

      optionsYear,
      optionsCountry,
      optionsDivision,
      optionsCampaigns,
      optionsCategories,
      optionsBrands,
      optionsBrandsLoading,

      isLoadingCampaigns,

      form: {
        flowchartName,
        divisionId,
        brandId,
        categoriesIds,
        campaignsIds,
        country,
        year,
        status,
        isDeleted,
        validation: formValidation,
      },
      isUsingCompanies,
      categoriesData,
      campaignsData,
      numbersIO,
      regions,
      targetAudiences,
      mediaTypes,
      spendsTypes,
      frequencies,
      descriptions,
      tvcTypeList,
      mapYearToTvcTypes,
      media,
      submitted,
      yearDependenciesLoading,
      currencyValue,
      mediaOrderSorting,

      categoriesEnable,
      campaignsEnabled,
      budgetsLoading,
      summaryBudgetsAndReachs,
    } = this.state;

    const {
      categories: budgetCategories,
      campaigns: budgetCampaigns,
    } = this.budgets;

    /* eslint-disable-next-line eqeqeq */
    const hasLock = isMyUserLocking != undefined;
    if (isDeleted) {
      Alert.error(
        localizeMessage({id: 'flowchart.errors.flowchart-has-been-deleted'})
      );

      return null;
    }

    if (!checkRoles('SUPER_ADMIN, ADMIN, PLANNER,BUYER, CLIENT')) {
      return null;
    }

    const canUnlock = checkRoles('SUPER_ADMIN, ADMIN');

    if (!isPageLoaded) {
      return null;
    }
    const tvcTypes =
      year && mapYearToTvcTypes[year.value]
        ? mapYearToTvcTypes[year.value]
        : {};

    const validation = submitted
      ? this.validator.validate(this.state.form)
      : formValidation;

    const isVersion = Boolean(params.versionId);
    const isDisabled = isVersion || hasLock;

    return (
      <Fragment>
        <LocalizedMessage
          id={`site.title.flowchart.${isEditMode ? 'edit' : 'new'}`}
        >
          {(localizedMessage) => (
            <Fragment>
              <Helmet
                title={
                  (isEditMode
                    ? `${flowchartName} (${this.status.nameTranslation}) - `
                    : '') + localizedMessage
                }
              />
              <Breadcrumbs
                title={
                  isEditMode
                    ? `${flowchartName} (${this.status.nameTranslation})`
                    : localizedMessage
                }
                data={this.getBreadcrumbs()}
              />
            </Fragment>
          )}
        </LocalizedMessage>

        {hasLock && (
          <div className='lock-info'>
            {isMyUserLocking ? (
              <div className='lock-info__text'>
                Флоучарт заблокирован Вами из другой вкладки!
              </div>
            ) : (
              <>
                <div className='lock-info__text'>
                  Флоучарт заблокирован пользователем{' '}
                  <b>
                    {this.flowChart.lock.lockUser.firstName}{' '}
                    {this.flowChart.lock.lockUser.lastName}
                  </b>
                </div>
                {canUnlock && (
                  <button
                    onClick={this.unlockFlowchart}
                    className={cx(
                      'btn btn-danger btn-sm btn-block lock-info__unlock'
                    )}
                  >
                    Разблокировать флоучарт для работы
                  </button>
                )}
              </>
            )}
          </div>
        )}

        <div className='row'>
          <div className='col-lg-12'>
            <div className='wrapper wrapper-content'>
              <div className='ibox'>
                <div className='ibox-title'>
                  <h5>Base planning parameters</h5>
                </div>

                <div className='ibox-content'>
                  <div className='form-horizontal'>
                    <Input
                      type='text'
                      formName='flowchartName'
                      labelId='flowchart.flowchartName.label'
                      placeholderId='flowchart.flowchartName.placeholder'
                      value={flowchartName}
                      onChange={this.handleInputChange}
                      validation={validation.flowchartName}
                      showErrors={submitted}
                      disabled={isDisabled}
                    />

                    <Input
                      type='c-select'
                      formName='country'
                      labelId='flowchart.country.label'
                      placeholderId='flowchart.country.placeholder'
                      value={country}
                      onChange={this.handleCountryInputChange}
                      validation={validation.country}
                      showErrors={submitted}
                      options={optionsCountry}
                      disabled={isDisabled}
                    />

                    <Input
                      type='c-select'
                      formName='year'
                      labelId='flowchart.year.label'
                      placeholderId='flowchart.year.placeholder'
                      value={year}
                      onChange={this.handleYearInputChange}
                      disabled={yearDependenciesLoading || isDisabled}
                      validation={validation.year}
                      showErrors={submitted}
                      options={optionsYear}
                    />

                    <Input
                      type='c-select'
                      formName='divisionId'
                      labelId='flowchart.divisionId.label'
                      placeholderId='flowchart.divisionId.placeholder'
                      value={divisionId}
                      onChange={this.handleDivisionChange}
                      disabled={
                        yearDependenciesLoading ||
                        !year ||
                        !year.value ||
                        isDisabled
                      }
                      validation={validation.divisionId}
                      showErrors={submitted}
                      options={optionsDivision}
                    />

                    <Input
                      type='c-select'
                      formName='brandId'
                      labelId='flowchart.brandId.label'
                      placeholderId='flowchart.brandId.placeholder'
                      value={brandId}
                      onChange={this.handleBrandChange}
                      disabled={
                        yearDependenciesLoading ||
                        !year ||
                        !year.value ||
                        !divisionId ||
                        !divisionId.value ||
                        isDisabled
                      }
                      validation={validation.brandId}
                      showErrors={submitted}
                      containerClassName='flowCharts-spinner-container'
                      options={optionsBrands}
                    >
                      {optionsBrandsLoading ? (
                        <Tooltip
                          placement='top'
                          overlay={
                            <span>
                              <LocalizedMessage id='flowchart.brandId.loading' />
                            </span>
                          }
                        >
                          <div className='sk-loader'>
                            <Loader position='inline' isSmall />
                          </div>
                        </Tooltip>
                      ) : null}
                    </Input>

                    <Input
                      type='c-select'
                      formName='categoriesIds'
                      labelId='flowchart.categoriesIds.label'
                      placeholderId='flowchart.categoriesIds.placeholder'
                      value={categoriesIds}
                      onChange={this.handleCategoriesChange}
                      disabled={
                        yearDependenciesLoading ||
                        !year ||
                        !year.value ||
                        !divisionId ||
                        !divisionId.value ||
                        !brandId ||
                        !brandId.value ||
                        isDisabled
                      }
                      validation={validation.categoriesIds}
                      showErrors={submitted}
                      generalContainerClassName='campaign-checkbox-form'
                      options={optionsCategories}
                      settings={{
                        isSearchable: true,
                        isMulti: true,
                        closeMenuOnSelect: false,
                      }}
                      nestedChildren={
                        <label className='campaign-checkbox-container'>
                          <LocalizedMessage id='flowchart.plannedOnCompanies' />
                          <input
                            type='checkbox'
                            checked={isUsingCompanies}
                            onChange={() =>
                              this.handleIsUsingCompanies(!isUsingCompanies)
                            }
                            disabled={isDisabled}
                          />
                          <span className='campaign-checkbox-checkmark' />
                        </label>
                      }
                      nesetdContainerClassName='campaign-checkbox-form-container'
                    />

                    {isUsingCompanies ? (
                      <OnLoadWrapper
                        onLoad={() => this.campaignsInputChange('', true)}
                      >
                        <Input
                          type='c-select-creatable'
                          formName='campaignsIds'
                          labelId='flowchart.campaignsIds.label'
                          placeholderId='flowchart.campaignsIds.placeholder'
                          value={campaignsIds}
                          onChange={this.handleCampaignsChange}
                          disabled={
                            isLoadingCampaigns ||
                            !year ||
                            !year.value ||
                            !categoriesIds ||
                            !categoriesIds.length ||
                            !divisionId ||
                            !divisionId.value ||
                            !brandId ||
                            !brandId.value ||
                            isDisabled
                          }
                          validation={validation.campaignsIds}
                          showErrors={submitted}
                          options={optionsCampaigns}
                          settings={{
                            isSearchable: true,
                            isMulti: true,
                            closeMenuOnSelect: false,
                            onInputChange: this.campaignsInputChange,
                            onCreateOption: this.onCreateCampaignOption,
                          }}
                        />
                      </OnLoadWrapper>
                    ) : null}
                  </div>
                </div>
              </div>

              {year &&
              year.value &&
              categoriesIds &&
              categoriesIds.length &&
              divisionId &&
              divisionId.value &&
              brandId &&
              brandId.value &&
              ((isUsingCompanies && campaignsIds && campaignsIds.length) ||
                !isUsingCompanies) ? (
                  <Fragment>
                    {isUsingCompanies ? (
                      <div className='ibox'>
                        <div className='ibox-title budgets-switcher_container'>
                          <h5>
                            <LocalizedMessage id='flowchart.budget-campaigns' />
                          </h5>

                          <Switch
                            checked={campaignsEnabled}
                            className='budgets-switcher_item'
                            onChange={(toggle) =>
                              this.onChangeBudgetsEnable(
                                'campaignsEnabled',
                                toggle
                              )
                            }
                            options={{
                              color: '#1AB394',
                              secondaryColor: '#E22C0D',
                            }}
                          />
                        </div>

                        {campaignsEnabled && (
                          <div className='ibox-content'>
                            <BudgetsCampaigns
                              ref={this.setBudgetsCampaignsRef}
                              onUpdateData={this.onUpdateBudgetsData}
                              data={budgetCampaigns}
                              tablesData={this.mediaData}
                              selectedCampaigns={campaignsData}
                              type='campaigns'
                              media={media}
                              currencyValue={currencyValue}
                              budgetsLoading={budgetsLoading}
                              isDisabled={isDisabled}
                            />
                          </div>
                        )}
                      </div>
                    ) : null}

                    <div className='ibox'>
                      <div className='ibox-title budgets-switcher_container'>
                        <h5>
                          <LocalizedMessage id='flowchart.budget-category' />
                        </h5>

                        <Switch
                          checked={categoriesEnable}
                          className='budgets-switcher_item'
                          onChange={(toggle) =>
                            this.onChangeBudgetsEnable('categoriesEnable', toggle)
                          }
                          options={{
                            color: '#1AB394',
                            secondaryColor: '#E22C0D',
                          }}
                        />
                      </div>
                      {categoriesEnable && (
                        <div className='ibox-content'>
                          <Budgets
                            ref={this.setBudgetsRef}
                            onUpdateData={this.onUpdateBudgetsData}
                            data={budgetCategories}
                            tablesData={this.mediaData}
                            selectedCategories={categoriesData}
                            media={media}
                            currencyValue={currencyValue}
                            type='categories'
                            isUsingCompanies={isUsingCompanies}
                            budgetsLoading={budgetsLoading}
                            isDisabled={isDisabled}
                          />
                        </div>
                      )}
                    </div>
                    <CategoryList
                      isUsingCompanies={isUsingCompanies}
                      categories={categoriesData}
                      campaigns={campaignsData}
                      year={year}
                      numbersIo={numbersIO}
                      regions={regions}
                      targetAudiences={targetAudiences}
                      mediaTypes={mediaTypes}
                      mediaTypesMap={this.mediaTypesMap}
                      spendsTypes={spendsTypes}
                      effectiveFrequenciesList={frequencies}
                      brand={brandId}
                      descriptions={descriptions}
                      tvcTypeList={tvcTypeList}
                      tvcTypes={tvcTypes}
                      media={media}
                      addMedia={this.addMedia}
                      removeMedia={this.removeMedia}
                      updateMediaTypes={this.updateMediaTypes}
                      updateSpendsTypeForm={this.updateSpendsTypeForm}
                      updateSpendsTypeTvc={this.updateSpendsTypeTvc}
                      updateSpendsType={this.updateSpendsType}
                      updateMediaData={this.updateMediaData}
                      mediaData={this.mediaData}
                      formData={this.formData}
                      onCreateDescriptionOption={this.onCreateDescriptionOption}
                      mediaOrderSorting={mediaOrderSorting}
                      trackPromiseAll={this.trackPromiseAll}
                      onUpdateMediaValidationStatus={
                        this.onUpdateMediaValidationStatus
                      }
                      isDisabled={isDisabled}
                    />
                  </Fragment>
                ) : null}

              {Object.keys(media).length > 0 && (
                <TableBudgetsAndReach
                  ref={this._budgetsAndReachRef}
                  media={media}
                  data={this.mediaData}
                  isUsingCompanies={isUsingCompanies}
                  summaryBudgetsAndReachs={summaryBudgetsAndReachs}
                  isDisabled={isDisabled}
                />
              )}

              <div className='hr-line-dashed' />

              <ButtonsGroup
                isDisabled={isDisabled}
                onSave={this.onSaveFlowchart}
                onRevert={isVersion ? this.onRevertVersion : undefined}
              />
            </div>
          </div>
        </div>

        <SaveModal
          nameValue={flowchartName}
          statusValue={status}
          isActive={this.state.modalActive}
          onChange={this.handleInputChange}
          onAccept={this.onAcceptSaveModal}
          onClose={() => this.setState({modalActive: false})}
        />

        <RevertVersionModal
          active={this.state.revertModalActive}
          onAccept={this.onAcceptRevertVersionModal}
          onClose={this.onCloseRevertVersionModal}
        />

        <ScrollTopButton />
      </Fragment>
    );
  }
}

export default FlowchartPage;
