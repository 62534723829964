import {connect} from 'react-redux';
import PageLoader from './PageLoader';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  loading: state.loader.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(PageLoader);
