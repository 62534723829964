import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const API = {
  list (request) {
    return Client.post('/api/users/', request);
  },

  getRoles (request) {
    return Client.get('/api/users/roles/', request);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/users/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  get (id) {
    return Client.get(`/api/users/${id}`);
  },

  save (id, request) {
    if (!id) {
      return Client.post(`/api/users/new`, request, true, {}, false);
    }

    return Client.post(`/api/users/${id}`, request, true, {}, false);
  },

  enable (id) {
    return Client.post(`/api/users/${id}/enable`);
  },

  disable (id) {
    return Client.post(`/api/users/${id}/disable`);
  }
};

export default API;
