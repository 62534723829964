import {fetchResponse} from './helpers/fakeFetch';

import currenciesList from './data/currenciesList.json';
import currenciesYears from './data/currenciesYears.json';

const currenciesAPI = {
  list (request) {
    console.log('api.currencies.list(request)');

    return fetchResponse(1000, currenciesList);
  },

  create (request) {
    console.log('api.currencies.create(request)');

    return fetchResponse(1000, {});
  },

  update (request) {
    console.log('api.currencies.update(request)');

    return fetchResponse(1000, {});
  },

  get (year) {
    console.log('api.currencies.get(' + year + ')');

    return fetchResponse(1000, currenciesYears[year]);
  }
};

export default currenciesAPI;
