import React, {Component} from 'react';

import cx from 'classnames';

import MultiSelect from '@khanacademy/react-multi-select';
import './RegionalityList.scss';
import {localizeMessage} from '../../../../components/LocalizedMessage';

const CustomOption = props => {
  const {checked, option, onClick} = props;

  return (
    <div className='regionality-list-list-item'>
      <div
        onClick={onClick}
        className='regionality-list-checkbox-container'
      >
        <div
          className={cx('regionality-list-checkbox-item', {
            'regionality-list-checkbox-item-active': checked
          })}
        />
      </div>

      <div className='regionality-list-list-item-region-name'>
        <span>{option.label}</span>
      </div>
    </div>
  );
};

class RegionalityList extends Component {
  valueRendererHandler = (selected, options) => {
    if (selected.length === 0) {
      return '-';
    }

    if (selected.length === options.length) {
      return '-';
    }

    return selected.map(option => {
      if (typeof option === 'number') {
        option = options.find((_option) => _option.value === option);
      }

      if (!option) {
        return '';
      }

      return option.label;
    }).join(', ');
  };

  onSelectedChanged = (selected) => {
    const {onChange, options} = this.props;

    if (typeof onChange === 'function') {
      onChange(selected.map(option => options.find(_option => _option.value === option)));
    }
  };

  render () {
    const {
      options,
      selected = []
    } = this.props;

    return (
      <MultiSelect
        options={options}
        valueRenderer={this.valueRendererHandler}
        onSelectedChanged={this.onSelectedChanged}
        selectAllLabel={localizeMessage({id: 'select-all'})}
        selected={selected.map(option => option.value)}
        ItemRenderer={CustomOption}
        disableSearch
      />
    );
  }
};

export default RegionalityList;
