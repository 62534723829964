import React, {Component} from 'react';
import cx from 'classnames';
import clone from 'lodash/clone';
import find from 'lodash/find';
import set from 'lodash/set';
import each from 'lodash/each';
import get from 'lodash/get';
import sum from 'lodash/sum';
import moment from 'moment';
import LazyLoad from 'react-lazyload';

import RoundedInput from '../../../../components/RoundedInput';
import Placeholder from '../Form/Placeholder';
import RegionalityList from './RegionalityList';

import {getMonthNames} from '../../../../helpers/utils';

class TableDescriptionsProductionRegionality extends Component {
  state = {
    resultMatrix: [],
    calendarMatrix: [],
    months: [],
    weeks: [],
    tableData: this.props.tableData || {
      table: [],
      budgets: []
    }
  };

  updateSpendsType (values, cb) {
    const {updateSpendsType} = this.props;

    updateSpendsType(values, () => {
      this.setState(values, cb);
    });
  }

  async componentDidMount () {
    await this.recalculateCalendar();
    this.refreshData();
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.year !== this.props.year) {
      const isClearData = typeof this.props.year !== 'undefined';
      await this.recalculateCalendar(nextProps.year);
      this.refreshData(isClearData);
    }

    if (nextProps.descriptionsList !== this.props.descriptionsList) {
      this.refreshData(false, nextProps.descriptionsList);
    }

    if (
      nextProps.regions &&
      this.props.regions &&
      nextProps.regions.length !== this.props.regions.length
    ) {
      this.refreshData(false, null, nextProps.regions);
    }
  }

  recalculateCalendar (_year) {
    const year = _year || this.props.year;

    const dateStart = moment().year(year.value).startOf('year');
    const dateEnd = moment().year(year.value).endOf('year');
    const period = {
      startDate: moment(dateStart),
      endDate: moment(dateEnd)
    };
    const months = [];
    let weekList = [];
    let weekNumber = 1;

    const localizedMonthsName = getMonthNames();

    // eslint-disable-next-line no-unmodified-loop-condition
    while (dateEnd > dateStart) {
      const month = dateStart.format('MM');
      const monthStartDate = moment(dateStart).startOf('month');
      const monthEndDate = moment(dateStart).endOf('month');

      const weeks = [];

      const startWeekDay = moment(monthStartDate).day(1);

      while (startWeekDay.isBefore(monthEndDate)) {
        if (
          (period.startDate.isBefore(startWeekDay) || period.startDate.isSame(startWeekDay)) &&
          (period.endDate.isAfter(startWeekDay) || period.endDate.isSame(startWeekDay)) &&
          (monthStartDate.isBefore(startWeekDay) || monthStartDate.isSame(startWeekDay))
        ) {
          weeks.push({
            date: moment(startWeekDay),
            day: startWeekDay.format('DD'),
            needWeekNumber: true
          });
        }

        startWeekDay.add(7, 'days');
      }

      const isFirstMonth = !months.length;
      let diffDays = null;

      if (isFirstMonth) {
        if (!weeks.length || period.startDate.isBefore(weeks[0].date)) {
          weeks.unshift({
            date: period.startDate,
            day: period.startDate.format('DD'),
            needWeekNumber: true,
            diffDays: weeks[0].date.diff(monthStartDate, 'days'),
          });
        }
      } else if (
        (!weeks.length || monthStartDate.isBefore(weeks[0].date)) &&
        period.startDate.isBefore(monthStartDate)
      ) {
        diffDays = weeks[0].date.diff(monthStartDate, 'days');

        weeks.unshift({
          date: monthStartDate,
          day: monthStartDate.format('DD'),
          needWeekNumber: false,
          diffDays
        });
      }

      // eslint-disable-next-line no-loop-func
      weeks.forEach(week => {
        week.weekNumber = week.needWeekNumber ? weekNumber.toString() : '';

        if (week.needWeekNumber) {
          weekNumber++;
        }
      });

      if (
        typeof diffDays === 'number' &&
        months[months.length - 1]?.weeks[months[months.length - 1].weeks.length - 1]
      ) {
        months[months.length - 1].weeks[months[months.length - 1].weeks.length - 1].diffDays = 7 - diffDays;

        if (weekList[weekList.length - 1]) {
          weekList[weekList.length - 1].diffDays = 7 - diffDays;
        }
      }

      months.push({
        month: month,
        monthFullName: localizedMonthsName[parseInt(month) - 1],
        weeks: weeks
      });

      weekList = weekList.concat(weeks);

      dateStart.add(1, 'month');
    }

    return new Promise((resolve) => {
      this.setState({
        months,
        weeks: weekList
      }, resolve);
    });
  }

  refreshData (clear, _descriptionsList, _regions) {
    let {
      tableData: externalTableData
    } = this.state;

    const descriptionsList = _descriptionsList || this.props.descriptionsList;
    const regions = _regions || this.props.regions;

    const {
      months
    } = this.state;

    if (Array.isArray(externalTableData) && !externalTableData.table) {
      const tableData = clone(externalTableData);
      externalTableData = {
        table: tableData,
        budgets: []
      };
    }

    const resultData = clear
      ? []
      : externalTableData.table;

    descriptionsList.forEach((description) => {
      if (!description) {
        return;
      }

      const descriptionIndex = resultData.findIndex((cell) => cell.name === description.label);

      if (descriptionIndex === -1) {
        const cLens = [];

        const cMonths = [];

        months.forEach((month) => {
          const cWeeks = [];

          month.weeks.forEach(() => {
            cWeeks.push({
              value: null
            });
          });

          cMonths.push({
            weeks: cWeeks,
            value: null
          });
        });

        cLens.push({
          name: description.label,
          cells: {
            len: {
              value: 0
            },
            tpc: {
              value: 0
            }
          },
          months: cMonths,
          regions: regions
        });

        const descriptionMonths = [];

        months.forEach((month) => {
          descriptionMonths.push({
            result: {
              value: 0
            },
            weeksLength: month.weeks.length
          });
        });

        resultData.push({
          name: description.label,
          lens: cLens,
          months: descriptionMonths,
          results: {
            budget: 0
          }
        });
      } else {
        each(resultData[descriptionIndex].lens, len => {
          if (
            !len.regions ||
            !len.regions.length
          ) {
            len.regions = regions;
          }

          each(len.regions, (region, regionIndex) => {
            if (!region) {
              return;
            }

            if (!find(regions, _region => _region.value === region.value)) {
              len.regions.splice(regionIndex, 1);
            }
          });
        });
      }
    });

    const _resultData = clone(resultData);

    each(_resultData, (descriptionData) => {
      const descriptionIndex = resultData.findIndex(d => d.name === descriptionData.name);

      if (!descriptionData) {
        return;
      }

      const descriptionsListDescriptionIndex = descriptionsList.findIndex((description) => {
        return description && (description.label === descriptionData.name);
      });

      if (descriptionsListDescriptionIndex === -1) {
        resultData.splice(descriptionIndex, 1);
      }
    });

    externalTableData.table = resultData;

    this.updateSpendsType({tableData: externalTableData}, () => {
      this.recalculateData();
    });
  }

  recalculateData () {
    const {
      tableData,
      months
    } = this.state;

    const totalBudgetMonth = [];
    const activeWeeks = Array(months.length).fill(0);

    tableData.table.forEach((description) => {
      let tpcSum = 0;

      const monthSums = {};

      description.lens.forEach((len) => {
        if (!len.cells) {
          len.cells = {
            len: {
              value: 0
            },
            tpc: {
              value: 0
            }
          };
        }

        len.cells.tpc.value = 0;

        len.months.forEach((month, monthIndex) => {
          len.cells.tpc.value += month.value;

          if (typeof monthSums[monthIndex] === 'undefined') {
            monthSums[monthIndex] = 0;
          }
          monthSums[monthIndex] += month.value;
        });

        tpcSum += len.cells.tpc.value;
      });

      description.lens.forEach((len) => {
        len.cells.len.value = tpcSum > 0 ? Math.round(len.cells.tpc.value / tpcSum * 100) : 0;
      });

      description.months.forEach((month, monthIndex) => {
        month.result.value = monthSums[monthIndex];

        totalBudgetMonth[monthIndex] = typeof totalBudgetMonth[monthIndex] !== 'number'
          ? monthSums[monthIndex]
          : monthSums[monthIndex] + totalBudgetMonth[monthIndex];
      });

      const budgetsOfDay = months.map((month, monthIndex) => {
        const monthBudget = totalBudgetMonth[monthIndex];
        const numDays = month.weeks.reduce((acc, week) => acc + (week.diffDays || 7), 0);

        return monthBudget / numDays;
      });

      budgetsOfDay.forEach((budgetOfDay, index) => {
        if (budgetOfDay) {
          const currentMonth = months[index];
          const prevMonth = months[index - 1];
          const nextMonth = months[index + 1];
          activeWeeks[index] = currentMonth.weeks.length;

          if (nextMonth && !nextMonth.weeks[0].needWeekNumber) {
            const currentDiffDays = currentMonth.weeks[currentMonth.weeks.length - 1].diffDays;
            const nextDiffDays = nextMonth.weeks[0].diffDays;

            if (
              budgetOfDay * currentDiffDays < budgetsOfDay[index + 1] * nextDiffDays ||
              (
                budgetOfDay * currentDiffDays === budgetsOfDay[index + 1] * nextDiffDays &&
                currentDiffDays < nextDiffDays
              )
            ) {
              activeWeeks[index] -= 1;
            }
          }

          if (prevMonth && !currentMonth.weeks[0].needWeekNumber) {
            const currentDiffDays = currentMonth.weeks[0].diffDays;
            const prevDiffDays = prevMonth.weeks[prevMonth.weeks.length - 1].diffDays;

            if (
              budgetOfDay * currentDiffDays < budgetsOfDay[index - 1] * prevDiffDays ||
              (
                budgetOfDay * currentDiffDays === budgetsOfDay[index - 1] * prevDiffDays &&
                currentDiffDays < prevDiffDays
              )
            ) {
              activeWeeks[index] -= 1;
            }
          }
        }
      });
    });

    tableData.active_weeks = activeWeeks;
    tableData.total_active_weeks = activeWeeks.reduce((acc, count) => acc + count, 0);

    if (!Array.isArray(tableData.budgets)) {
      tableData.budgets = [];
    }

    tableData.budgets[0] = totalBudgetMonth;

    this.updateSpendsType({tableData}, () => {
      this.refreshTables();
    });
  }

  refreshTables () {
    const {
      mediaType
    } = this.props;

    const {
      tableData
    } = this.state;

    const resultMatrix = [];
    const calendarMatrix = [];
    let headCellRowSpan = 0;

    const _totalRow = [];

    const totalBudget = tableData.budgets.reduce((t, e) => t.concat(e)).reduce((t, e) => t + e, 0);
    const resultRows = ['budget', 'active_weeks'];

    tableData.table.forEach((description, descriptionIndex) => {
      headCellRowSpan += description.lens.length + resultRows.length;

      set(description, 'results.budget', sum(tableData.budgets[descriptionIndex]));

      description.lens.forEach((len, lenIndex) => {
        // Result matrix
        const resultRow = [];

        if (!lenIndex) {
          resultRow.push({
            rowspan: description.lens.length,
            classes: ['_text-align--left'],
            data: {
              value: description.name
            }
          });

          resultRow.push({
            rowspan: description.lens.length,
            type: 'regionality-list',
            classes: ['regionality-list-cell'],
            data: {
              descriptionIndex: descriptionIndex,
              lenIndex: lenIndex
            }
          });
        }

        let cNet = 0;
        len.months.forEach((month, monthIndex) => {
          cNet += month.value || 0;
        });

        resultRow.push({
          data: {
            value: cNet.toFixed(2),
          },
          classes: ['with-rub-postfix']
        });

        resultMatrix.push(resultRow);

        // Field matrix
        const fieldRow = [];

        len.months.forEach((month, monthIndex) => {
          fieldRow.push({
            type: 'input',
            dataPath: {
              descriptionIndex: descriptionIndex,
              lenIndex: lenIndex,
              monthIndex: monthIndex
            },
            colspan: month.weeks.length,
            classes: ['input-cell']
          });
        });

        calendarMatrix.push(fieldRow);
      });

      description.months.forEach((month, monthIndex) => {
        _totalRow[monthIndex] = {
          colspan: month.weeksLength,
          data: tableData.budgets[0][monthIndex]
        };
      });
    });

    if (resultMatrix.length) {
      resultMatrix[0].unshift({
        rowspan: headCellRowSpan,
        data: {
          value: mediaType.name
        }
      });
    }

    resultMatrix.push([
      {
        colspan: 2,
        data: {
          value: 'Budget'
        }
      },
      {
        colspan: 1,
        data: {
          value: totalBudget.toFixed(2),
        },
        classes: ['with-rub-postfix']
      }
    ]);

    resultMatrix.push([
      {
        colspan: 2,
        data: {
          value: 'Active Weeks'
        }
      },
      {
        data: {
          value: tableData.total_active_weeks
        }
      }
    ]);

    resultRows.forEach((type) => {
      const totalRow = [];

      _totalRow.forEach((row, monthIndex) => {
        totalRow.push({
          type: 'result',
          colspan: row.colspan,
          data: type === 'budget' ?
            {
              value: row.data
            } :
            {
              value: tableData.active_weeks[monthIndex]
            },
          classes: type === 'budget'
            ? ['with-rub-postfix']
            : []
        });
      });

      calendarMatrix.push(totalRow);
    });

    this.updateSpendsType({tableData});

    this.setState({
      resultMatrix,
      calendarMatrix
    });
  }

  handleInputChange = (e, descriptionIndex, lenIndex, monthIndex) => {
    const {tableData} = this.state;

    tableData.table[descriptionIndex].lens[lenIndex].months[monthIndex].value = +(e.target.value);
    this.updateSpendsType({tableData});
  };

  handleRegionsChange = (values, descriptionIndex, lenIndex) => {
    const {tableData} = this.state;

    tableData.table[descriptionIndex].lens[lenIndex].regions = values;

    this.updateSpendsType({tableData});
  };

  onCalendarFieldChange = () => {
    this.recalculateData();
  };

  safeGetInputValue (descriptionIndex, lenIndex, monthIndex) {
    const {tableData} = this.state;

    return get(tableData, `table[${descriptionIndex}].lens[${lenIndex}].months[${monthIndex}].value`) || '';
  }

  safeGetRegionsValue (descriptionIndex, lenIndex) {
    const {tableData} = this.state;

    return get(tableData, `table[${descriptionIndex}].lens[${lenIndex}].regions`, 0) || [];
  }

  render () {
    const {
      isDisabled = false,
      regions
    } = this.props;

    const {
      tableData,
      resultMatrix,
      calendarMatrix,
      months,
      weeks
    } = this.state;

    if (!tableData || !tableData.table || !tableData.table.length) {
      return null;
    }

    return (
      <LazyLoad
        once
        height={200}
        offset={[200, 200]}
        scrollContainer='#main_content'
        placeholder={<Placeholder />}
      >
        <div className='display-flex'>
          <div>
            <table className='table flowCharts-media-fixed-table'>
              <thead>
                <tr>
                  <th className='text-nowrap' style={{minWidth: '100px'}}>
                    <div>
                      <span>Media</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Description</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Regionality</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>Net<br />media</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  resultMatrix.map((row, rowIndex) => (
                    <tr className='campaigns-table-row-height' key={'row_resultMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            className={cx('text-nowrap', 'campaigns-table-cell-center', ...(cell.classes || []))}
                            colSpan={cell.colspan || 1}
                            rowSpan={cell.rowspan || 1}
                            key={'row_' + rowIndex + '_cell_' + cellIndex}
                          >
                            {
                              cell.type === 'regionality-list' && !isDisabled ? (
                                <RegionalityList
                                  options={regions}
                                  selected={this.safeGetRegionsValue(cell.data.descriptionIndex, cell.data.lenIndex)}
                                  onChange={values =>
                                    this.handleRegionsChange(values, cell.data.descriptionIndex, cell.data.lenIndex)
                                  }
                                />
                              ) : (
                                <span>
                                  {cell.data.value}
                                </span>
                              )
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className='right-flex-table'>
            <table className='table flowCharts-media-months-table'>
              <thead>
                <tr>
                  {
                    months.map((month, monthIndex) => (
                      <th
                        key={'month_' + monthIndex}
                        className='text-nowrap'
                        colSpan={month.weeks.length}
                      >
                        <span>{month.monthFullName}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <th
                        key={'week-number_' + weekIndex}
                        className='text-nowrap'
                      >
                        <span>{week.weekNumber}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <th
                        key={'week-day_' + weekIndex}
                        className='text-nowrap'
                      >
                        <span>{week.day}</span>
                      </th>
                    ))
                  }
                </tr>
              </thead>

              <tbody>
                {
                  calendarMatrix.map((row, rowIndex) => (
                    <tr key={'row_calendarMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            key={'row_calendarMatrix_' + rowIndex + '_cell_' + cellIndex}
                            className={cx(...cell.classes)}
                            colSpan={cell.colspan || 1}
                          >
                            {
                              cell.type === 'input' ? (
                                <div>
                                  <RoundedInput
                                    type='number'
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        cell.dataPath.descriptionIndex,
                                        cell.dataPath.lenIndex,
                                        cell.dataPath.monthIndex
                                      )
                                    }
                                    value={
                                      this.safeGetInputValue(
                                        cell.dataPath.descriptionIndex,
                                        cell.dataPath.lenIndex,
                                        cell.dataPath.monthIndex
                                      )
                                    }
                                    disabled={cell.disabled || isDisabled}
                                    className={cx(
                                      'form-control',
                                      'flowCharts-media-months-table-field',
                                      'flowCharts-media-months-table-field--full-width'
                                    )}
                                    min='0'
                                    onBlur={this.onCalendarFieldChange}
                                  />
                                </div>
                              ) : (
                                <span>
                                  {cell.data.value}
                                </span>
                              )
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </LazyLoad>
    );
  }
}

export default TableDescriptionsProductionRegionality;
