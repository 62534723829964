import Client from './HTTPClient';
import Config from '../../config';

const AuthAPI = {
  logIn (mail, password) {
    return Client.post(`${Config.api.authHost}/api/auth/login`, {
      mail,
      password
    }, false, {isJson: true}, false);
  },

  impersonateLogIn (username) {
    return Client.get(`${Config.api.authHost}/api/auth/login/impersonate`, {
      username
    }, false, {isJson: false}, false, null, {
      mode: 'no-cors'
    });
  },

  getProfile () {
    return Client.get(`${Config.api.authHost}/api/auth/logged-in`, {}, false, {isJson: false});
  },

  impersonateLogOut () {
    return Client.get(`${Config.api.authHost}/api/auth/logout/impersonate`, {}, false, {isJson: false}, false, null, {
      mode: 'no-cors'
    });
  },

  logOut () {
    return Client.get(`${Config.api.authHost}/api/auth/logout`, {}, false, {isJson: false}, false, null, {
      mode: 'no-cors'
    });
  }
};

export default AuthAPI;
