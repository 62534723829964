import {connect} from 'react-redux';
import {checkRoles} from '../../../modules/auth';
import FlowchartPage from '../components/Flowchart';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({
  user: state.auth.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(FlowchartPage);
