import moment from 'moment';
import zipObject from 'lodash/zipObject';

export const wait = ms => new Promise(resolve => {
  setTimeout(resolve, ms);
});

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const UUID = a => (
  // eslint-disable-next-line no-mixed-operators
  a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, UUID)
);

export const escape = (str) => {
  return (str + '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);
};

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export const arrayToObject = (sourceArray, keyName) => {
  const resultObject = {};

  for (let i = 0; i < sourceArray.length; i++) {
    resultObject[sourceArray[i][keyName]] = sourceArray[i];
  }

  return resultObject;
};

const requestTimeoutsStorage = {};
const requestCallbacksStorage = {};

export const safeApiRequest = (requestFunction, ...args) => { // todo fix multiple no uniq requests
  const argsStr = JSON.stringify(args);

  if (!requestTimeoutsStorage[requestFunction]) {
    requestTimeoutsStorage[requestFunction] = {};
  }

  if (!requestCallbacksStorage[requestFunction]) {
    requestCallbacksStorage[requestFunction] = {};
  }

  if (!requestTimeoutsStorage[requestFunction][argsStr]) {
    requestTimeoutsStorage[requestFunction][argsStr] = 0;
  }

  return new Promise((resolve, reject) => {
    clearTimeout(requestTimeoutsStorage[requestFunction][argsStr]);

    const prevFn = requestCallbacksStorage[requestFunction][argsStr];

    requestCallbacksStorage[requestFunction][argsStr] = () => {
      if (typeof prevFn === 'function') {
        prevFn();
      }

      requestFunction.apply(null, args).then(resolve).catch(reject);
    };

    requestTimeoutsStorage[requestFunction][argsStr] = setTimeout(() => {
      requestCallbacksStorage[requestFunction][argsStr]();
    }, 10);
  });
};

export const capitalizeFirstLetter = sourceString => (
  sourceString.charAt(0).toUpperCase() + sourceString.slice(1).toLowerCase()
);

export const PromiseAll = (obj) => {
  const keys = Object.keys(obj);

  return Promise.all(keys.map(key => {
    const value = obj[key];
    // Promise.resolve(value) !== value should work, but !value.then always works
    if (typeof value === 'object' && !value.then) {
      return PromiseAll(value);
    }

    return value;
  }))
    .then(result => zipObject(keys, result));
};

export const tryParseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return void 0;
  }
};

export const getMonthNames = () => (
  moment.months()
);

export const getMonthShortNames = () => (
  moment.monthsShort()
);

export const generateOptionListFromActiveMediaTypes = (mediaTypes) => {
  const resultOptions = [];

  for (const mediaType of Object.values(mediaTypes)) {
    if (mediaType.isActive) {
      resultOptions.push({
        value: mediaType.id,
        label: mediaType.name
      });
    }
  }

  return resultOptions;
};
