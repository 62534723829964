import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import FilterList from '../../../../components/FilterList';
import TableList from '../../../../components/TableList';
import Select from '../../../../components/Select';

import API from '../../../../api';
import Alert from '../../../../helpers/alert';

interface IOwnProps {
  checkRoles: (role: string) => boolean;
}

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;

  aimMacOptions: any[];
  categoryExtensionOptions: any[];
  numberIoOptions: any[];
  newName: string;
  newExternalId: string;
  aimMacValue: any;
  categoryExtensionsValue: any;
  numberIoValue: any;
}

class Brands extends PureComponent<IOwnProps & Props, IState> {
  static propTypes = {};

  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,

    aimMacOptions: [],
    categoryExtensionOptions: [],
    numberIoOptions: [],

    newName: '',
    newExternalId: '',
    aimMacValue: null,
    categoryExtensionsValue: null,
    numberIoValue: null
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;
  _form = React.createRef<HTMLFormElement>();

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async refreshFilterItems () {
    const [filterItems, aimMac, categoryExtensions, io] = await Promise.all([
      API.brands.getFilterItems(),
      API.aimMac.list({ max: 0 }),
      API.categoryExtensions.list({ max: 0 }),
      API.io.list({ max: 0 })
    ]);

    if (!this.mounted) {
      return;
    }

    const aimMacOptions = aimMac.items.map(item => ({
      value: item.id,
      label: item.aimBudget + ' - ' + item.macBudget
    }));

    const categoryExtensionOptions = categoryExtensions.items.map(item => ({
      value: item.id,
      label: item.name
    }));

    const numberIoOptions = io.items.map(item => ({
      value: item.id,
      label: item.name
    }));

    this.setState({
      filterItems,
      aimMacOptions,
      categoryExtensionOptions,
      numberIoOptions
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });
      try {
        const dataList = await API.brands.list(dataListRequest);

        if (!this.mounted) {
          return;
        }

        this.setState({
          dataList: dataList.items,
          dataListTotal: dataList.total,
          isLoading: false,
        });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='brands' />,
        link: '/app/dictionaries/brands'
      }
    ];
  }

  setFormRef = (ref) => {
    this._form = ref;
  };

  handleChangeAimMacSelect = (aimMacValue) => {
    this.setState({ aimMacValue });
  };

  getSelectAimMacConfig () {
    const { aimMacValue, aimMacOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: true,
      value: aimMacValue,
      onChange: this.handleChangeAimMacSelect,
      options: aimMacOptions,
      placeholder: <LocalizedMessage id='brands-dictionary.aim-mac.placeholder' />
    };
  }

  handleChangeCategoryExtensionsSelect = (categoryExtensionsValue) => {
    this.setState({ categoryExtensionsValue });
  };

  getSelectCategoryExtensionsConfig () {
    const { categoryExtensionsValue, categoryExtensionOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: true,
      value: categoryExtensionsValue,
      onChange: this.handleChangeCategoryExtensionsSelect,
      options: categoryExtensionOptions,
      placeholder: <LocalizedMessage id='brands-dictionary.categoryExtensions.placeholder' />
    };
  }

  handleChangeNumberIoSelect = (numberIoValue) => {
    this.setState({ numberIoValue });
  };

  getSelectNumberIoConfig () {
    const { numberIoValue, numberIoOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: true,
      value: numberIoValue,
      onChange: this.handleChangeNumberIoSelect,
      options: numberIoOptions,
      placeholder: <LocalizedMessage id='brands-dictionary.numberIo.placeholder' />
    };
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    this.setState({
      [e.target.name as 'newExternalId']: value
    });
  };

  resetClick = () => {
    if (this._form.current) {
      this._form.current.reset();
    }

    this.setState({
      newName: '',
      newExternalId: '',
      aimMacValue: null,
      categoryExtensionsValue: null,
      numberIoValue: null
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      newName,
      newExternalId,
      aimMacValue,
      categoryExtensionsValue,
      numberIoValue
    } = this.state;

    if (
      (!newName || !newName.length) ||
      (!newExternalId || !newExternalId.length) ||
      (!aimMacValue || !aimMacValue.length) ||
      (!categoryExtensionsValue || !categoryExtensionsValue.length) ||
      (!numberIoValue || !numberIoValue.length)
    ) {
      return;
    }

    try {
      await API.brands.create({
        aimMacsIds: aimMacValue.map(item => item.value),
        categoryExtensionIds: categoryExtensionsValue.map(item => item.value),
        ioIds: numberIoValue.map(item => item.value),
        externalId: newExternalId,
        name: newName,
        ver: 0
      });

      Alert.success(localizeMessage({ id: 'brands-dictionary.successfullyCreated' }));

      this.resetClick();

      this.refreshList();
    } catch (error) {
      console.error(error);

      Alert.warn(localizeMessage({ id: 'brands-dictionary.errorCreating' }));
    }
  };

  render () {
    const {
      checkRoles, onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,

      newName,
      newExternalId,
      aimMacValue,
      categoryExtensionsValue,
      numberIoValue
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.brands'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <form
                    ref={this.setFormRef}
                    onSubmit={this.onSubmit}
                  >
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label className='col-lg-2 control-label'><LocalizedMessage id='name' /></label>
                        <div className='col-lg-10'>
                          <LocalizedMessage id='name'>
                            {localizedPlaceholder => (
                              <input
                                type='text'
                                className='form-control'
                                name='newName'
                                value={newName}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleInputChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='form-group'>
                        <label className='col-lg-2 control-label'><LocalizedMessage id='externalId' /></label>

                        <div className='col-lg-10'>
                          <LocalizedMessage id='externalId'>
                            {localizedPlaceholder => (
                              <input
                                type='text'
                                className='form-control'
                                name='newExternalId'
                                value={newExternalId}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleInputChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='form-group'>
                        <label className='col-lg-2 control-label' htmlFor='aimMacIds'>Aim Mac</label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectAimMacConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='categoryExtensionIds'
                        >
                          <LocalizedMessage id='categoryExtensions' />
                        </label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectCategoryExtensionsConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label className='col-lg-2 control-label' htmlFor='ioIds'>Number IO</label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectNumberIoConfig()} />
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <button className='btn btn-white' onClick={this.resetClick}>
                            <LocalizedMessage id='reset' />
                          </button>
                          &nbsp;&nbsp;
                          {
                            checkRoles('SUPER_ADMIN,ADMIN')
                              ? (
                                <button
                                  type='submit'
                                  className='btn btn-primary'
                                  disabled={(
                                    (!newName || !newName.length) ||
                                    (!newExternalId || !newExternalId.length) ||
                                    (!aimMacValue || !aimMacValue.length) ||
                                    (!categoryExtensionsValue || !categoryExtensionsValue.length) ||
                                    (!numberIoValue || !numberIoValue.length)
                                  )}
                                  onClick={this.onSubmit}
                                >
                                  <>
                                    <i className='fa fa-chevron-down fa-lg' />
                                    <span><LocalizedMessage id='save' /></span>
                                  </>
                                </button>
                              )
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='brands-dictionary.brandsFilter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: 'ID',
                          sort: 'id'
                        },
                        {
                          label: localizeMessage({ id: 'name' }),
                          sort: 'name'
                        }
                      ]}
                    >
                      {
                        dataList.map(brand => (
                          <tr key={brand.id + '_' + brand.name}>
                            <td>
                              {brand.id}
                            </td>
                            <td>
                              {brand.name}
                            </td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Brands, 'id');
