import {connect} from 'react-redux';
import {checkRoles} from '../../../../modules/auth';
import Sync from '../components/Sync';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sync);
