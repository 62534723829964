import React, {Component} from 'react';
import cx from 'classnames';
import {VariableSizeList as List} from 'react-window';
import Select, {Async, AsyncCreatable, Creatable} from '../../../../components/Select';
import {localizeMessage} from '../../../../components/LocalizedMessage';

const GROUP_HEADER_HEIGHT = 13;
const itemHeight = 34;

const MenuList = (props) => {
  const {options, getValue} = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;

  const children = React.Children.toArray(props.children);

  function getOptionSize (option) {
    if (option && option.options) {
      return option.options.length * itemHeight + GROUP_HEADER_HEIGHT;
    }

    return itemHeight;
  }

  function getItemSize (i) {
    return getOptionSize(options[i]);
  }

  const totalHeight = children.reduce((height, option) => {
    return height + getOptionSize(option);
  }, 0);

  const estimatedItemSize = totalHeight / children.length;

  return (
    <List
      height={Math.min(totalHeight, 300)}
      itemCount={children.length}
      itemSize={getItemSize}
      estimatedItemSize={estimatedItemSize}
      initialScrollOffset={initialOffset}
      className='react-select__menu-list'
    >
      {({index, style}) => {
        return <div style={style}>{children[index]}</div>;
      }}
    </List>
  );
};

class Input extends Component {
  state = {
    inputValue: ''
  };

  setInputText = (inputValue) => {
    this.setState({inputValue});
  };

  getOptions = (value, options) => {
    const {inputValue} = this.state;

    if (!value) {
      return options;
    }

    return options.filter(option => {
      if (Array.isArray(value)) {
        return value.findIndex(_option =>
          _option.value === option.value) !== -1 ||
          option.label.toLowerCase().includes(inputValue.toLowerCase());
      }

      return value.value === option.value || option.label.toLowerCase().includes(inputValue.toLowerCase());
    });
  };

  handleSelectChange = (values, action) =>
    this.props.onChange({target: {value: values, name: this.props.formName}}, action);

  render () {
    const {
      formName,
      value,
      onChange,
      labelId,
      placeholderId,
      type,
      maxLength = 256,
      validation = {},
      showErrors,
      options,
      settings = {},
      containerClassName = '',
      generalContainerClassName = '',
      nesetdContainerClassName = '',
      disabled = false,
      children,
      nestedChildren
    } = this.props;

    const label = localizeMessage({id: labelId});
    const placeholder = localizeMessage({id: placeholderId});

    let component = null;
    switch (type) {
      case 'c-select': {
        component = (
          <Select
            isDisabled={disabled}
            className={cx({
              error: validation.isInvalid && showErrors
            })}
            value={value}
            onChange={this.handleSelectChange}
            placeholder={placeholder}
            options={options}
            {...settings}
          />
        );

        break;
      }
      case 'c-select-virtualized': {
        component = (
          <Select
            components={{
              MenuList
            }}
            isDisabled={disabled}
            className={cx({
              error: validation.isInvalid && showErrors
            })}
            value={value}
            onChange={this.handleSelectChange}
            placeholder={placeholder}
            onInputChange={this.setInputText}
            options={this.getOptions(value, options)}
            {...settings}
          />
        );

        break;
      }
      case 'c-select-async': {
        component = (
          <Async
            isDisabled={disabled}
            className={cx({
              error: validation.isInvalid && showErrors
            })}
            value={value}
            onChange={this.handleSelectChange}
            placeholder={placeholder}
            options={options}
            {...settings}
          />
        );

        break;
      }
      case 'c-select-creatable': {
        component = (
          <Creatable
            isDisabled={disabled}
            className={cx({
              error: validation.isInvalid && showErrors
            })}
            value={value}
            onChange={this.handleSelectChange}
            placeholder={placeholder}
            options={options}
            {...settings}
          />
        );

        break;
      }
      case 'c-select-asyncCreatable': {
        component = (
          <AsyncCreatable
            isDisabled={disabled}
            className={cx({
              error: validation.isInvalid && showErrors
            })}
            value={value}
            onChange={this.handleSelectChange}
            placeholder={placeholder}
            options={options}
            {...settings}
          />
        );

        break;
      }
      default: {
        component = (
          <input
            id={formName}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            className={cx('form-control', {
              error: validation.isInvalid && showErrors
            })}
            value={value}
            onChange={onChange}
            name={formName}
            disabled={disabled}
          />
        );

        break;
      }
    }

    return (
      <div className={cx('row', 'form-group', generalContainerClassName)}>
        <div className='col-lg-6 center-label'>
          <label
            className='col-lg-5 control-label'
            htmlFor={formName}
          >
            {label}
          </label>
          <div className={cx('col-lg-7', containerClassName)}>
            {component}
            {children}
          </div>
        </div>
        <div className={cx('col-lg-6', nesetdContainerClassName)}>
          {nestedChildren}
        </div>
      </div>
    );
  }
}

export default Input;
