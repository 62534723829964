import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';

class Main extends Component {
  static propTypes = {
    profile: PropTypes.object
  };

  render () {
    const {profile} = this.props;

    if (profile.role.name === 'SUPER_ADMIN') {
      return (
        <Redirect
          to={{
            pathname: '/app/settings/users'
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/app/flowcharts'
        }}
      />
    );
  }
}

export default Main;
