import auth from './auth';
import agencies from './agencies';
import countries from './countries';
import cpp from './cpp';
import currencies from './currencies';
import divisions from './divisions';
import flowcharts from './flowcharts';
import frequencies from './frequencies';
import indoor from './indoor';
import io from './io';
import mediaTypes from './mediaTypes';
import personaTargetAudience from './personaTargetAudience';
import regions from './regions';
import spendsType from './spendsType';
import tvc from './tvc';

export default {
  auth,
  agencies,
  countries,
  cpp,
  currencies,
  divisions,
  flowcharts,
  frequencies,
  indoor,
  io,
  mediaTypes,
  personaTargetAudience,
  regions,
  spendsType,
  tvc
};
