import React, {Component} from 'react';
import clone from 'lodash/clone';
import find from 'lodash/find';
import set from 'lodash/set';
import each from 'lodash/each';
import flatMapDeep from 'lodash/flatMapDeep';
import get from 'lodash/get';
import sum from 'lodash/sum';
import cx from 'classnames';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import Tooltip from 'rc-tooltip';

import LocalizedMessage from '../../../../components/LocalizedMessage';
import RoundedInput from '../../../../components/RoundedInput';
import Placeholder from '../Form/Placeholder';
import RegionalityList from './RegionalityList';

import {getMonthNames} from '../../../../helpers/utils';

class TableDescriptionsPlacementRegionality extends Component {
  state = {
    resultMatrix: [],
    calendarMatrix: [],
    months: [],
    weeks: [],
    tableData: this.props.tableData || {
      table: [],
      budgets: [],
      unlockedCrossWeeksMonths: []
    }
  };

  budgetInputOldValues = {};

  updateSpendsType (values, cb) {
    const {updateSpendsType} = this.props;

    updateSpendsType(values, () => {
      this.setState(values, cb);
    });
  }

  async componentDidMount () {
    await this.recalculateCalendar();
    this.refreshData();
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.year !== this.props.year) {
      const isClearData = typeof this.props.year !== 'undefined';
      await this.recalculateCalendar(nextProps.year);
      this.refreshData(isClearData);
    }

    if (nextProps.descriptionsList !== this.props.descriptionsList) {
      this.refreshData(false, nextProps.descriptionsList);
    }

    if (
      nextProps.regions &&
      this.props.regions &&
      nextProps.regions.length !== this.props.regions.length
    ) {
      this.refreshData(false, null, nextProps.regions);
    }
  }

  recalculateCalendar (_year) {
    const year = _year || this.props.year;

    const dateStart = moment().year(year.value).startOf('year');
    const dateEnd = moment().year(year.value).endOf('year');
    const period = {
      startDate: moment(dateStart),
      endDate: moment(dateEnd)
    };
    const months = [];
    let weekList = [];
    let weekNumber = 1;

    const localizedMonthsName = getMonthNames();

    // eslint-disable-next-line no-unmodified-loop-condition
    while (dateEnd > dateStart) {
      const month = dateStart.format('MM');
      const monthStartDate = moment(dateStart).startOf('month');
      const monthEndDate = moment(dateStart).endOf('month');

      const weeks = [];

      const startWeekDay = moment(monthStartDate).day(1);

      while (startWeekDay.isBefore(monthEndDate)) {
        if (
          (period.startDate.isBefore(startWeekDay) || period.startDate.isSame(startWeekDay)) &&
          (period.endDate.isAfter(startWeekDay) || period.endDate.isSame(startWeekDay)) &&
          (monthStartDate.isBefore(startWeekDay) || monthStartDate.isSame(startWeekDay))
        ) {
          weeks.push({
            date: moment(startWeekDay),
            day: startWeekDay.format('DD'),
            monthIndex: dateStart.month(),
            needWeekNumber: true
          });
        }

        startWeekDay.add(7, 'days');
      }

      const isFirstMonth = !months.length;

      let diffDays = null;

      if (isFirstMonth) {
        if (!weeks.length || period.startDate.isBefore(weeks[0].date)) {
          weeks.unshift({
            date: period.startDate,
            day: period.startDate.format('DD'),
            monthIndex: dateStart.month(),
            needWeekNumber: true
          });
        }
      } else {
        if (
          (
            !weeks.length ||
            monthStartDate.isBefore(weeks[0].date)
          ) &&
          period.startDate.isBefore(monthStartDate)
        ) {
          diffDays = weeks[0].date.diff(monthStartDate, 'days');

          weeks.unshift({
            date: monthStartDate,
            day: monthStartDate.format('DD'),
            monthIndex: dateStart.month(),
            needWeekNumber: false,
            diffDays: weeks[0].date.diff(monthStartDate, 'days')
          });
        }
      }

      weeks.forEach(week => { // eslint-disable-line no-loop-func
        week.weekNumber = week.needWeekNumber ? weekNumber.toString() : ''; // eslint-disable-line no-loop-func

        if (week.needWeekNumber) {
          weekNumber++;
        }
      });

      if (
        typeof diffDays === 'number' &&
        months[months.length - 1] &&
        months[months.length - 1].weeks[months[months.length - 1].weeks.length - 1]
      ) {
        months[months.length - 1].weeks[months[months.length - 1].weeks.length - 1].diffDays = 7 - diffDays;

        if (weekList[weekList.length - 1]) {
          weekList[weekList.length - 1].diffDays = 7 - diffDays;
        }
      }

      months.push({
        month: month,
        monthFullName: localizedMonthsName[parseInt(month) - 1],
        weeks: weeks
      });

      weekList = weekList.concat(weeks);

      dateStart.add(1, 'month');
    }

    return new Promise((resolve) => {
      this.setState({
        months,
        weeks: weekList
      }, resolve);
    });
  }

  toggleCrossWeeksLocking (monthIndex) {
    const {
      tableData,
      months
    } = this.state;

    const updatedUnlockedCrossWeeksMonths = tableData.unlockedCrossWeeksMonths.slice();

    const foundMonthIndex = updatedUnlockedCrossWeeksMonths.indexOf(monthIndex);

    if (foundMonthIndex > -1) {
      updatedUnlockedCrossWeeksMonths.splice(foundMonthIndex, 1);

      tableData.table.forEach(table => {
        table.lens.forEach(len => {
          const lastMonthWeekIndex = len.months[monthIndex].weeks.length - 1;

          const cValue =
            (len.months[monthIndex].weeks[lastMonthWeekIndex].value || 0) +
            (len.months[monthIndex + 1].weeks[0].value || 0);
          len.months[monthIndex].weeks[lastMonthWeekIndex].value =
            Math.round(cValue * 1000) / 1000;
          len.months[monthIndex + 1].weeks[0].value = '';
        });
      });
    } else {
      updatedUnlockedCrossWeeksMonths.push(monthIndex);

      tableData.table.forEach(table => {
        table.lens.forEach(len => {
          const lastMonthWeekIndex = len.months[monthIndex].weeks.length - 1;

          const cValue =
            (len.months[monthIndex].weeks[lastMonthWeekIndex].value || 0) +
            (len.months[monthIndex + 1].weeks[0].value || 0);
          const diffDays =
            months[monthIndex + 1].weeks[0].date.diff(months[monthIndex].weeks[lastMonthWeekIndex].date, 'days');

          len.months[monthIndex].weeks[lastMonthWeekIndex].value =
            Math.round((diffDays / 7 * cValue) * 1000) / 1000;
          len.months[monthIndex + 1].weeks[0].value =
            Math.round(((7 - diffDays) / 7 * cValue) * 1000) / 1000;
        });
      });
    }

    this.updateSpendsType({
      tableData: {
        ...tableData,
        unlockedCrossWeeksMonths: updatedUnlockedCrossWeeksMonths.sort((a, b) => a - b)
      }
    }, () => {
      this.recalculateData();
    });
  }

  refreshData (clear, _descriptionsList, _regions) {
    let {
      tableData: externalTableData
    } = this.state;

    const descriptionsList = _descriptionsList || this.props.descriptionsList;
    const regions = _regions || this.props.regions;

    const {
      months
    } = this.state;

    if (Array.isArray(externalTableData) && !externalTableData.table) {
      const tableData = clone(externalTableData);

      externalTableData = {
        table: tableData,
        budgets: [],
        unlockedCrossWeeksMonths: []
      };
    }

    const resultData = clear
      ? []
      : externalTableData.table;

    descriptionsList.forEach((description) => {
      if (!description) {
        return;
      }

      const descriptionIndex = resultData.findIndex((cell) => cell.name === description.label);

      if (descriptionIndex === -1) {
        const cLens = [];
        const cMonths = [];

        months.forEach((month) => {
          const cWeeks = [];

          month.weeks.forEach(() => {
            cWeeks.push({
              value: null
            });
          });

          cMonths.push({
            weeks: cWeeks,
            value: null
          });
        });

        cLens.push({
          name: description.label,
          months: cMonths,
          regions: regions
        });

        const descriptionMonths = [];

        months.forEach((month) => {
          descriptionMonths.push({
            result: {
              value: 0
            },
            results: {
              active_weeks: {
                value: 0
              }
            },
            weeksLength: month.weeks.length
          });
        });

        resultData.push({
          name: description.label,
          lens: cLens,
          months: descriptionMonths,
          results: {
            budget: 0
          }
        });
      } else {
        each(resultData[descriptionIndex].lens, (len) => {
          if (
            !len.regions ||
            !len.regions.length
          ) {
            len.regions = regions;
          }

          each(len.regions, (region, regionIndex) => {
            if (!region) {
              return;
            }

            if (!find(regions, _region => _region.value === region.value)) {
              len.regions.splice(regionIndex, 1);
            }
          });
        });
      }
    });

    const _resultData = clone(resultData);

    each(_resultData, (descriptionData) => {
      const descriptionIndex = resultData.findIndex(d => d.name === descriptionData.name);

      if (!descriptionData) {
        return;
      }

      const descriptionsListDescriptionIndex = descriptionsList.findIndex((description) => {
        return description && (description.label === descriptionData.name);
      });

      if (descriptionsListDescriptionIndex === -1) {
        resultData.splice(descriptionIndex, 1);
      }
    });

    externalTableData.table = resultData;

    this.updateSpendsType({tableData: externalTableData}, () => {
      this.recalculateData();
    });
  }

  recalculateData () {
    const {
      tableData,
      months
    } = this.state;

    const totalBudgetMonth = [];

    tableData.table.forEach((description, descriptionIndex) => {
      const monthSums = {};

      description.lens.forEach((len) => {
        len.months.forEach((month, monthIndex) => {
          let cMonthSum = 0;

          month.weeks.forEach((week) => {
            if (typeof week.value === 'number') {
              cMonthSum += week.value;
            }
          });

          if (typeof monthSums[monthIndex] === 'undefined') {
            monthSums[monthIndex] = 0;
          }

          month.sum = cMonthSum;
          monthSums[monthIndex] += cMonthSum;
        });
      });

      let sumLens = months.map(month => month.weeks.map(() => 0));

      description.months.forEach((month, monthIndex) => {
        description.lens.forEach(len => {
          len.months[monthIndex].weeks.forEach((week, weekIndex) => {
            sumLens[monthIndex][weekIndex] += Number(week.value);
          });
        });
      });

      description.months.forEach((month, monthIndex) => {
        month.result.value = monthSums[monthIndex];

        if (!Array.isArray(totalBudgetMonth[descriptionIndex])) {
          totalBudgetMonth[descriptionIndex] = [];
        }

        totalBudgetMonth[descriptionIndex][monthIndex] =
          typeof totalBudgetMonth[descriptionIndex][monthIndex] !== 'number'
            ? monthSums[monthIndex]
            : +((monthSums[monthIndex] + totalBudgetMonth[descriptionIndex][monthIndex]));

        if (!this.budgetInputOldValues[descriptionIndex]) this.budgetInputOldValues[descriptionIndex] = {};
        this.budgetInputOldValues[descriptionIndex][monthIndex] = totalBudgetMonth[descriptionIndex][monthIndex];

        const activeWeeksNumber = [];

        description.lens.forEach((len) => {
          len.months[monthIndex].weeks.forEach((week, weekIndex) => {
            if (
              activeWeeksNumber.indexOf(monthIndex + '-' + weekIndex) < 0 &&
              week.value
            ) {
              if (
                weekIndex !== months[monthIndex].weeks.length - 1 &&
                months[monthIndex].weeks[weekIndex].needWeekNumber
              ) {
                activeWeeksNumber.push(monthIndex + '-' + weekIndex);

                return;
              }

              if (
                months[monthIndex].weeks[weekIndex].needWeekNumber &&
                len.months[monthIndex + 1] &&
                len.months[monthIndex + 1].weeks[0] &&
                !months[monthIndex + 1].weeks[0].needWeekNumber &&
                sumLens[monthIndex] && sumLens[monthIndex + 1]
              ) {
                if (sumLens[monthIndex][weekIndex] > sumLens[monthIndex + 1][0] ||
                  (
                    sumLens[monthIndex][weekIndex] === sumLens[monthIndex + 1][0] &&
                    months[monthIndex].weeks[weekIndex].diffDays > months[monthIndex + 1].weeks[0].diffDays
                  )
                ) {
                  activeWeeksNumber.push(monthIndex + '-' + weekIndex);
                }

                return;
              }

              const lastWeek = len.months[monthIndex - 1] ? len.months[monthIndex - 1].weeks.length - 1 : -1;

              if (
                !months[monthIndex].weeks[weekIndex].needWeekNumber &&
                len.months[monthIndex - 1] &&
                len.months[monthIndex - 1].weeks[lastWeek] &&
                months[monthIndex - 1].weeks[lastWeek].needWeekNumber &&
                sumLens[monthIndex][weekIndex] && sumLens[monthIndex - 1]
              ) {
                const lastWeekIndex = months[monthIndex - 1].weeks.length - 1;

                if (sumLens[monthIndex][weekIndex] > sumLens[monthIndex - 1][lastWeekIndex] ||
                  (
                    sumLens[monthIndex][weekIndex] === sumLens[monthIndex - 1][lastWeekIndex] &&
                    months[monthIndex].weeks[weekIndex].diffDays > months[monthIndex - 1].weeks[lastWeekIndex].diffDays
                  )
                ) {
                  activeWeeksNumber.push(monthIndex + '-' + weekIndex);
                }

                return;
              }

              if (
                weekIndex === months[monthIndex].weeks.length - 1 &&
                months[monthIndex].weeks[weekIndex].needWeekNumber &&
                (
                  (
                    len.months[monthIndex + 1] &&
                    len.months[monthIndex + 1].weeks[0] &&
                    months[monthIndex + 1].weeks[0].needWeekNumber
                  ) || monthIndex === months.length - 1
                )
              ) {
                activeWeeksNumber.push(monthIndex + '-' + weekIndex);

                return; // eslint-disable-line no-useless-return
              }
            }
          });
        });

        month.results.active_weeks.value = activeWeeksNumber.length;
      });
    });

    tableData.budgets = totalBudgetMonth;

    this.updateSpendsType({tableData}, () => {
      this.refreshTables();
    });
  }

  refreshTables () {
    const {
      mediaType
    } = this.props;

    const {
      tableData,
      months
    } = this.state;

    const resultMatrix = [];
    const calendarMatrix = [];
    let headCellRowSpan = 0;

    const totalBudget = flatMapDeep(tableData.budgets).reduce((a, b) => a + b, 0);

    tableData.table.forEach((description, descriptionIndex) => {
      const cellRowSpan = description.lens.length + 2;
      headCellRowSpan += cellRowSpan + 2;

      set(description, 'results.budget', sum(tableData.budgets[descriptionIndex]));

      description.lens.forEach((len, lenIndex) => {
        const resultRow = [];

        if (!lenIndex) {
          resultRow.push({
            rowspan: cellRowSpan,
            classes: ['_text-align--left'],
            data: {
              value: description.name
            }
          });

          resultRow.push({
            rowspan: description.lens.length,
            type: 'regionality-list',
            classes: ['regionality-list-cell'],
            data: {
              descriptionIndex: descriptionIndex,
              lenIndex: lenIndex
            }
          });
        }

        resultRow.push({
          data: {
            value: tableData.budgets[descriptionIndex].reduce((a, b) => a + b, 0)
          },
          classes: ['with-rub-postfix']
        });

        resultMatrix.push(resultRow);

        const fieldRow = [];
        len.months.forEach((month, monthIndex) => {
          let enableRecalculateInfoTooltip = false;

          if (
            months[monthIndex + 1] &&
            months[monthIndex + 1].weeks &&
            months[monthIndex + 1].weeks[0] &&
            !months[monthIndex + 1].weeks[0].needWeekNumber
          ) {
            enableRecalculateInfoTooltip = true;
          }

          month.weeks.forEach((week, weekIndex) => {
            let enableRecalculateInfoTooltipWeek = false;

            if (enableRecalculateInfoTooltip && month.weeks.length - 1 === weekIndex) {
              enableRecalculateInfoTooltipWeek = true;
            }

            fieldRow.push({
              type: 'input',
              dataPath: {
                descriptionIndex: descriptionIndex,
                lenIndex: lenIndex,
                monthIndex: monthIndex,
                weekIndex: weekIndex
              },
              needWeekNumber: months[monthIndex].weeks[weekIndex].needWeekNumber,
              isLastWeekOfMonth: weekIndex === month.weeks.length - 1,
              data: week,
              classes: ['input-cell'],
              disabled: !months[monthIndex].weeks[weekIndex].needWeekNumber,
              enableRecalculateInfoTooltip: enableRecalculateInfoTooltipWeek
            });
          });
        });

        calendarMatrix.push(fieldRow);
      });

      resultMatrix.push([
        {
          data: {
            value: 'Budget'
          },
          colspan: 2
        }
      ]);

      resultMatrix.push([
        {
          data: {
            value: 'Active Weeks'
          },
          colspan: 2
        }
      ]);

      const resultRows = ['budget', 'active_weeks'];

      resultRows.forEach((type) => {
        const totalRow = [];

        description.months.forEach((month, monthIndex) => {
          const row = {
            type: 'result',
            colspan: month.weeksLength,
            data: type === 'budget'
              ? {value: tableData.budgets[descriptionIndex][monthIndex]}
              : (month.results && month.results[type] ? month.results[type] : ''),
            classes:
              type === 'budget'
                ? ['input-budget-cell']
                : (type.includes('reach_') ? ['with-empty-spaces'] : [])
          };

          if (type === 'budget') {
            row.dataPath = {
              descriptionIndex: descriptionIndex,
              monthIndex: monthIndex
            };
            row.type = 'input-budget';
          }
          totalRow.push(row);
        });

        calendarMatrix.push(totalRow);
      });

      if (descriptionIndex === tableData.table.length - 1) {
        let totalColspan = 0;

        description.months.forEach((month) => {
          totalColspan += month.weeksLength;
        });

        calendarMatrix.push([
          {
            type: 'result',
            colspan: totalColspan,
            data: '',
            classes: []
          }
        ]);
      }
    });

    if (resultMatrix.length) {
      resultMatrix[0].unshift({
        rowspan: headCellRowSpan,
        data: {
          value: mediaType.name
        }
      });
    }

    resultMatrix.push([
      {
        data: {
          value: 'Total budget'
        },
        colspan: 2
      },
      {
        data: {
          value: totalBudget
        },
        colspan: 1,
        classes: ['with-rub-postfix']
      }
    ]);

    this.updateSpendsType({tableData});

    this.setState({
      resultMatrix,
      calendarMatrix
    });
  }

  handleInputChange = (e, descriptionIndex, lenIndex, monthIndex, weekIndex) => {
    const {tableData} = this.state;

    tableData.table[descriptionIndex].lens[lenIndex].months[monthIndex].weeks[weekIndex].value = +(e.target.value);
    this.updateSpendsType({tableData});
  };

  handleRegionsChange = (values, descriptionIndex, lenIndex) => {
    const {tableData} = this.state;

    tableData.table[descriptionIndex].lens[lenIndex].regions = values;

    this.updateSpendsType({tableData});
  };

  safeGetRegionsValue (descriptionIndex, lenIndex) {
    const {tableData} = this.state;

    return get(tableData, `table[${descriptionIndex}].lens[${lenIndex}].regions`, 0) || [];
  }

  onCalendarFieldChange = (isLastWeekOfMonth, row, cellIndex, path) => {
    const {
      tableData,
      months,
      weeks
    } = this.state;

    const cValue = this.safeGetInputValue(path.descriptionIndex, path.lenIndex, path.monthIndex, path.weekIndex);

    if (cValue >= 100000) {
      tableData.table[path.descriptionIndex].lens[path.lenIndex].months[path.monthIndex].weeks[path.weekIndex].value =
        100000;
    }

    if (
      isLastWeekOfMonth &&
      tableData.unlockedCrossWeeksMonths.includes(weeks[cellIndex].monthIndex) &&
      row[cellIndex + 1] &&
      !row[cellIndex + 1].needWeekNumber
    ) {
      const cValue =
        tableData.table[path.descriptionIndex].lens[path.lenIndex].months[path.monthIndex].weeks[path.weekIndex].value;
      const diffDays =
        months[path.monthIndex + 1].weeks[0].date.diff(months[path.monthIndex].weeks[path.weekIndex].date, 'days');

      tableData.table[path.descriptionIndex].lens[path.lenIndex].months[path.monthIndex].weeks[path.weekIndex].value =
        Math.round((diffDays / 7 * cValue) * 1000) / 1000;
      tableData.table[path.descriptionIndex].lens[path.lenIndex].months[path.monthIndex + 1].weeks[0].value =
        Math.round(((7 - diffDays) / 7 * cValue) * 1000) / 1000;
    }

    this.recalculateData();
  };

  safeGetInputValue (descriptionIndex, lenIndex, monthIndex, weekIndex) {
    const {tableData} = this.state;

    return get(
      tableData,
      `table[${descriptionIndex}].lens[${lenIndex}].months[${monthIndex}].weeks[${weekIndex}].value`
    ) || '';
  }

  handleBudgetInputChange = (e, descriptionIndex, monthIndex) => {
    const {tableData} = this.state;

    tableData.budgets[descriptionIndex][monthIndex] = +(e.target.value);
    this.updateSpendsType({tableData});
  };

  onCalendarBudgetFieldChange (row, index, dataPath) {
    const {tableData} = this.state;

    const newVal = tableData.budgets[dataPath.descriptionIndex][dataPath.monthIndex];
    const oldVal = get(this, `budgetInputOldValues[${dataPath.descriptionIndex}][${dataPath.monthIndex}]`, 0);

    if (newVal === oldVal) {
      return;
    }

    const diffBudget = +(newVal - oldVal);

    each(tableData.table[dataPath.descriptionIndex].lens, (len) => {
      let weeks = len.months[dataPath.monthIndex].weeks;
      let weeksBad = [];

      let howToAdding = diffBudget;

      if (diffBudget < 0) {
        const negativeHowToAddingWeek = +(howToAdding / weeks.length);

        weeks = len.months[dataPath.monthIndex].weeks.filter(week => (
          week.value && week.value >= Math.abs(negativeHowToAddingWeek)
        ));

        weeksBad = len.months[dataPath.monthIndex].weeks.filter(week => (
          week.value && week.value < Math.abs(negativeHowToAddingWeek)
        ));
      }

      if (weeks.length !== len.months[dataPath.monthIndex].weeks.length) {
        each(weeksBad, (week) => {
          howToAdding = howToAdding + week.value;

          week.value = 0;
        });
      }

      howToAdding = +(howToAdding / weeks.length);
      each(weeks, (week) => {
        week.value = (week.value || 0) + howToAdding;

        week.value = week.value < 0 ? 0 : +(week.value);
      });
    });

    this.updateSpendsType({tableData}, () => {
      this.recalculateData();
    });
  }

  safeGetBudgetInputValue (descriptionIndex, monthIndex) {
    const {tableData} = this.state;

    return get(tableData, `budgets[${descriptionIndex}][${monthIndex}]`) || '';
  }

  render () {
    const {
      isDisabled = false,
      regions
    } = this.props;

    const {
      tableData,
      resultMatrix,
      calendarMatrix,
      months,
      weeks
    } = this.state;

    if (!tableData || !tableData.table || !tableData.table.length) {
      return null;
    }

    return (
      <LazyLoad
        once
        height={200}
        offset={[200, 200]}
        scrollContainer='#main_content'
        placeholder={<Placeholder />}
      >
        <div className='display-flex'>
          <div>
            <table className='table flowCharts-media-fixed-table'>
              <thead>
                <tr>
                  <th className='text-nowrap' style={{minWidth: '100px'}}>
                    <div>
                      <span>Media</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Description</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Regionality</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}} colSpan={2}>
                    <div>
                      <span>Net<br />media</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  resultMatrix.map((row, rowIndex) => (
                    <tr className='campaigns-table-row-height' key={'row_resultMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            className={cx('text-nowrap', 'campaigns-table-cell-center', ...(cell.classes || []))}
                            colSpan={cell.colspan || 1}
                            rowSpan={cell.rowspan || 1}
                            key={'row_' + rowIndex + '_cell_' + cellIndex}
                          >
                            {
                              cell.type === 'regionality-list' && !isDisabled ? (
                                <RegionalityList
                                  options={regions}
                                  selected={this.safeGetRegionsValue(cell.data.descriptionIndex, cell.data.lenIndex)}
                                  onChange={values =>
                                    this.handleRegionsChange(values, cell.data.descriptionIndex, cell.data.lenIndex)
                                  }
                                />
                              ) : (
                                <span>
                                  {cell.data.value}
                                </span>
                              )
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className='right-flex-table'>
            <table className='table flowCharts-media-months-table'>
              <thead>
                <tr>
                  {
                    months.map((month, monthIndex) => (
                      <th
                        key={'month_' + monthIndex}
                        className='text-nowrap'
                        colSpan={month.weeks.length}
                      >
                        <span>{month.monthFullName}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <th
                        key={'week-number_' + weekIndex}
                        className='text-nowrap'
                      >
                        {
                          week.needWeekNumber && week.diffDays ?
                            <div className='flowCharts-media-months-table-cell-with-locker'>
                              <i
                                className={cx(
                                  'flowCharts-media-months-table-cell-with-locker-icon',
                                  'fa',
                                  !tableData.unlockedCrossWeeksMonths.includes(week.monthIndex)
                                    ? 'fa-lock'
                                    : 'fa-unlock'
                                )}
                                onClick={() => this.toggleCrossWeeksLocking(week.monthIndex)}
                              />
                            </div>
                            : null
                        }
                        <span>{week.weekNumber}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <Tooltip
                        placement='top'
                        overlay={(
                          <span>{week.diffDays}</span>
                        )}
                        mouseLeaveDelay={0.0}
                        trigger={week.diffDays > 0 ? ['hover'] : []}
                        key={'week-day_' + weekIndex}
                      >
                        <th
                          className='text-nowrap'
                        >

                          <span>{week.day}</span>

                        </th>
                      </Tooltip>
                    ))
                  }
                </tr>
              </thead>

              <tbody>
                {
                  calendarMatrix.map((row, rowIndex) => (
                    <tr key={'row_calendarMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            key={'row_calendarMatrix_' + rowIndex + '_cell_' + cellIndex}
                            className={cx(...cell.classes)}
                            colSpan={cell.colspan || 1}
                          >
                            {
                              cell.type === 'input' ?
                                (
                                  <Tooltip
                                    placement='top'
                                    overlay={(
                                      <span><LocalizedMessage id='flowchart.rating-is-recalculated' /></span>
                                    )}
                                    trigger={cell.enableRecalculateInfoTooltip ? ['hover'] : []}
                                    mouseLeaveDelay={0.0}
                                  >
                                    <div>
                                      <RoundedInput
                                        type='number'
                                        step='any'
                                        onChange={e =>
                                          this.handleInputChange(
                                            e,
                                            cell.dataPath.descriptionIndex,
                                            cell.dataPath.lenIndex,
                                            cell.dataPath.monthIndex,
                                            cell.dataPath.weekIndex
                                          )
                                        }
                                        value={
                                          this.safeGetInputValue(
                                            cell.dataPath.descriptionIndex,
                                            cell.dataPath.lenIndex,
                                            cell.dataPath.monthIndex,
                                            cell.dataPath.weekIndex
                                          )
                                        }
                                        disabled={
                                          (
                                            cell.disabled &&
                                            tableData.unlockedCrossWeeksMonths.includes(weeks[cellIndex - 1].monthIndex)
                                          ) || isDisabled
                                        }
                                        className='form-control flowCharts-media-months-table-field'
                                        min='0'
                                        onBlur={() =>
                                          this.onCalendarFieldChange(
                                            cell.isLastWeekOfMonth,
                                            row,
                                            cellIndex,
                                            cell.dataPath
                                          )
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                )
                                :
                                cell.type === 'input-budget'
                                  ? (
                                    <div>
                                      <RoundedInput
                                        type='number'
                                        step='any'
                                        onChange={(e) =>
                                          this.handleBudgetInputChange(
                                            e,
                                            cell.dataPath.descriptionIndex,
                                            cell.dataPath.monthIndex
                                          )
                                        }
                                        value={
                                          this.safeGetBudgetInputValue(
                                            cell.dataPath.descriptionIndex,
                                            cell.dataPath.monthIndex
                                          )
                                        }
                                        className={cx(
                                          'form-control',
                                          'flowCharts-media-months-table-field',
                                          'flowCharts-media-months-table-field--full-width',
                                          {
                                            'fake-disabled': cell.disabled
                                          }
                                        )}
                                        min='0'
                                        onBlur={() => this.onCalendarBudgetFieldChange(row, cellIndex, cell.dataPath)}
                                        disabled={isDisabled}
                                      />
                                    </div>
                                  )
                                  : <span>{cell.data ? cell.data.value : null}</span>
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </LazyLoad>
    );
  }
}

export default TableDescriptionsPlacementRegionality;
