import React, { PureComponent } from 'react';
import ReactSelect, {
  components as nativeComponents,
  Async as SelectAsync,
  AsyncCreatable as SelectAsyncCreatable,
  Creatable as SelectCreatable,
  SelectBase as SelectSelectBase,
} from '../../libs/react-select/react-select.esm';
import { Props, SingleValueProps, OptionTypeBase } from 'react-select';
import Loader from '../Loader';

const LoadingIndicator = () => (
  <div className='loading-indicator'>
    <Loader
      position='inline'
      isSmall
    />
  </div>
);

const makeSingleValue = (dataTestAttr?: string) => <T extends OptionTypeBase>(props: SingleValueProps<T>) => {
  const { children, className, cx, getStyles, isDisabled, innerProps } = props;
  const cxx = cx as any;

  return (
    <div
      css={getStyles('singleValue', props)}
      className={cxx(
        className,
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled,
        },
      )}
      {...innerProps}
      data-test={dataTestAttr}
    >
      {children}
    </div>
  );
};

const getSelectComponent = <T extends unknown>(type?: SelectType): React.ComponentType<T> => {
  switch (type) {
    case 'Async':
      return SelectAsync;
    case 'AsyncCreatable':
      return SelectAsyncCreatable;
    case 'Creatable':
      return SelectCreatable;
    case 'SelectBase':
      return SelectSelectBase;
    default:
      return ReactSelect;
  }
};

type SelectType = 'Async' | 'AsyncCreatable' | 'Creatable' | 'SelectBase';

interface IOwnProps {
  type?: SelectType;
  dataTestAttr?: string;
}

class Select<T> extends PureComponent<Props<T> & IOwnProps > {
  _mainContent = document.getElementById('main_content');
  SingleValue = makeSingleValue(this.props.dataTestAttr);

  render () {
    const { isMulti = false, type, dataTestAttr, ...otherParams } = this.props;

    const SelectComponent = getSelectComponent<Props<T>>(type);

    return (
      <SelectComponent
        classNamePrefix='react-select'
        theme={theme => ({
          ...theme,
          borderRadius: 0,
        })}
        isMulti={isMulti}
        menuPosition='fixed'
        menuPlacement='auto'
        portalPlacement='fixed'
        menuShouldBlockScroll
        scrollContainer={this._mainContent}
        closeMenuOnSelect={!isMulti}
        {...otherParams}
        components={{
          SingleValue: this.SingleValue,
          LoadingIndicator,
          ...otherParams.components,
        }}
      />
    );
  }
}

export const Async = (props) => (
  <Select
    {...props}
    type='Async'
  />
);

export const AsyncCreatable = (props) => (
  <Select
    {...props}
    type='AsyncCreatable'
  />
);

export const Creatable = (props) => (
  <Select
    {...props}
    type='Creatable'
  />
);

export const SelectBase = (props) => (
  <Select
    {...props}
    type='SelectBase'
  />
);

export const components = nativeComponents;

export default Select;
