import {fetchResponse} from './helpers/fakeFetch';

import mediaTypesList from './data/mediaTypesList.json';

const API = {
  list (request) {
    console.log('api.mediaTypes.list(request)');

    return fetchResponse(1000, mediaTypesList);
  },

  create (request) {
    console.log('api.mediaTypes.create(request)');

    return fetchResponse(1000, {});
  }
};

export default API;
