import React, {Component} from 'react';
import clone from 'lodash/clone';
import find from 'lodash/find';
import each from 'lodash/each';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import uniq from 'lodash/uniq';
import cx from 'classnames';
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import Tooltip from 'rc-tooltip';

import LocalizedMessage from '../../../../components/LocalizedMessage';
import RoundedInput from '../../../../components/RoundedInput';
import Placeholder from '../Form/Placeholder';

import {safeApiRequest, getMonthNames} from '../../../../helpers/utils';
import API from '../../../../api';

const frequency1 = {value: '1+', label: '1+'};

class TableTvcPlacement extends Component {
  state = {
    resultMatrix: [],
    calendarMatrix: [],
    months: [],
    weeks: [],
    tableData: this.props.tableData || {
      table: [],
      budgets: [],
      unlockedCrossWeeksMonths: []
    }
  };

  budgetInputOldValues = {};

  updateSpendsType (values, cb) {
    const {updateSpendsType} = this.props;

    updateSpendsType(values, () => {
      this.setState(values, cb);
    });
  }

  async componentDidMount () {
    this.loadCbu();
    await this.recalculateCalendar();
    this.refreshData();
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.frequencies !== this.props.frequencies) {
      this.refreshData(false);
      this.loadCbu(nextProps.frequencies);
    }

    if (nextProps.year !== this.props.year) {
      const isClearData = typeof this.props.year !== 'undefined';
      await this.recalculateCalendar(nextProps.year);
      this.refreshData(isClearData);
      this.loadCbu();
    }

    if (nextProps.tvcList !== this.props.tvcList) {
      this.refreshData(false, nextProps.tvcList);
    }

    if (nextProps.targetAudience !== this.props.targetAudience) {
      this.loadCbu(null, nextProps.targetAudience);
    }

    if (nextProps.cpp.length !== this.props.cpp.length) {
      this.recalculateData(nextProps.cpp);
    }
  }

  findFrequency (frequency) {
    const {frequenciesList} = this.props;
    const result = find(frequenciesList, (value) => value.value === frequency.value);

    return result ? result.label : '';
  }

  async loadCbu (_frequencies, _targetAudience) {
    const {year, trackPromiseAll} = this.props;

    const frequenciesValues = uniq([...(_frequencies || this.props.frequencies), frequency1].map(({value}) => value));
    const targetAudience = _targetAudience || this.props.targetAudience;
    const promises = {};

    frequenciesValues.forEach((frequencyValue) => {
      promises[frequencyValue] = safeApiRequest(API.cbu.rating, {
        frequencyValue: frequencyValue,
        targetAudienceId: targetAudience.value,
        year: year.value
      });
    });

    try {
      const values = await trackPromiseAll(promises);

      this.cbu = values || {};

      this.refreshData();
    } catch (error) {
      console.error(error);
    }
  }

  getCbuByTpcValue (tpc, frequency) {
    if (
      !this.cbu ||
      !Object.keys(this.cbu).length ||
      !this.cbu[frequency.value] ||
      !Object.keys(this.cbu[frequency.value]).length ||
      tpc <= 0
    ) {
      return 0;
    }

    if (tpc >= 2000) {
      tpc = 2000;
    }

    const cbuKeys = Object.keys(this.cbu[frequency.value]).sort((a, b) => b - a);

    const cbuKey = find(cbuKeys, (cbu) => tpc >= +cbu);

    return this.cbu[frequency.value][cbuKey] || this.cbu[frequency.value][cbuKeys[cbuKeys.length - 1]];
  }

  getMediaTypeKey () {
    const {mediaTypes, mediaType} = this.props;

    return findKey(mediaTypes, _mediaType => (+_mediaType.id) === (+mediaType.id));
  }

  recalculateCalendar (_year) {
    const year = _year || this.props.year;

    const dateStart = moment().year(year.value).startOf('year');
    const dateEnd = moment().year(year.value).endOf('year');
    const period = {
      startDate: moment(dateStart),
      endDate: moment(dateEnd)
    };
    const months = [];
    let weekList = [];
    let weekNumber = 1;

    const localizedMonthsName = getMonthNames();

    // eslint-disable-next-line no-unmodified-loop-condition
    while (dateEnd > dateStart) {
      const month = dateStart.format('MM');
      const monthStartDate = moment(dateStart).startOf('month');
      const monthEndDate = moment(dateStart).endOf('month');

      const weeks = [];

      const startWeekDay = moment(monthStartDate).day(1);

      while (startWeekDay.isBefore(monthEndDate)) {
        if (
          (period.startDate.isBefore(startWeekDay) || period.startDate.isSame(startWeekDay)) &&
          (period.endDate.isAfter(startWeekDay) || period.endDate.isSame(startWeekDay)) &&
          (monthStartDate.isBefore(startWeekDay) || monthStartDate.isSame(startWeekDay))
        ) {
          weeks.push({
            date: moment(startWeekDay),
            day: startWeekDay.format('DD'),
            monthIndex: dateStart.month(),
            needWeekNumber: true
          });
        }

        startWeekDay.add(7, 'days');
      }

      const isFirstMonth = !months.length;

      let diffDays = null;

      if (isFirstMonth) {
        if (!weeks.length || period.startDate.isBefore(weeks[0].date)) {
          weeks.unshift({
            date: period.startDate,
            day: period.startDate.format('DD'),
            monthIndex: dateStart.month(),
            needWeekNumber: true
          });
        }
      } else {
        if (
          (
            !weeks.length ||
            monthStartDate.isBefore(weeks[0].date)
          ) &&
          period.startDate.isBefore(monthStartDate)
        ) {
          diffDays = weeks[0].date.diff(monthStartDate, 'days');

          weeks.unshift({
            date: monthStartDate,
            day: monthStartDate.format('DD'),
            monthIndex: dateStart.month(),
            needWeekNumber: false,
            diffDays: weeks[0].date.diff(monthStartDate, 'days')
          });
        }
      }

      // eslint-disable-next-line no-loop-func
      weeks.forEach(week => {
        week.weekNumber = week.needWeekNumber ? weekNumber.toString() : '';

        if (week.needWeekNumber) {
          weekNumber++;
        }
      });

      if (
        typeof diffDays === 'number' &&
        months[months.length - 1] &&
        months[months.length - 1].weeks[months[months.length - 1].weeks.length - 1]
      ) {
        months[months.length - 1].weeks[months[months.length - 1].weeks.length - 1].diffDays = 7 - diffDays;

        if (weekList[weekList.length - 1]) {
          weekList[weekList.length - 1].diffDays = 7 - diffDays;
        }
      }

      months.push({
        month: month,
        monthFullName: localizedMonthsName[parseInt(month) - 1],
        weeks: weeks
      });

      weekList = weekList.concat(weeks);

      dateStart.add(1, 'month');
    }

    return new Promise((resolve) => {
      this.setState({
        months,
        weeks: weekList
      }, resolve);
    });
  }

  toggleCrossWeeksLocking (monthIndex) {
    const {
      tableData,
      months
    } = this.state;

    const updatedUnlockedCrossWeeksMonths = tableData.unlockedCrossWeeksMonths.slice();

    const foundMonthIndex = updatedUnlockedCrossWeeksMonths.indexOf(monthIndex);

    if (foundMonthIndex > -1) {
      updatedUnlockedCrossWeeksMonths.splice(foundMonthIndex, 1);

      tableData.table.forEach(table => {
        table.lens.forEach(len => {
          const lastMonthWeekIndex = len.months[monthIndex].weeks.length - 1;

          const cValue =
            (len.months[monthIndex].weeks[lastMonthWeekIndex].value || 0) +
            (len.months[monthIndex + 1].weeks[0].value || 0);
          len.months[monthIndex].weeks[lastMonthWeekIndex].value =
            Math.round(cValue * 1000) / 1000;
          len.months[monthIndex + 1].weeks[0].value = '';
        });
      });
    } else {
      updatedUnlockedCrossWeeksMonths.push(monthIndex);

      tableData.table.forEach(table => {
        table.lens.forEach(len => {
          const lastMonthWeekIndex = len.months[monthIndex].weeks.length - 1;

          const cValue =
            (len.months[monthIndex].weeks[lastMonthWeekIndex].value || 0) +
            (len.months[monthIndex + 1].weeks[0].value || 0);
          const diffDays =
            months[monthIndex + 1].weeks[0].date.diff(months[monthIndex].weeks[lastMonthWeekIndex].date, 'days');

          len.months[monthIndex].weeks[lastMonthWeekIndex].value =
            Math.round((diffDays / 7 * cValue) * 1000) / 1000;
          len.months[monthIndex + 1].weeks[0].value =
            Math.round(((7 - diffDays) / 7 * cValue) * 1000) / 1000;
        });
      });
    }

    this.updateSpendsType({
      tableData: {
        ...tableData,
        unlockedCrossWeeksMonths: updatedUnlockedCrossWeeksMonths.sort((a, b) => a - b)
      }
    }, () => {
      this.recalculateData();
    });
  }

  serializeTvcLength (len, tvc) {
    const {tvcTypes} = this.props;
    const {months} = this.state;

    const cMonths = [];

    months.forEach((month) => {
      const cWeeks = [];

      month.weeks.forEach(() => {
        cWeeks.push({
          value: null
        });
      });

      cMonths.push({
        weeks: cWeeks
      });
    });

    let preTest = '';
    let info = {};

    if (tvcTypes[tvc.type.value]) {
      const lengthIndex = tvcTypes[tvc.type.value].length.findIndex(_length => (
        (+_length.value) === (+len)
      ));

      if (lengthIndex !== -1) {
        preTest = tvcTypes[tvc.type.value].length[lengthIndex].preTest;
        info = tvcTypes[tvc.type.value].length[lengthIndex].info;
      }
    }

    return {
      info,
      name: len,
      preTest: preTest,
      cells: {
        len: {
          value: 0
        },
        tpc: {
          value: 0
        }
      },
      months: cMonths
    };
  }

  refreshData (clear, _tvcList) {
    const {tvcTypes} = this.props;

    let {
      tableData: externalTableData
    } = this.state;

    const tvcList = _tvcList || this.props.tvcList;

    const {months} = this.state;

    if (Array.isArray(externalTableData) && !externalTableData.table) {
      const tableData = clone(externalTableData);

      externalTableData = {
        table: tableData,
        budgets: [],
        unlockedCrossWeeksMonths: []
      };
    }

    const resultData = clear
      ? []
      : externalTableData.table;

    tvcList.forEach((tvc) => {
      const tvcIndex = resultData.findIndex((cell) => cell.tvcUuid === tvc.uuid);

      if (tvcIndex === -1) {
        const cLens = [];

        tvc.length.forEach((len) => {
          cLens.push(this.serializeTvcLength(len.value, tvc));
        });

        const tvcMonths = [];

        months.forEach((month) => {
          tvcMonths.push({
            results: {
              total: {
                value: 0
              },
              total30: {
                value: 0
              },
              budget: {
                value: 0
              },
              active_weeks: {
                value: 0
              },
              reach_n: {
                value: ''
              }
            },
            weeksLength: month.weeks.length
          });
        });

        const type = tvcTypes[tvc.type.value];
        resultData.push({
          name: type ? type.name : '',
          lens: cLens,
          months: tvcMonths,
          tvcUuid: tvc.uuid,
          results: {
            budget: 0,
            total30: 0,
            reach: {},
            tpc: {
              total: {
                value: 0
              }
            },
            active_weeks: 0,
          },
        });
      } else {
        const localTvcLengths = resultData[tvcIndex].lens.map((len) => len.name);

        each(tvc.length, (len, lenIndex) => {
          if (
            !localTvcLengths[lenIndex]
          ) {
            resultData[tvcIndex].lens[lenIndex] = this.serializeTvcLength(len.value, tvc);

            return;
          }

          if (
            localTvcLengths[lenIndex] !== len.value
          ) {
            resultData[tvcIndex].lens[lenIndex] = this.serializeTvcLength(len.value, tvc);
          }
        });

        const type = tvcTypes[tvc.type?.value];
        if (type?.name) {
          resultData[tvcIndex].name = type.name;
        }
      }
    });

    each(resultData, (tvcData, tvcDataIndex) => {
      if (!tvcData) {
        return;
      }

      const tvcListTvcIndex = tvcList.findIndex((tvc) => tvc.uuid === tvcData.tvcUuid);

      if (tvcListTvcIndex === -1) {
        resultData.splice(tvcDataIndex, 1);
      } else {
        each(tvcData.lens, (len, lenIndex) => {
          if (
            !tvcList[tvcListTvcIndex].length[lenIndex]
          ) {
            tvcData.lens.splice(lenIndex, 1);
          }
        });
      }
    });

    externalTableData.table = resultData;

    this.updateSpendsType({tableData: externalTableData}, () => {
      this.recalculateData();
    });
  }

  recalculateData (_cpp) {
    const {
      year: {value: year},
      tvcList,
      targetAudience: {value: ta},
    } = this.props;

    const cpp = (_cpp && _cpp[ta]) || (this.props.cpp && this.props.cpp[ta]);

    const {
      tableData,
      months
    } = this.state;

    const totalBudgetMonth = [];

    tableData.table.forEach((tvc, tvcIndex) => {
      let tpcSum = 0;
      const tvcType = tvcList.find(type => type.uuid === tvc.tvcUuid);

      const monthSums = {};
      const total30Sums = {};

      tvc.lens.forEach((len) => {
        len.cells.tpc.value = 0;
        const tvcLength = tvcType && tvcType.length.find(l => l.value === len.name);

        len.months.forEach((month, monthIndex) => {
          let cMonthSum = 0;

          month.weeks.forEach((week) => {
            if (typeof week.value === 'number') {
              len.cells.tpc.value = (+((+len.cells.tpc.value) + (+week.value) || 0));
              cMonthSum += week.value;
            }
          });

          if (typeof monthSums[monthIndex] === 'undefined') {
            monthSums[monthIndex] = 0;
            total30Sums[monthIndex] = 0;
          }

          month.sum = cMonthSum;
          monthSums[monthIndex] += cMonthSum;
          const total30 = year >= 2020 ? len.name / 20 * cMonthSum : len.name / 30 * cMonthSum;
          month.sum30 = total30;
          total30Sums[monthIndex] += total30;

          if (!Array.isArray(totalBudgetMonth[tvcIndex])) {
            totalBudgetMonth[tvcIndex] = [];
          }

          if (cpp && cpp.length) {
            const coefficient = tvcLength && tvcLength.info ? tvcLength.info.coefficient : 1;
            const monthCPP = cpp[monthIndex] || 0;
            const cBudget = year >= 2020
              ? coefficient * monthCPP * (len.name / 20 * month.sum)
              : monthCPP * (len.name / 30 * month.sum);

            month.value = cBudget;

            if (!totalBudgetMonth[tvcIndex][monthIndex]) {
              totalBudgetMonth[tvcIndex][monthIndex] = 0;
            }

            totalBudgetMonth[tvcIndex][monthIndex] += cBudget;
          } else {
            totalBudgetMonth[tvcIndex][monthIndex] = 0;
          }

          if (!this.budgetInputOldValues[tvcIndex]) this.budgetInputOldValues[tvcIndex] = {};
          this.budgetInputOldValues[tvcIndex][monthIndex] = totalBudgetMonth[tvcIndex][monthIndex];
        });

        tpcSum += len.cells.tpc.value;
      });

      tvc.lens.forEach((len) => {
        len.cells.len.value = tpcSum > 0 ? Math.round(len.cells.tpc.value / tpcSum * 100) : 0;
      });

      let sumLens = months.map(month => month.weeks.map(() => 0));

      tvc.months.forEach((month, monthIndex) => {
        tvc.lens.forEach(len => {
          len.months[monthIndex].weeks.forEach((week, weekIndex) => {
            sumLens[monthIndex][weekIndex] += Number(week.value);
          });
        });
      });

      tvc.months.forEach((month, monthIndex) => {
        month.results.total.value = +((+monthSums[monthIndex] || 0).toFixed(2));
        month.results.total30.value = total30Sums[monthIndex];

        const activeWeeksNumber = [];

        tvc.lens.forEach((len) => {
          len.months[monthIndex].weeks.forEach((week, weekIndex) => {
            if (
              activeWeeksNumber.indexOf(monthIndex + '-' + weekIndex) < 0 &&
              week.value
            ) {
              if (
                weekIndex !== months[monthIndex].weeks.length - 1 &&
                months[monthIndex].weeks[weekIndex].needWeekNumber
              ) {
                activeWeeksNumber.push(monthIndex + '-' + weekIndex);

                return;
              }

              if (
                months[monthIndex].weeks[weekIndex].needWeekNumber &&
                len.months[monthIndex + 1] &&
                len.months[monthIndex + 1].weeks[0] &&
                !months[monthIndex + 1].weeks[0].needWeekNumber &&
                sumLens[monthIndex] && sumLens[monthIndex + 1]
              ) {
                if (sumLens[monthIndex][weekIndex] > sumLens[monthIndex + 1][0] ||
                  (
                    sumLens[monthIndex][weekIndex] === sumLens[monthIndex + 1][0] &&
                    months[monthIndex].weeks[weekIndex].diffDays > months[monthIndex + 1].weeks[0].diffDays
                  )
                ) {
                  activeWeeksNumber.push(monthIndex + '-' + weekIndex);
                }

                return;
              }

              const lastWeek = len.months[monthIndex - 1] ? len.months[monthIndex - 1].weeks.length - 1 : -1;

              if (
                !months[monthIndex].weeks[weekIndex].needWeekNumber &&
                len.months[monthIndex - 1] &&
                len.months[monthIndex - 1].weeks[lastWeek] &&
                months[monthIndex - 1].weeks[lastWeek].needWeekNumber &&
                sumLens[monthIndex][weekIndex] && sumLens[monthIndex - 1]
              ) {
                const lastWeekIndex = months[monthIndex - 1].weeks.length - 1;

                if (sumLens[monthIndex][weekIndex] > sumLens[monthIndex - 1][lastWeekIndex] ||
                  (
                    sumLens[monthIndex][weekIndex] === sumLens[monthIndex - 1][lastWeekIndex] &&
                    months[monthIndex].weeks[weekIndex].diffDays > months[monthIndex - 1].weeks[lastWeekIndex].diffDays
                  )
                ) {
                  activeWeeksNumber.push(monthIndex + '-' + weekIndex);
                }

                return;
              }

              if (
                weekIndex === months[monthIndex].weeks.length - 1 &&
                months[monthIndex].weeks[weekIndex].needWeekNumber &&
                (
                  (
                    len.months[monthIndex + 1] &&
                    len.months[monthIndex + 1].weeks[0] &&
                    months[monthIndex + 1].weeks[0].needWeekNumber
                  ) || monthIndex === months.length - 1
                )
              ) {
                activeWeeksNumber.push(monthIndex + '-' + weekIndex);

                return; // eslint-disable-line no-useless-return
              }
            }
          });
        });

        month.results.active_weeks.value = activeWeeksNumber.length;
      });

      tvc.results.tpc.total.value = tpcSum;
    });

    tableData.budgets = totalBudgetMonth;

    this.updateSpendsType({tableData}, () => {
      this.refreshTables();
    });
  }

  refreshTables () {
    const {
      mediaType,
      cpp,
      frequencies,
      year: {value: year},
      tvcList,
      targetAudience: {value: ta},
    } = this.props;

    const {
      tableData,
      months
    } = this.state;
    const resultMatrix = [];
    const calendarMatrix = [];
    let headCellRowSpan = 0;

    tableData.table.forEach((tvc, tvcIndex) => {
      const cellRowSpan = tvc.lens.length + frequencies.length + 4;
      headCellRowSpan += cellRowSpan;
      const tvcType = tvcList.find(type => type.uuid === tvc.tvcUuid);

      let total30 = 0;
      let budget = 0;
      const activeWeeks = tvc.months.reduce((acc, month) => acc + month.results.active_weeks.value, 0);

      tvc.months.forEach((month) => {
        month.results.budget.value = 0;
      });

      tvc.lens.forEach((len, lenIndex) => {
        // Result matrix
        const resultRow = [];
        const tvcLength = tvcType && tvcType.length.find(l => l.value === len.name);

        if (!lenIndex) {
          resultRow.push({
            rowspan: cellRowSpan,
            classes: ['_text-align--left'],
            data: {
              value: tvc.name
            }
          });
        }

        resultRow.push({
          classes: ['Pretest' + (typeof len.preTest === 'string' ? len.preTest : '').toLowerCase()],
          data: {
            value: len.name
          }
        });

        resultRow.push({
          classes: ['with-percent-postfix'],
          data: len.cells.len
        });

        resultRow.push({
          data: len.cells.tpc
        });

        let cNet = 0;
        len.months.forEach((month, monthIndex) => {
          if (!cpp) {
            return;
          }
          const coefficient = tvcLength && tvcLength.info ? tvcLength.info.coefficient : 1;
          const monthCPP = (cpp[ta] && cpp[ta][monthIndex]) || 0;
          const cBudget = year >= 2020
            ? coefficient * monthCPP * (len.name / 20 * month.sum)
            : monthCPP * (len.name / 30 * month.sum);

          tvc.months[monthIndex].results.budget.value += cBudget;
          cNet += cBudget;
        });

        budget += cNet;

        resultRow.push({
          data: {
            value: Math.round(cNet)
          },
          classes: ['with-rub-postfix']
        });

        total30 += year >= 2020
          ? len.name / 20 * len.cells.tpc.value
          : len.name / 30 * len.cells.tpc.value;

        resultMatrix.push(resultRow);

        // Field matrix
        const fieldRow = [];

        len.months.forEach((month, monthIndex) => {
          let enableRecalculateInfoTooltip = false;

          if (
            months[monthIndex + 1] &&
            months[monthIndex + 1].weeks &&
            months[monthIndex + 1].weeks[0] &&
            !months[monthIndex + 1].weeks[0].needWeekNumber
          ) {
            enableRecalculateInfoTooltip = true;
          }

          month.weeks.forEach((week, weekIndex) => {
            let enableRecalculateInfoTooltipWeek = false;

            if (enableRecalculateInfoTooltip && month.weeks.length - 1 === weekIndex) {
              enableRecalculateInfoTooltipWeek = true;
            }

            fieldRow.push({
              type: 'input',
              dataPath: {
                tvcIndex: tvcIndex,
                lenIndex: lenIndex,
                monthIndex: monthIndex,
                weekIndex: weekIndex
              },
              needWeekNumber: months[monthIndex].weeks[weekIndex].needWeekNumber,
              isLastWeekOfMonth: weekIndex === month.weeks.length - 1,
              data: week,
              classes: ['input-cell'],
              disabled: !months[monthIndex].weeks[weekIndex].needWeekNumber,
              enableRecalculateInfoTooltip: enableRecalculateInfoTooltipWeek
            });
          });
        });

        calendarMatrix.push(fieldRow);
      });

      resultMatrix.push([
        {
          data: {
            value: 'total'
          }
        },
        {
          data: {
            value: ''
          }
        },
        {
          data: {
            value: get(tvc, 'results.tpc.total.value', 0).toFixed(1)
          }
        },
        {
          colspan: 1,
          data: {
            value: ''
          }
        }
      ]);

      resultMatrix.push([
        {
          data: {
            value: year >= 2020 ? 'total 20"' : 'total 30"',
          }
        },
        {
          data: {
            value: ''
          }
        },
        {
          data: {
            value: Math.round(total30)
          }
        },
        {
          colspan: 1,
          data: {
            value: ''
          }
        }
      ]);

      resultMatrix.push([
        {
          data: {
            value: ''
          }
        },
        {
          colspan: 2,
          data: {
            value: 'Budget'
          }
        },
        {
          colspan: 1,
          data: {
            value: Math.round(budget)
          },
          classes: ['with-rub-postfix']
        }
      ]);

      resultMatrix.push([
        {
          data: {
            value: ''
          }
        },
        {
          colspan: 2,
          data: {
            value: 'Active Weeks'
          }
        },
        {
          colspan: 1,
          data: {
            value: activeWeeks
          }
        }
      ]);

      const resultRows = ['total', 'total30', 'budget', 'active_weeks'];
      const reachData = {};

      frequencies.forEach((frequency) => {
        const frequencyKey = this.findFrequency(frequency);
        const frequencyValue =
          +(this.getCbuByTpcValue(tvc.results.tpc.total.value, frequency) || 0).toFixed(2);
        reachData[frequencyKey] = frequencyValue;

        resultMatrix.push([
          {
            data: {
              value: ''
            }
          },
          {
            colspan: 2,
            data: {
              value: 'Reach ' + frequencyKey
            }
          },
          {
            colspan: 1,
            data: {
              value: frequencyValue
            }
          }
        ]);

        resultRows.push('reach_' + frequency.value);
      });

      tvc.results.budget = budget;
      tvc.results.total30 = total30;
      tvc.results.reach = reachData;
      tvc.results.reach1 =
        +(this.getCbuByTpcValue(tvc.results.tpc.total.value, frequency1) || 0).toFixed(2);
      tvc.results.active_weeks = activeWeeks;

      resultRows.forEach((type) => {
        const totalRow = [];

        tvc.months.forEach((month, monthIndex) => {
          const row = {
            type: 'result',
            colspan: month.weeksLength,
            data: type === 'budget' ?
              {
                value: tableData.budgets[tvcIndex][monthIndex]
              } :
              month.results[type],
            classes: type === 'budget'
              ? ['input-budget-cell']
              : (type.includes('reach_') ? ['with-empty-spaces'] : [])
          };

          if (type === 'budget') {
            row.dataPath = {
              tvcIndex: tvcIndex,
              monthIndex: monthIndex
            };
            row.type = 'input-budget';
          }

          totalRow.push(row);
        });

        calendarMatrix.push(totalRow);
      });
    });

    if (resultMatrix.length) {
      resultMatrix[0].unshift({
        rowspan: headCellRowSpan,
        data: {
          value: mediaType.name
        }
      });
    }

    this.updateSpendsType({tableData});

    this.setState({
      resultMatrix,
      calendarMatrix
    });
  }

  handleInputChange = (e, tvcIndex, lenIndex, monthIndex, weekIndex) => {
    const {
      tableData
    } = this.state;

    tableData.table[tvcIndex].lens[lenIndex].months[monthIndex].weeks[weekIndex].value = +(e.target.value);
    this.updateSpendsType({tableData});
  };

  onCalendarFieldChange = (isLastWeekOfMonth, row, cellIndex, path) => {
    const {
      tableData,
      months,
      weeks
    } = this.state;

    const cValue = this.safeGetInputValue(path.tvcIndex, path.lenIndex, path.monthIndex, path.weekIndex);

    if (cValue >= 100000) {
      tableData.table[path.tvcIndex].lens[path.lenIndex].months[path.monthIndex].weeks[path.weekIndex].value = 100000;
    }

    if (
      isLastWeekOfMonth &&
      tableData.unlockedCrossWeeksMonths.includes(weeks[cellIndex].monthIndex) &&
      row[cellIndex + 1] &&
      !row[cellIndex + 1].needWeekNumber
    ) {
      const cValue =
        tableData.table[path.tvcIndex].lens[path.lenIndex].months[path.monthIndex].weeks[path.weekIndex].value;
      const diffDays =
        months[path.monthIndex + 1].weeks[0].date.diff(months[path.monthIndex].weeks[path.weekIndex].date, 'days');

      tableData.table[path.tvcIndex].lens[path.lenIndex].months[path.monthIndex].weeks[path.weekIndex].value =
        Math.round((diffDays / 7 * cValue) * 1000) / 1000;
      tableData.table[path.tvcIndex].lens[path.lenIndex].months[path.monthIndex + 1].weeks[0].value =
        Math.round(((7 - diffDays) / 7 * cValue) * 1000) / 1000;
    }

    this.recalculateData();
  };

  safeGetInputValue (tvcIndex, lenIndex, monthIndex, weekIndex) {
    const {tableData} = this.state;

    return get(
      tableData,
      `table[${tvcIndex}].lens[${lenIndex}].months[${monthIndex}].weeks[${weekIndex}].value`
    ) || '';
  }

  handleBudgetInputChange = (e, tvcIndex, monthIndex) => {
    const {tableData} = this.state;

    tableData.budgets[tvcIndex][monthIndex] = +(e.target.value);
    this.updateSpendsType({tableData});
  };

  onCalendarBudgetFieldChange (row, index, dataPath) {
    const {
      cpp,
      year: {value: year},
      tvcList,
      targetAudience: {value: ta},
    } = this.props;
    const {
      tableData
    } = this.state;

    const newVal = tableData.budgets[dataPath.tvcIndex][dataPath.monthIndex];
    const oldVal = get(
      this,
      `budgetInputOldValues[${dataPath.tvcIndex}][${dataPath.monthIndex}]`,
      0
    );

    if (newVal === oldVal) {
      return;
    }

    const diffBudget = +(newVal - oldVal);

    let total30 =
      ((diffBudget * 1000) / tableData.table[dataPath.tvcIndex].lens.length) /
        ((cpp[ta] && cpp[ta][dataPath.monthIndex]) || 0);

    if (!Number.isFinite(total30)) {
      total30 = 0;
    }
    const tvcType = tvcList.find(type => type.uuid === tableData.table[dataPath.tvcIndex].tvcUuid);

    each(tableData.table[dataPath.tvcIndex].lens, (len) => {
      let weeks = len.months[dataPath.monthIndex].weeks;
      let weeksBad = [];
      const tvcLength = tvcType && tvcType.length.find(l => l.value === len.name);
      const coefficient = tvcLength && tvcLength.info ? tvcLength.info.coefficient : 1;

      let howToAdding = year >= 2020
        ? +((total30 / coefficient) / (+len.name) * 20)
        : +(total30 / (+len.name) * 30);

      if (diffBudget < 0) {
        const negativeHowToAddingWeek = +(howToAdding / weeks.length);

        weeks = len.months[dataPath.monthIndex].weeks.filter(week =>
          week.value && week.value >= Math.abs(negativeHowToAddingWeek));

        weeksBad = len.months[dataPath.monthIndex].weeks.filter(week =>
          week.value && week.value < Math.abs(negativeHowToAddingWeek));
      }

      if (weeks.length !== len.months[dataPath.monthIndex].weeks.length) {
        each(weeksBad, (week) => {
          howToAdding = howToAdding + week.value;

          week.value = 0;
        });
      }

      howToAdding = +(howToAdding / weeks.length);

      each(weeks, (week) => {
        week.value = (week.value || 0) + howToAdding;

        week.value = week.value < 0 ? 0 : +(week.value);
      });
    });

    this.updateSpendsType({tableData}, () => {
      this.recalculateData();
    });
  }

  safeGetBudgetInputValue (tvcIndex, monthIndex) {
    const {tableData} = this.state;

    return get(tableData, `budgets[${tvcIndex}][${monthIndex}]`) || '';
  }

  render () {
    const {year: {value: year}, isDisabled = false} = this.props;
    const {
      resultMatrix,
      calendarMatrix,
      months,
      weeks,
      tableData,
    } = this.state;

    if (!tableData || !tableData.table || !tableData.table.length) {
      return null;
    }

    return (
      <LazyLoad
        once
        height={200}
        offset={[200, 200]}
        scrollContainer='#main_content'
        placeholder={<Placeholder />}
      >
        <div className='display-flex'>
          <div>
            <table className='table flowCharts-media-fixed-table'>
              <thead>
                <tr>
                  <th className='text-nowrap' style={{minWidth: '100px'}}>
                    <div>
                      <span>Media</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Description</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>TVC</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>TVC length<br />proportion</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>TRPs<br />{year >= 2020 ? 'act & 20' : 'act & 30'}</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>Net<br />media</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  resultMatrix.map((row, rowIndex) => (
                    <tr className='campaigns-table-row-height' key={'row_resultMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            className={cx('text-nowrap', 'campaigns-table-cell-center', ...(cell.classes || []))}
                            colSpan={cell.colspan || 1}
                            rowSpan={cell.rowspan || 1}
                            key={'row_' + rowIndex + '_cell_' + cellIndex}
                          >
                            <span>
                              {cell.data.value}
                            </span>
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className='right-flex-table'>
            <table className='table flowCharts-media-months-table'>
              <thead>
                <tr>
                  {
                    months.map((month, monthIndex) => (
                      <th
                        key={'month_' + monthIndex}
                        className='text-nowrap'
                        colSpan={month.weeks.length}
                      >
                        <span>{month.monthFullName}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <th
                        key={'week-number_' + weekIndex}
                        className='text-nowrap'
                      >
                        {
                          week.needWeekNumber && week.diffDays ?
                            <div className='flowCharts-media-months-table-cell-with-locker'>
                              <i
                                className={cx(
                                  'flowCharts-media-months-table-cell-with-locker-icon',
                                  'fa',
                                  !tableData.unlockedCrossWeeksMonths.includes(week.monthIndex)
                                    ? 'fa-lock'
                                    : 'fa-unlock'
                                )}
                                onClick={() => this.toggleCrossWeeksLocking(week.monthIndex)}
                              />
                            </div>
                            : null
                        }
                        <span>{week.weekNumber}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <Tooltip
                        placement='top'
                        overlay={(
                          <span>{week.diffDays}</span>
                        )}
                        mouseLeaveDelay={0.0}
                        trigger={week.diffDays > 0 ? ['hover'] : []}
                        key={'week-day_' + weekIndex}
                      >
                        <th
                          className='text-nowrap'
                        >
                          <span>{week.day}</span>
                        </th>
                      </Tooltip>
                    ))
                  }
                </tr>
              </thead>

              <tbody>
                {
                  calendarMatrix.map((row, rowIndex) => (
                    <tr key={'row_calendarMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            key={'row_calendarMatrix_' + rowIndex + '_cell_' + cellIndex}
                            className={cx(...cell.classes)}
                            colSpan={cell.colspan || 1}
                          >
                            {
                              cell.type === 'input'
                                ? (
                                  <Tooltip
                                    placement='top'
                                    overlay={(
                                      <span><LocalizedMessage id='flowchart.rating-is-recalculated' /></span>
                                    )}
                                    trigger={cell.enableRecalculateInfoTooltip ? ['hover'] : []}
                                    mouseLeaveDelay={0.0}
                                  >
                                    <div>
                                      <RoundedInput
                                        type='number'
                                        step='any'
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            e,
                                            cell.dataPath.tvcIndex,
                                            cell.dataPath.lenIndex,
                                            cell.dataPath.monthIndex,
                                            cell.dataPath.weekIndex
                                          )
                                        }
                                        value={this.safeGetInputValue(
                                          cell.dataPath.tvcIndex,
                                          cell.dataPath.lenIndex,
                                          cell.dataPath.monthIndex,
                                          cell.dataPath.weekIndex
                                        )}
                                        disabled={
                                          (
                                            cell.disabled &&
                                            tableData.unlockedCrossWeeksMonths.includes(weeks[cellIndex - 1].monthIndex)
                                          ) || isDisabled
                                        }
                                        className='form-control flowCharts-media-months-table-field'
                                        min='0'
                                        onBlur={() => this.onCalendarFieldChange(
                                          cell.isLastWeekOfMonth,
                                          row,
                                          cellIndex,
                                          cell.dataPath
                                        )}
                                      />
                                    </div>
                                  </Tooltip>
                                )
                                : (
                                  cell.type === 'input-budget'
                                    ? (
                                      <div>
                                        <RoundedInput
                                          type='number'
                                          step='any'
                                          onChange={(e) => this.handleBudgetInputChange(
                                            e,
                                            cell.dataPath.tvcIndex,
                                            cell.dataPath.monthIndex
                                          )}
                                          value={this.safeGetBudgetInputValue(
                                            cell.dataPath.tvcIndex,
                                            cell.dataPath.monthIndex
                                          )}
                                          className={cx(
                                            'form-control',
                                            'flowCharts-media-months-table-field',
                                            'flowCharts-media-months-table-field--full-width',
                                            {
                                              'fake-disabled': cell.disabled
                                            }
                                          )}
                                          min='0'
                                          onBlur={() => this.onCalendarBudgetFieldChange(row, cellIndex, cell.dataPath)}
                                          disabled={isDisabled}
                                        />
                                      </div>
                                    )
                                    : <span>{cell.data ? cell.data.value : null}</span>
                                )
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </LazyLoad>
    );
  }
}

export default TableTvcPlacement;
