import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import classes from './PageLoader.module.scss';

class PageLoader extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired
  };

  render () {
    return (
      <Loader
        active={this.props.loading}
        className={classes.container}
      />
    );
  }
}

export default PageLoader;
