import Client from './HTTPClient';

const cbuOlvAPI = {
  list (request) {
    return Client.post('/api/cbu-olv/', request);
  },

  upload (year, data) {
    return Client.post(`/api/cbu-olv/upload/${year}`, data, true, {isJson: false, isMultipart: true});
  },

  intersect (request) {
    return Client.post('/api/cbu-olv/intersect', request);
  },

  intersectStatictic (request) {
    return Client.post('/api/cbu-olv/intersect/statistic', request, true, {}, true, null, {
      withoutLoader: true
    });
  }
};

export default cbuOlvAPI;
