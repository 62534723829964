import Client from './HTTPClient';

const API = {
  list (request) {
    return Client.post('/api/spends-type/', request);
  },

  create (request) {
    return Client.post('/api/spends-type/new', request);
  }
};

export default API;
