import {connect} from 'react-redux';
import {checkRoles} from '../../../modules/auth';
import Flowcharts from '../components/Flowcharts';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({
  user: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(Flowcharts);
