import React from 'react';
import LocalizedMessage from '../../../../components/LocalizedMessage';
import Select from '../../../../components/Select';
import { IOption } from '../../../../types';
import { Statuses } from '../Flowcharts';

import classes from './StatusList.module.scss';

interface IProps {
  name: Statuses;
  onChange: (newStatus: IOption<Statuses>) => void;
}

const allStatuses = ['INITIAL', 'CURRENT', 'APPROVED', 'FOR_REPORTS', 'WORKING', 'ARCHIVE'];

const getOptions = (localizedStatuses) => allStatuses.map((status, i) => ({
  value: status,
  label: localizedStatuses[i],
}));

const StatusList = ({ name, onChange }: IProps) => (
  <LocalizedMessage id={['flowcharts.statuses.initial', 'flowcharts.statuses.current', 'flowcharts.statuses.approved',
    'flowcharts.statuses.for-reports', 'flowcharts.statuses.working', 'flowcharts.statuses.archive']}>
    {(...localizedStatuses) => {
      const options = getOptions(localizedStatuses);

      return (
        <Select
          options={options}
          value={options.find(option => option.value === name)}
          isSearchable={false}
          onChange={onChange}
          className={classes.Select}
        />
      );
    }}
  </LocalizedMessage>
);

export default StatusList;
