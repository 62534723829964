import Client from './HTTPClient';

const agenciesAPI = {
  current () {
    return Client.get('/api/agencies/current');
  },

  list (request) {
    return Client.post('/api/agencies/', request);
  },

  get (id) {
    return Client.get(`/api/agencies/${id}`);
  }
};

export default agenciesAPI;
