import isNumber from 'lodash/isNumber';

const FilterItems = {
  toUniqueId (item) {
    return `${item.type}_${item.id}`;
  },

  uniqueIdToObject (uniqueId) {
    const pos = uniqueId.lastIndexOf('_');
    let id = uniqueId.substring(pos + 1);

    if (isNumber(parseInt(id))) {
      id = parseInt(id);
    }

    return {
      type: uniqueId.substring(0, pos),
      id: id
    };
  },

  postProcess (item) {
    item.idUnique = FilterItems.toUniqueId(item);
    item.text = item.typeTranslation + ' ' + item.label;

    return item;
  },

  getDataAndPostProcess (response) {
    return response.map(FilterItems.postProcess);
  }
};

export default FilterItems;
