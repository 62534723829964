import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import Tooltip from 'rc-tooltip';
import cloneDeep from 'lodash/cloneDeep';

import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import TableList from '../../../../components/TableList';
import Select from '../../../../components/Select';
import FilterList, { IOption } from '../../../../components/FilterList/FilterList';

import API from '../../../../api';
import Alert from '../../../../helpers/alert';

import './Tvc.scss';

interface IState {
  dataList: any[],
  dataListTotal: number;
  filterItems: IOption[],
  isLoading: boolean;
  editing: boolean;
}

const lastYear = new Date().getFullYear() + 1;
let years: any[] = [];
for (let year = 2014; year <= lastYear; year++) {
  years.push(year);
}

const yearOptions = years.map(n => ({ label: String(n), value: n }));

class Tvc extends PureComponent<Props, IState> {
  state: IState = {
    dataList: [],
    dataListTotal: 1,
    filterItems: [],
    isLoading: false,
    editing: false,
  };

  mapYearToTVCOptions = {};
  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;
  _form = React.createRef<HTMLFormElement>();

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async loadTvcInfoByYear (year, shouldUpdate?: boolean) {
    try {
      const tvcInfoList = await API.tvcInfo.get(year);

      if (!this.mounted) {
        return;
      }
      this.mapYearToTVCOptions[year] = tvcInfoList.items.map(info => ({
        label: info.length,
        value: info.id,
        ...info
      }));
    } catch (e) {
      this.mapYearToTVCOptions[year] = [];
    }
    if (shouldUpdate) {
      this.forceUpdate();
    }
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.tvc.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      const toDisplay: any[] = [];

      dataList.items.forEach(_item => {
        _item.meta.forEach((_item_, lengthIndex) => {
          toDisplay.push({
            name: _item.name,
            tvcId: _item_.tvcId,
            tvcExternalId: !_item_.tvcExternalId ? _item_.tvcId : _item_.tvcExternalId,
            tvcLength: _item_.tvcInfo,
            year: _item_.tvcInfo.year,
            preTest: _item_.preTest ? _item_.preTest.name : '',
            preTestText: _item_.preTest ? _item_.preTest.nameTranslation : '',
            index: lengthIndex
          });
        });
      });

      this.setState({
        dataList: toDisplay,
        dataListTotal: dataList.total,
        isLoading: false
      });
    }, this.refreshListTimerDelay);
  }

  async refreshFilterItems () {
    const filterItems = await API.tvc.getFilterItems();

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems
    });
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='menu.dictionaries.tvc' />,
        link: '/app/dictionaries/tvc'
      }
    ];
  }

  onChangeNewData = (rowIndex, name, e) => {
    const value = e.target.value;

    this.setState(prevState => ({
      dataList: prevState.dataList.map((item, i) => i !== rowIndex
        ? item
        : ({
          ...item,
          new: {
            ...item?.new,
            [name]: value,
          },
        }),
      ),
    }));
  };

  handleChangeTvcLengthSelect = (row, rowIndex, value) => {
    this.setState(prevState => ({
      dataList: prevState.dataList.map((item, i) => i !== rowIndex
        ? item
        : ({
          ...item,
          new: {
            ...item?.new,
            tvcLength: value,
          },
        }),
      ),
    }));
  };

  handleChangeTvcYearSelect = (row, rowIndex: number, value) => {
    const {
      dataList,
    } = this.state;
    const newDataList = cloneDeep(dataList);
    const newYear = value.value;

    if (newDataList[rowIndex].new.year !== newYear) {
      newDataList[rowIndex].new.year = newYear;
      newDataList[rowIndex].new.tvcLength = null;
      if (this.mapYearToTVCOptions[newYear] === undefined) {
        this.loadTvcInfoByYear(newYear, true);
      }
    }

    this.setState({
      dataList: newDataList,
    });
  };

  getSelectTvcYearConfig (row, rowIndex: number) {
    const currentOption = yearOptions.find(({ value }) => value === row.new.year);

    return {
      isSearchable: false,
      isMulti: false,
      value: currentOption,
      onChange: (value) => this.handleChangeTvcYearSelect(row, rowIndex, value),
      options: yearOptions,
      placeholder: <LocalizedMessage id='tvc.year-field-placeholder' />,
    };
  }

  getSelectTvcLengthConfig (row, rowIndex) {
    const options = row.new.year && this.mapYearToTVCOptions[row.new.year]
      ? this.mapYearToTVCOptions[row.new.year]
      : [];

    return {
      isSearchable: false,
      isMulti: false,
      value: row.new.tvcLength,
      onChange: (value) => this.handleChangeTvcLengthSelect(row, rowIndex, value),
      options,
      placeholder: <LocalizedMessage id='tvc.length-field-placeholder' />
    };
  }

  editPreTest = (row, rowIndex, value) => {
    const {
      isLoading,
      dataList
    } = this.state;

    if (isLoading) {
      return;
    }

    const newDataList = cloneDeep(dataList);
    const item = newDataList[rowIndex];

    if (value === item.new.preTest) {
      item.new.preTest = '';
    } else {
      item.new.preTest = value;
    }

    this.setState({
      dataList: newDataList,
    });
  };

  addNewTvc = () => {
    const {
      isLoading,
      editing,
      dataList
    } = this.state;

    if (isLoading || editing) {
      return;
    }

    const newDataList = cloneDeep(dataList);
    newDataList.unshift({
      name: '',
      tvcId: 0,
      tvcExternalId: 0,
      tvcLength: 0,
      preTest: '',
      preTestText: '',
      index: 0,
      editing: true,
      isNew: true,
      new: {
        name: '',
        tvcLength: 0,
        preTest: ''
      }
    });

    this.setState({
      dataList: newDataList,
      editing: true
    });
  };

  addNewLength = (row, rowIndex) => {
    const {
      isLoading,
      editing,
      dataList
    } = this.state;

    if (isLoading || editing) {
      return;
    }

    const newDataList = cloneDeep(dataList);
    const item = newDataList[rowIndex];

    newDataList.unshift({
      name: item.name,
      tvcId: item.tvcId,
      tvcExternalId: item.tvcExternalId,
      tvcLength: 0,
      preTest: '',
      preTestText: '',
      index: 0,
      editing: true,
      isNew: true,
      isNewLength: true,
      new: {
        name: item.name,
        tvcExternalId: item.tvcExternalId,
        tvcLength: 0,
        preTest: '',
        year: item.year,
      }
    });

    if (this.mapYearToTVCOptions[item.year] === undefined) {
      this.loadTvcInfoByYear(item.year, true);
    }

    this.setState({
      editing: true,
      dataList: newDataList,
    });
  };

  editTvc = async (row, rowIndex) => {
    const {
      dataList,
      isLoading,
      editing,
    } = this.state;

    if (isLoading || editing) {
      return;
    }

    const newDataList = cloneDeep(dataList);
    const item = newDataList[rowIndex];

    item.editing = true;
    if (this.mapYearToTVCOptions[item.year] === undefined) {
      await this.loadTvcInfoByYear(item.year);
    }

    const tvcLength = this.mapYearToTVCOptions[item.year].find(option => option.length === item.tvcLength.length);
    if (!tvcLength) {
      return;
    }
    item.new = {
      name: item.name,
      tvcLength,
      tvcExternalId: item.tvcExternalId,
      preTest: item.preTest,
      year: tvcLength.year,
    };

    this.setState({
      dataList: newDataList,
      editing: true
    });
  };

  onSave = (row, rowIndex) => {
    const {
      isLoading
    } = this.state;

    if (isLoading) {
      return;
    }

    if (row.isNewLength) {
      this.saveNewLengthTvc(row, rowIndex);

      return;
    }

    if (row.isNew) {
      this.saveNewTvc(row, rowIndex);

      return;
    }

    this.saveTvc(row, rowIndex);
  };

  postParseDataList () {
    const {
      dataList
    } = this.state;

    const newDataList = cloneDeep(dataList);
    newDataList.forEach((tvc, index) => {
      if (tvc && tvc.deleting) {
        newDataList.splice(index, 1);
      }
    });

    this.setState({
      dataList: newDataList,
    });
  }

  validateNewTvcName (name) {
    const {
      dataList
    } = this.state;

    return dataList.findIndex(displayTvc => {
      return displayTvc.name === name;
    }) === -1;
  }

  validateNewTvc (name, tvcLength, tvcExternalId) {
    const {
      dataList
    } = this.state;

    return dataList.findIndex(displayTvc => {
      return displayTvc.name === name &&
        (+displayTvc.tvcLength.id) === (+tvcLength.id) &&
        (+tvcExternalId) === (+displayTvc.tvcExternalId);
    }) === -1;
  }

  saveNewTvc = async (row, rowIndex) => {
    const {
      dataList
    } = this.state;

    const newDataList = cloneDeep(dataList);
    const tvc = newDataList[rowIndex];

    if (!this.validateNewTvcName(tvc.new.name)) {
      Alert.error(localizeMessage({ id: 'tvc.you-cannot-add-new-video-because-video-with-same-name-already-exists' }));

      return;
    }

    if (!this.validateNewTvc(tvc.new.name, tvc.new.tvcLength, tvc.new.tvcExternalId)) {
      Alert.error(localizeMessage({ id: 'tvc.you-cannot-add-2-or-more-absolutely-identical-videos' }));

      return;
    }

    if (!tvc.new.tvcLength || !tvc.new.tvcLength.value) {
      Alert.error(localizeMessage({ id: 'tvc.error-you-did-not-select-tvc-length' }));

      return;
    }

    tvc.name = tvc.new.name;
    tvc.tvcExternalId = tvc.new.tvcExternalId;
    tvc.tvcLength = tvc.new.tvcLength;
    tvc.preTest = tvc.new.preTest;
    tvc.preTestText = tvc.new.preTest.length ? localizeMessage({ id: tvc.new.preTest.toLowerCase() }) : '';
    tvc.year = tvc.new.year;

    this.setState({
      isLoading: true
    });

    try {
      const value = await API.tvc.create({
        name: tvc.name,
        preTest: tvc.preTest,
        tvcInfoId: tvc.tvcLength.id,
        tvcExternalId: typeof tvc.tvcExternalId !== 'undefined' ? tvc.tvcExternalId.toString() : null
      });

      tvc.tvcId = value.id;
      tvc.isNew = false;
      tvc.editing = false;

      Alert.success(localizeMessage({ id: 'tvc.you-successful-added-new-video' }));

      if (!this.mounted) {
        return;
      }

      this.setState({
        editing: false,
        isLoading: false,
        dataList: newDataList,
      }, this.refreshFilterItems);
    } catch (error) {
      console.error(error);

      tvc.deleting = true;

      tvc.editing = false;
      tvc.isNew = false;

      if (!this.mounted) {
        return;
      }

      this.setState({
        editing: false,
        isLoading: false,
        dataList: newDataList,
      }, () => {
        this.postParseDataList();
      });

      Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-adding-new-video-please-try-later' }));
    }
  };

  validateNewLengthTvc (name, tvcExternalId, tvcLength) {
    const {
      dataList
    } = this.state;

    return typeof dataList.find((displayTvc, index) => {
      return index !== 0 &&
        name === displayTvc.name &&
        (+tvcExternalId) === (+displayTvc.tvcExternalId) &&
        (+tvcLength.id) === (+displayTvc.tvcLength.id);
    }) === 'undefined';
  }

  saveNewLengthTvc = async (row, rowIndex) => {
    const {
      dataList
    } = this.state;

    const newDataList = cloneDeep(dataList);
    const tvc = newDataList[rowIndex];

    if (!this.validateNewLengthTvc(tvc.name, tvc.new.tvcExternalId, tvc.new.tvcLength)) {
      Alert.error(localizeMessage({ id: 'tvc.you-cannot-add-2-or-more-absolutely-identical-videos' }));

      return;
    }

    if (!tvc.new.tvcLength || !tvc.new.tvcLength.value) {
      Alert.error(localizeMessage({ id: 'tvc.error-you-did-not-select-tvc-length' }));

      return;
    }

    const lastName = tvc.name;

    tvc.name = tvc.new.name;
    tvc.tvcExternalId = tvc.new.tvcExternalId;
    tvc.tvcLength = tvc.new.tvcLength;
    tvc.preTest = tvc.new.preTest;
    tvc.year = tvc.new.year;
    tvc.preTestText = localizeMessage({ id: tvc.new.preTest.toLowerCase() });

    this.setState({
      isLoading: true
    });

    try {
      const value = await API.tvc.create({
        name: lastName,
        preTest: tvc.preTest,
        tvcInfoId: tvc.tvcLength.id,
        tvcExternalId: typeof tvc.tvcExternalId !== 'undefined' ? tvc.tvcExternalId.toString() : null
      });

      tvc.tvcId = value.id;
      tvc.editing = false;
      tvc.isNew = false;
      tvc.isNewLength = false;

      Alert.success(localizeMessage({ id: 'tvc.you-successful-added-new-length-for-video' }));

      if (!this.mounted) {
        return;
      }

      this.setState({
        editing: false,
        isLoading: false,
        dataList: newDataList,
      });
    } catch (error) {
      console.error(error);

      tvc.deleting = true;

      tvc.editing = false;
      tvc.isNew = false;
      tvc.isNewLength = false;

      Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-adding-new-video-length-please-try-later' }));

      if (!this.mounted) {
        return;
      }

      this.setState({
        editing: false,
        isLoading: false,
        dataList: newDataList,
      }, () => {
        this.postParseDataList();
      });
    }
  };

  validateTvcName (name) {
    const {
      dataList
    } = this.state;

    return dataList.findIndex(displayTvc => {
      return !displayTvc.editing &&
        displayTvc.name === name;
    }) === -1;
  }

  validateTvc (name, tvcLength) {
    const {
      dataList
    } = this.state;

    return dataList.findIndex(displayTvc => {
      return !displayTvc.editing &&
        displayTvc.name === name &&
        (+displayTvc.tvcLength.id) === (+tvcLength.id);
    }) === -1;
  }

  changeAllLastNames (lastName, newName) {
    const {
      dataList
    } = this.state;

    const newDataList = cloneDeep(dataList);
    newDataList.forEach(tvc => {
      if (tvc.name === lastName) {
        tvc.name = newName;
      }
    });

    this.setState({
      dataList: newDataList,
    });
  }

  saveTvc = async (row, rowIndex) => {
    const {
      dataList
    } = this.state;

    const newDataList = cloneDeep(dataList);
    const tvc = newDataList[rowIndex];

    if (tvc.new.name !== tvc.name && !this.validateTvcName(tvc.new.name)) {
      Alert.error(localizeMessage({ id: 'tvc.you-cannot-save-video-because-video-with-same-name-already-exists' }));

      return;
    }

    if (!this.validateTvc(tvc.new.name, tvc.new.tvcLength)) {
      Alert.error(localizeMessage({
        id: 'tvc.you-cannot-save-video-because-have-2-or-more-absolutely-identical-videos'
      }));

      return;
    }

    if (!tvc.new.tvcLength || !tvc.new.tvcLength.value) {
      Alert.error(localizeMessage({ id: 'tvc.error-you-did-not-select-tvc-length' }));

      return;
    }

    const isLengthChanged = tvc.new.tvcLength !== tvc.tvcLength;
    const isNameChanged = tvc.new.name !== tvc.name;

    const isChanged = (
      tvc.new.name !== tvc.name ||
      tvc.new.tvcExternalId !== tvc.tvcExternalId ||
      tvc.new.tvcLength !== tvc.tvcLength ||
      tvc.new.preTest !== tvc.preTest
    );

    const lastTvcInfoId = tvc.tvcLength.id || tvc.tvcLength.value;
    const lastName = tvc.name;
    const lastTvcExternalId = tvc.tvcExternalId;
    const lastTvcLength = tvc.tvcLength;
    const lastPreTest = tvc.preTest;
    const lastPreTestText = tvc.preTestText;
    const lastYear = tvc.year;

    tvc.name = tvc.new.name;
    tvc.tvcExternalId = tvc.new.tvcExternalId;
    tvc.tvcLength = tvc.new.tvcLength;
    tvc.preTest = tvc.new.preTest;
    tvc.preTestText = localizeMessage({ id: tvc.new.preTest.toLowerCase() });
    tvc.year = tvc.new.year;

    if (isLengthChanged) {
      try {
        await API.tvc.deleteTvcInfo(tvc.tvcId, lastTvcInfoId);

        const value = await API.tvc.create({
          name: lastName,
          preTest: tvc.preTest,
          tvcInfoId: tvc.tvcLength.id,
          tvcExternalId: typeof tvc.tvcExternalId !== 'undefined' ? tvc.tvcExternalId.toString() : null
        });

        tvc.tvcId = value.id;

        if (isNameChanged) {
          try {
            await API.tvc.update(tvc.tvcId, {
              name: tvc.name,
              preTest: tvc.preTest,
              tvcInfoId: tvc.tvcLength.id,
              tvcExternalId: typeof tvc.tvcExternalId !== 'undefined' ? tvc.tvcExternalId.toString() : null
            });

            tvc.editing = false;
            tvc.isNew = false;

            Alert.success(localizeMessage({ id: 'tvc.you-successful-saving-video' }));

            if (!this.mounted) {
              return;
            }

            this.setState({
              editing: false,
              isLoading: false,
              dataList: newDataList,
            }, () => {
              this.changeAllLastNames(lastName, tvc.name);
              this.refreshFilterItems();
            });
          } catch (error) {
            console.error(error);

            tvc.editing = false;
            tvc.isNew = false;

            tvc.name = lastName;
            tvc.tvcExternalId = lastTvcExternalId;
            tvc.tvcLength = lastTvcLength;
            tvc.preTest = lastPreTest;
            tvc.preTestText = lastPreTestText;
            tvc.year = lastYear;

            Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-saving-the-video-please-try-later' }));

            if (!this.mounted) {
              return;
            }

            this.setState({
              editing: false,
              isLoading: false,
              dataList: newDataList,
            });
          }

          return;
        }

        tvc.editing = false;
        tvc.isNew = false;

        this.setState({
          editing: false,
          isLoading: false,
          dataList: newDataList,
        });

        Alert.success(localizeMessage({ id: 'tvc.you-successful-saving-video' }));
      } catch (error) {
        console.error(error);

        tvc.editing = false;
        tvc.isNew = false;

        tvc.name = lastName;
        tvc.tvcExternalId = lastTvcExternalId;
        tvc.tvcLength = lastTvcLength;
        tvc.preTest = lastPreTest;
        tvc.preTestText = lastPreTestText;
        tvc.year = lastYear;

        this.setState({
          editing: false,
          isLoading: false,
          dataList: newDataList,
        });

        Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-saving-the-video-please-try-later' }));
      }

      return;
    }

    if (isChanged) {
      try {
        await API.tvc.update(tvc.tvcId, {
          name: tvc.name,
          preTest: tvc.preTest,
          tvcInfoId: tvc.tvcLength.id,
          tvcExternalId: typeof tvc.tvcExternalId !== 'undefined' ? tvc.tvcExternalId.toString() : null
        });

        tvc.editing = false;
        tvc.isNew = false;

        Alert.success(localizeMessage({ id: 'tvc.you-successful-saving-video' }));

        if (!this.mounted) {
          return;
        }

        this.setState({
          editing: false,
          isLoading: false,
          dataList: newDataList,
        }, () => {
          this.changeAllLastNames(lastName, tvc.name);
        });
      } catch (error) {
        console.error(error);

        tvc.editing = false;
        tvc.isNew = false;

        tvc.name = lastName;
        tvc.tvcExternalId = lastTvcExternalId;
        tvc.tvcLength = lastTvcLength;
        tvc.preTest = lastPreTest;
        tvc.preTestText = lastPreTestText;
        tvc.year = lastYear;

        Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-saving-the-video-please-try-later' }));

        if (!this.mounted) {
          return;
        }

        this.setState({
          editing: false,
          isLoading: false,
          dataList: newDataList,
        });
      }

      return;
    }

    tvc.editing = false;
    tvc.isNew = false;

    this.setState({
      editing: false,
      isLoading: false,
      dataList: newDataList,
    });

    Alert.success(localizeMessage({ id: 'tvc.you-successful-saving-video' }));
  };

  cancelEditTvc = (row, rowIndex) => {
    const {
      isLoading,
      dataList
    } = this.state;

    if (isLoading) {
      return;
    }

    const newDataList = cloneDeep(dataList);
    const item = newDataList[rowIndex];

    if (item.isNew) {
      newDataList.splice(0, 1);
    }

    item.editing = false;

    this.setState({
      dataList: newDataList,
      isLoading: false,
      editing: false
    });
  };

  deleteTvc = async (item, rowIndex) => {
    const {
      isLoading,
      editing,
      dataList
    } = this.state;

    if (isLoading || editing) {
      return;
    }

    this.setState({
      isLoading: true
    });

    const newDataList = cloneDeep(dataList);
    const metaLength = newDataList.filter(tvc => {
      return tvc.tvcId === item.tvcId;
    }).length;

    if (metaLength <= 1) {
      try {
        await API.tvc.delete(item.tvcId);

        newDataList.splice(rowIndex, 1);

        Alert.success(localizeMessage({ id: 'tvc.you-successfully-removed-video' }));

        if (!this.mounted) {
          return;
        }

        this.setState({
          dataList: newDataList,
          isLoading: false
        });
      } catch (error) {
        console.error(error);

        Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-trying-delete-video' }));

        if (!this.mounted) {
          return;
        }

        this.setState({
          isLoading: false
        });
      }

      return;
    }

    try {
      await API.tvc.deleteTvcInfo(item.tvcId, item.tvcLength.id);

      newDataList.splice(rowIndex, 1);

      Alert.success(localizeMessage({ id: 'tvc.you-have-successfully-removed-video-length' }));

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: newDataList,
        isLoading: false
      });
    } catch (error) {
      console.log(error);

      Alert.error(localizeMessage({ id: 'tvc.error-occurred-while-trying-to-delete-video-length' }));

      if (!this.mounted) {
        return;
      }

      this.setState({
        isLoading: false
      });
    }
  };

  render () {
    const {
      onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      dataList,
      dataListTotal,
      filterItems,
      isLoading
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.tvc'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-4'
              >
                <div className='col-lg-8'>
                  <div className='title-action'>
                    <button
                      type='button'
                      onClick={this.addNewTvc}
                      className='btn btn-success'
                    >
                      <i className='fa fa-plus-circle' />
                      <LocalizedMessage id='tvc.add-tvc'>
                        {localizedMessage => (
                          <span className='bold ng-scope'>{localizedMessage}</span>
                        )}
                      </LocalizedMessage>
                    </button>
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='tvc.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      tableClassName='table table-hover c-smart-table centered-cells'
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: localizeMessage({ id: 'name' }),
                          sort: 'name',
                          props: {
                            style: {
                              width: '16%'
                            }
                          }
                        },
                        {
                          label: localizeMessage({ id: 'id' }),
                          sort: 'id',
                          props: {
                            style: {
                              width: '16%'
                            }
                          }
                        },
                        {
                          label: 'year',
                          props: {
                            style: {
                              width: '16%'
                            }
                          }
                        },
                        {
                          label: localizeMessage({ id: 'tvcLength' }),
                          props: {
                            style: {
                              width: '16%'
                            }
                          }
                        },
                        {
                          label: localizeMessage({ id: 'tvc.pre-test-video' }),
                          props: {
                            style: {
                              width: '16%'
                            }
                          }
                        },
                        {
                          empty: true,
                          props: {
                            style: {
                              width: '16%'
                            }
                          }
                        }
                      ]}
                    >
                      {
                        dataList.map((row, rowIndex) => (
                          <tr key={rowIndex + '_tvc'}>
                            <td
                              style={{
                                maxWidth: 100
                              }}
                            >
                              {
                                !row.editing ? (<span>{row.name}</span>) : (
                                  <div className='form-group-sm padding-top-bottom-1px'>
                                    <input
                                      type='text'
                                      name='name'
                                      className='form-control'
                                      onChange={(e) => this.onChangeNewData(rowIndex, 'name', e)}
                                      value={row.new.name}
                                      disabled={row.isNewLength}
                                    />
                                  </div>
                                )
                              }
                            </td>
                            <td>
                              {
                                !row.editing ? (<span>{row.tvcExternalId}</span>) : (
                                  <div className='form-group-sm padding-top-bottom-1px'>
                                    <input
                                      type='number'
                                      name='tvcExternalId'
                                      className='form-control'
                                      onChange={(e) => this.onChangeNewData(rowIndex, 'tvcExternalId', e)}
                                      value={row.new.tvcExternalId}
                                      disabled={row.isNewLength || row.isNew || row.editing}
                                    />
                                  </div>
                                )
                              }
                            </td>
                            <td>
                              {
                                !row.editing ? (<span>{row.tvcLength && row.year}</span>) : (
                                  <div className='form-group-sm padding-top-bottom-1px'>
                                    <Select {...this.getSelectTvcYearConfig(row, rowIndex)} />
                                  </div>
                                )
                              }
                            </td>
                            <td>
                              {
                                !row.editing ? (<span>{row.tvcLength && row.tvcLength.length}</span>) : (
                                  <div className='form-group-sm padding-top-bottom-1px'>
                                    <Select {...this.getSelectTvcLengthConfig(row, rowIndex)} />
                                  </div>
                                )
                              }
                            </td>
                            <td>
                              {
                                !row.editing ? (
                                  <Tooltip
                                    placement='top'
                                    overlay={(
                                      <span>
                                        <LocalizedMessage
                                          id={`tvc.${row.preTest.toLowerCase()}`}
                                        />
                                      </span>
                                    )}
                                    trigger={[...(!row.preTest || !row.preTest.length ? [] : ['hover'])]}
                                  >
                                    <div
                                      className={cx('PrestatusItem', {
                                        PrestatusItemGood: (row.preTest || '').toLowerCase() === 'good',
                                        PrestatusItemNorm: (row.preTest || '').toLowerCase() === 'normal',
                                        PrestatusItemBadly: (row.preTest || '').toLowerCase() === 'badly'
                                      })}
                                    >
                                      {
                                        !row.preTest || !row.preTest.length ? <span>-</span> : null
                                      }
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div>
                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='tvc.good' />
                                      )}
                                    >
                                      <div
                                        className={cx('PrestatusItem PrestatusItemGood fa', {
                                          'fa-check': row.new.preTest.toUpperCase() === 'GOOD'
                                        })}
                                        onClick={() => this.editPreTest(row, rowIndex, 'GOOD')}
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='tvc.normal' />
                                      )}
                                    >
                                      <div
                                        className={cx('PrestatusItem PrestatusItemNorm fa', {
                                          'fa-check': row.new.preTest.toUpperCase() === 'NORMAL'
                                        })}
                                        onClick={() => this.editPreTest(row, rowIndex, 'NORMAL')}
                                      />
                                    </Tooltip>

                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='tvc.badly' />
                                      )}
                                    >
                                      <div
                                        className={cx('PrestatusItem PrestatusItemBadly fa', {
                                          'fa-check': row.new.preTest.toUpperCase() === 'BADLY'
                                        })}
                                        onClick={() => this.editPreTest(row, rowIndex, 'BADLY')}
                                      />
                                    </Tooltip>
                                  </div>
                                )
                              }
                            </td>
                            <td className='control-col'>
                              {
                                !row.editing ? (
                                  <div>&nbsp;&nbsp;
                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='tvc.add-new-length' />
                                      )}
                                    >
                                      <i
                                        className='fa fa-plus-circle inline-button-edit-active'
                                        onClick={() => this.addNewLength(row, rowIndex)}
                                      />
                                    </Tooltip>
                                    &nbsp;&nbsp;
                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='edit' />
                                      )}
                                    >
                                      <i
                                        className='fa fa-pencil inline-button-edit-active'
                                        onClick={() => this.editTvc(row, rowIndex)}
                                      />
                                    </Tooltip>
                                    &nbsp;&nbsp;
                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='delete' />
                                      )}
                                    >
                                      <i
                                        className='fa fa fa-trash inline-button-edit-active'
                                        onClick={() => this.deleteTvc(row, rowIndex)}
                                      />
                                    </Tooltip>
                                    &nbsp;&nbsp;
                                  </div>
                                ) : (
                                  <div>&nbsp;&nbsp;
                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='confirm' />
                                      )}
                                    >
                                      <i
                                        className='fa fa-check inline-button-cancel-active'
                                        onClick={() => this.onSave(row, rowIndex)}
                                      />
                                    </Tooltip>
                                    &nbsp;&nbsp;
                                    <Tooltip
                                      placement='top'
                                      overlay={(
                                        <LocalizedMessage id='cancel' />
                                      )}
                                    >
                                      <i
                                        className='fa fa-times-rectangle inline-button-cancel-active'
                                        onClick={() => this.cancelEditTvc(row, rowIndex)}
                                      />
                                    </Tooltip>
                                  </div>
                                )
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Tvc);
