import {fetchResponse} from './helpers/fakeFetch';

import countriesList from './data/countriesList.json';
import countriesRegions from './data/countriesRegions.json';

const API = {
  list (request) {
    console.log('api.countries.list(request)');

    return fetchResponse(1000, countriesList);
  },

  getRegions (countryId) {
    console.log('api.countries.getRegions(countryId)');

    return fetchResponse(1000, countriesRegions);
  },

  create (request) {
    console.log('api.countries.create(request)');

    return fetchResponse(1000, {});
  }
};

export default API;
