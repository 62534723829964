import {fetchResponse} from './helpers/fakeFetch';

const agenciesAPI = {
  current () {
    console.log('api.agencies.current()');

    return fetchResponse(1000, {'id': 108, 'name': 'Universal Media'});
  },

  list () {
    console.log('api.agencies.list()');

    return fetchResponse(1000, {
      list: [],
      total: 0
    });
  },

  get (id) {
    console.log('api.agencies.get(' + id + ')');

    return fetchResponse(1000, {});
  }
};

export default agenciesAPI;
