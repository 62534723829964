export default {
  '__TEMPLATE__': { // Doesn't remove it
    targetAudience: true,
    tvc: true
  },
  'tvc-production': {
    targetAudience: true,
    tvc: true
  },
  'tvc-production-regionality': {
    regionality: true,
    targetAudience: true,
    tvc: true
  },
  'tvc-placement': {
    targetAudience: true,
    effectiveFrequencies: true,
    tvc: true
  },
  'tvc-placement-regionality': {
    regionality: true,
    targetAudience: true,
    effectiveFrequencies: true,
    tvc: true
  },
  // only for OLV and Smart TV ( real table files do not exist. The table is generated based on the selected option
  // with the 'tvc-placement', 'tvc-placement-impressions' and 'tvc-production' tables )
  'dynamic': {
    targetAudience: true,
    effectiveFrequencies: true,
    tvc: true,
    planByWithImpressions: true
  },
  // only for Regional TV
  'dynamic-1': {
    regionality: true,
    targetAudience: true,
    effectiveFrequencies: true,
    tvc: true,
    planBy: true
  },
  'dynamic-2': {
    targetAudience: true,
    effectiveFrequencies: true,
    tvc: true,
    planByNotRegions: true
  },
  'descriptions-production': {
    targetAudience: true,
    descriptions: true
  },
  'descriptions-production-regionality': {
    regionality: true,
    targetAudience: true,
    descriptions: true
  },
  'descriptions-placement': {
    targetAudience: true,
    descriptions: true
  },
  'descriptions-placement-regionality': {
    regionality: true,
    targetAudience: true,
    descriptions: true
  }
};
