import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const API = {
  list (request) {
    return Client.post('/api/sites/', request);
  },

  programmatic () {
    return Client.get('/api/sites/programmatic/');
  },

  get (id) {
    return Client.get(`/api/sites/${id}`);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/sites/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  }
};

export default API;
