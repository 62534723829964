import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {NavLink} from 'react-router-dom';
import LocalizedMessage from '../LocalizedMessage';
import classes from './Sidebar.module.scss';
import {ReactComponent as SkLogo} from './assets/sk-logo.svg';
import {ReactComponent as SkLogoSmall} from './assets/sk-logo-small.svg';

class Sidebar extends Component {
  static propTypes = {
    profile: PropTypes.object,
    isAuthorized: PropTypes.bool.isRequired,
    isOpenMenu: PropTypes.bool.isRequired
  };

  state = {
    dictionariesIsOpen: this.getDefaultDictionariesState(this.props),
    rendered: false
  };

  componentDidMount () {
    this.setState({
      rendered: true
    });
  }

  componentWillUnmount () {
    this.setState({
      rendered: false
    });
  }

  static getDerivedStateFromProps (props, state) {
    if (!props.isOpenMenu) {
      return {
        dictionariesIsOpen: false
      };
    }

    return null;
  }

  getDefaultDictionariesState (props) {
    const {location} = props;

    return location.pathname.match(/\/app\/dictionaries\/([0-9a-zA-Z]+)/);
  }

  toggleDictionaries = () => {
    if (!this.props.isOpenMenu) {
      return;
    }

    this.setState({
      dictionariesIsOpen: !this.state.dictionariesIsOpen
    });
  };

  render () {
    const {profile, isAuthorized, isOpenMenu} = this.props;
    const {dictionariesIsOpen, rendered} = this.state;

    if (!isAuthorized) {
      return false;
    }

    return (
      <div
        className={cx(
          classes.Container,
          {
            [classes.ContainerClosed]: !isOpenMenu
          }
        )}
      >
        <div className={classes.Header}>
          <img
            src='/img/logo.png'
            alt='Aizek.Flowchart'
            className={classes.Logo}
          />
        </div>
        <ul className={classes.List}>
          {
            ['ADMIN', 'PLANNER', 'BUYER', 'CLIENT'].includes(profile.role.name) ?
              <li className={classes.Item}>
                <LocalizedMessage
                  id='menu.flowcharts'
                >
                  {localizedMessage => (
                    <NavLink
                      to='/app/flowcharts'
                      className={classes.Link}
                      activeClassName={classes.LinkActive}
                      title={localizedMessage}
                    >
                      <i className={cx(
                        'fa',
                        'fa-th-list',
                        classes.LinkIcon
                      )} />
                      <span className={classes.LinkText}>
                        {localizedMessage}
                      </span>
                    </NavLink>
                  )}
                </LocalizedMessage>
              </li>
              : null
          }
          {
            ['ADMIN', 'PLANNER', 'BUYER'].includes(profile.role.name) ?
              <li className={classes.Item}>
                <LocalizedMessage
                  id='menu.reports'
                >
                  {localizedMessage => (
                    <NavLink
                      to='/app/reports'
                      className={classes.Link}
                      activeClassName={classes.LinkActive}
                      title={localizedMessage}
                    >
                      <i className={cx(
                        'fa',
                        'fa-th-list',
                        classes.LinkIcon
                      )} />
                      <span className={classes.LinkText}>
                        {localizedMessage}
                      </span>
                    </NavLink>
                  )}
                </LocalizedMessage>
              </li>
              : null
          }
          {
            ['CLIENT'].includes(profile.role.name) ?
              <li className={classes.Item}>
                <LocalizedMessage
                  id='menu.reports'
                >
                  {localizedMessage => (
                    <NavLink
                      to='/app/reports/create'
                      className={classes.Link}
                      activeClassName={classes.LinkActive}
                      title={localizedMessage}
                    >
                      <i className={cx(
                        'fa',
                        'fa-th-list',
                        classes.LinkIcon
                      )} />
                      <span className={classes.LinkText}>
                        {localizedMessage}
                      </span>
                    </NavLink>
                  )}
                </LocalizedMessage>
              </li>
              : null
          }
          {
            ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
              <li className={classes.Item}>
                <LocalizedMessage
                  id='menu.users'
                >
                  {localizedMessage => (
                    <NavLink
                      to='/app/settings/users'
                      className={classes.Link}
                      activeClassName={classes.LinkActive}
                      title={localizedMessage}
                    >
                      <i className={cx(
                        'fa',
                        'fa-user',
                        classes.LinkIcon
                      )} />
                      <span className={classes.LinkText}>
                        {localizedMessage}
                      </span>
                    </NavLink>
                  )}
                </LocalizedMessage>
              </li>
              : null
          }
          {
            ['SUPER_ADMIN'].includes(profile.role.name) ?
              <li className={classes.Item}>
                <LocalizedMessage
                  id='menu.dictionariesSynchronization'
                >
                  {localizedMessage => (
                    <NavLink
                      to='/app/settings/sync'
                      className={classes.Link}
                      activeClassName={classes.LinkActive}
                      title={localizedMessage}
                    >
                      <i className={cx(
                        'fa',
                        'fa-refresh',
                        classes.LinkIcon
                      )} />
                      <span className={classes.LinkText}>
                        {localizedMessage}
                      </span>
                    </NavLink>
                  )}
                </LocalizedMessage>
              </li>
              : null
          }
          {
            ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'].includes(profile.role.name) ?
              <li
                className={cx(
                  classes.Item,
                  classes.ItemSubList
                )}
              >
                <LocalizedMessage
                  id='menu.dictionaries'
                >
                  {localizedMessage => (
                    <span
                      className={cx(
                        classes.Link,
                        classes.LinkSubList,
                        {
                          [classes.LinkActive]: dictionariesIsOpen
                        }
                      )}
                      onClick={this.toggleDictionaries}
                      title={localizedMessage}
                    >
                      <i className={cx(
                        'fa',
                        'fa-cubes',
                        classes.LinkIcon
                      )} />
                      <span className={classes.LinkText}>
                        {localizedMessage}
                      </span>
                      <span className={cx(classes.LinkArrow, 'fa', 'arrow')} />
                    </span>
                  )}
                </LocalizedMessage>
                <ul
                  className={cx(
                    classes.SubList,
                    {
                      [classes.SubListHidden]: rendered && !dictionariesIsOpen
                    }
                  )}
                >
                  {
                    ['SUPER_ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.agencies'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/agencies'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-refresh',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.advertisers'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/advertisers'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-users',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.brands'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/brands'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-flag',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.cpp'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/cpp'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.cbu'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/cbu'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.cbuOlv'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/olvCbu'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.frequencies'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/frequencies'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.currencies'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/currencies'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.spendsTypes'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/spendsType'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.tvc'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/tvc'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.tvcLength'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/tvclength'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.countries'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/countries'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.regions'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/regions'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN', 'BUYER'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.numberIo'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/numberio'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.mediaTypes'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/mediaTypes'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.aimMac'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/aimMac'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.campaigns'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/campaigns'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.descriptions'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/indoor'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-tv',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                  {
                    ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'].includes(profile.role.name) ?
                      <li className={classes.Item}>
                        <LocalizedMessage
                          id='menu.dictionaries.targetAudiences'
                        >
                          {localizedMessage => (
                            <NavLink
                              to='/app/dictionaries/targetAudiences'
                              className={classes.Link}
                              activeClassName={classes.LinkActive}
                              title={localizedMessage}
                            >
                              <i className={cx(
                                'fa',
                                'fa-bullseye',
                                classes.LinkIcon
                              )} />
                              <span className={classes.LinkText}>
                                {localizedMessage}
                              </span>
                            </NavLink>
                          )}
                        </LocalizedMessage>
                      </li>
                      : null
                  }
                </ul>
              </li>
              : null
          }
        </ul>
        <a className={classes.SkLink} href='https://sk.ru/' target='_blank' rel='noopener noreferrer'>
          {isOpenMenu
            ? <SkLogo className={classes.SkLogo} />
            : <SkLogoSmall className={classes.SkLogo} />}
        </a>
      </div>
    );
  }
}

export default Sidebar;
