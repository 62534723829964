import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import TableList from '../../../../components/TableList';

import Alert from '../../../../helpers/alert';
import API from '../../../../api';

interface IState {
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;

  spendsType: any;
}

class SpendsTypes extends PureComponent<Props, IState> {
  state: IState = {
    dataList: [],
    dataListTotal: 1,
    isLoading: false,

    spendsType: ''
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;
  _form = React.createRef<HTMLFormElement>();

  componentDidMount () {
    this.mounted = true;
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.spendsType.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='menu.dictionaries.spendsTypes' />,
        link: '/app/dictionaries/spendsType'
      }
    ];
  }

  setFormRef = (ref) => {
    this._form = ref;
  };

  handleInputChange = (e) => {
    const value = e.target.value;

    this.setState({
      spendsType: value
    });
  };

  resetClick = () => {
    if (this._form.current) {
      this._form.current.reset();
    }

    this.setState({
      spendsType: null
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      spendsType
    } = this.state;

    if (!spendsType || !spendsType.length) {
      return;
    }

    try {
      await API.spendsType.create({
        name: spendsType
      });

      Alert.success(localizeMessage({ id: 'spends-types-dictionary.successfullySent' }));

      this.resetClick();

      this.refreshList();
    } catch (error) {
      console.error(error);

      Alert.warn(localizeMessage({ id: 'spends-types-dictionary.errorSending' }));
    }
  };

  render () {
    const {
      onUpdateSort, onUpdatePage, filters,
    } = this.props;
    const {
      spendsType,
      dataList,
      dataListTotal,
      isLoading
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.spendsTypes'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <form
                    ref={this.setFormRef}
                    onSubmit={this.onSubmit}
                  >
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label className='col-lg-2 control-label'>
                          <LocalizedMessage id='spends-types-dictionary.spendsType.label' />
                        </label>

                        <div className='col-lg-10'>
                          <LocalizedMessage id='spendsType'>
                            {localizedPlaceholder => (
                              <input
                                type='text'
                                className='form-control'
                                name='spendsType'
                                value={spendsType}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleInputChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <button
                            className='btn btn-white'
                            onClick={this.resetClick}
                          >
                            <span><LocalizedMessage id='reset' /></span>
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={this.onSubmit}
                            disabled={!spendsType || !spendsType.length}
                          >
                            <>
                              <i className='fa fa-chevron-down fa-lg' />
                              <span><LocalizedMessage id='save' /></span>
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: localizeMessage({ id: 'spendsType' }),
                          sort: 'name'
                        }
                      ]}
                    >
                      {
                        dataList.map((row, rowIndex) => (
                          <tr key={rowIndex + '_spends-types'}>
                            <td>{row.name}</td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(SpendsTypes);
