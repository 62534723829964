import Client from './HTTPClient';

const API = {
  lockFlowchart (id) {
    return Client.post(`/api/flowchart/lock/${id}/`, {}, true, {}, false);
  },
  unlockFlowchart (id) {
    return Client.delete(`/api/flowchart/lock/${id}/unlock`, {}, true, {}, false);
  },

  updateFlowchartLock (id) {
    return Client.post(
      `/api/flowchart/lock/${id}/update`,
      {},
      true,
      {},
      false,
      undefined,
      {withoutLoader: true}
    );
  },
};

export default API;
