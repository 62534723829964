import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const API = {
  list (request) {
    return Client.post('/api/tvc/', request);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/tvc/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  update (id, request) {
    return Client.put(`/api/tvc/${id}`, request);
  },

  delete (id, request) {
    return Client.delete(`/api/tvc/${id}`, request);
  },

  deleteTvcInfo (id, tvcInfoId, request) {
    return Client.delete(`/api/tvc/${id}/${tvcInfoId}`, request);
  },

  create (request) {
    return Client.post('/api/tvc/new', request);
  },

  get (year) {
    return Client.get(`/api/tvc/year/${year}`);
  }
};

export default API;
