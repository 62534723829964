import {fetchResponse} from './helpers/fakeFetch';

import frequenciesList from './data/frequenciesList.json';

const API = {
  list (request) {
    console.log('api.frequencies.list(request)');

    return fetchResponse(1000, frequenciesList);
  },

  create (request) {
    console.log('api.frequencies.create(request)');

    return fetchResponse(1000, {});
  }
};

export default API;
