// ------------------------------------
// Constants
// ------------------------------------
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

// ------------------------------------
// Actions
// ------------------------------------
export const startLoading = () => {
  return {
    type: START_LOADING
  };
};

export const stopLoading = () => {
  return {
    type: STOP_LOADING
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [START_LOADING]: (state) => ({
    ...state,
    loading: true,
    countLoading: state.countLoading + 1
  }),
  [STOP_LOADING]: (state) => ({
    ...state,
    loading: state.countLoading - 1 > 0,
    countLoading: state.countLoading - 1
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: false,
  countLoading: 0
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
