import {connect} from 'react-redux';
import Regions from '../components/Regions';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang
});

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
