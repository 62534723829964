import React from 'react';
import ClampLines from 'react-clamp-lines';

import './CollapsibleCell.scss';

type Props = {
  content: string;
  id: number;
}

const CollapsibleCell = ({ content, id }: Props) => (
  <ClampLines
    text={content}
    id={String(id)}
    className='collapsible-cell'
    lines={2}
    moreText='+ Read more'
    lessText='- Read less'
  />
);
export default CollapsibleCell;
