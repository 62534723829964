import {Component} from 'react';

class OnLoadWrapper extends Component {
  componentDidMount () {
    const {onLoad} = this.props;

    if (typeof onLoad === 'function') {
      onLoad();
    }
  }

  render () {
    const {
      children
    } = this.props;

    return (children);
  }
}

export default OnLoadWrapper;
