import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const API = {
  list (request) {
    return Client.post('/api/io/', request);
  },

  get (id) {
    return Client.get(`/api/io/${id}`);
  },

  upload (year, data) {
    return Client.post(`/api/io/upload/${year}`, data, true, {isJson: false, isMultipart: true});
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/io/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  search (request) {
    return Client.post('/api/io/search', request);
  }
};

export default API;
