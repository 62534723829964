import Client from './HTTPClient';

const countriesAPI = {
  list (request) {
    return Client.post('/api/countries/', request);
  },

  getRegions (countryId) {
    return Client.get(`/api/countries/${countryId}/regions`);
  },

  create (request) {
    return Client.post('/api/countries/new', request);
  }
};

export default countriesAPI;
