import {fetchResponse} from './helpers/fakeFetch';

import ioList from './data/ioList.json';
import ioSearch from './data/ioSearch.json';

const API = {
  list (request) {
    console.log('api.io.list(', request, ')');

    return fetchResponse(1000, ioList);
  },

  get (id) {
    console.log('api.io.get(', id, ')');

    return fetchResponse(1000, ioList.items.find(item => item.id === id) || null);
  },

  upload (year, data) {
    console.log('api.io.upload(' + year + ', ', data, ')');

    return fetchResponse(1000, {});
  },

  getFilterItems (request) {
    console.log('api.io.getFilterItems(', request, ')');

    return fetchResponse(1000, {});
  },

  search (request) {
    console.log('api.io.search(', request, ')');

    return fetchResponse(1000, ioSearch);
  }
};

export default API;
