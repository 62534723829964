import auth from './auth';
import advertisers from './advertisers';
import agencies from './agencies';
import aimMac from './aimMac';
import brands from './brands';
import campaignPriceTemplates from './campaignPriceTemplates';
import categoryExtensions from './categoryExtensions';
import cbu from './cbu';
import lock from './lock';
import cbuOlv from './cbuOlv';
import countries from './countries';
import cpp from './cpp';
import currencies from './currencies';
import digitals from './digitals';
import divisions from './divisions';
import flowcharts from './flowcharts';
import frequencies from './frequencies';
import indoor from './indoor';
import io from './io';
import mediaTypes from './mediaTypes';
import personaTargetAudience from './personaTargetAudience';
import regions from './regions';
import reports from './reports';
import sites from './sites';
import spendsType from './spendsType';
import sync from './sync';
import targetAudiences from './targetAudiences';
import tvc from './tvc';
import tvcInfo from './tvcInfo';
import users from './users';

export default {
  auth,
  advertisers,
  agencies,
  aimMac,
  brands,
  campaignPriceTemplates,
  categoryExtensions,
  cbu,
  cbuOlv,
  countries,
  cpp,
  currencies,
  digitals,
  divisions,
  flowcharts,
  frequencies,
  indoor,
  io,
  mediaTypes,
  personaTargetAudience,
  regions,
  reports,
  sites,
  lock,
  spendsType,
  sync,
  targetAudiences,
  tvc,
  tvcInfo,
  users
};
