import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import history from '../../history';

class BreadcrumbsComponent extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    data: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  };

  static defaultProps = {
    className: 'col-lg-12'
  };

  onLinkClick (e, link) {
    e.preventDefault();

    history.push(link);
  }

  getBreadcrumbs () {
    const {data} = this.props;

    if (!data || !data.length) {
      return null;
    }

    // TODO: make links
    return (
      <ol className='breadcrumb'>
        {
          data.map((breadcrumb, index) => (
            <li key={'breadcrumb_' + index}>
              {
                index + 1 === data.length
                  ? <strong>{breadcrumb.title}</strong>
                  : (
                    breadcrumb.link && breadcrumb.link.length
                      ? (
                        <Link to={breadcrumb.link}>
                          <span className='active'>{breadcrumb.title}</span>
                        </Link>
                      )
                      : <span className='active'>{breadcrumb.title}</span>
                  )
              }
            </li>
          ))
        }
      </ol>
    );
  }

  render () {
    const {
      title,
      className,
      children
    } = this.props;

    if (!title) {
      return null;
    }

    return (
      <div className='row wrapper border-bottom white-bg page-heading'>
        <div className={className}>
          <h2>{title}</h2>
          {this.getBreadcrumbs()}
        </div>
        {children}
      </div>
    );
  }
}

export default BreadcrumbsComponent;
