import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import LocalizedMessage, {localizeMessage} from '../../../components/LocalizedMessage';

import SweetAlert from '@sweetalert/with-react';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Select from '../../../components/Select';
import ReportTable from './Table';

import {PromiseAll, tryParseJSON} from '../../../helpers/utils';

import exportRawTable from '../helpers/reportTableExporter.js';
import history from '../../../history';
import Alert from '../../../helpers/alert';
import API from '../../../api';

import classes from './Report.module.scss';

class Report extends Component {
  static propTypes = {
    checkRoles: PropTypes.func.isRequired
  };

  years = {
    startYear: moment().add(1, 'year').year(),
    endYear: moment().add(-5, 'year').year()
  };

  state = {
    isEditMode: false,
    isPageLoaded: false,

    formStatistic: null,
    formFlowchartStatus: null,
    formYear: null,
    formFlowcharts: [],
    formReportName: '',

    tableData: null,

    optionsYear: this.getYearOptions(),
    optionsFlowchartStatus: this.props.checkRoles('CLIENT') ?
      [
        {
          value: 'CURRENT',
          label: localizeMessage({id: 'settings.flowcharts.statuses.current'}),
        },
      ] :
      [
        {
          value: 'WORKING',
          label: localizeMessage({id: 'settings.flowcharts.statuses.working'}),
        },
        {
          value: 'INITIAL',
          label: localizeMessage({id: 'settings.flowcharts.statuses.initial'}),
        },
        {
          value: 'CURRENT',
          label: localizeMessage({id: 'settings.flowcharts.statuses.current'}),
        },
        {
          value: 'FOR_REPORTS',
          label: localizeMessage({id: 'settings.flowcharts.statuses.for-reports'}),
        },
        {
          value: 'APPROVED',
          label: localizeMessage({id: 'settings.flowcharts.statuses.approved'}),
        }
      ],
    optionsFlowcharts: [],
    optionsStatistics: [
      {
        value: 'budgetting',
        label: localizeMessage({id: 'report.budgetting'})
      },
      {
        value: 'budgetSummary',
        label: localizeMessage({id: 'report.budget-summary'})
      }
    ],

    flowchartsData: [],
    errors: {}
  };

  mounted = false;
  _table = null;

  report = {};

  async componentDidMount () {
    this.mounted = true;

    await this.loadReport();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  setTableRef = ref => {
    this._table = ref;
  };

  getYearOptions () {
    const years = [];

    for (let year = this.years.startYear; year >= this.years.endYear; year--) {
      years.push({
        value: year,
        label: year
      });
    }

    return years;
  }

  async loadReport () {
    const {
      checkRoles,
      match: {
        params
      }
    } = this.props;
    const {
      optionsFlowchartStatus,
      optionsYear,
      optionsStatistics
    } = this.state;

    const promises = {
      flowcharts: API.flowcharts.list({
        max: 0,
        showArchivedFlowcharts: false,
      })
    };

    const id = params && params.id;

    const isEditMode = typeof id !== 'undefined';

    if (isEditMode) {
      promises.report = API.reports.get(id);
    }

    try {
      const {flowcharts, report} = await PromiseAll(promises);

      if (!this.mounted) {
        return;
      }

      const state = {};

      state.optionsFlowcharts = flowcharts.items.map(flowChart => ({
        value: flowChart.id,
        label: flowChart.title
      }));

      this.flowchartsItems = flowcharts.items;

      if (isEditMode) {
        this.report = report;

        state.formReportName = report.title;

        const reportData = tryParseJSON(report.view);

        if (reportData) {
          state.formFlowcharts = (reportData.formFlowcharts || []).map(flowchart => (
            state.optionsFlowcharts.find(flowchartOption => (+flowchart.value) === (+flowchartOption.value))
          ));

          if (state.formFlowcharts.length) {
            state.flowchartsData = this.flowchartsItems.filter(flowchart => (
              state.formFlowcharts.findIndex(_flowchart => _flowchart.value === flowchart.id) !== -1
            ));
          }

          state.formFlowchartStatus = reportData.status && reportData.status.length
            ? reportData.status.map(status => optionsFlowchartStatus.find(statusOption => (
              statusOption.value.toLowerCase() === status.value.toLowerCase()
            )))
            : null;

          state.formYear = reportData.status && reportData.status.length && reportData.year
            ? optionsYear.find(yearOption => (+yearOption.value) === (+reportData.year.value))
            : null;

          if (
            state.formFlowchartStatus &&
            state.formFlowchartStatus.length &&
            state.formYear &&
            state.formYear.value
          ) {
            state.flowchartsData = this.flowchartsItems.filter(flowchart => (
              state.formFlowchartStatus.findIndex(status => (
                status.value.toLowerCase() === flowchart.status.name.toLowerCase()
              )) !== -1 &&
              flowchart.year === state.formYear.value
            ));
          }

          if (reportData.statistic) {
            state.formStatistic = optionsStatistics.find(statisticOption => {
              return statisticOption.value.toLowerCase() === reportData.statistic.value.toLowerCase();
            });
          }

          if (reportData.tableData) {
            state.tableData = reportData.tableData;
          }
        }
      }

      if (checkRoles('CLIENT')) {
        state.formStatistic = optionsStatistics[1];
      }

      state.isEditMode = isEditMode;
      state.isPageLoaded = true;

      this.setState(state);
    } catch (error) {
      console.error(error);
    }
  }

  setReportName = (event) => {
    this.setState({
      formReportName: event.target.value
    });
  };

  getReportName = () => {
    return this.state.formReportName;
  };

  handleChangeStatisticSelect = (formStatistic) => {
    this.setState({formStatistic});
  };

  getSelectStatisticConfig () {
    const {checkRoles} = this.props;
    const {formStatistic, optionsStatistics} = this.state;

    return {
      isSearchable: false,
      value: formStatistic,
      onChange: this.handleChangeStatisticSelect,
      options: optionsStatistics,
      isDisabled: checkRoles('CLIENT'),
      placeholder: <LocalizedMessage id='report.statistics.placeholder' />
    };
  }

  handleChangeFlowchartStatusSelect = (formFlowchartStatus) => {
    const {formYear} = this.state;

    const year = formYear && formYear.value ? formYear.value : -1;

    const flowchartsData = this.flowchartsItems.filter(flowchart => {
      if (Array.isArray(formFlowchartStatus)) {
        return formFlowchartStatus.findIndex(status => (
          status.value.toLowerCase() === flowchart.status.name.toLowerCase()
        )) !== -1 && flowchart.year === year;
      }

      return formFlowchartStatus.value.toLowerCase() === flowchart.status.name.toLowerCase() && flowchart.year === year;
    });

    this.setState({
      formFlowchartStatus,
      flowchartsData
    });
  };

  getSelectFlowchartStatusConfig () {
    const {checkRoles} = this.props;
    const {formFlowchartStatus, optionsFlowchartStatus} = this.state;

    return {
      isSearchable: false,
      isMulti: !checkRoles('CLIENT'),
      value: formFlowchartStatus,
      onChange: this.handleChangeFlowchartStatusSelect,
      options: optionsFlowchartStatus,
      placeholder: <LocalizedMessage id='report.flowchart-status.placeholder' />
    };
  }

  handleChangeYearSelect = (formYear) => {
    const {formFlowchartStatus} = this.state;

    const year = formYear && formYear.value ? formYear.value : -1;

    const flowchartsData = this.flowchartsItems.filter(flowchart => {
      if (Array.isArray(formFlowchartStatus)) {
        return formFlowchartStatus.findIndex(status => (
          status.value.toLowerCase() === flowchart.status.name.toLowerCase()
        )) !== -1 && flowchart.year === year;
      }

      return formFlowchartStatus.value.toLowerCase() === flowchart.status.name.toLowerCase() && flowchart.year === year;
    });

    this.setState({
      formYear,
      flowchartsData
    });
  };

  getSelectYearConfig () {
    const {formYear, optionsYear} = this.state;

    return {
      isSearchable: false,
      value: formYear,
      onChange: this.handleChangeYearSelect,
      options: optionsYear,
      placeholder: <LocalizedMessage id='report.year.placeholder' />,
      ref: 'year'
    };
  }

  handleChangeFlowchartsSelect = (formFlowcharts) => {
    const flowchartsData = this.flowchartsItems.filter(flowchart => (
      formFlowcharts.findIndex(_flowchart => _flowchart.value === flowchart.id) !== -1
    ));

    this.setState({
      formFlowcharts,
      flowchartsData
    });
  };

  getSelectFlowchartsConfig () {
    const {formFlowcharts, formFlowchartStatus, optionsFlowcharts} = this.state;

    return {
      isSearchable: true,
      isMulti: true,
      isDisabled: formFlowchartStatus && formFlowchartStatus.length !== 0,
      value: formFlowcharts,
      onChange: this.handleChangeFlowchartsSelect,
      options: optionsFlowcharts,
      placeholder: <LocalizedMessage id='report.flowcharts.placeholder' />
    };
  }

  getBreadcrumbs () {
    const {isEditMode} = this.state;

    const breadcrumbs = [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='reports' />,
        link: '/app/reports'
      }
    ];

    if (isEditMode) {
      breadcrumbs.push({
        title: <LocalizedMessage id='edit-report' />,
        link: '/app/report/edit',
        variables: {
          id: this.report.id
        }
      });
    } else {
      breadcrumbs.push({
        title: <LocalizedMessage id='new-report' />
      });
    }

    return breadcrumbs;
  }

  checkErrors () {
    const {
      formReportName,
      formFlowchartStatus,
      formFlowcharts,
      formYear,
      formStatistic
    } = this.state;

    const errors = {};

    if (typeof formReportName !== 'string' || !formReportName.length) {
      errors['reportName'] = localizeMessage({id: 'report.check-errors.name'});
    }

    if ((!formFlowchartStatus || formFlowchartStatus.length === 0) && (!formFlowcharts || !formFlowcharts.length)) {
      errors['flowcharts'] = localizeMessage({id: 'report.check-errors.flowcharts'});
    }

    if (((formFlowchartStatus && formFlowchartStatus.length !== 0) && !formYear)) {
      errors['year'] = localizeMessage({id: 'report.check-errors.year'});
    }

    if (!formStatistic || !formStatistic.value) {
      errors['statistic'] = localizeMessage({id: 'report.check-errors.statistics'});
    }

    return errors;
  }

  export = (type) => {
    const {checkRoles} = this.props;

    const errors = this.checkErrors();

    const errorsKeys = Object.keys(errors);

    let showErrors = false;

    if (errorsKeys.length) {
      const firstRef = this.refs[errorsKeys[0]];

      if (firstRef) {
        window.scrollTo(0, firstRef.offsetTop);
      }

      SweetAlert({
        icon: 'error',
        title: localizeMessage({id: 'validation-errors.title'}),
        content: (
          <Fragment>
            {
              errorsKeys.map((errorKey, errorIndex) => <p
                key={'validation-error-' + errorIndex}>{errors[errorKey]}</p>)
            }
          </Fragment>
        ),
        buttons: {
          cancel: 'Ok'
        }
      });

      showErrors = true;

      return;
    }

    this.setState({showErrors, errors});

    if (checkRoles('CLIENT')) {
      this.exportReport(type);

      return;
    }

    SweetAlert({
      title: localizeMessage({id: `report.export.${type}`}),
      text: localizeMessage({id: 'report.export-without-saving'}),
      buttons: {
        confirm: localizeMessage({id: 'yes'}),
        cancel: localizeMessage({id: 'no'})
      }
    })
      .then(async isConfirm => {
        if (isConfirm) {
          this.exportReport(type);
        }
      });
  };

  exportReport = async (type) => {
    const {
      formReportName,
      formStatistic,
      flowchartsData
    } = this.state;

    if (type === 'report') {
      if (this._table) {
        this._table.exportTable();
      }
    } else {
      await exportRawTable(formReportName, flowchartsData, formStatistic.value);
    }
  };

  generateDataForSaving () {
    const {
      formFlowcharts,
      formReportName,
      formFlowchartStatus,
      formYear,
      formStatistic
    } = this.state;

    const savingObject = {
      flowchartIds: formFlowcharts.map(flowchart => flowchart.value),
      title: formReportName,
      view: {
        status: formFlowchartStatus,
        year: formYear,
        statistic: formStatistic,
        formFlowcharts: formFlowcharts
      }
    };

    if (
      this._table &&
      this._table._pivot
    ) {
      savingObject.view.tableData = this._table.getTableData();
    }

    return {
      ...savingObject,
      view: JSON.stringify(savingObject.view)
    };
  }

  onClickSaveReport = async () => {
    const errors = this.checkErrors();

    const errorsKeys = Object.keys(errors);

    if (errorsKeys.length) {
      const firstRef = this.refs[errorsKeys[0]];

      if (firstRef) {
        window.scrollTo(0, firstRef.offsetTop);
      }

      const errorsComponent = errorsKeys.map(errorKey => `<p>${errors[errorKey]}</p>`).join('');

      // @TODO: See better html processing solution
      const content = document.createElement('div');
      content.innerHTML = errorsComponent;

      SweetAlert(localizeMessage({id: 'validation-errors.title'}), {
        icon: 'error',
        content: content,
        buttons: {
          confirm: {
            text: 'Ok',
            visible: true,
            closeModal: true
          }
        },
        className: classes['report-errors-alert']
      });

      this.setState({showErrors: true, errors});

      return;
    }

    try {
      const report = await API.reports.save(this.report.id, this.generateDataForSaving());

      Alert.success(localizeMessage({id: 'report.saving.successfuly'}));

      history.push(`/app/reports/${report.id}/edit`);
    } catch (error) {
      console.error(error);

      Alert.error(error.message || localizeMessage({id: 'report.saving.error'}));
    }
  };

  onClickCancel = () => {
    history.push('/app/reports');
  };

  onClickClear = () => {
    this.setState({
      formReportName: '',
      formFlowcharts: [],
      flowchartsData: []
    });
  };

  render () {
    const {checkRoles} = this.props;
    const {
      showErrors,
      errors,
      isPageLoaded,
      isEditMode,
      flowchartsData,
      formReportName,
      formFlowchartStatus,
      formStatistic,
      tableData
    } = this.state;

    if (!isPageLoaded) {
      return null;
    }

    const isUserClient = checkRoles('CLIENT');

    return (
      <Fragment>
        <LocalizedMessage
          id={`site.title.report.${isEditMode ? 'edit' : 'new'}`}
        >
          {localizedMessage => (
            <Fragment>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </Fragment>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-title'>
                  <h5><LocalizedMessage id='report.base-parameters' /></h5>
                </div>

                <div className='ibox-content'>
                  <div className='form-horizontal'>

                    <div className='row form-group'>
                      <div className='col-lg-6'>
                        <div className='row'>

                          <label className='col-lg-5 control-label'>
                            <LocalizedMessage id='report.name.label' />
                          </label>

                          <div className='col-lg-7'>
                            <LocalizedMessage id='report.name.placeholder'>
                              {localizedPlaceholder => (
                                <input
                                  ref='reportName'
                                  type='text'
                                  name='formReportName'
                                  value={formReportName}
                                  onChange={this.setReportName}
                                  placeholder={localizedPlaceholder}
                                  className={cx('form-control', {error: errors.reportName && showErrors})}
                                  required
                                />
                              )}
                            </LocalizedMessage>
                          </div>

                        </div>

                        {
                          errors.reportName && showErrors ? (
                            <div className='row'>
                              <div className='col-lg-5' />
                              <div className='col-lg-7'>
                                <small className='text-danger'>
                                  <LocalizedMessage id='validation-error.required-field' />
                                </small>
                              </div>
                            </div>
                          ) : null
                        }
                      </div>

                      <div className='col-lg-6' />

                    </div>

                    <div className='row form-group'>
                      <div className='col-lg-6'>
                        <div className='row'>

                          <label className='col-lg-5 control-label'>
                            <LocalizedMessage id='report.flowchart-status.label' />
                          </label>

                          <div className='col-lg-7'>
                            <Select
                              {...this.getSelectFlowchartStatusConfig()}
                            />
                          </div>

                        </div>
                      </div>

                      <div className='col-lg-6' />
                    </div>

                    {
                      formFlowchartStatus && formFlowchartStatus.length !== 0 ? (
                        <div className='row form-group'>
                          <div className='col-lg-6'>
                            <div className='row'>

                              <label className='col-lg-5 control-label'>
                                <LocalizedMessage id='report.year.label' />
                              </label>

                              <div className='col-lg-7'>
                                <Select
                                  {...this.getSelectYearConfig()}
                                />
                              </div>

                            </div>
                          </div>

                          <div className='col-lg-6' />
                        </div>
                      ) : null
                    }

                    <div className='row form-group'>
                      <div className='col-lg-6'>
                        <div className='row'>

                          <label className='col-lg-5 control-label'>
                            <LocalizedMessage id='report.flowcharts.label' />
                          </label>

                          <div className='col-lg-7'>
                            <Select
                              {...this.getSelectFlowchartsConfig()}
                            />
                          </div>

                        </div>
                      </div>

                      <div className='col-lg-6' />
                    </div>

                    <div className='row form-group'>
                      <div className='col-lg-6'>
                        <div className='row'>

                          <label className='col-lg-5 control-label'>
                            <LocalizedMessage id='report.report-type' />
                          </label>

                          <div className='col-lg-7'>
                            <Select
                              {...this.getSelectStatisticConfig()}
                            />
                          </div>

                        </div>
                      </div>

                      <div className='col-lg-6' />
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          {
            formStatistic &&
            formStatistic.value === 'budgetSummary' &&
            flowchartsData &&
            flowchartsData.length ?
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='ibox float-e-margins'>
                    <div className='ibox-content'>
                      <ReportTable
                        ref={this.setTableRef}
                        flowchartsData={flowchartsData}
                        tableData={tableData}
                        getReportName={this.getReportName}
                      />
                    </div>
                  </div>
                </div>
              </div>
              : null
          }

          <div className='hr-line-dashed' />

          <div className='row bottom-page-row'>
            <div className='col-lg-2' />
            <div className='col-lg-10 text-right'>
              {
                !isUserClient ?
                  <Fragment>
                    <button className='btn btn-white step-button' type='button' onClick={this.onClickCancel}>
                      <span className='bold'><LocalizedMessage id='cancel' /></span>
                    </button>
                    &nbsp;&nbsp;
                  </Fragment>
                  :
                  <Fragment>
                    <button className='btn btn-white step-button' type='button' onClick={this.onClickClear}>
                      <span className='bold'><LocalizedMessage id='reset' /></span>
                    </button>
                    &nbsp;&nbsp;
                  </Fragment>
              }
              {
                formStatistic &&
                formStatistic.value === 'budgetSummary' ?
                  <Fragment>
                    <button
                      type='button'
                      className='btn btn-success step-button'
                      disabled={!flowchartsData || !flowchartsData.length}
                      onClick={() => this.export('report')}
                    >
                      <i className='fa fa-download' />
                      &nbsp;
                      <span className='bold'><LocalizedMessage id='report.export.report' /></span>
                    </button>
                    &nbsp;&nbsp;
                  </Fragment>
                  : null
              }
              <button
                type='button'
                className='btn btn-success step-button'
                onClick={() => this.export('raw')}
              >
                <i className='fa fa-download' />
                &nbsp;
                <span className='bold'><LocalizedMessage id='report.export.raw' /></span>
              </button>
              {
                !isUserClient ?
                  <Fragment>
                    &nbsp;&nbsp;
                    <button
                      type='button'
                      className='btn btn-info step-button'
                      onClick={this.onClickSaveReport}
                    >
                      <i className='fa fa-download' />
                      &nbsp;
                      <span className='bold'><LocalizedMessage id='report.save' /></span>
                    </button>
                  </Fragment>
                  : null
              }
            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

export default Report;
