import Client from './HTTPClient';

const API = {
  list (request) {
    return Client.post('/api/divisions/', request);
  },

  year (year) {
    return Client.get(`/api/divisions/year/${year}`);
  },

  get (id, request) {
    return Client.get(`/api/divisions/${id}`, request);
  }
};

export default API;
