import Client from './HTTPClient';

const API = {
  list (request) {
    return Client.post('/api/targetAudiences/', request);
  },

  getVolumeType (targetAudienceid) {
    return Client.get(`/api/targetAudiences/${targetAudienceid}/volume`);
  }
};

export default API;
