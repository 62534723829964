import {connect} from 'react-redux';
import {switchMenu} from '../../modules/menu';
import {logOut, impersonateLogOut} from '../../modules/auth';
import Header from './Header';

const mapDispatchToProps = {
  switchMenu,
  impersonateLogOut,
  logOut
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
  profile: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
