import React, { PureComponent } from 'react';
import Modal from 'react-bootstrap-modal';
import { Link } from 'react-router-dom';

import { IVersion } from 'types/flowchart';

import API from '../../../../api';
import Alert from '../../../../helpers/alert';
import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import { CollapsibleCell } from '../../../../components/CollapsibleCell';

import './ChangelogModal.scss';

interface IProps {
  data: { id: number, title: string } | null;
  onClose: () => void;
}

interface IState {
  versions: IVersion[];
}

class ChangelogModal extends PureComponent<IProps, IState> {
  state = {
    versions: [],
  };

  componentDidUpdate (prevProps: IProps) {
    if (prevProps.data !== this.props.data) {
      this.loadVersions();
    }
  }

  async loadVersions () {
    const { data } = this.props;

    if (data === null) {
      return;
    }

    try {
      const versions: IVersion[] = await API.flowcharts.getVersions(data.id);
      this.setState({ versions });
    } catch (error) {
      console.error(error);
      Alert.error(localizeMessage({ id: 'flowcharts.errors.changelog-data-loading-failed' }));
    }
  }

  render () {
    const {
      data,
      onClose,
    } = this.props;
    const { versions } = this.state;

    if (data === null) {
      return null;
    }

    return (
      <Modal
        show={data !== null}
        onHide={onClose}
        aria-labelledby='ModalHeader'
        className='changelog-modal'
      >
        <Modal.Header>
          <h1 id='ModalHeader' className='modal-title'>{data.title}</h1>
        </Modal.Header>
        <Modal.Body>
          <div className='table-responsive'>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th><LocalizedMessage id='flowcharts.changelog-table.date' /></th>
                  <th><LocalizedMessage id='flowcharts.changelog-table.user' /></th>
                  <th><LocalizedMessage id='flowcharts.changelog-table.event' /></th>
                  <th><LocalizedMessage id='flowcharts.changelog-table.changes' /></th>
                  <th><LocalizedMessage id='flowcharts.changelog-table.comment' /></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  versions.map((version: IVersion, index: number) => (
                    <tr key={version.id}>
                      <td className='name-col'>
                        {version.dateCreated}
                      </td>
                      <td>
                        {version.changer
                          ? `${version.changer.firstName} ${version.changer.lastName}`
                          : ''
                        }
                      </td>
                      <td>
                        <LocalizedMessage id={version.eventName.name.toLowerCase()} />
                      </td>
                      <td className='collapsible'>
                        <CollapsibleCell content={version.changes} id={version.id} />
                      </td>
                      <td className='collapsible'>
                        <CollapsibleCell content={version.comment} id={version.id} />
                      </td>
                      <td className='text-right text-nowrap'>
                        <div>
                          <Link
                            className='btn btn-warning btn-xs'
                            to={index === 0
                              ? `/app/flowcharts/${data.id}/edit`
                              : `/app/flowcharts/${data.id}/${version.id}/edit`
                            }
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            &nbsp;&nbsp;
                            <span><LocalizedMessage id='open' /></span>
                            &nbsp;&nbsp;
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Dismiss className='btn btn-default'><LocalizedMessage id='cancel' /></Modal.Dismiss>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangelogModal;
