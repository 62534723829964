import React, {PureComponent} from 'react';
import Modal from 'react-bootstrap-modal';
import cx from 'classnames';

import LocalizedMessage from '../../../../components/LocalizedMessage';

class RevertVersionModal extends PureComponent {
  state = {
    comment: '',
  }

  handleCommentChange = (e) => {
    this.setState({comment: e.target.value});
  }

  onAcceptLocal = () => {
    const {comment} = this.state;

    if (comment.length) {
      this.props.onAccept(comment);
    }
  };

  render () {
    const {
      onClose,
      active = false,
    } = this.props;
    const {comment} = this.state;

    return (
      <Modal
        show={active}
        onHide={onClose}
        aria-labelledby='ModalHeader'
        className='flowchart-save-modal'
      >
        <Modal.Header>
          <h1 id='ModalHeader' className='modal-title'><LocalizedMessage id='flowchart.revert-version' />?</h1>
        </Modal.Header>
        <Modal.Body>
          <div className='form'>
            <div className='form-group row text-nowrap'>
              <div className='col-lg-12'>
                <div className='col-lg-3'>
                  <label className='form-control-static'><LocalizedMessage id='flowchart.comment.label' /></label>
                </div>
                <div className='col-lg-9'>
                  <textarea
                    className={cx('form-control', {
                      error: !comment.length
                    })}
                    value={comment}
                    onChange={this.handleCommentChange}
                    maxLength={255}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary'
            onClick={this.onAcceptLocal}
          >
            <LocalizedMessage id='save' />
          </button>

          <Modal.Dismiss className='btn btn-default'><LocalizedMessage id='cancel' /></Modal.Dismiss>

        </Modal.Footer>
      </Modal>
    );
  }
}

export default RevertVersionModal;
