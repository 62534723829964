import React, { Children } from 'react';
import { VariableSizeList as List } from 'react-window';
import cx from 'classnames';
import { MenuListComponentProps } from 'react-select';

import * as utils from '../../helpers/utils';
import Select, { components } from '../Select';

const GROUP_HEADER_HEIGHT = 13;
const itemHeight = 55;

const MenuList = (props: MenuListComponentProps<any>) => {
  const { options, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;
  const children = Children.toArray(props.children);

  function getOptionSize (option): number {
    if (option && option.options) {
      return option.options.length * itemHeight + GROUP_HEADER_HEIGHT;
    }

    return itemHeight;
  }

  function getItemSize (i) {
    return getOptionSize(options[i]);
  }

  const totalHeight = children.reduce<number>((height: number, option) => height + getOptionSize(option), 0);

  const estimatedItemSize = totalHeight / children.length;

  return (
    <List
      height={Math.min(totalHeight, 300)}
      itemCount={children.length}
      itemSize={getItemSize}
      estimatedItemSize={estimatedItemSize}
      initialScrollOffset={initialOffset}
      className='react-select__menu-list'
    >
      {({ index, style }) => <div key={index} style={style}>{children[index]}</div>}
    </List>
  );
};

interface IOptionProps {
  data: { type: string; label: string; typeTranslation: string; };
  children: React.ReactNode;
}

const Option = ({ children, ...props }: IOptionProps) => (
  <components.Option {...props}>
    <div className='item'>
      <div>{utils.escape(props.data.label)}</div>
      <div className={cx(`m-mp-filter-type _${props.data.type}`)}>{props.data.typeTranslation}</div>
    </div>
  </components.Option>
);

interface IMultiValueLabelProps {
  data: { type: string; label: string; };
  children: React.ReactNode;
}

const MultiValueLabel = ({ children, ...props }: IMultiValueLabelProps) => (
  <components.MultiValueLabel {...props}>
    <div className='item'>
      <div className={cx(`m-mp-filter-type _${props.data.type}`)}>{utils.escape(props.data.label)}</div>
    </div>
  </components.MultiValueLabel>
);

const makeSelectChangeHandler = (onChange: (newValue: string[]) => void) => (selectedItems: IOption[]) => {
  onChange(selectedItems.map(item => item.idUnique));
};

const formatString = (str) => str.replace(/^\s+|\s+$/g, '').toLowerCase();

const filterSelectOption = ({ label }, rawInput) => {
  const formattedInput = formatString(rawInput);
  const formattedOption = formatString(label);
  const words = formattedInput.split(' ');

  return words.reduce(
    (acc, word) => acc && formattedOption.includes(word),
    true,
  );
};

export interface IOption {
  id: string;
  type: string;
  typeTranslation: string;
  label: string;
  idUnique: string;
  text: string;
}

interface IProps {
  options: IOption[];
  placeholder: React.ReactNode;
  value: string[];
  onChange: (newValue: string[]) => void;
}

const FilterList = (props: IProps) => {
  const { options, placeholder, value = [], onChange } = props;
  const selectedItems = options.filter(item => value.includes(item.idUnique));

  return (
    <Select<IOption>
      components={{ MenuList, Option, MultiValueLabel }}
      options={options}
      getOptionValue={(option) => (option.idUnique)}
      onChange={makeSelectChangeHandler(onChange)}
      value={selectedItems}
      placeholder={placeholder}
      isMulti
      filterOption={filterSelectOption}
    />
  );
};

export default FilterList;
