// ------------------------------------
// Constants
// ------------------------------------
export const SWITCH_MENU = 'SWITCH_MENU';

// ------------------------------------
// Actions
// ------------------------------------
export const switchMenu = () => {
  return {
    type: SWITCH_MENU
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SWITCH_MENU]: (state, action) => ({
    isOpen: !state.isOpen
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isOpen: true
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
