import {fetchResponse} from './helpers/fakeFetch';

import tvcList from './data/tvcList.json';

const API = {
  list () {
    console.log('api.tvc.list()');

    return fetchResponse(1000, tvcList);
  },

  update (id, request) {
    console.log('api.tvc.update(' + id + ', request)');

    return fetchResponse(1000, {});
  },

  delete (id, request) {
    console.log('api.tvc.delete(' + id + ', request)');

    return fetchResponse(1000, {});
  },

  deleteTvcInfo (id, tvcInfoId, request) {
    console.log('api.tvc.deleteTvcInfo(' + id + ', ' + tvcInfoId + ', request)');

    return fetchResponse(1000, {});
  },

  create (request) {
    console.log('api.tvc.create(request)');

    return fetchResponse(1000, {});
  }
};

export default API;
