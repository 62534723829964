import React, {Component} from 'react';
import isEqual from 'react-fast-compare';
import memoizeOne from 'memoize-one';

import LocalizedMessage from '../../../../components/LocalizedMessage';
import Input from './Input';
import FormValidator from '../../../../helpers/FormValidator';
import {generateOptionListFromActiveMediaTypes} from '../../../../helpers/utils';

class NewMedia extends Component {
  validator = new FormValidator([
    {
      field: 'numberIo',
      method: option => !!(option && option.value),
      validWhen: true,
      message: 'Required field'
    },
    {
      field: 'mediaTypes',
      method: options => !!(options && options.length),
      validWhen: true,
      message: 'Required field'
    },
    {
      field: 'spendsTypes',
      method: options => !!(options && options.length),
      validWhen: true,
      message: 'Required field'
    }
  ]);

  state = {
    form: {
      numberIo: null,
      mediaTypes: [],
      spendsTypes: [],
      validation: this.validator.valid()
    },
    submitted: false,
    optionsMediaType: generateOptionListFromActiveMediaTypes(this.props.mediaTypes),
    optionsSpendsType: Object.entries(this.props.spendsTypes).map(([key, spendsType]) => ({
      value: key,
      label: spendsType.name
    }))
  };

  handleInputChange = e => {
    const {form} = this.state;

    this.setState({
      form: {
        ...form,
        [e.target.name]: e.target.value
      }
    });
  };

  addMediaLocal = () => {
    const {
      category,
      campaign,
      isUsingCompanies,
      addMedia
    } = this.props;

    const {form} = this.state;

    if (
      !form.numberIo || !form.numberIo.value ||
      !form.mediaTypes || !form.mediaTypes.length ||
      !form.spendsTypes || !form.spendsTypes.length
    ) {
      this.setState({
        submitted: true
      });

      return;
    }

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    addMedia(category.id, form.numberIo, form.mediaTypes, form.spendsTypes, campaignId);

    this.setState({
      form: {
        ...form,
        numberIo: null,
        mediaTypes: [],
        spendsTypes: []
      },
      submitted: false
    });
  };

  getNumberIOSelectOptions = memoizeOne((options, categoryId) =>
    options.filter(({instance}) =>
      instance.categoryExtensions.findIndex(ce => ce.id === categoryId) !== -1), isEqual);

  render () {
    const {
      numbersIo,
      category,
      isDisabled = false,
    } = this.props;

    const {
      form: {
        validation: formValidation,
        numberIo,
        mediaTypes,
        spendsTypes
      },
      optionsMediaType,
      optionsSpendsType,
      submitted
    } = this.state;

    const validation = submitted
      ? this.validator.validate(this.state.form)
      : formValidation;

    return (
      <div className='ibox'>
        <div className='ibox-content'>
          <Input
            type='c-select'
            formName='numberIo'
            labelId='flowchart.numberIo.label'
            placeholderId='flowchart.numberIo.placeholder'
            value={numberIo}
            onChange={this.handleInputChange}
            validation={validation.numberIo}
            showErrors={submitted}
            generalContainerClassName='flowCharts-media-control'
            options={this.getNumberIOSelectOptions(numbersIo, category.id)}
            settings={{
              portalPlacement: 'fixed',
              menuPlacement: 'auto'
            }}
            disabled={isDisabled}
          />

          <Input
            type='c-select'
            formName='mediaTypes'
            labelId='flowchart.mediaType.label'
            placeholderId='flowchart.mediaType.placeholder'
            value={mediaTypes}
            onChange={this.handleInputChange}
            validation={validation.mediaTypes}
            showErrors={submitted}
            options={optionsMediaType}
            settings={{
              isMulti: true,
              closeMenuOnSelect: false,
              portalPlacement: 'fixed',
              menuPlacement: 'auto',
            }}
            disabled={isDisabled}
          />

          <Input
            type='c-select'
            formName='spendsTypes'
            labelId='flowchart.spendsType.label'
            placeholderId='flowchart.spendsType.placeholder'
            value={spendsTypes}
            onChange={this.handleInputChange}
            validation={validation.spendsTypes}
            showErrors={submitted}
            options={optionsSpendsType}
            settings={{
              isMulti: true,
              closeMenuOnSelect: false,
              portalPlacement: 'fixed',
              menuPlacement: 'auto',
            }}
            disabled={isDisabled}
          />

          <div className='row form-group'>
            <div className='col-lg-6'>
              <div className='col-lg-5' />
              <div className='col-lg-7'>
                <button
                  type='button'
                  onClick={() => this.addMediaLocal()}
                  className='btn btn-success step-button btn-sm'
                  disabled={isDisabled}
                >
                  <i className='fa fa-plus-circle' />
                  <span className='bold'><LocalizedMessage id='flowchart.add-media' /></span>
                </button>
              </div>
            </div>
            <div className='col-lg-6' />
          </div>
        </div>
      </div>
    );
  }
}

export default NewMedia;
