import SweetAlert from '@sweetalert/with-react';
import store from '../store';
import history from '../history';
import alert from '../helpers/alert';
import {startLoading, stopLoading} from '../modules/loader';
import {localizeMessage} from '../components/LocalizedMessage';

export const serialize = (obj) => {
  const str = [];

  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
};

const checkStatus = async (response, showError) => {
  if (response.status === 0) {
    return response;
  }

  if (
    response.status < 200 ||
    response.status >= 500
  ) {
    let errorMessage = response.statusText || (response.status >= 500 ? 'Internal server error' : 'Unknown error');
    let jsonResponse = null;

    if (
      response.status >= 500 &&
      response.headers.get('content-type') &&
      response.headers.get('content-type').toLowerCase().indexOf('application/json') > -1
    ) {
      jsonResponse = await parseJSON(response);

      if (jsonResponse.message) {
        errorMessage = jsonResponse.message;
      }
    }

    const error = new Error(errorMessage);
    error.response = response || null;

    if (jsonResponse) {
      error.jsonResponse = jsonResponse;
    }

    if (showError) {
      alert.error(errorMessage);
      error.withoutAlerts = true;
    }

    throw error;
  }

  if (response.status >= 400 && response.status < 500) {
    const jsonResponse = await parseJSON(response);

    const error = new Error(jsonResponse.message || response.statusText);
    error.response = response || null;
    error.jsonResponse = jsonResponse || null;

    throw error;
  }

  if (
    response.status !== 204 &&
    response.headers.get('content-type') &&
    response.headers.get('content-type').toLowerCase().indexOf('application/json') > -1
  ) {
    return parseJSON(response);
  } else {
    return response;
  }
};

const parseJSON = async response => {
  const data = await response.json();

  if (Array.isArray(data?.errorParameters) && data.errorParameters.length) {
    let errorMessage = '';

    data.errorParameters.forEach(param => {
      const {field, errorCode} = param;
      const message = localizeMessage({id: `${field}.${errorCode}`});

      if (message) {
        errorMessage += message + '\n';
      }
    });

    if (errorMessage) {
      data.message = errorMessage;
    }
  }

  return data;
};

export const fetchResponse = async (url, params = {}, showError) => {
  const {headers, body, initController, withoutLoader = false, ...otherParams} = params;

  if (!withoutLoader) {
    store.dispatch(startLoading());
  }

  try {
    const responseData = {
      headers: headers(),
      ...otherParams
    };

    if (body) {
      responseData.body = typeof body === 'function' ? body() : body;
    }

    if (typeof initController === 'function' && AbortController) {
      const controller = new AbortController();

      responseData.signal = controller.signal;

      initController(controller);
    }

    const response = await fetch(url, responseData);

    const status = await checkStatus(response, showError);

    if (!withoutLoader) {
      store.dispatch(stopLoading());
    }

    return status;
  } catch (error) {
    if (!withoutLoader) {
      store.dispatch(stopLoading());
    }

    return handleErrorRequest(error, () => fetchResponse(url, params, showError), showError);
  }
};

const handleErrorRequest = async (error, retry, showError) => {
  const errorMessage = error.message || 'Request error';

  if (errorMessage === 'Access is denied' || errorMessage === 'Access Denied') {
    history.replace('/');

    error.withoutAlerts = true;

    throw error;
  }

  if (
    error.response &&
    error.response.status &&
    error.response.status !== 200 &&
    (
      error.response.status < 400 ||
      error.response.status >= 500
    )
  ) {
    if (!error.jsonResponse) {
      return new Promise((resolve, reject) => {
        SweetAlert({
          text: localizeMessage({
            id: 'errors.errorLoadingDataWithMessage'
          }, {
            errorMessage
          }),
          buttons: {
            confirm: localizeMessage({id: 'yes'}),
            cancel: localizeMessage({id: 'no'})
          }
        })
          .then(isConfirm => {
            if (isConfirm) {
              resolve(retry());
            } else {
              reject(error);
            }
          });
      });
    } else {
      throw error;
    }
  } else if (errorMessage === 'Failed to fetch') {
    if (showError) {
      alert.error(
        localizeMessage({
          id: 'errors.errorConnection'
        })
      );

      error.withoutAlerts = true;
    }

    throw error;
  } else {
    if (showError) {
      alert.error(errorMessage);
      error.withoutAlerts = true;
    }

    throw error;
  }
};
