import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PivotGrid, {FieldChooser} from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {localizeMessage} from '../../../../components/LocalizedMessage';
import {budgetSummary} from '../../helpers/exportHandlers';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

class ReportTable extends PureComponent {
  static propTypes = {
    flowchartsData: PropTypes.array,
    tableData: PropTypes.shape({
      areas: PropTypes.shape({
        filter: PropTypes.arrayOf(PropTypes.string).isRequired,
        row: PropTypes.arrayOf(PropTypes.string).isRequired,
        column: PropTypes.arrayOf(PropTypes.string).isRequired,
        data: PropTypes.arrayOf(PropTypes.string).isRequired
      }).isRequired,
      store: PropTypes.array.isRequired
    }),
    getReportName: PropTypes.func.isRequired
  };

  columns = [
    {
      dataField: 'brand'
    },
    {
      dataField: 'country'
    },
    {
      dataField: 'division'
    },
    {
      dataField: 'date',
      dataType: 'string'
    },
    {
      dataField: 'status'
    },
    {
      dataField: 'campaign',
      dataType: 'string'
    },
    {
      dataField: 'categoryExtension'
    },
    {
      dataField: 'spendType'
    },
    {
      dataField: 'targetAudience'
    },
    {
      dataField: 'year',
      dataType: 'number'
    },
    {
      dataField: 'month',
      dataType: 'date'
    },
    {
      dataField: 'week',
      dataType: 'number'
    },
    {
      dataField: 'bigMedia'
    },
    {
      dataField: 'mediaType'
    },
    {
      dataField: 'description',
      dataType: 'string'
    },
    {
      dataField: 'tvcDuration',
      dataType: 'string'
    },
    {
      dataField: 'activeWeek',
      dataType: 'number'
    },
    {
      dataField: 'budgetRur',
      dataType: 'number'
    },
    {
      dataField: 'budgetEur',
      dataType: 'number'
    },
    {
      dataField: 'grps',
      dataType: 'number'
    },
    {
      dataField: '30grps',
      dataType: 'number'
    },
    {
      dataField: 'measures'
    }
  ];

  fields = [
    {
      caption: 'Brand',
      dataField: 'brand',
      expanded: true
    },
    {
      caption: 'Country',
      dataField: 'country',
      expanded: true
    },
    {
      caption: 'Division',
      dataField: 'division',
      expanded: true
    },
    {
      caption: 'Date',
      dataField: 'date',
      dataType: 'string'
    },
    {
      caption: 'Status',
      dataField: 'status',
      expanded: true
    },
    {
      caption: 'Campaign',
      dataField: 'campaign',
      dataType: 'string'
    },
    {
      caption: 'Category (extension)',
      dataField: 'categoryExtension',
      expanded: true
    },
    {
      caption: 'Spend Type',
      dataField: 'spendType',
      expanded: true
    },
    {
      caption: 'Target Audience',
      dataField: 'targetAudience',
      expanded: true
    },
    {
      caption: 'Quarter Only',
      dataField: 'month',
      dataType: 'date',
      groupInterval: 'quarter',
      expanded: true
    },
    {
      caption: 'Year',
      dataField: 'year',
      dataType: 'number',
      expanded: true
    },
    {
      caption: 'Month Only',
      dataField: 'month',
      dataType: 'date',
      groupInterval: 'month',
      expanded: true
    },
    {
      caption: 'Week',
      dataField: 'week',
      dataType: 'number',
      expanded: true
    },
    {
      caption: 'Big Media',
      dataField: 'bigMedia',
      expanded: true
    },
    {
      caption: 'Media Type',
      dataField: 'mediaType',
      expanded: true
    },
    {
      caption: 'Description',
      dataField: 'description',
      dataType: 'string'
    },
    {
      caption: 'TVC duration',
      dataField: 'tvcDuration',
      dataType: 'string'
    },
    {
      caption: 'Active week',
      dataField: 'activeWeek',
      dataType: 'number',
      summaryType: 'custom',
      calculateCustomSummary: (options) => {
        switch (options.summaryProcess) {
          case 'start':
            options.totalValue = {};

            break;
          case 'calculate':
            const weekString = moment(options.value.Month).format('YYYY-MM') + '-' + options.value.Week;

            options.totalValue[weekString] = options.totalValue[weekString] || !!options.value.IsActiveWeek;

            break;
          case 'finalize':
            options.totalValue = Object.keys(options.totalValue).reduce((prev, curWeek) => {
              const curValue = options.totalValue[curWeek];

              if (!curValue) {
                return prev;
              }

              return prev + 1;
            }, 0);

            break;
          default:
            break;
        }
      }
    },
    {
      caption: 'Budget RUR',
      dataField: 'budgetRur',
      dataType: 'number',
      format: price => `${price.toFixed(2)}₽`,
      summaryType: 'sum'
    },
    {
      caption: 'Budget EUR',
      dataField: 'budgetEur',
      dataType: 'number',
      format: {
        style: 'currency',
        currency: 'EUR',
        useGrouping: true
      },
      summaryType: 'sum'
    },
    {
      caption: 'GRPs',
      dataField: 'grps',
      dataType: 'number',
      format: {
        type: 'fixedPoint'
      },
      summaryType: 'sum'
    },
    {
      caption: 'Adj.GRP',
      dataField: '30grps',
      dataType: 'number',
      format: {
        type: 'fixedPoint'
      },
      summaryType: 'sum'
    },
    {
      caption: 'Measures',
      dataField: 'measures',
      expanded: true,
      isMeasure: true
    }
  ];

  textKeys = [
    'dataNotAvailable',
    'collapseAll',
    'expandAll',
    'exportToExcel',
    'grandTotal',
    'noData',
    'removeAllSorting',
    'showFieldChooser',
    'sortColumnBySummary',
    'sortRowBySummary',
    'total',
    'fieldChooser',
    'allFields',
    'columnFields',
    'dataFields',
    'filterFields',
    'rowFields',
    'columnFieldArea',
    'dataFieldArea',
    'filterFieldArea',
    'rowFieldArea'
  ];

  defaultAreas = {
    filter: [
      'country',
      'status',
      'bigMedia',
      'division'
    ],
    row: [
      'brand',
      'categoryExtension',
      'targetAudience',
      'mediaType',
      'measures'
    ],
    column: [
      'month',
      'week'
    ],
    data: [
      'budgetRur',
      'budgetEur'
    ]
  };

  state = {
    store: this.props.tableData && this.props.tableData.store ? this.props.tableData.store : null,
    areas: this.props.tableData && this.props.tableData.areas ? this.props.tableData.areas : this.defaultAreas
  };

  _pivot = null;

  componentDidMount () {
    if (!this.state.store) {
      this.updateData();
    }
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.flowchartsData.map(flowchart => flowchart.id).join('-') ===
      this.props.flowchartsData.map(flowchart => flowchart.id).join('-')
    ) {
      return false;
    }

    this.updateData();
  }

  setPivotRef = ref => {
    this._pivot = ref ? ref.instance : null;
  };

  async updateData () {
    const store = await this.generateStore();

    this.setState({
      store
    });
  }

  async generateStore () {
    const {flowchartsData} = this.props;

    const sourceTable = await budgetSummary(flowchartsData);

    const resultStore = [];

    sourceTable.forEach(row => {
      const rowData = {};

      this.columns.forEach((column, columnIndex) => {
        const value = row[columnIndex];

        rowData[column.dataField] = column.dataType === 'date' && value ? new Date(value) : value;
      });

      resultStore.push(rowData);
    });

    return resultStore;
  }

  exportTable = () => {
    this._pivot.option('export.fileName', this.props.getReportName());
    this._pivot.exportToExcel();
  };

  getTableData = () => {
    const {store} = this.state;

    const areas = {
      filter: [],
      row: [],
      column: [],
      data: []
    };

    this._pivot.getDataSource().state().fields.forEach(field => {
      if (field.area) {
        areas[field.area].push(field.dataField);
      }
    });

    return {
      areas,
      store
    };
  };

  getAreaByFieldName = (fieldName) => {
    const {areas} = this.state;

    for (let areaKey of Object.keys(areas)) {
      if (areas[areaKey].includes(fieldName)) {
        return areaKey;
      }
    }

    return undefined;
  };

  generateSourceData = (store) => {
    return new PivotGridDataSource({
      fields: this.fields.map(field => ({
        ...field,
        area: this.getAreaByFieldName(field.dataField)
      })),
      store
    });
  };

  generateTexts = () => {
    const texts = {};

    this.textKeys.forEach(textKey => {
      texts[textKey] = localizeMessage({id: 'pivot.' + textKey});
    });

    return texts;
  };

  render () {
    const {store} = this.state;

    if (!store) {
      return null;
    }

    const localizedTexts = this.generateTexts();

    return (
      <PivotGrid
        ref={this.setPivotRef}
        dataSource={this.generateSourceData(store)}
        dataFieldArea='row'
        allowSortingBySummary={true}
        allowSorting={true}
        allowFiltering={true}
        allowExpandAll={true}
        expanded={true}
        showBorders={true}
        showColumnTotals={true}
        showColumnGrandTotals={true}
        showRowTotals={true}
        showRowGrandTotals={true}
        rowHeaderLayout='standard'
        fieldPanel={{
          visible: true,
          showColumnFields: false,
          showDataFields: false,
          showFilterFields: true,
          showRowFields: false,
          allowFieldDragging: true,
          texts: localizedTexts
        }}
        export={{
          enabled: false
        }}
        texts={localizedTexts}
      >
        <FieldChooser
          enabled={true}
          title={localizedTexts.fieldChooser}
          height={600}
          layout={2}
          texts={localizedTexts}
        />
      </PivotGrid>
    );
  }
}

export default ReportTable;
