import React, {PureComponent} from 'react';
import Input from './Input';

class TargetAudienceSelect extends PureComponent {
  componentDidMount () {
    const {value, onChange} = this.props;
    if (value) {
      const target = {name: 'targetAudience', value};
      onChange({target});
    }
  }

  render () {
    const {options, value, disabled, onChange} = this.props;

    return (
      <Input
        type='c-select-virtualized'
        formName='targetAudience'
        labelId='flowchart.target-audience.label'
        placeholderId='flowchart.target-audience.placeholder'
        options={options}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
}

export default TargetAudienceSelect;
