import React from 'react';
import {Switch, Route} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Auth from './Auth';
import Flowcharts from './Flowcharts';
import Flowchart from './Flowchart';
import FlowchartView from './FlowchartView';
import Reports from './Reports';
import Report from './Report';
import Users from './Settings/Users';
import User from './Settings/User';
import Sync from './Settings/Sync';
import Advertisers from './Dictionaries/Advertisers';
import Agencies from './Dictionaries/Agencies';
import Brands from './Dictionaries/Brands';
import Cpp from './Dictionaries/Cpp';
import AimMac from './Dictionaries/AimMac';
import Campaigns from './Dictionaries/Campaigns';
import Cbu from './Dictionaries/Cbu';
import CbuOlv from './Dictionaries/CbuOlv';
import Currencies from './Dictionaries/Currencies';
import Frequencies from './Dictionaries/Frequencies';
import Indoor from './Dictionaries/Indoor';
import MediaTypes from './Dictionaries/MediaTypes';
import NumberIo from './Dictionaries/NumberIo';
import Countries from './Dictionaries/Countries';
import Regions from './Dictionaries/Regions';
import SpendsTypes from './Dictionaries/SpendsTypes';
import TargetAudiences from './Dictionaries/TargetAudiences';
import TvcLength from './Dictionaries/TvcLength';
import Tvc from './Dictionaries/Tvc';
import Main from './Main';
import NotFound from './NotFound';

export const Routes = () => (
  <Switch>
    <Route exact path='/app/auth' component={Auth} />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER', 'CLIENT']}
      path='/app/flowcharts'
      component={Flowcharts}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER']}
      path='/app/flowcharts/:id/edit'
      component={Flowchart}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER']}
      path='/app/flowcharts/:id/:versionId/edit'
      component={Flowchart}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER']}
      path='/app/flowcharts/create'
      component={Flowchart}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER', 'CLIENT']}
      path='/app/flowcharts/:id'
      component={FlowchartView}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER']}
      path='/app/reports'
      component={Reports}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER']}
      path='/app/reports/:id/edit'
      component={Report}
    />
    <PrivateRoute
      exact
      roles={['ADMIN', 'PLANNER', 'BUYER', 'CLIENT']}
      path='/app/reports/create'
      component={Report}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/settings/users/create'
      component={User}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/settings/users/:id/edit'
      component={User}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/settings/users'
      component={Users}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/settings/sync'
      component={Sync}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/dictionaries/advertisers'
      component={Advertisers}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/dictionaries/agencies'
      component={Agencies}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/dictionaries/brands'
      component={Brands}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/cpp'
      component={Cpp}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/aimMac'
      component={AimMac}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/campaigns'
      component={Campaigns}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/cbu'
      component={Cbu}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/countries'
      component={Countries}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/currencies'
      component={Currencies}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/frequencies'
      component={Frequencies}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/indoor'
      component={Indoor}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/mediaTypes'
      component={MediaTypes}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'BUYER']}
      path='/app/dictionaries/numberio'
      component={NumberIo}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/olvCbu'
      component={CbuOlv}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/regions'
      component={Regions}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/spendsType'
      component={SpendsTypes}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/dictionaries/targetAudiences'
      component={TargetAudiences}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/tvc'
      component={Tvc}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/tvclength'
      component={TvcLength}
    />
    <PrivateRoute
      exact
      path='/'
      component={Main}
    />
    <PrivateRoute
      exact
      path='/app'
      component={Main}
    />
    <PrivateRoute
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      component={NotFound}
    />
  </Switch>
);

export default Routes;
