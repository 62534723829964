import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import Loader from '../Loader';

class PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.any.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAuthorizing: PropTypes.bool.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    authorizingError: PropTypes.object,
    checkAuth: PropTypes.func.isRequired
  };

  static defaultProps = {
    roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER', 'CLIENT']
  };

  componentDidMount () {
    this.checkAuth(this.props);
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.checkAuth(props);
  }

  async checkAuth (props) {
    const {history, roles, isAuthorizing, isAuthorized, authorizingError, profile, checkAuth} = props;

    if (isAuthorized && !roles.includes(profile.role.name)) {
      history.replace('/app');

      return;
    }

    if (isAuthorizing || isAuthorized || authorizingError) {
      return;
    }

    try {
      const status = await checkAuth();

      if (!status) {
        history.replace('/app/auth');
      }
    } catch (error) {
      console.error(error);
    }
  }

  render () {
    const {component: Component, isAuthorizing, isAuthorized, authorizingError, ...rest} = this.props;

    if (
      isAuthorizing ||
      (
        !isAuthorized &&
        !authorizingError
      )
    ) {
      return (
        <Loader />
      );
    }

    return (
      <Route
        {...rest}
        render={props =>
          isAuthorized
            ? (
              <Component
                {...props}
              />
            )
            : (
              <Redirect
                to={{
                  pathname: '/app/auth',
                  state: {
                    from: props.location
                  }
                }}
              />
            )
        }
      />
    );
  }
}

export default PrivateRoute;
