import React from 'react';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import Alert from 'react-s-alert';
import Scrollbar from '../Scrollbar';
import Header from '../Header';
import Sidebar from '../Sidebar';
import YandexMetrika from '../YandexMetrika';
import PageLoader from '../PageLoader';
import Routes from '../../routes';
import classes from './App.module.scss';

export const App = () => (
  <div className={classes.Container}>
    <Helmet
      titleTemplate='%s - Aizek.Flowchart'
      defaultTitle='Aizek.Flowchart'
    />
    <Sidebar />
    <div className={cx('gray-bg', classes.Main)}>
      <Header />
      <div className={classes.Content}>
        <Scrollbar className={classes.ContentScrollbar} viewProps={{id: 'main_content'}}>
          <Routes />
        </Scrollbar>
      </div>
    </div>
    <Alert
      stack={{limit: 5}}
      position='top-right'
      effect='slide'
      timeout={5000}
    />
    <YandexMetrika />
    <PageLoader />
  </div>
);

export default App;
