const Config = {
  api: {
    host: process.env.REACT_APP_API_URL,
    authHost: process.env.REACT_APP_API_AUTH_URL || process.env.REACT_APP_API_URL,
    headers: {
      // 'Pragma': 'no-cache',
      // 'Cache-control': 'no-cache'
    }
  },
  appOptions: {
  },
  services: {
    yandexMetrika: {
      account: process.env.REACT_APP_YANDEX_METRIKA_ACCOUNT,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      }
    }
  }
};

export default Config;
