import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import classes from './Scrollbar.module.scss';

class Scrollbar extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    viewClassName: PropTypes.string,
    autoWidth: PropTypes.bool,
    autoHeightMax: PropTypes.number,
    viewProps: PropTypes.object,
    hideTracksWhenNotNeeded: PropTypes.bool,
    hideHorizontalScrollbar: PropTypes.bool,
    hideVerticalScrollbar: PropTypes.bool,
    onScroll: PropTypes.func
  };

  static defaultProps = {
    id: null,
    className: null,
    viewClassName: null,
    autoWidth: false,
    autoHeightMax: null,
    viewProps: null,
    hideTracksWhenNotNeeded: true,
    hideHorizontalScrollbar: false,
    hideVerticalScrollbar: false,
    onScroll: null
  };

  _scrollbars = null;
  _content = null;

  componentDidMount () {
    this.calculateHeight();
  }

  UNSAFE_componentWillReceiveProps () {
    this.calculateHeight();
  }

  setScrollbarsRef = ref => {
    this._scrollbars = ref;
  };

  setContentRef = ref => {
    this._content = ref;
  };

  calculateHeight = () => {
    setTimeout(() => {
      const {autoWidth, autoHeightMax} = this.props;

      if (!this._scrollbars || !this._scrollbars.container) {
        return;
      }

      if (autoWidth) {
        this._scrollbars.container.style.width = `${this._content.offsetWidth}px`;
      }
      if (autoHeightMax) {
        this._scrollbars.container.style.height = `${Math.min(this._content.offsetHeight, autoHeightMax)}px`;
      }

      this._scrollbars.update();
    });
  };

  getContainer () {
    if (!this._scrollbars) {
      return null;
    }

    return this._scrollbars.container;
  }

  getView () {
    if (!this._scrollbars) {
      return null;
    }

    return this._scrollbars.view;
  }

  getScrollLeft () {
    if (!this._scrollbars) {
      return 0;
    }

    return this._scrollbars.getScrollLeft();
  }

  getScrollTop () {
    if (!this._scrollbars) {
      return 0;
    }

    return this._scrollbars.getScrollTop();
  }

  scrollLeft (left) {
    if (!this._scrollbars) {
      return;
    }

    this._scrollbars.scrollLeft(left);
  }

  scrollTop (top) {
    if (!this._scrollbars) {
      return;
    }

    this._scrollbars.scrollTop(top);
  }

  renderView = props => {
    const {viewProps, viewClassName} = this.props;
    const {style, className, ...otherProps} = props;

    const viewStyle = {
      ...style
    };

    return (
      <div
        {...otherProps}
        className={cx(
          className,
          viewClassName
        )}
        style={viewStyle}
        {...viewProps}
      />
    );
  };

  render () {
    const {
      children,
      id,
      className,
      autoWidth,
      hideTracksWhenNotNeeded,
      hideHorizontalScrollbar,
      hideVerticalScrollbar,
      onScroll
    } = this.props;

    return (
      <Scrollbars
        ref={this.setScrollbarsRef}
        hideTracksWhenNotNeeded={hideTracksWhenNotNeeded}
        renderTrackHorizontal={hideHorizontalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderTrackVertical={hideVerticalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderView={this.renderView}
        onScroll={onScroll}
      >
        <div
          ref={this.setContentRef}
          id={id}
          className={cx(
            {
              [classes.ContentAutoWidth]: autoWidth
            },
            className
          )}
        >
          {children}
        </div>
      </Scrollbars>
    );
  }
}

export default Scrollbar;
