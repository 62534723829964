import {fetchResponse} from './helpers/fakeFetch';

import flowchartsList from './data/flowchartsList.json';
import flowchartsItems from './data/flowchartsItems.json';
import flowchartVersions from './data/flowchartVersions.json';

const API = {
  list (request) {
    console.log('api.flowcharts.list(', request, ')');

    return fetchResponse(1000, flowchartsList);
  },

  async getFilterItems (request) {
    console.log('api.flowcharts.getFilterItems(', request, ')');

    return fetchResponse(1000, []);
  },

  get (id) {
    console.log('api.flowcharts.get()');

    return fetchResponse(1000, flowchartsItems[id]);
  },

  getVersions (id) {
    console.log(`api.flowcharts.getVersions(${id})`);

    return fetchResponse(1000, flowchartVersions);
  },

  getVersion (versionId) {
    console.log(`api.flowcharts.getVersion(${versionId})`);

    return fetchResponse(1000, flowchartsItems[versionId]);
  },

  revertToVersion (flowchartId, versionId, comment) {
    console.log(`api.flowcharts.revertToVersion(${flowchartId}, ${versionId}, ${comment})`);

    return fetchResponse(1000, flowchartsItems[flowchartId]);
  },

  delete (id) {
    console.log('api.flowcharts.delete(' + id + ')');

    return fetchResponse(1000, {});
  },

  save (id, data) {
    console.log('api.flowcharts.save(' + id + ', data)');

    return fetchResponse(1000, {});
  },

  status (year, status) {
    console.log('api.flowcharts.status(' + year + ', ' + status + ')');

    return fetchResponse(1000, {});
  },

  changeStatus (id, newStatus) {
    console.log('api.flowcharts.changeStatus(' + id + ', ' + newStatus + ')');

    return fetchResponse(1000, {});
  },

  clone (id) {
    console.log('api.flowcharts.clone(' + id + ')');

    return fetchResponse(1000, {});
  },

  export (id) {
    console.log('api.flowcharts.export(' + id + ')');

    return fetchResponse(1000, {});
  }
};

export default API;
