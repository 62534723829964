import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const API = {
  list (request) {
    return Client.post('/api/reports/', request);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/reports/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  get (id) {
    return Client.get(`/api/reports/${id}`);
  },

  delete (id) {
    return Client.delete(`/api/reports/${id}`);
  },

  save (id, data) {
    if (!id) {
      return Client.post('/api/reports/new', data, true, {}, false);
    }

    return Client.put(`/api/reports/${id}`, data, true, {}, false);
  }
};

export default API;
