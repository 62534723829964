import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import FilterList from '../../../../components/FilterList';
import TableList, { HeadItem } from '../../../../components/TableList';
import API from '../../../../api';

interface IOwnProps {
  checkRoles: (role: string) => boolean;
}

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

class Advertisers extends PureComponent<IOwnProps & Props, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });
      try {
        const dataList = await API.advertisers.list(dataListRequest);

        if (!this.mounted) {
          return;
        }

        this.setState({
          dataList: dataList.items,
          dataListTotal: dataList.total,
          isLoading: false,
        });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    }, this.refreshListTimerDelay);
  }

  async refreshFilterItems () {
    const filterItems = await API.advertisers.getFilterItems();

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems
    });
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='advertisers' />,
        link: '/app/dictionaries/advertisers'
      }
    ];
  }

  getBodyItems () {
    const { checkRoles } = this.props;
    const { dataList } = this.state;

    const isSuperUser = checkRoles('SUPER_ADMIN');

    return dataList.map((row, rowIndex) => (
      <tr key={rowIndex + '_' + row.name}>
        <td>
          <span>{row.name}</span>
        </td>
        {
          isSuperUser
            ? (
              <td>
                <span>{row.extend.agency.name}</span>
              </td>
            )
            : null
        }
      </tr>
    ));
  }

  render () {
    const {
      checkRoles, onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      isLoading
    } = this.state;

    const isSuperUser = checkRoles('SUPER_ADMIN');

    const tableHead: HeadItem[] = [
      {
        label: localizeMessage({ id: 'name' }),
        sort: 'name'
      }
    ];

    if (isSuperUser) {
      tableHead.push({
        label: localizeMessage({ id: 'agency' }),
      });
    }

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.advertisers'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='advertisers-dictionary.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={tableHead}
                    >
                      {this.getBodyItems()}
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Advertisers);
