import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ym, {YMInitializer} from 'react-yandex-metrika';
import Config from '../../config';

class YandexMetrika extends Component {
  static propTypes = {
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired
    }).isRequired,
  };

  accounts =
    Config.services.yandexMetrika.account &&
    Config.services.yandexMetrika.account.length
      ? [Number(Config.services.yandexMetrika.account)]
      : [];

  componentDidMount () {
    if (!this.accounts.length) {
      return;
    }

    this.props.history.listen(location => {
      ym('hit', location.pathname);
    });
  }

  render () {
    if (!this.accounts.length) {
      return null;
    }

    return (
      <YMInitializer
        accounts={this.accounts}
        version='2'
        options={Config.services.yandexMetrika.options}
      />
    );
  }
}

export default YandexMetrika;
