import Client from './HTTPClient';

const aimMacAPI = {
  list (request) {
    return Client.post('/api/aim-mac/', request);
  },
  upload (year, data) {
    return Client.post(`/api/aim-mac/upload/${year}`, data, true, {isJson: false, isMultipart: true});
  }
};

export default aimMacAPI;
