import Client from './HTTPClient';

const API = {
  getDictionaries () {
    return Client.get('/api/sync/dictionaries');
  },

  sync (data) {
    return Client.post('/api/sync/', data);
  }
};

export default API;
