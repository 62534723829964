import {fetchResponse} from './helpers/fakeFetch';

const API = {
  list () {
    console.log('api.spendsType.list()');

    return fetchResponse(1000, {
      'items': [
        {'id': 1, 'name': 'Placement'},
        {'id': 2, 'name': 'Production'},
        {'id': 3, 'name': 'Research'},
        {'id': 4, 'name': 'Tracking'},
        {'id': 5, 'name': 'Commission'}
      ],
      'total': 5
    });
  },

  create () {
    console.log('api.spendsType.create()');

    return fetchResponse(1000, {});
  }
};

export default API;
