import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Sidebar from './Sidebar';

const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
  isOpenMenu: state.menu.isOpen,
  profile: state.auth.profile
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
