import {connect} from 'react-redux';
import {checkRoles} from '../../../../modules/auth';
import User from '../components/User';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
