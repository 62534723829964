import Client from './HTTPClient';

const API = {
  list (request) {
    return Client.post('/api/tvc-info/', request);
  },

  upload (year, data) {
    return Client.post(`/api/tvc-info/upload/${year}`, data, true, {isJson: false, isMultipart: true});
  },

  get (request) {
    return Client.get(`/api/tvc-info/year/${request}`);
  }
};

export default API;
