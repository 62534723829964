import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';

const brandsAPI = {
  list (request) {
    return Client.post('/api/brands/', request);
  },

  listByAdvertiser (advertiserId, request) {
    return Client.get(`/api/brands/${advertiserId}/advertiser`, request);
  },

  get (id) {
    return Client.get(`/api/brands/${id}`);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/brands/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  create (request) {
    return Client.post('/api/brands/new', request);
  }
};

export default brandsAPI;
