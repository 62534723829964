import React from 'react';

import TableTvcProduction from './TableTvcProduction';
import TableTvcProductionRegionality from './TableTvcProductionRegionality';
import TableTvcPlacement from './TableTvcPlacement';
import TableTvcPlacementImpressions from './TableTvcPlacementImpressions';
import TableTvcPlacementRegionality from './TableTvcPlacementRegionality';
import TableDescriptionsProduction from './TableDescriptionsProduction';
import TableDescriptionsProductionRegionality from './TableDescriptionsProductionRegionality';
import TableDescriptionsPlacement from './TableDescriptionsPlacement';
import TableDescriptionsPlacementRegionality from './TableDescriptionsPlacementRegionality';

const TableGenerator = props => {
  switch (props.tableType) {
    case 'tvc-production':
      return (
        <TableTvcProduction
          {...props}
        />
      );
    case 'tvc-production-regionality':
      return (
        <TableTvcProductionRegionality
          {...props}
        />
      );
    case 'tvc-placement':
      return (
        <TableTvcPlacement
          {...props}
        />
      );
    case 'tvc-placement-impressions':
      return (
        <TableTvcPlacementImpressions
          {...props}
        />
      );
    case 'tvc-placement-regionality':
      return (
        <TableTvcPlacementRegionality
          {...props}
        />
      );
    case 'descriptions-production':
      return (
        <TableDescriptionsProduction
          {...props}
        />
      );
    case 'descriptions-production-regionality':
      return (
        <TableDescriptionsProductionRegionality
          {...props}
        />
      );
    case 'descriptions-placement':
      return (
        <TableDescriptionsPlacement
          {...props}
        />
      );
    case 'descriptions-placement-regionality':
      return (
        <TableDescriptionsPlacementRegionality
          {...props}
        />
      );
    default:
      return (
        <span>Table doesn't work</span>
      );
  }
};

TableGenerator.defaultProps = {
  cpp: []
};

export default TableGenerator;
