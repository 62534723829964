import React, {Component} from 'react';
import get from 'lodash/get';
import each from 'lodash/each';
import debounce from 'lodash/debounce';
import flatMapDeep from 'lodash/flatMapDeep';
import cloneDeep from 'lodash/cloneDeep';
import cx from 'classnames';

import Loader from '../../../../components/Loader';
import RoundedInput from '../../../../components/RoundedInput';
import {clamp} from '../../../../helpers/utils';

class Budgets extends Component {
  state = {
    validatedBalance: true,
    validatedBriefed: true,
    data: this.generateData(this.props.data)
  };

  debouncedRecalculate = debounce((...args) => this.recalculate(...args), 500);

  prevMedia = JSON.stringify(this.props.media).length;

  componentDidMount () {
    const {
      selectedCategories,
      media
    } = this.props;

    this.removeCategories(selectedCategories);

    this.recalculate(media);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const nextPropsMedia = JSON.stringify(nextProps.media);

    if (nextPropsMedia !== this.prevMedia) {
      this.prevMedia = nextPropsMedia;
      this.debouncedRecalculate(nextProps.media);
    }

    if (this.props.selectedCategories !== nextProps.selectedCategories) {
      this.removeCategories(nextProps.selectedCategories);
    }

    if (this.props.isUsingCompanies !== nextProps.isUsingCompanies) {
      this.recalculate(nextProps.media);
    }
  }

  generateData (data) {
    delete data.prices;
    delete data.price_total;

    return {
      briefed: {
        price: {},
        total: ''
      },
      planned: {
        price: {},
        total: ''
      },
      reserve: {
        price: {},
        total: ''
      },
      balance: {
        price: {},
        total: ''
      },
      ...data
    };
  }

  onUpdateBudgets = (tablesData) => {
    this.debouncedRecalculate(this.props.media, tablesData);
  };

  onUpdateValidateBriefed = (validated) => {
    const {validatedBriefed} = this.state;

    if (validatedBriefed === validated) {
      return;
    }

    this.setState({
      validatedBriefed: validated
    });
  };

  onHandleInput = (e, dataType, categoryId) => {
    const {
      onUpdateData,
      type,
      media
    } = this.props;

    const {
      data
    } = this.state;

    data[dataType].price[categoryId] = clamp((parseFloat(e.target.value)), 0, 1000000000000).toString();

    onUpdateData({
      [type]: data
    }, () => {
      this.setState({
        [type]: data
      });

      this.debouncedRecalculate(media);
    });
  };

  removeCategories (newSelectedCategories) {
    const {
      onUpdateData,
      type,
      media
    } = this.props;

    const {
      data
    } = this.state;

    for (const dataType in data) {
      for (const price in data[dataType].price) {
        const isCategoryExists = newSelectedCategories.findIndex(category => (+category.id) === (+price)) !== -1;

        if (!isCategoryExists) {
          delete data[dataType].price[price];
        }
      }
    }

    onUpdateData({
      [type]: data
    }, () => {
      this.setState({
        [type]: data
      });

      this.debouncedRecalculate(media);
    });
  }

  async recalculate (media, _tablesData) {
    const {
      onUpdateData,
      type,
      selectedCategories
    } = this.props;

    let tablesData = _tablesData || this.props.tablesData;

    const {
      data
    } = this.state;

    const newBudgets = {
      planned: {},
      reserve: {}
    };

    each(selectedCategories, (category) => {
      newBudgets.reserve[category.id] = 0;
      newBudgets.planned[category.id] = 0;
    });

    each(media, (companies) => {
      each(companies, (categories, categoryExtensionId) => {
        each(categories, media => {
          each(media.mediaTypes, mediaType => {
            const isReserve = mediaType.name.toLowerCase().includes('reserve');

            each(mediaType.spendsTypes, (spendsType) => {
              const budget = tablesData[spendsType.mediaDataHash] ?
                flatMapDeep(tablesData[spendsType.mediaDataHash].budgets || [])
                  .reduce((a, b) => a + b, 0)
                : 0;

              if (isReserve) {
                const reserveSum = (+newBudgets.reserve[categoryExtensionId] || 0) + budget;

                newBudgets.reserve[categoryExtensionId] = +(reserveSum.toFixed(2));
              } else {
                const plannedSum = (+newBudgets.planned[categoryExtensionId] || 0) + budget;

                newBudgets.planned[categoryExtensionId] = +(plannedSum.toFixed(2));
              }
            });
          });
        });
      });
    });

    each(newBudgets, (dataType, dataTypeKey) => {
      each(dataType, (category, categoryId) => {
        if (!data[dataTypeKey]) {
          return;
        }

        data[dataTypeKey].price[categoryId] = category;
      });
    });

    onUpdateData({
      [type]: data
    }, () => {
      this.setState({
        [type]: data
      });

      this.recalculateBalance();
    });
  }

  recalculateBalance () {
    const {
      onUpdateData,
      type
    } = this.props;

    const {
      data
    } = this.state;

    const excludeTypes = ['balance', 'briefed'];

    if (!data.briefed) {
      return;
    }

    let briefedPrice = cloneDeep(data.briefed.price);

    each(data, (dataType, dataTypeKey) => {
      if (excludeTypes.includes(dataTypeKey)) {
        return;
      }

      each(dataType.price, (price, categoryId) => {
        briefedPrice[categoryId] = (briefedPrice[categoryId] || 0) - (price || 0);
      });
    });

    data.balance.price = briefedPrice;

    onUpdateData({
      [type]: data
    }, () => {
      this.setState({
        [type]: data
      });

      this.recalculateTotal();
    });
  }

  recalculateTotal () {
    const {
      onUpdateData,
      type
    } = this.props;

    const {
      data
    } = this.state;

    for (const dataType in data) {
      const sum = Object.values(data[dataType].price).reduce((a, b) => (+a) + (+b), 0);

      data[dataType].total = +(sum.toFixed(2));
    }

    const validatedBalance = !this.validateErrorByCurrency(data.balance.total);

    return new Promise(resolve => (
      onUpdateData({
        [type]: data,
        validatedBalance
      }, () => {
        this.setState({
          [type]: data,
          validatedBalance
        });
        resolve();
      })
    ));
  }

  validateErrorByCurrency (balanceValue) {
    const {currencyValue} = this.props;

    const balance = balanceValue * currencyValue;

    return balance > 500 || balance < -500;
  }

  safeGet = (path, defaultValue = '') => {
    const {
      data
    } = this.state;

    const result = get(data, path, defaultValue);

    return isNaN(result) ? 0 : result;
  };

  render () {
    const {
      selectedCategories,
      budgetsLoading,
      isDisabled = false,
    } = this.props;

    const {
      validatedBalance,
      validatedBriefed
    } = this.state;

    return (
      <div className={cx('display-flex', 'position-relative')}>
        <div>
          <table className='table centered-cells flowCharts-budgets-fixed-table'>
            <thead>
              <tr>
                <th className='text-nowrap'>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={cx('text-nowrap text-uppercase', {
                  'error-balance error-balance-text': !validatedBriefed
                })}>
                  <span>Briefed</span>
                </td>
              </tr>
              <tr>
                <td className='text-nowrap text-uppercase'>
                  <span>Planned</span>
                </td>
              </tr>
              <tr>
                <td className='text-nowrap text-uppercase'>
                  <span>Reserve</span>
                </td>
              </tr>
              <tr>
                <td className={cx('text-nowrap text-uppercase', {
                  'error-balance error-balance-text': !validatedBalance,
                  'success-balance success-balance-text': validatedBalance
                })}>
                  <span>Balance</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='right-flex-table'>
          <table className='table centered-cells flowCharts-budget-table'>
            <thead>
              <tr>
                <th
                  className='text-nowrap'
                  style={{minWidth: '136px'}}
                >
                  <span className='text-uppercase'>Total brand</span>
                </th>

                {
                  selectedCategories.map((category, categoryIndex) => (
                    <th
                      key={'budgets_category_' + categoryIndex}
                      className='text-nowrap'
                      style={{minWidth: '136px'}}
                    >
                      <span>{category.name}</span>
                    </th>
                  ))
                }
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className={cx('text-nowrap', {
                  'error-balance': !validatedBriefed,
                })}>
                  <div className='form-group-sm'>
                    <RoundedInput
                      type='number'
                      step='any'
                      name='price_briefed_total'
                      className='form-control'
                      value={this.safeGet('briefed.total')}
                      readOnly
                      min={0}
                      max={1000000000000}
                      disabled={isDisabled}
                    />
                  </div>
                </td>

                {
                  selectedCategories.map((category, categoryIndex) => (
                    <td
                      className={cx('text-nowrap', {
                        'error-balance': !validatedBriefed,
                      })}
                      key={'budgets_category_input_briefed_' + categoryIndex}
                    >
                      <div className='form-group-sm'>
                        <RoundedInput
                          type='number'
                          step='any'
                          name={'price_briefed_' + categoryIndex}
                          className='form-control'
                          onChange={(e) => this.onHandleInput(e, 'briefed', category.id)}
                          value={this.safeGet(`briefed.price["${category.id}"]`)}
                          min={0}
                          max={1000000000000}
                          disabled={isDisabled}
                        />
                      </div>
                    </td>
                  ))
                }
              </tr>
              <tr>
                <td className='text-nowrap'>
                  <div className='form-group-sm'>
                    <RoundedInput
                      type='number'
                      step='any'
                      name='price_planned_total'
                      className='form-control'
                      value={this.safeGet('planned.total')}
                      readOnly
                      min={0}
                      max={1000000000000}
                      disabled={isDisabled}
                    />
                  </div>
                </td>

                {
                  selectedCategories.map((category, categoryIndex) => (
                    <td
                      className='text-nowrap'
                      key={'budgets_category_input_planned_' + categoryIndex}
                    >
                      <div className='form-group-sm'>
                        <RoundedInput
                          type='number'
                          step='any'
                          name={'price_planned_' + categoryIndex}
                          className='form-control'
                          onChange={(e) => this.onHandleInput(e, 'planned', category.id)}
                          value={this.safeGet(`planned.price["${category.id}"]`)}
                          readOnly
                          min={0}
                          max={1000000000000}
                          disabled={isDisabled}
                        />
                      </div>
                    </td>
                  ))
                }
              </tr>
              <tr>
                <td className='text-nowrap'>
                  <div className='form-group-sm'>
                    <RoundedInput
                      type='number'
                      step='any'
                      name='price_reserve_total'
                      className='form-control'
                      value={this.safeGet('reserve.total')}
                      readOnly
                      min={0}
                      max={1000000000000}
                      disabled={isDisabled}
                    />
                  </div>
                </td>

                {
                  selectedCategories.map((category, categoryIndex) => (
                    <td
                      className='text-nowrap'
                      key={'budgets_category_input_reserve_' + categoryIndex}
                    >
                      <div className='form-group-sm'>
                        <RoundedInput
                          type='number'
                          step='any'
                          name={'price_reserve_' + categoryIndex}
                          className='form-control'
                          onChange={(e) => this.onHandleInput(e, 'reserve', category.id)}
                          value={this.safeGet(`reserve.price["${category.id}"]`)}
                          readOnly
                          min={0}
                          max={1000000000000}
                          disabled={isDisabled}
                        />
                      </div>
                    </td>
                  ))
                }
              </tr>
              <tr>
                <td className={cx('text-nowrap', {
                  'error-balance': !validatedBalance,
                  'success-balance': validatedBalance
                })}>
                  <div className='form-group-sm'>
                    <RoundedInput
                      type='number'
                      step='any'
                      name='price_balance_total'
                      className='form-control'
                      value={this.safeGet('balance.total')}
                      readOnly
                      min={0}
                      max={1000000000000}
                      disabled={isDisabled}
                    />
                  </div>
                </td>

                {
                  selectedCategories.map((category, categoryIndex) => (
                    <td
                      className={cx('text-nowrap', {
                        'error-balance': !validatedBalance,
                        'success-balance': validatedBalance
                      })}
                      key={'budgets_category_input_balance_' + categoryIndex}
                    >
                      <div className='form-group-sm'>
                        <RoundedInput
                          type='number'
                          step='any'
                          name={'price_balance_' + categoryIndex}
                          className='form-control'
                          value={this.safeGet(`balance.price["${category.id}"]`)}
                          readOnly
                          min={0}
                          max={1000000000000}
                          disabled={isDisabled}
                        />
                      </div>
                    </td>
                  ))
                }
              </tr>
            </tbody>
          </table>
        </div>
        <Loader
          active={budgetsLoading}
        />
      </div>
    );
  }
}

export default Budgets;
