import {connect} from 'react-redux';
import {checkRoles} from '../../../../modules/auth';
import Advertisers from '../components/Advertisers';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Advertisers);
