import {saveAs} from 'file-saver';
import ExcelJS from 'exceljs/dist/exceljs';

import exportHandlers from './exportHandlers';

import Alert from '../../../helpers/alert';
import {localizeMessage} from '../../../components/LocalizedMessage';

const Workbook = function Workbook (data, props) {
  const self = this;

  if (typeof props !== 'object') {
    props = {};
  }

  self.SheetNames = Object.keys(data);
  self.Sheets = {};
  self.Props = props;

  self.SheetNames.forEach(function (name) {
    self.Sheets[name] = data[name];
  });
};

function s2ab (s) {
  const buffer = new ArrayBuffer(s.length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }

  return buffer;
}

function sheetFromArrayOfArrays (data, options) {
  const ws = {};
  const range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  const cols = new Array(data[0].length).fill(null).map(function () {
    return {
      wch: 8
    };
  });
  const merges = [];

  for (let R = 0; R < data.length; R++) {
    for (let C = 0; C < data[R].length; C++) {
      // Calculate table range
      if (range.s.r > R) {
        range.s.r = R;
      }
      if (range.s.c > C) {
        range.s.c = C;
      }
      if (range.e.r < R) {
        range.e.r = R;
      }
      if (range.e.c < C) {
        range.e.c = C;
      }

      const cellData = data[R][C];

      if (!cellData) {
        continue;
      }

      const isHead = ['head', 'statistic'].indexOf(
        cellData.place ? cellData.place.link.type : cellData.type
      ) > -1;

      // Cell position
      const cellRef = XLSX.utils.encode_cell({
        c: C,
        r: R
      });

      const cell = {
        s: {
          alignment: {
            vertical: 'top'
          }
        }
      };

      if (isHead) {
        cell.s.border = {
          top: {
            style: 'thin',
            color: {
              auto: 1
            }
          },
          right: {
            style: 'thin',
            color: {
              auto: 1
            }
          },
          bottom: {
            style: 'thin',
            color: {
              auto: 1
            }
          },
          left: {
            style: 'thin',
            color: {
              auto: 1
            }
          }
        };
      }

      if (
        cellData.type === 'skip' ||
        ['string', 'number'].indexOf(typeof cellData.content) === -1
      ) {
        cell.t = 's';
        cell.v = '';
        ws[cellRef] = cell;

        continue;
      }

      const content = cellData.content;
      const contentLength = content.toString().length;

      // Dynamic columns width
      if (cellData.colSpan && cellData.colSpan > 1) {
        const currentCellContentWidth = Math.floor(contentLength / cellData.colSpan);

        for (let i = 0; i < cellData.colSpan; i++) {
          if (!cols[C + i].wch || cols[C + i].wch < currentCellContentWidth) {
            cols[C + i].wch = currentCellContentWidth + 2;
          }
        }
      } else if (!cols[C].wch || cols[C].wch < contentLength) {
        cols[C].wch = contentLength + 2;
      }

      // Merging columns and rows
      if (
        (cellData.colSpan && cellData.colSpan > 1) ||
        (cellData.rowSpan && cellData.rowSpan > 1)
      ) {
        merges.push({
          s: {
            c: C,
            r: R
          },
          e: {
            c: C + (cellData.colSpan > 1 ? cellData.colSpan - 1 : 0),
            r: R + (cellData.rowSpan > 1 ? cellData.rowSpan - 1 : 0)
          }
        });
      }

      // Cell content
      cell.v = content || 0;

      // Head cell styles
      if (isHead) {
        cell.s.fill = {
          fgColor: {
            rgb: 'F6F6F6'
          }
        };
        cell.s.font = {
          bold: true
        };
      }

      // Cell type
      switch (typeof content) {
        case 'number':
          cell.t = 'n';

          break;
        default:
          cell.t = 's';
      }

      ws[cellRef] = cell;
    }
  }

  if (range.s.c < 10000000) {
    ws['!ref'] = XLSX.utils.encode_range(range);
  }

  ws['!cols'] = cols;
  ws['!merges'] = merges;

  if (typeof options === 'object') {
    Object.keys(options).forEach(function (optionKey) {
      ws[optionKey] = options[optionKey];
    });
  }

  return ws;
}

export default async (name, data, handlerName) => {
  const now = new Date();

  if (typeof exportHandlers[handlerName] !== 'function') {
    Alert.error(localizeMessage({id: 'report.export.handler-not-found'}, {name: handlerName}));

    return;
  }

  const table = await exportHandlers[handlerName](data, true);

  const verticalLength = 1;
  const horizontalLength = 1;

  const formattedTable = table.map(row => row.map(content => ({content})));

  const options = {
    xSplit: verticalLength,
    ySplit: horizontalLength,
    topLeftCell: XLSX.utils.encode_col(verticalLength) + XLSX.utils.encode_row(horizontalLength),
    activePane: 'bottomRight',
    state: 'frozen'
  };

  if (handlerName === 'budgetting') {
    exportBudgettingTable(table, options, name);

    return;
  }

  const ws = sheetFromArrayOfArrays(formattedTable, {
    '!viewPane': options,
  });

  ws['!merges'].push({
    s: {
      c: 0,
      r: 0
    },
    e: {
      c: verticalLength - 1,
      r: horizontalLength - 1
    }
  });

  // Workbook generation
  const wb = new Workbook({
    'Cube': ws
  }, {
    Title: name,
    Author: 'Aizek.Flowchart',
    Company: 'ADV Group',
    CreatedDate: now
  });

  // XLSX file generation
  const wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'binary'
  });

  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  });

  saveAs(blob, name + '.xlsx');
};

function exportBudgettingTable (table, options, name) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name, {
    views: [options],
  });

  const headers = table[0];
  worksheet.addRows(table);
  worksheet.columns = headers.map(header => ({
    header,
    style: {
      font: {name: 'Calibri', size: 12},
    },
  }));

  worksheet.columns.forEach(column => {
    let dataMax = 0;
    const values = [column.header, ...column.values];
    values.forEach(value => {
      const columnLength = String(value).length;
      if (columnLength > dataMax) {
        dataMax = columnLength;
      }
    });
    column.width = dataMax < 12 ? 12 : dataMax + 4;
  });

  const colIndex = headers.findIndex(col => col === 'Campaign');
  table.forEach((_, rowIndex) => {
    const row = worksheet.getRow(rowIndex + 1);

    if (rowIndex === 0) {
      row.style = {
        font: {bold: true},
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb:'F6F6F6'},
        },
      };
    } else {
      const cell = row.getCell(colIndex + 1);
      if (isNotIniqueValue(cell.value, table, colIndex)) {
        cell.style = {
          font: {
            name: 'Calibri',
            color: {argb: '9C0006'},
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb:'FFC7CE'},
          },
        };
      }
    }
  });

  workbook.xlsx.writeBuffer().then(data => {
    const blob = new Blob([data], {
      type: 'application/octet-stream'
    });

    saveAs(blob, name + '.xlsx');
  });
}

function isNotIniqueValue (content, table, colIndex) {
  return table.filter(row => row[colIndex] === content).length !== 1;
}
