import {localizeMessage} from '../components/LocalizedMessage';

const statuses = [
  'WORKING',
  'INITIAL',
  'CURRENT',
  'FOR_REPORTS',
  'APPROVED',
  'ARCHIVE',
];

function convertStatusToLocal (status) {
  return status.replace('_', '-').toLowerCase();
}

export const statusOptions = statuses.map(status => ({
  value: status,
  label: localizeMessage({id: `settings.flowcharts.statuses.${convertStatusToLocal(status)}`}),
}));
