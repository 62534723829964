import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import LocalizedMessage, {localizeMessage} from '../../../components/LocalizedMessage';
import LangSwitcher from '../../../components/LangSwitcher';
import alert from '../../../helpers/alert';

class Auth extends Component {
  static propTypes = {
    location: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
        hash: PropTypes.string.isRequired
      })
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired,
    isAuthorizing: PropTypes.bool.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    logIn: PropTypes.func.isRequired,
    checkAuth: PropTypes.func.isRequired
  };

  state = {
    mail: '',
    password: '',
    errors: {}
  };

  _form = null;

  componentDidMount () {
    const {isAuthorizing, isAuthorized} = this.props;

    this.checkAuth(isAuthorizing, isAuthorized);
  }

  static getDerivedStateFromProps ({location, history, isAuthorized}) {
    const from = location.state && location.state.from && location.state.from.pathname !== '/app/auth'
      ? location.state.from
      : {
        pathname: '/app',
        search: '',
        hash: ''
      };

    if (isAuthorized) {
      history.replace(from);
    }

    return null;
  }

  setFormRef = ref => {
    this._form = ref;
  };

  checkAuth (isAuthorizing, isAuthorized) {
    const {location, history, checkAuth} = this.props;

    const from = location.state && location.state.from
      ? location.state.from
      : {
        pathname: '/app',
        search: '',
        hash: ''
      };

    if (isAuthorized) {
      history.replace(from);
    }

    if (isAuthorizing || isAuthorized) {
      return;
    }

    checkAuth();
  }

  onFieldChange = e => {
    const {errors} = this.state;

    const updatedErrors = {
      ...errors
    };

    delete updatedErrors[e.target.name];

    this.setState({
      [e.target.name]: e.target.value.trim(),
      errors: updatedErrors
    });
  };

  onSubmit = async e => {
    const {isAuthorizing, logIn} = this.props;
    const {mail, password} = this.state;

    e.preventDefault();

    if (isAuthorizing) {
      return;
    }

    const errors = {};

    if (!mail.length) {
      errors.mail = {
        message: <LocalizedMessage id='auth.errors.emptyField' />
      };
    }

    if (!password.length) {
      errors.password = {
        message: <LocalizedMessage id='auth.errors.emptyField' />
      };
    }

    if (Object.keys(errors).length) {
      this.setState({
        errors
      });

      if (!mail.length) {
        this._form.mail.focus();
      } else {
        this._form.password.focus();
      }

      return;
    }

    try {
      await logIn(mail, password);
    } catch (error) {
      if (
        error.message === 'Failed to fetch'
      ) {
        alert.warn(localizeMessage({
          id: 'errors.errorConnection'
        }));

        return;
      }

      if (
        error.jsonResponse &&
        !error.jsonResponse.ok &&
        typeof error.jsonResponse.errors === 'object' &&
        Object.keys(error.jsonResponse.errors).length
      ) {
        this.setState({
          password: '',
          errors: error.jsonResponse.errors
        }, () => {
          this._form.mail.focus();
        });

        return;
      }

      alert.error(
        localizeMessage({id: 'errors.errorConnection'})
      );
    }
  };

  render () {
    const {mail, password, errors} = this.state;

    return (
      <div className='middle-box text-center loginscreen animated fadeInDown'>
        <LocalizedMessage
          id='site.title.auth'
        >
          {localizedMessage => (
            <Helmet
              title={localizedMessage}
            />
          )}
        </LocalizedMessage>
        <div className='_margin-top--50'>
          <h3>
            <LocalizedMessage
              id='auth.title'
            />
          </h3>

          <form
            ref={this.setFormRef}
            method='post'
            onSubmit={this.onSubmit}
            className='m-t'
          >
            <div
              className={cx(
                'form-group',
                {
                  'has-errors': errors.mail
                }
              )}
            >
              <LocalizedMessage
                id='auth.form.email'
              >
                {localizedMessage => (
                  <input
                    type='text'
                    placeholder={localizedMessage}
                    name='mail'
                    value={mail}
                    onChange={this.onFieldChange}
                    className='form-control'
                  />
                )}
              </LocalizedMessage>
              {
                errors.mail ?
                  <div
                    className='m-t-xs'>
                    <span className='text-danger'>
                      {errors.mail.message}
                    </span>
                  </div>
                  : null
              }
            </div>

            <div
              className={cx(
                'form-group',
                {
                  'has-errors': errors.password
                }
              )}
            >
              <LocalizedMessage
                id='auth.form.password'
              >
                {localizedMessage => (
                  <input
                    type='password'
                    placeholder={localizedMessage}
                    name='password'
                    value={password}
                    onChange={this.onFieldChange}
                    className='form-control'
                  />
                )}
              </LocalizedMessage>
              {
                errors.password ?
                  <div
                    className='m-t-xs'>
                    <span className='text-danger'>
                      {errors.password.message}
                    </span>
                  </div>
                  : null
              }
            </div>

            <button
              type='submit'
              className='btn btn-primary block full-width m-b'
            >
              <LocalizedMessage
                id='auth.form.submitBtn'
              />
            </button>
          </form>
          <div>
            <LangSwitcher />
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
