import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {checkAuth} from '../../modules/auth';
import PrivateRoute from './PrivateRoute';

const mapDispatchToProps = {
  checkAuth
};

const mapStateToProps = (state) => ({
  isAuthorizing: state.auth.isAuthorizing,
  isAuthorized: state.auth.isAuthorized,
  profile: state.auth.profile,
  authorizingError: state.auth.authorizingError,
  lang: state.locale.lang
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
