import { connect } from 'react-redux';
import { checkRoles } from '../../../../modules/auth';
import Brands from '../components/Brands';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
