import releaseApi from './release';
import fakeApi from './fake';

const getAPI = () => {
  switch (process.env.REACT_APP_API_TYPE) {
    case 'fake':
      return fakeApi;
    default:
      return releaseApi;
  }
};

const api = getAPI();

export default api;
