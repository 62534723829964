import React, {Component} from 'react';
import PropTypes from 'prop-types';

class RoundedInput extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    type: PropTypes.string,
    decimals: PropTypes.number,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };

  static defaultProps = {
    type: 'number',
    decimals: 0
  };

  state = {
    focused: false
  };

  onFocus = (e) => {
    const {onFocus} = this.props;

    this.setState({
      focused: true
    });

    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  onBlur = (e) => {
    const {onBlur} = this.props;

    e.target.value = parseFloat(e.target.value) || '';

    this.setState({
      focused: false
    });

    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  onChange = (e) => {
    const {onChange} = this.props;

    const value = e.target.value;

    const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

    if (value === '' || floatRegExp.test(value)) {
      onChange(e);
    }
  };

  roundValue = (value, decimals) => {
    if (!value || (typeof value === 'string' && !value.length)) {
      return '';
    }

    return parseFloat(value).toFixed(decimals);
  };

  render () {
    const {value, decimals, onFocus, onBlur, onChange, ...props} = this.props;
    const {focused} = this.state;

    return (
      <input
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={focused ? value : this.roundValue(value, decimals)}
        onChange={this.onChange}
        {...props}
      />
    );
  }
}

export default RoundedInput;
