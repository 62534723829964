import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import LocalizedMessage, { localizeMessage } from '../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../components/Breadcrumbs';
import FilterList from '../../../components/FilterList';
import TableList from '../../../components/TableList';
import SweetAlert from '@sweetalert/with-react';

import Alert from '../../../helpers/alert';
import API from '../../../api';

interface IOwnProps {
  checkRoles: (role: string) => boolean;
}

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

class Reports extends PureComponent<IOwnProps & Props, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.reports.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false
      });
    }, this.refreshListTimerDelay);
  }

  async refreshFilterItems () {
    const filterItems = await API.reports.getFilterItems();

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems
    });
  }

  onDelete (id) {
    SweetAlert({
      title: localizeMessage({ id: 'reports.report-delete' }),
      text: localizeMessage({ id: 'reports.report-will-be-deleted' }),
      buttons: {
        confirm: localizeMessage({ id: 'yes' }),
        cancel: localizeMessage({ id: 'cancel' })
      }
    })
      .then(async isConfirm => {
        if (isConfirm) {
          try {
            await API.reports.delete(id);

            Alert.success(localizeMessage({ id: 'reports.report-successfully-deleted' }));

            this.refreshList();
            this.refreshFilterItems();
          } catch (error) {
            console.error(error);

            Alert.error(`${localizeMessage({ id: 'reports.report-failed-delete' })} : ${id}`);
          }
        }
      });
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='reports' />,
        link: '/app/reports'
      }
    ];
  }

  render () {
    const {
      checkRoles, onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id={['site.title.reports', 'site.title.reports.add']}
        >
          {(localizedReportsMessage, localizedAddReportMessage) => (
            <>
              <Helmet
                title={localizedReportsMessage}
              />
              <Breadcrumbs
                title={localizedReportsMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-4'
              >
                <div className='col-lg-8'>
                  <div className='title-action'>
                    {
                      checkRoles('ADMIN,PLANNER,CLIENT') && (
                        <Link
                          to='/app/reports/create'
                          className='btn btn-success'
                        >
                          <i className='fa fa-plus fa-lg' />
                          <span>{localizedAddReportMessage}</span>
                        </Link>
                      )
                    }
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='reports.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: localizeMessage({ id: 'id' }),
                          sort: 'id'
                        },
                        {
                          label: localizeMessage({ id: 'name' }),
                          sort: 'title'
                        },
                        {
                          label: localizeMessage({ id: 'modified-at' }),
                          sort: 'data_changes'
                        },
                        {
                          empty: true,
                          props: {
                            className: 'controls'
                          }
                        }
                      ]}
                    >
                      {
                        dataList.map(report => (
                          <tr key={report.id + '_' + report.title}>
                            <td>
                              {report.id}
                            </td>
                            <td>
                              {report.title}
                            </td>
                            <td>
                              {moment(report.dataChanges).format('DD/MM/YY HH:mm')}
                            </td>
                            <td className='text-right text-nowrap'>
                              <Link
                                to={`/app/reports/${report.id}/edit`}
                                className='btn btn-warning btn-xs'
                              >
                                &nbsp;&nbsp;
                                <LocalizedMessage id='edit'>
                                  {(localizedMessage) => (
                                    <span>{localizedMessage}</span>
                                  )}
                                </LocalizedMessage>
                                &nbsp;&nbsp;
                              </Link>
                              &nbsp;&nbsp;
                              <button
                                className='btn btn-danger btn-xs'
                                onClick={() => this.onDelete(report.id)}
                              >
                                &nbsp;&nbsp;
                                <LocalizedMessage id='delete'>
                                  {(localizedMessage) => (
                                    <span>{localizedMessage}</span>
                                  )}
                                </LocalizedMessage>
                                &nbsp;&nbsp;
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default URLFiltersHoc(Reports, 'id');
