import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import LocalizedMessage from '../../../../components/LocalizedMessage';

const ButtonsGroup = ({isDisabled, onSave, onRevert}) => {
  const mainContainer = document.getElementById('main_content');

  const [isFixed, setIsFixed] = useState(true);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);

    return () => {
      document.removeEventListener('scroll', onScroll, true);
    };
  });

  const onScroll = (e) => {
    if (e.target !== mainContainer) return;

    const {scrollHeight, scrollTop, clientHeight} = e.target;
    const remainingHeight = scrollHeight - scrollTop - clientHeight;
    setIsFixed(remainingHeight > 120);
  };

  return (
    <div className='row bottom-page-row'>
      <div className='col-lg-2'></div>
      <div className='col-lg-10 text-right'>
        <button
          className={cx('btn', 'btn-info', 'step-button', {
            'button-fixed': isFixed && !onRevert,
          })}
          type='button'
          onClick={onSave}
          disabled={isDisabled}
        >
          <i className='fa fa-download' />
          &nbsp;
          <span className='bold'><LocalizedMessage id='flowchart.save' /></span>
        </button>
        &nbsp;
        {onRevert && (
          <button
            className={cx('btn', 'btn-info', {
              'button-fixed': isFixed,
            })}
            onClick={onRevert}
          >
            <i className='fa fa-history' />
            &nbsp;
            <span className='bold'><LocalizedMessage id='flowchart.revert-version' /></span>
          </button>
        )}
      </div>
    </div>
  );
};

export {ButtonsGroup};
