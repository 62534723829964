export default {
  Default: {
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-placement'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'National TV': { // National TV
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-2'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'National TV freeze': { // National TV freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-2'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Regional TV': { // Regional TV
    Production: {
      typeTable: 'tvc-production-regionality'
    },
    Placement: {
      typeTable: 'dynamic-1'
    },
    Default: {
      typeTable: 'tvc-production-regionality'
    }
  },
  'Affinitive TV': { // Affinitive TV
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-2'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Cable TV': { // Cable TV
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-placement'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'TVSPcommit': {
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-placement-regionality'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'TVSPflex': {
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-placement-regionality'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Banners': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'SMM': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Paid social': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Digital SP': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Bloggers': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Other digital': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'E-com': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Ecom media': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Context': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Cinema': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Radio': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Indoor': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Freeze': {
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Orbita TV': { // Orbita TV
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-2'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'TV reserve': { // TV reserve
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-2'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Smart TV': { // Smart TV
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'TV sponsorship': { // TV sponsorship
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-placement'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'OLV': { // OLV
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Digital': { // Digital
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Print': { // Print
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Radio Direct': { // Radio Direct
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Radio SP': { // Radio SP
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Transport': { // Transport
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Subway': { // Subway
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'OOH': { // OOH
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Brand freeze': { // Brand freeze
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'TBP': { // TBP
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Other': { // Other
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'TV freeze': { // TV freeze
    Production: {
      typeTable: 'descriptions-production-regionality'
    },
    Placement: {
      typeTable: 'descriptions-production-regionality'
    },
    Default: {
      typeTable: 'descriptions-production-regionality'
    }
  },
  'Cable TV freeze': { // Cable TV freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-placement'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Affinitive TV freeze': { // Affinitive TV freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-2'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Smart TV freeze': { // Smart TV freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'OLV freeze': { // OLV freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Regional TV freeze': { // Regional TV freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'dynamic-1'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },
  'Digital freeze': { // Digital freeze
    Production: {
      typeTable: 'descriptions-production'
    },
    Placement: {
      typeTable: 'descriptions-production'
    },
    Default: {
      typeTable: 'descriptions-production'
    }
  },
  'OOH freeze': { // Digital freeze
    Production: {
      typeTable: 'descriptions-production'
    },
    Placement: {
      typeTable: 'descriptions-production'
    },
    Default: {
      typeTable: 'descriptions-production'
    }
  },
  'Radio freeze': { // Radio freeze
    Production: {
      typeTable: 'descriptions-production'
    },
    Placement: {
      typeTable: 'descriptions-production'
    },
    Default: {
      typeTable: 'descriptions-production'
    }
  },
  'Print freeze': { // Print freeze
    Production: {
      typeTable: 'descriptions-production'
    },
    Placement: {
      typeTable: 'descriptions-production'
    },
    Default: {
      typeTable: 'descriptions-production'
    }
  },
  'TV SP freeze': { // TV SP freeze
    Production: {
      typeTable: 'tvc-production'
    },
    Placement: {
      typeTable: 'tvc-production'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  },

  'TV SP': {
    Production: {
      typeTable: 'tvc-production-regionality'
    },
    Placement: {
      typeTable: 'tvc-production-regionality'
    },
    Default: {
      typeTable: 'tvc-production'
    }
  }
};
