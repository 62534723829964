import {fetchResponse} from './helpers/fakeFetch';

import personaTargetAudienceList from './data/personaTargetAudienceList.json';

const API = {
  list (request) {
    console.log('api.personaTargetAudience.list(request)');

    return fetchResponse(1000, personaTargetAudienceList);
  }
};

export default API;
