const mediaTypes = [
  'National TV',
  'National TV freeze',
  'Affinitive TV',
  'Affinitive TV freeze',
  'Regional TV',
  'Regional TV freeze',
  'Orbita TV',
  'TV reserve',
  'OLV commit',
  'OLV flex',
  'OLV freeze',
  'Smart TV',
  'Smart TV freeze',
  'Cable TV',
  'Cable TV freeze',
  'SMM commit',
  'SMM flex',
  'Banners commit',
  'Banners flex',
  'Digital SP commit',
  'Digital SP flex',
  'Bloggers commit',
  'Bloggers flex',
  'Other digital commit',
  'Other digital flex',
  'Digital freeze',
  'Context',
  'E-com',
  'TV SP commit',
  'TV SP flex',
  'TV SP freeze',
  'OOH',
  'Transport',
  'Subway',
  'Indoor',
  'Cinema',
  'OOH freeze',
  'Print commit',
  'Print flex',
  'Print freeze',
  'Radio',
  'Radio freeze',
  'Other',
  'TBP',
  'Freeze'
];
const spendsTypes = [
  'Placement',
  'Production'
];

export default {
  mediaTypes,
  spendsTypes
};
