import React, {Component} from 'react';
import clone from 'lodash/clone';
import find from 'lodash/find';
import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';
import sum from 'lodash/sum';
import cx from 'classnames';
import moment from 'moment';
import LazyLoad from 'react-lazyload';

import RoundedInput from '../../../../components/RoundedInput';
import Placeholder from '../Form/Placeholder';
import RegionalityList from './RegionalityList';
import {getMonthNames} from '../../../../helpers/utils';

class TableTvcProductionRegionality extends Component {
  state = {
    resultMatrix: [],
    calendarMatrix: [],
    months: [],
    weeks: [],
    tableData: this.props.tableData || {
      table: [],
      budgets: []
    }
  };

  updateSpendsType (values, cb) {
    const {updateSpendsType} = this.props;

    updateSpendsType(values, () => {
      this.setState(values, cb);
    });
  }

  async componentDidMount () {
    await this.recalculateCalendar();
    this.refreshData();
  }

  async UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.year !== this.props.year) {
      const isClearData = typeof this.props.year !== 'undefined';
      await this.recalculateCalendar(nextProps.year);
      this.refreshData(isClearData);
    }

    if (nextProps.tvcList !== this.props.tvcList) {
      this.refreshData(false, nextProps.tvcList);
    }

    if (
      nextProps.regions &&
      this.props.regions &&
      nextProps.regions.length !== this.props.regions.length
    ) {
      this.refreshData(false, null, nextProps.regions);
    }
  }

  recalculateCalendar (_year) {
    const year = _year || this.props.year;

    const dateStart = moment().year(year.value).startOf('year');
    const dateEnd = moment().year(year.value).endOf('year');
    const period = {
      startDate: moment(dateStart),
      endDate: moment(dateEnd)
    };
    const months = [];
    let weekList = [];
    let weekNumber = 1;

    const localizedMonthsName = getMonthNames();

    // eslint-disable-next-line no-unmodified-loop-condition
    while (dateEnd > dateStart) {
      const month = dateStart.format('MM');
      const monthStartDate = moment(dateStart).startOf('month');
      const monthEndDate = moment(dateStart).endOf('month');

      const weeks = [];

      const startWeekDay = moment(monthStartDate).day(1);

      while (startWeekDay.isBefore(monthEndDate)) {
        if (
          (period.startDate.isBefore(startWeekDay) || period.startDate.isSame(startWeekDay)) &&
          (period.endDate.isAfter(startWeekDay) || period.endDate.isSame(startWeekDay)) &&
          (monthStartDate.isBefore(startWeekDay) || monthStartDate.isSame(startWeekDay))
        ) {
          weeks.push({
            date: moment(startWeekDay),
            day: startWeekDay.format('DD'),
            needWeekNumber: true
          });
        }

        startWeekDay.add(7, 'days');
      }

      const isFirstMonth = !months.length;
      let diffDays = null;

      if (isFirstMonth) {
        if (!weeks.length || period.startDate.isBefore(weeks[0].date)) {
          weeks.unshift({
            date: period.startDate,
            day: period.startDate.format('DD'),
            needWeekNumber: true,
            diffDays: weeks[0].date.diff(monthStartDate, 'days'),
          });
        }
      } else if (
        (!weeks.length || monthStartDate.isBefore(weeks[0].date)) &&
        period.startDate.isBefore(monthStartDate)
      ) {
        diffDays = weeks[0].date.diff(monthStartDate, 'days');

        weeks.unshift({
          date: monthStartDate,
          day: monthStartDate.format('DD'),
          needWeekNumber: false,
          diffDays
        });
      }

      // eslint-disable-next-line no-loop-func
      weeks.forEach(week => {
        week.weekNumber = week.needWeekNumber ? weekNumber.toString() : '';

        if (week.needWeekNumber) {
          weekNumber++;
        }
      });

      if (
        typeof diffDays === 'number' &&
        months[months.length - 1]?.weeks[months[months.length - 1].weeks.length - 1]
      ) {
        months[months.length - 1].weeks[months[months.length - 1].weeks.length - 1].diffDays = 7 - diffDays;

        if (weekList[weekList.length - 1]) {
          weekList[weekList.length - 1].diffDays = 7 - diffDays;
        }
      }

      months.push({
        month: month,
        monthFullName: localizedMonthsName[parseInt(month) - 1],
        weeks: weeks
      });

      weekList = weekList.concat(weeks);

      dateStart.add(1, 'month');
    }

    return new Promise((resolve) => {
      this.setState({
        months,
        weeks: weekList
      }, resolve);
    });
  }

  serializeTvcLength (len, tvc) {
    const {months} = this.state;
    const {tvcTypes, regions} = this.props;

    const cMonths = [];

    months.forEach((month) => {
      const cWeeks = [];

      month.weeks.forEach(() => {
        cWeeks.push({
          value: 0
        });
      });

      cMonths.push({
        weeks: cWeeks,
        value: 0
      });
    });

    let preTest = '';

    if (tvcTypes[tvc.type.value]) {
      const lengthIndex = tvcTypes[tvc.type.value].length.findIndex(_length => (
        (+_length.value) === (+len)
      ));

      if (lengthIndex !== -1) {
        preTest = tvcTypes[tvc.type.value].length[lengthIndex].preTest;
      }
    }

    return {
      name: len,
      preTest: preTest,
      cells: {
        len: {
          value: 0
        },
        tpc: {
          value: 0
        }
      },
      months: cMonths,
      regions
    };
  }

  refreshData (clear, _tvcList, _regions) {
    const {tvcTypes} = this.props;

    let {
      tableData: externalTableData
    } = this.state;

    const tvcList = _tvcList || this.props.tvcList;
    const regions = _regions || this.props.regions;

    const {
      months
    } = this.state;

    if (Array.isArray(externalTableData) && !externalTableData.table) {
      const tableData = clone(externalTableData);
      externalTableData = {
        table: tableData,
        budgets: []
      };
    }

    const resultData = clear
      ? []
      : externalTableData.table;

    tvcList.forEach((tvc) => {
      const tvcIndex = resultData.findIndex((cell) => cell.tvcUuid === tvc.uuid);

      if (tvcIndex === -1) {
        const cLens = [];

        tvc.length.forEach((len) => {
          cLens.push(this.serializeTvcLength(len.value, tvc));
        });

        const tvcMonths = [];

        months.forEach((month) => {
          tvcMonths.push({
            results: {
              budget: {
                value: 0
              }
            },
            weeksLength: month.weeks.length
          });
        });

        const type = tvcTypes[tvc.type.value];
        resultData.push({
          name: type ? type.name : '',
          lens: cLens,
          months: tvcMonths,
          tvcUuid: tvc.uuid,
          results: {
            budget: 0
          }
        });
      } else {
        const localTvcLengths = resultData[tvcIndex].lens.map(len => len.name);

        each(tvc.length, (len, lenIndex) => {
          if (
            !localTvcLengths[lenIndex]
          ) {
            resultData[tvcIndex].lens[lenIndex] = this.serializeTvcLength(len.value, tvc);

            return;
          }

          if (
            localTvcLengths[lenIndex] !== len.value
          ) {
            resultData[tvcIndex].lens[lenIndex] = this.serializeTvcLength(len.value, tvc);
          }
        });

        each(resultData[tvcIndex].lens, (len) => {
          if (
            !len.regions ||
            !len.regions.length
          ) {
            len.regions = regions;
          }

          each(len.regions, (region, regionIndex) => {
            if (!region) {
              return;
            }

            if (!find(regions, _region => _region.value === region.value)) {
              len.regions.splice(regionIndex, 1);
            }
          });
        });

        const type = tvcTypes[tvc.type?.value];
        if (type?.name) {
          resultData[tvcIndex].name = type.name;
        }
      }
    });

    each(resultData, (tvcData, tvcDataIndex) => {
      if (!tvcData) {
        return;
      }

      const tvcListTvcIndex = tvcList.findIndex((tvc) => tvc.uuid === tvcData.tvcUuid);

      if (tvcListTvcIndex === -1) {
        resultData.splice(tvcDataIndex, 1);
      } else {
        each(tvcData.lens, (len, lenIndex) => {
          if (
            !tvcList[tvcListTvcIndex].length[lenIndex]
          ) {
            tvcData.lens.splice(lenIndex, 1);
          }
        });
      }
    });

    externalTableData.table = resultData;

    this.updateSpendsType({tableData: externalTableData}, () => {
      this.recalculateData();
    });
  }

  recalculateData () {
    const {
      tableData,
      months
    } = this.state;

    const totalBudgetMonth = [];
    const activeWeeks = Array(months.length).fill(0);

    tableData.table.forEach((tvc, tvcIndex) => {
      let tpcSum = 0;

      const monthSums = {};

      tvc.lens.forEach((len) => {
        len.cells.tpc.value = 0;

        len.months.forEach((month, monthIndex) => {
          len.cells.tpc.value += month.value;

          if (typeof monthSums[monthIndex] === 'undefined') {
            monthSums[monthIndex] = 0;
          }
          monthSums[monthIndex] += month.value;
        });

        tpcSum += len.cells.tpc.value;
      });

      tvc.lens.forEach((len) => {
        len.cells.len.value = tpcSum > 0 ? Math.round(len.cells.tpc.value / tpcSum * 100) : 0;
      });

      tvc.months.forEach((month, monthIndex) => {
        month.results.budget.value = monthSums[monthIndex];

        if (!Array.isArray(totalBudgetMonth[tvcIndex])) {
          totalBudgetMonth[tvcIndex] = [];
        }

        totalBudgetMonth[tvcIndex][monthIndex] = typeof totalBudgetMonth[tvcIndex][monthIndex] !== 'number'
          ? monthSums[monthIndex]
          : monthSums[monthIndex] + totalBudgetMonth[tvcIndex][monthIndex];
      });

      const budgetsOfDay = months.map((month, monthIndex) => {
        const monthBudget = totalBudgetMonth.reduce((acc, arr) => acc + arr[monthIndex], 0);
        const numDays = month.weeks.reduce((acc, week) => acc + (week.diffDays || 7), 0);

        return monthBudget / numDays;
      });

      budgetsOfDay.forEach((budgetOfDay, index) => {
        if (budgetOfDay) {
          const currentMonth = months[index];
          const prevMonth = months[index - 1];
          const nextMonth = months[index + 1];
          activeWeeks[index] = currentMonth.weeks.length;

          if (nextMonth && !nextMonth.weeks[0].needWeekNumber) {
            const currentDiffDays = currentMonth.weeks[currentMonth.weeks.length - 1].diffDays;
            const nextDiffDays = nextMonth.weeks[0].diffDays;

            if (
              budgetOfDay * currentDiffDays < budgetsOfDay[index + 1] * nextDiffDays ||
              (
                budgetOfDay * currentDiffDays === budgetsOfDay[index + 1] * nextDiffDays &&
                currentDiffDays < nextDiffDays
              )
            ) {
              activeWeeks[index] -= 1;
            }
          }

          if (prevMonth && !currentMonth.weeks[0].needWeekNumber) {
            const currentDiffDays = currentMonth.weeks[0].diffDays;
            const prevDiffDays = prevMonth.weeks[prevMonth.weeks.length - 1].diffDays;

            if (
              budgetOfDay * currentDiffDays < budgetsOfDay[index - 1] * prevDiffDays ||
              (
                budgetOfDay * currentDiffDays === budgetsOfDay[index - 1] * prevDiffDays &&
                currentDiffDays < prevDiffDays
              )
            ) {
              activeWeeks[index] -= 1;
            }
          }
        }
      });
    });

    tableData.budgets = totalBudgetMonth;
    tableData.active_weeks = activeWeeks;
    tableData.total_active_weeks = activeWeeks.reduce((acc, count) => acc + count, 0);

    this.updateSpendsType({tableData}, () => {
      this.refreshTables();
    });
  }

  refreshTables () {
    const {
      mediaType
    } = this.props;

    const {
      months,
      tableData
    } = this.state;

    const resultMatrix = [];
    const calendarMatrix = [];
    let headCellRowSpan = 0;

    const budget = tableData.budgets.reduce((t, e) => t.concat(e)).reduce((t, e) => t + e);
    const resultRows = ['budget', 'active_weeks'];

    tableData.table.forEach((tvc, tvcIndex) => {
      headCellRowSpan += tvc.lens.length + resultRows.length;

      set(tvc, 'results.budget', sum(tableData.budgets[tvcIndex]));

      tvc.lens.forEach((len, lenIndex) => {
        // Result matrix
        const resultRow = [];

        if (!lenIndex) {
          resultRow.push({
            rowspan: tvc.lens.length,
            classes: ['_text-align--left'],
            data: {
              value: tvc.name
            }
          });
        }

        resultRow.push({
          type: 'regionality-list',
          classes: ['regionality-list-cell'],
          data: {
            tvcIndex: tvcIndex,
            lenIndex: lenIndex
          }
        });

        resultRow.push({
          classes: ['Pretest' + (typeof len.preTest === 'string' ? len.preTest : '').toLowerCase()],
          data: {
            value: len.name
          }
        });

        let cNet = 0;
        len.months.forEach((month, monthIndex) => {
          cNet += month.value || 0;
        });

        resultRow.push({
          data: {
            value: cNet.toFixed(2),
          },
          classes: ['with-rub-postfix']
        });

        resultMatrix.push(resultRow);

        // Field matrix
        const fieldRow = [];

        len.months.forEach((month, monthIndex) => {
          fieldRow.push({
            type: 'input',
            dataPath: {
              tvcIndex: tvcIndex,
              lenIndex: lenIndex,
              monthIndex: monthIndex
            },
            colspan: month.weeks.length,
            classes: ['input-cell']
          });
        });

        calendarMatrix.push(fieldRow);
      });
    });

    resultMatrix.push([
      {
        colspan: 2,
        data: {
          value: 'Budget'
        }
      },
      {
        colspan: 2,
        data: {
          value: budget.toFixed(2),
        },
        classes: ['with-rub-postfix']
      }
    ]);

    resultMatrix.push([
      {
        colspan: 2,
        data: {
          value: 'Active Weeks'
        }
      },
      {
        colspan: 2,
        data: {
          value: tableData.total_active_weeks
        }
      }
    ]);

    resultRows.forEach((type) => {
      const totalRow = [];

      months.forEach((month, monthIndex) => {
        totalRow.push({
          type: 'result',
          colspan: month.weeks.length,
          data: type === 'budget' ?
            {
              value: tableData.budgets.reduce((partialSum, a) => partialSum + a[monthIndex], 0)
            } :
            {
              value: tableData.active_weeks[monthIndex]
            },
          classes: type === 'budget'
            ? ['with-rub-postfix']
            : []
        });
      });

      calendarMatrix.push(totalRow);
    });

    // ['budget'].forEach((type) => {
    //   const totalRow = [];
    //
    //   tvc.months.forEach((month, monthIndex) => {
    //     totalRow.push({
    //       type: 'result',
    //       colspan: month.weeksLength,
    //       data: {
    //         value: tableData.budgets[tvcIndex][monthIndex]
    //       },
    //       classes:
    //         type === 'budget' ? ['with-rub-postfix'] :
    //           type.includes('reach_') ? ['with-empty-spaces'] :
    //             []
    //     });
    //   });
    //
    //   calendarMatrix.push(totalRow);
    // });

    if (resultMatrix.length) {
      resultMatrix[0].unshift({
        rowspan: headCellRowSpan,
        data: {
          value: mediaType.name
        }
      });
    }

    this.updateSpendsType({tableData});

    this.setState({
      resultMatrix,
      calendarMatrix
    });
  }

  handleInputChange = (e, tvcIndex, lenIndex, monthIndex) => {
    const {tableData} = this.state;

    tableData.table[tvcIndex].lens[lenIndex].months[monthIndex].value = +(e.target.value);
    this.updateSpendsType({tableData});
  };

  handleRegionsChange = (values, tvcIndex, lenIndex) => {
    const {tableData} = this.state;

    tableData.table[tvcIndex].lens[lenIndex].regions = values;

    this.updateSpendsType({tableData});
  };

  onCalendarFieldChange = () => {
    this.recalculateData();
  };

  safeGetInputValue (tvcIndex, lenIndex, monthIndex) {
    const {tableData} = this.state;

    return get(tableData, `table[${tvcIndex}].lens[${lenIndex}].months[${monthIndex}].value`) || '';
  }

  safeGetRegionsValue (tvcIndex, lenIndex) {
    const {tableData} = this.state;

    return get(tableData, `table[${tvcIndex}].lens[${lenIndex}].regions`, 0) || [];
  }

  render () {
    const {
      isDisabled = false,
      regions
    } = this.props;

    const {
      resultMatrix,
      calendarMatrix,
      months,
      weeks,
      tableData
    } = this.state;

    if (!tableData || !tableData.table || !tableData.table.length) {
      return null;
    }

    return (
      <LazyLoad
        once
        height={200}
        offset={[200, 200]}
        scrollContainer='#main_content'
        placeholder={<Placeholder />}
      >
        <div className='display-flex'>
          <div>
            <table className='table flowCharts-media-fixed-table'>
              <thead>
                <tr>
                  <th className='text-nowrap' style={{minWidth: '100px'}}>
                    <div>
                      <span>Media</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Description</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '200px'}}>
                    <div>
                      <span>Regionality</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>TVC</span>
                    </div>
                  </th>
                  <th className='text-nowrap' style={{minWidth: '80px'}}>
                    <div>
                      <span>Net<br />media</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  resultMatrix.map((row, rowIndex) => (
                    <tr className='campaigns-table-row-height' key={'row_resultMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            className={cx('text-nowrap', 'campaigns-table-cell-center', ...(cell.classes || []))}
                            colSpan={cell.colspan || 1}
                            rowSpan={cell.rowspan || 1}
                            key={'row_' + rowIndex + '_cell_' + cellIndex}
                          >
                            {
                              cell.type === 'regionality-list' && !isDisabled ? (
                                <RegionalityList
                                  options={regions}
                                  selected={this.safeGetRegionsValue(cell.data.tvcIndex, cell.data.lenIndex)}
                                  onChange={values =>
                                    this.handleRegionsChange(values, cell.data.tvcIndex, cell.data.lenIndex)
                                  }
                                />
                              ) : (
                                <span>
                                  {cell.data.value}
                                </span>
                              )
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className='right-flex-table'>
            <table className='table flowCharts-media-months-table'>
              <thead>
                <tr>
                  {
                    months.map((month, monthIndex) => (
                      <th
                        key={'month_' + monthIndex}
                        className='text-nowrap'
                        colSpan={month.weeks.length}
                      >
                        <span>{month.monthFullName}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <th
                        key={'week-number_' + weekIndex}
                        className='text-nowrap'
                      >
                        <span>{week.weekNumber}</span>
                      </th>
                    ))
                  }
                </tr>
                <tr>
                  {
                    weeks.map((week, weekIndex) => (
                      <th
                        key={'week-day_' + weekIndex}
                        className='text-nowrap'
                      >
                        <span>{week.day}</span>
                      </th>
                    ))
                  }
                </tr>
              </thead>

              <tbody>
                {
                  calendarMatrix.map((row, rowIndex) => (
                    <tr key={'row_calendarMatrix_' + rowIndex}>
                      {
                        row.map((cell, cellIndex) => (
                          <td
                            key={'row_calendarMatrix_' + rowIndex + '_cell_' + cellIndex}
                            className={cx(...cell.classes)}
                            colSpan={cell.colspan || 1}
                          >
                            {
                              cell.type === 'input'
                                ? (
                                  <div>
                                    <RoundedInput
                                      type='number'
                                      onChange={e =>
                                        this.handleInputChange(
                                          e,
                                          cell.dataPath.tvcIndex,
                                          cell.dataPath.lenIndex,
                                          cell.dataPath.monthIndex
                                        )
                                      }
                                      value={
                                        this.safeGetInputValue(
                                          cell.dataPath.tvcIndex,
                                          cell.dataPath.lenIndex,
                                          cell.dataPath.monthIndex
                                        )
                                      }
                                      disabled={cell.disabled || isDisabled}
                                      className={cx(
                                        'form-control',
                                        'flowCharts-media-months-table-field',
                                        'flowCharts-media-months-table-field--full-width'
                                      )}
                                      min='0'
                                      onBlur={this.onCalendarFieldChange}
                                    />
                                  </div>
                                )
                                : <span>{cell.data.value}</span>
                            }
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </LazyLoad>
    );
  }
}

export default TableTvcProductionRegionality;
