import React, {Component, Fragment} from 'react';
import findKey from 'lodash/findKey';
import map from 'lodash/map';

import NewMedia from './NewMedia';
import Media from './Media';

class CategoryListContent extends Component {
  removeMediaLocal = (categoryId, mediaRecordIndex, mediaTypeId, spendsTypeId) => {
    const {
      isUsingCompanies,
      campaign,
      removeMedia
    } = this.props;

    const campaignId = isUsingCompanies
      ? campaign.id
      : 0;

    removeMedia(campaignId, categoryId, mediaRecordIndex, mediaTypeId, spendsTypeId);
  };

  getMediaRecords () {
    const {
      isUsingCompanies,
      media,
      campaign,
      category,
      mediaOrderSorting = {}
    } = this.props;

    const campaignId = isUsingCompanies ? campaign.id : 0;
    const result =
      media[campaignId] &&
      media[campaignId][category.id]
        ? media[campaignId][category.id]
        : {};

    return {
      records: result,
      sorting:
        mediaOrderSorting[campaignId] &&
        mediaOrderSorting[campaignId][category.id]
          ? mediaOrderSorting[campaignId][category.id]
          : {}
    };
  }

  getMediaTypeKey (mediaType) {
    const {mediaTypes} = this.props;

    return findKey(mediaTypes, _mediaType => (+_mediaType.id) === (+mediaType.id));
  }

  render () {
    const {
      numbersIo,
      category,
      regions,
      mediaTypes: mediaTypesList,
      spendsTypes,
      targetAudiences,
      descriptions,
      tvcTypeList,
      tvcTypes,
      effectiveFrequenciesList,
      campaign,
      mediaTypesMap,
      year,
      addMedia,
      isUsingCompanies,
      mediaData,
      updateSpendsTypeForm,
      updateSpendsTypeTvc,
      updateSpendsType,
      updateMediaData,
      media,
      brand,
      updateMediaTypes,
      formData,
      trackPromiseAll,
      onUpdateMediaValidationStatus,
      onCreateDescriptionOption,
      isDisabled = false,
    } = this.props;

    const mediaRecordsData = this.getMediaRecords();

    return (
      <Fragment>

        <NewMedia
          mediaTypes={mediaTypesList}
          numbersIo={numbersIo}
          spendsTypes={spendsTypes}
          isUsingCompanies={isUsingCompanies}
          category={category}
          campaign={campaign}
          addMedia={addMedia}
          removeMedia={this.removeMediaLocal}
          isDisabled={isDisabled}
        />

        {
          map(mediaRecordsData.records, (mediaRecord, mediaRecordIndex) => (
            <Fragment key={mediaRecordIndex}>
              {
                mediaRecordsData.sorting[mediaRecordIndex].mediaTypes.map((mediaTypeIndex) => {
                  const mediaType = mediaRecord.mediaTypes[mediaTypeIndex];

                  return (
                    <Fragment key={mediaTypeIndex + '_' + mediaType.id}>
                      {
                        mediaRecordsData.sorting[mediaRecordIndex].spendsTypes[mediaType.id].map((spendsTypeIndex) => {
                          const spendsType = mediaType.spendsTypes[spendsTypeIndex];

                          return (
                            <Media
                              key={
                                'media_record_' +
                                mediaRecordIndex + '_' +
                                mediaTypeIndex + '_' +
                                spendsTypeIndex + '_' + spendsType.id
                              }
                              media={media}
                              brand={brand}
                              removeMedia={this.removeMediaLocal}
                              campaign={campaign}
                              category={category}
                              mediaRecord={mediaRecord}
                              mediaRecordIndex={mediaRecordIndex}
                              mediaTypes={mediaTypesList}
                              mediaTypesMap={mediaTypesMap}
                              mediaType={mediaType}
                              mediaTypeKey={this.getMediaTypeKey(mediaType)}
                              spendsType={spendsType}
                              cpp={mediaTypesList[this.getMediaTypeKey(mediaType)].cpp[mediaRecord.numberIO.value]}
                              year={year}
                              effectiveFrequenciesList={effectiveFrequenciesList}
                              tvcTypes={tvcTypes}
                              tvcList={spendsType.resultTvcList}
                              descriptions={descriptions}
                              regionsList={regions}
                              tableData={mediaData[spendsType.mediaDataHash]}
                              numberIo={mediaRecord.numberIO}
                              updateSpendsType={updateSpendsType}
                              optionsTargetAudiences={targetAudiences}
                              optionsTvc={tvcTypeList}
                              isUsingCompanies={isUsingCompanies}
                              onCreateDescriptionOption={onCreateDescriptionOption}
                              updateMediaData={updateMediaData}
                              updateSpendsTypeForm={updateSpendsTypeForm}
                              updateSpendsTypeTvc={updateSpendsTypeTvc}
                              updateMediaTypes={updateMediaTypes}
                              formData={formData[spendsType.mediaDataHash].form}
                              tvcListData={formData[spendsType.mediaDataHash].tvcList}
                              resultTvcListData={formData[spendsType.mediaDataHash].resultTvcList}
                              trackPromiseAll={trackPromiseAll}
                              onUpdateMediaValidationStatus={onUpdateMediaValidationStatus}
                              isDisabled={isDisabled}
                            />
                          );
                        })
                      }
                    </Fragment>
                  );
                })
              }
            </Fragment>
          ))
        }
      </Fragment>
    );
  }
}

export default CategoryListContent;
