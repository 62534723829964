import Client from './HTTPClient';

const cbuAPI = {
  list (request) {
    return Client.post('/api/cbu/', request);
  },

  rating (request) {
    return Client.post('/api/cbu/intersect/rating', request, true, {}, true, null, {
      withoutLoader: true
    });
  },

  upload (year, data) {
    return Client.post(`/api/cbu/upload/${year}`, data, true, {isJson: false, isMultipart: true});
  }
};

export default cbuAPI;
