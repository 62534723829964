import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import {saveAs} from 'file-saver';
import XLSX from 'xlsx';
import jexcel from 'jexcel';

import LocalizedMessage, {localizeMessage} from '../../../components/LocalizedMessage';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Scrollbar from '../../../components/Scrollbar';
import Loader from '../../../components/Loader';
import Alert from '../../../helpers/alert';
import API from '../../../api';

class FlowchartView extends Component {
  state = {
    flowchartName: '',
    nameTranslation: '',
    isDeleted: false,
    tableCsvUrls: [],
    loaded: false
  };

  mounted = false;
  priceCells = [];
  scrollbars = [
    null,
    null,
    null,
  ];
  minScrollStep = 50; // px
  prevSelectionPosition = {
    x1: null,
    y1: null,
    x2: null,
    y2: null
  };

  componentDidMount () {
    this.mounted = true;
    this.loadData();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadData () {
    const {
      match: {
        params
      }
    } = this.props;
    const {flowchartName} = this.state;

    if (!params.id) {
      return;
    }

    try {
      const flowchart = await API.flowcharts.get(params.id);

      this.setState({
        flowchartName: flowchart.title.replace(/['"]+/g, ''),
        isDeleted: flowchart.isDeleted,
        nameTranslation: flowchart.status.nameTranslation
      });

      this.exportedFlowchart = await API.flowcharts.export(params.id);
      this.exportedFlowchartBlob = await this.exportedFlowchart.blob();

      if (!this.mounted) {
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        this.setState({
          loaded: true
        });

        const data = new Uint8Array(e.target.result);
        const wb = XLSX.read(data, {type: 'array'});
        const ws = wb.SheetNames.map(name => wb.Sheets[name]);
        const tables = ws.map(sheet => this.sheetToCsv(sheet));
        const blobs = tables.map(table => new Blob([table], {type: 'text/csv'}));
        const tableXlsxUrl = URL.createObjectURL(this.exportedFlowchartBlob);
        const tableCsvUrls = blobs.map(blob => URL.createObjectURL(blob));
        this.setState({tableCsvUrls});

        jexcel.fromSpreadsheet(tableXlsxUrl, (result) => {
          if (!result.length) {
            console.error('JEXCEL: Something went wrong.');
            Alert.error(
              `${localizeMessage({id: 'flowchart-view.flowchart-failed-import'})}: ${flowchartName} [${params.id}]`
            );
          } else {
            let loaded = false;

            const getOptions = (index) => ({
              csv: tableCsvUrls[index],
              csvHeaders: false,
              wordWrap: true,
              mergeCells: result[index].mergeCells,
              onload: () => {
                this.updateScrollbarHeight(index);
                if (index === 0) {
                  loaded = true;
                }
              },
              onselection: (instance, x1, y1, x2, y2) => {
                if (!loaded) {
                  return;
                }
                this.onSelectionCells(x1, y1, x2, y2, instance, index);
              },
              columns: result[index].columns.map((item, cIndex) => (
                cIndex > 1 ? item : {...item, name: String(cIndex)}
              )),
              updateTable: (instance, cell, col, row, val) => {
                const currentRow = instance.querySelector(`tr[data-y="${row}"]`);
                currentRow.classList.add('jexcel__row');

                if (this.priceCells.includes(`${col}-${row}`)) {
                  cell.classList.add('jexcel__price-cell');
                }
                if (val[0] === '€' || val[1] === '€') {
                  !this.priceCells.includes(cell) && this.priceCells.push(`${col}-${row}`);
                }
                if (val) {
                  cell.innerText = !cell.classList.contains('jexcel__price-cell') ?
                    val :
                    index === 0 ? `${Math.round(val)} P.` : Math.round(val);
                }
                if ([7, 8, 9].includes(row) || (col === 0 && val.indexOf('Total') !== -1)) {
                  currentRow.classList.add('jexcel__row_color_gray');
                }
                if (row < 7 && col !== 1) {
                  cell.style.fontWeight = 700;
                }
              },
            });

            jexcel(document.getElementById('tab-mediaplan-eur'), getOptions(1));
            jexcel(document.getElementById('tab-mediaplan-ru'), getOptions(0));

            if (tableCsvUrls.length > 2) {
              jexcel(document.getElementById('tab-mediaplan-budgets'), {
                ...getOptions(2),
                columns: [...result[2].columns, {width: '0px'}],
                updateTable: (instance, cell, col, row, val) => {
                  const currentRow = instance.querySelector(`tr[data-y="${row}"]`);
                  currentRow.classList.add('jexcel__row');

                  if ([0, 1].includes(row)) {
                    currentRow.classList.add('jexcel__row_color_gray');
                  }
                },
              });
            }
          }
        });
      };

      reader.readAsArrayBuffer(this.exportedFlowchartBlob);
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({id: 'flowchart-view.flowchart-failed-import'})}: ${flowchartName} [${params.id}]`
      );
    }
  }

  checkCellShowing = (_tableEl, index, x, y) => {
    const scrollbar = this.scrollbars[index];

    if (!scrollbar || typeof scrollbar.getScrollLeft !== 'function') {
      return;
    }

    const scrollContainerWidth = scrollbar.getContainer().offsetWidth;
    const scrollLeft = scrollbar.getScrollLeft();
    const _foundCell = _tableEl.querySelector(`table.jexcel td[data-x="${x}"][data-y="${y}"]`);
    const cellOffsetLeft = _foundCell.offsetLeft - scrollLeft;
    const cellOffsetRight = _foundCell.offsetWidth + _foundCell.offsetLeft - scrollLeft;

    if (cellOffsetLeft < this.minScrollStep) {
      scrollbar.scrollLeft(Math.max(
        scrollLeft + cellOffsetLeft - this.minScrollStep,
        0
      ));
    } else if (cellOffsetRight + this.minScrollStep > scrollContainerWidth) {
      scrollbar.scrollLeft(Math.min(
        scrollLeft + (cellOffsetRight - scrollContainerWidth) + this.minScrollStep,
        scrollLeft + cellOffsetRight
      ));
    }
  };

  onSelectionCells = (x1, y1, x2, y2, _tableEl, index) => {
    const {x1: prevX1, y1: prevY1, x2: prevX2, y2: prevY2} = this.prevSelectionPosition;

    const currentPosition = {
      x: x1,
      y: y1
    };

    if (
      (x1 !== x2 || y1 !== y2) &&
      typeof prevX1 === 'number' &&
      typeof prevY1 === 'number' &&
      typeof prevX2 === 'number' &&
      typeof prevY2 === 'number'
    ) {
      if (x1 !== prevX1 || y1 !== prevY1) {
        currentPosition.x = x1;
        currentPosition.y = y1;
      } else if (x2 !== prevX2 || y2 !== prevY2) {
        currentPosition.x = x2;
        currentPosition.y = y2;
      }
    }

    this.checkCellShowing(_tableEl, index, currentPosition.x, currentPosition.y);

    this.prevSelectionPosition = {x1, y1, x2, y2};
  };

  sheetToCsv (ws) {
    return XLSX.utils.sheet_to_csv(ws);
  }

  onExportFlowchart = async (e) => {
    e.preventDefault();

    const {
      match: {
        params
      }
    } = this.props;
    const {flowchartName} = this.state;

    if (!params.id) {
      return;
    }

    try {
      if (!this.exportedFlowchart) {
        this.exportedFlowchart = await API.flowcharts.export(params.id);
        this.exportedFlowchartBlob = await this.exportedFlowchart.blob();
      }

      const contentDisposition = this.exportedFlowchart.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${flowchartName}.xlsx`;

      saveAs(this.exportedFlowchartBlob, filename);

      Alert.success(localizeMessage({id: 'flowchart-view.flowchart-successfully-export'}));
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({id: 'flowchart-view.flowchart-failed-export'})}: ${flowchartName} [${params.id}]`
      );
    }
  };

  onSelectTab = (tabIndex) => {
    this.updateScrollbarHeight(tabIndex);
  };

  updateScrollbarHeight = (index) => {
    if (this.scrollbars[index] && typeof this.scrollbars[index].calculateHeight === 'function') {
      this.scrollbars[index].calculateHeight();
    }
  };

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='site.title.flowcharts' />,
        link: '/app/flowcharts'
      },
      {
        title: <LocalizedMessage id='site.title.flowchart.view' />
      }
    ];
  }

  render () {
    const {flowchartName, nameTranslation, isDeleted, tableCsvUrls, loaded} = this.state;

    if (isDeleted) {
      Alert.error(
        localizeMessage({id: 'flowchart.errors.flowchart-has-been-deleted'}),
      );

      return null;
    }

    const title = nameTranslation ? `${flowchartName} (${nameTranslation})` : flowchartName;

    return (
      <>
        <LocalizedMessage
          id={'site.title.flowchart.view'}
        >
          {localizedMessage => (
            <Fragment>
              <Helmet
                title={`${title} - ${localizedMessage}`}
              />
              <Breadcrumbs
                title={title}
                data={this.getBreadcrumbs()}
              />
            </Fragment>
          )}
        </LocalizedMessage>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='ibox'>
              <div className='ibox-content'>
                <Tabs
                  defaultIndex={0}
                  forceRenderTabPanel
                  onSelect={this.onSelectTab}
                >
                  <TabList>
                    <Tab>
                      <LocalizedMessage id='flowchart-view.mediaplans-ru' />
                    </Tab>
                    <Tab>
                      <LocalizedMessage id='flowchart-view.mediaplans-eur' />
                    </Tab>
                    {tableCsvUrls?.length > 2 && (
                      <Tab>
                        <LocalizedMessage id='flowchart-view.mediaplans-budget' />
                      </Tab>
                    )}
                  </TabList>
                  <TabPanel>
                    <Scrollbar
                      ref={ref => {
                        this.scrollbars[0] = ref;
                      }}
                      hideTracksWhenNotNeeded={false}
                      autoHeightMax={2000}
                    >
                      <div id='tab-mediaplan-ru'>
                        <div className='jexcel__loading'>
                          <Loader
                            active={loaded}
                            position='inline'
                          />
                        </div>
                      </div>
                    </Scrollbar>
                  </TabPanel>
                  <TabPanel>
                    <Scrollbar
                      ref={ref => {
                        this.scrollbars[1] = ref;
                      }}
                      autoHeightMax={2000}
                    >
                      <div id='tab-mediaplan-eur'>
                        <div className='jexcel__loading'>
                          <Loader
                            active={loaded}
                            position='inline'
                          />
                        </div>
                      </div>
                    </Scrollbar>
                  </TabPanel>
                  {tableCsvUrls?.length > 2 && (
                    <TabPanel>
                      <Scrollbar
                        ref={ref => {
                          this.scrollbars[2] = ref;
                        }}
                        autoHeightMax={2000}
                      >
                        <div id='tab-mediaplan-budgets'>
                          <div className='jexcel__loading'>
                            <Loader
                              active={loaded}
                              position='inline'
                            />
                          </div>
                        </div>
                      </Scrollbar>
                    </TabPanel>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className='hr-line-dashed' />

        <div className='row bottom-page-row'>
          <div className='col-lg-2' />
          <div className='col-lg-10 text-right'>
            <Link
              className='btn btn-success'
              to='/app/flowcharts'
            >
              <i className='fa fa-arrow-left fa-lg' />
              <span><LocalizedMessage id='close' /></span>
            </Link>
            &nbsp;
            <button
              className='btn btn-info step-button'
              type='button'
              onClick={this.onExportFlowchart}
            >
              <i className='fa fa-file-excel-o fa-lg' />&nbsp;
              <span><LocalizedMessage id='flowchart-view.flowchart-export' /></span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default FlowchartView;
