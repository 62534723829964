import React, { ChangeEvent, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Helmet } from 'react-helmet';
import Switch from 'react-switchery';
import SweetAlert from '@sweetalert/with-react';

import LocalizedMessage, { localizeMessage } from '../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../components/Breadcrumbs';
import FilterList from '../../../components/FilterList';
import TableList from '../../../components/TableList';
import SaveModal from '../../../components/SaveModal';
import Alert from '../../../helpers/alert';
import API from '../../../api';
import { IOption } from '../../../types';

import { ChangelogModal } from './ChangelogModal';
import StatusList from './StatusList';

export enum Statuses {
  INITIAL = 'INITIAL',
  CURRENT = 'CURRENT',
  APPROVED = 'APPROVED',
  FOR_REPORTS = 'FOR_REPORTS',
  WORKING = 'WORKING',
  ARCHIVE = 'ARCHIVE',
}

interface IOwnProps {
  user: any;
  checkRoles: (role: string) => boolean;
}

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
  isEditInitialAccess: boolean;
  flowchartId: number | null;
  changelogData: { id: number, title: string } | null;
  isShowCopyModal: boolean;
  copiedFlowchartData: { flowchartName: string, status: IOption<string> | null }
}

class Flowcharts extends PureComponent<IOwnProps & Props, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,

    isEditInitialAccess: false,

    flowchartId: null,
    changelogData: null,
    isShowCopyModal: false,
    copiedFlowchartData: { flowchartName: '', status: null },
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;
  showArchivedFlowcharts = false;
  isAlreadyExistsMessage = 'For current year and brand pair flowchart already exists';

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.initializeUser();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  initializeUser () {
    const { user } = this.props;

    this.setState({
      isEditInitialAccess: user && user.role.name !== 'PLANNER'
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
      showArchivedFlowcharts: this.showArchivedFlowcharts,
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });
      try {
        const dataList = await API.flowcharts.list(dataListRequest);

        if (!this.mounted) {
          return;
        }

        this.setState({
          dataList: dataList.items,
          dataListTotal: dataList.total,
          isLoading: false,
        });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    }, this.refreshListTimerDelay);
  }

  async refreshFilterItems () {
    const filterItems = await API.flowcharts.getFilterItems({
      showArchivedFlowcharts: this.showArchivedFlowcharts
    });

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems: filterItems.filter(({ type }) => type !== 'flowchart'),
    });
  }

  onDelete (id) {
    SweetAlert({
      title: localizeMessage({ id: 'flowcharts.flowchart-confirm' }),
      text: localizeMessage({ id: 'flowcharts.flowchart-will-be-deleted' }),
      buttons: {
        confirm: localizeMessage({ id: 'yes' }),
        cancel: localizeMessage({ id: 'cancel' })
      }
    })
      .then(async isConfirm => {
        if (isConfirm) {
          try {
            await API.flowcharts.delete(id);

            Alert.success(localizeMessage({ id: 'flowcharts.flowchart-successfully-deleted' }));

            this.refreshList();
            this.refreshFilterItems();
          } catch (error) {
            console.error(error);

            Alert.error(`${localizeMessage({ id: 'flowcharts.errors.flowchart-failed-delete' })} : ${id}`);
          }
        }
      });
  }

  handleStatusChange = (flowchartId: number, newStatus: IOption<Statuses>) => {
    if (newStatus.value === Statuses.ARCHIVE) {
      this.confirmChangeStatus(flowchartId);
    } else {
      this.onChangeStatus(flowchartId, newStatus.value);
    }
  }

  handleCopyModalClose = () => {
    this.setState({ isShowCopyModal: false });
  }

  confirmChangeStatus (id: number) {
    SweetAlert({
      title: localizeMessage({ id: 'flowcharts.flowchart-confirm' }),
      text: localizeMessage({ id: 'flowcharts.flowchart-will-be-archived' }),
      buttons: {
        confirm: localizeMessage({ id: 'yes' }),
        cancel: localizeMessage({ id: 'no' })
      }
    })
      .then(async isConfirm => {
        if (isConfirm) {
          this.onChangeStatus(id, Statuses.ARCHIVE);
        }
      });
  }

  async onChangeStatus (id: number, newStatus: Statuses) {
    try {
      await API.flowcharts.changeStatus(id, newStatus);

      Alert.success(localizeMessage({ id: 'flowcharts.flowchart-status-successfully-changed' }));

      this.refreshList();
      this.refreshFilterItems();
    } catch (error) {
      console.error(error);

      const msg = (error.message === this.isAlreadyExistsMessage)
        ? localizeMessage({ id: 'flowcharts.errors.flowchart-failed-change-status.already-exists' })
        : `${localizeMessage({ id: 'flowcharts.errors.flowchart-failed-change-status' })} : ${id}`;

      Alert.error(msg);
    }
  }

  onClone (id: number) {
    this.setState({ flowchartId: id, isShowCopyModal: true });
  }

  onShowChangelog (id: number) {
    const flowchart = this.state.dataList.find(flowchart => flowchart.id === id);

    if (flowchart !== undefined) {
      const changelogData = {
        id,
        title: `${flowchart.title} (${flowchart.status.nameTranslation})`,
      };
      this.setState({ changelogData });
    }
  }

  onHideChangelog = () => {
    this.setState({ changelogData: null });
  }

  handleAcceptClone = async (comment) => {
    const { flowchartId, copiedFlowchartData } = this.state;
    try {
      const data = {
        title: copiedFlowchartData.flowchartName,
        status: copiedFlowchartData.status?.value || '',
        comment,
      };
      await API.flowcharts.clone(flowchartId, data);

      Alert.success(
        localizeMessage({ id: 'flowcharts.flowchart-successfully-copied' })
      );

      this.refreshList();
      this.refreshFilterItems();
    } catch (error) {
      console.error(error);

      const msg = (error.message === this.isAlreadyExistsMessage)
        ? localizeMessage({ id: 'flowcharts.errors.flowchart-failed-clone.already-exists' })
        : `${localizeMessage({ id: 'flowcharts.errors.flowchart-failed-clone' })} : ${flowchartId}`;

      Alert.error(msg);
    }

    this.setState({ isShowCopyModal: false });
  };

  handleCopiedFlowchartDataChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    this.setState(prevState => ({
      copiedFlowchartData: {
        ...prevState.copiedFlowchartData,
        [name]: value,
      },
    }));
  };

  async onExport (e, flowchart) {
    e.preventDefault();

    try {
      const response = await API.flowcharts.export(flowchart.id);

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${flowchart.title}_${flowchart.year}.xlsx`;

      saveAs(blob, filename);

      Alert.success(localizeMessage({ id: 'flowcharts.flowchart-successfully-export' }));
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({ id: 'flowcharts.errors.flowchart-failed-export' })}: "${flowchart.title}" ` +
        `[${flowchart.id}]`
      );
    }
  }

  onChangeArchivedFlowcharts = (showArchivedFlowcharts) => {
    this.showArchivedFlowcharts = showArchivedFlowcharts;
    this.filterArchivedFlowcharts();
  };

  filterArchivedFlowcharts () {
    this.refreshList();
    this.refreshFilterItems();
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='flowcharts' />,
        link: '/app/flowcharts'
      }
    ];
  }

  render () {
    const {
      checkRoles, onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,
      isEditInitialAccess
    } = this.state;

    const isUserClient = checkRoles('CLIENT');
    const isUserAdmin = checkRoles('ADMIN');

    return (
      <>
        <LocalizedMessage
          id={['site.title.flowcharts', 'site.title.flowcharts.add']}
        >
          {(localizedFlowchartsMessage, localizedAddFlowchartMessage) => (
            <>
              <Helmet
                title={localizedFlowchartsMessage}
              />
              <Breadcrumbs
                title={localizedFlowchartsMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-4'
              >
                <div className='col-lg-8'>
                  <div className='title-action'>
                    {
                      checkRoles('ADMIN,PLANNER') && (
                        <Link
                          className='btn btn-success'
                          to='/app/flowcharts/create'
                        >
                          <i className='fa fa-plus fa-lg' />
                          <span>{localizedAddFlowchartMessage}</span>
                        </Link>
                      )
                    }
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-lg-9 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='flowcharts.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                    {
                      !isUserClient &&
                        <div className='col-lg-3 m-b-sm text-right'>
                          <label className='_cursor--pointer'>
                            <Switch
                              className='js-switch'
                              onChange={this.onChangeArchivedFlowcharts}
                              options={{
                                color: '#1AB394'
                              }}
                            />
                            &nbsp;&nbsp;
                            <LocalizedMessage id='settings.flowcharts.show-archived-flowcharts' />
                          </label>
                        </div>
                    }
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: localizeMessage({ id: 'name' }),
                          sort: 'title'
                        },
                        {
                          label: localizeMessage({ id: 'brand' }),
                          sort: 'brand_id'
                        },
                        {
                          label: localizeMessage({ id: 'division' }),
                          sort: 'division_id'
                        },
                        {
                          label: localizeMessage({ id: 'year' }),
                          sort: 'year_id'
                        },
                        {
                          label: localizeMessage({ id: 'creation-date' }),
                          sort: 'creation_date'
                        },
                        {
                          label: localizeMessage({ id: 'data-changes' }),
                          sort: 'data_changes'
                        },
                        {
                          label: localizeMessage({ id: 'changer' }),
                          sort: 'changer_id'
                        },
                        {
                          label: localizeMessage({ id: 'status' }),
                          sort: 'flowchart_status_id'
                        },
                        {
                          empty: true,
                          props: {
                            className: 'controls'
                          }
                        }
                      ]}
                    >
                      {
                        dataList.map(flowChart => (
                          <tr key={flowChart.id + '_' + flowChart.title}>
                            <td className='name-col'>
                              <Link
                                to={`/app/flowcharts/${flowChart.id}`}
                              >
                                {flowChart.title}
                              </Link>
                            </td>
                            <td>
                              {flowChart.brand.name}
                            </td>
                            <td>
                              {flowChart.division.name}
                            </td>
                            <td>
                              {flowChart.year}
                            </td>
                            <td>
                              {flowChart.creationDate}
                            </td>
                            <td>
                              {flowChart.dataChanges}
                            </td>
                            <td>
                              {flowChart.changer.name || flowChart.changer.mail}
                            </td>
                            <td>
                              {isUserClient || (flowChart.status.name === 'INITIAL' && !isUserAdmin) ?
                                flowChart.status.nameTranslation :
                                (
                                  <StatusList
                                    name={flowChart.status.name}
                                    onChange={(newStatus: IOption<Statuses>) => this.handleStatusChange(
                                      flowChart.id, newStatus,
                                    )}
                                  />
                                )
                              }
                            </td>
                            <td className='text-right text-nowrap'>
                              {
                                flowChart.status.name !== 'INITIAL' ||
                                isEditInitialAccess
                                  ? (
                                    <div>
                                      {
                                        !isUserClient ?
                                          <>
                                            <Link
                                              className='btn btn-warning btn-xs'
                                              to={`/app/flowcharts/${flowChart.id}/edit`}
                                            >
                                              &nbsp;&nbsp;
                                              <span><LocalizedMessage id='edit' /></span>
                                              &nbsp;&nbsp;
                                            </Link>
                                            &nbsp;&nbsp;
                                            <button
                                              className='btn btn-success btn-xs'
                                              onClick={() => this.onShowChangelog(flowChart.id)}
                                            >
                                              &nbsp;&nbsp;
                                              <span><LocalizedMessage id='changelog' /></span>
                                              &nbsp;&nbsp;
                                            </button>
                                            &nbsp;&nbsp;
                                            <button
                                              className='btn btn-info btn-xs'
                                              onClick={() => this.onClone(flowChart.id)}
                                            >
                                              &nbsp;&nbsp;
                                              <span><LocalizedMessage id='copy' /></span>
                                              &nbsp;&nbsp;
                                            </button>
                                            &nbsp;&nbsp;
                                          </>
                                          : null
                                      }
                                      <button
                                        className='btn btn-primary btn-xs'
                                        onClick={e => this.onExport(e, flowChart)}
                                      >
                                        &nbsp;&nbsp;
                                        <span><LocalizedMessage id='flowcharts.flowchart-export' /></span>
                                        &nbsp;&nbsp;
                                      </button>
                                      {
                                        !isUserClient ?
                                          <>
                                            &nbsp;&nbsp;
                                            <button
                                              className='btn btn-danger btn-xs'
                                              onClick={() => this.onDelete(flowChart.id)}
                                            >
                                              &nbsp;&nbsp;
                                              <span><LocalizedMessage id='delete' /></span>
                                              &nbsp;&nbsp;
                                            </button>
                                          </>
                                          : null
                                      }
                                    </div>
                                  )
                                  : null
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SaveModal
          type='copy'
          nameValue={this.state.copiedFlowchartData.flowchartName}
          statusValue={this.state.copiedFlowchartData.status}
          isActive={this.state.isShowCopyModal}
          onChange={this.handleCopiedFlowchartDataChange}
          onAccept={this.handleAcceptClone}
          onClose={this.handleCopyModalClose}
        />
        <ChangelogModal
          data={this.state.changelogData}
          onClose={this.onHideChangelog}
        />
      </>
    );
  }
}

export default URLFiltersHoc(Flowcharts, '-data_changes');
