import API from '../api';
import history from '../history';

// ------------------------------------
// Constants
// ------------------------------------
export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILED = 'CHECK_AUTH_FAILED';
export const SEND_AUTH = 'SEND_AUTH';
export const SEND_AUTH_SUCCESS = 'SEND_AUTH_SUCCESS';
export const SEND_AUTH_FAILED = 'SEND_AUTH_FAILED';
export const SEND_IMPERSONATE_AUTH = 'SEND_IMPERSONATE_AUTH';
export const SEND_IMPERSONATE_AUTH_SUCCESS = 'SEND_IMPERSONATE_AUTH_SUCCESS';
export const SEND_IMPERSONATE_AUTH_FAILED = 'SEND_IMPERSONATE_AUTH_FAILED';
export const SEND_IMPERSONATE_LOGOUT = 'SEND_IMPERSONATE_LOGOUT';
export const SEND_IMPERSONATE_LOGOUT_SUCCESS = 'SEND_IMPERSONATE_LOGOUT_SUCCESS';
export const SEND_IMPERSONATE_LOGOUT_FAILED = 'SEND_IMPERSONATE_LOGOUT_FAILED';
export const LOGOUT = 'LOGOUT';

// ------------------------------------
// Actions
// ------------------------------------
export const checkAuth = () => async (dispatch) => {
  dispatch({
    type: CHECK_AUTH
  });

  try {
    const profile = await API.auth.getProfile();

    if (!profile || profile.url) {
      dispatch({
        type: CHECK_AUTH_FAILED
      });

      return false;
    }

    profile.spaceName = 'Super admin';

    if (profile.role.name !== 'SUPER_ADMIN') {
      const agency = await API.agencies.current();

      profile.spaceName = agency.name;
    }

    dispatch({
      type: CHECK_AUTH_SUCCESS,
      profile
    });

    return true;
  } catch (error) {
    dispatch({
      type: CHECK_AUTH_FAILED,
      error
    });

    throw error;
  }
};

export const logIn = (mail, password) => async (dispatch) => {
  dispatch({
    type: SEND_AUTH
  });

  try {
    const authResponse = await API.auth.logIn(mail, password);

    if (!authResponse) {
      dispatch({
        type: SEND_AUTH_FAILED
      });

      return;
    }

    const profile = await API.auth.getProfile();

    if (!profile || profile.url) {
      return dispatch({
        type: SEND_AUTH_FAILED
      });
    }

    profile.spaceName = 'Super admin';

    if (profile.role.name !== 'SUPER_ADMIN') {
      const agency = await API.agencies.current();

      profile.spaceName = agency.name;
    }

    dispatch({
      type: SEND_AUTH_SUCCESS,
      profile
    });

    return authResponse;
  } catch (error) {
    dispatch({
      type: SEND_AUTH_FAILED
    });

    throw error;
  }
};

const impersonateAbstractLogIn = async (username) => {
  try {
    await API.auth.impersonateLogIn(username);

    return true;
  } catch (error) {
    return error;
  }
};

export const impersonateLogIn = (username) => async (dispatch) => {
  dispatch({
    type: SEND_IMPERSONATE_AUTH
  });

  try {
    const authResponse = await impersonateAbstractLogIn(username);
    const profile = await API.auth.getProfile();

    if (!profile || profile.url) {
      return dispatch({
        type: SEND_IMPERSONATE_AUTH_FAILED
      });
    }

    profile.spaceName = 'Super admin';

    if (profile.role.name !== 'SUPER_ADMIN') {
      const agency = await API.agencies.current();

      profile.spaceName = agency.name;
    }

    dispatch({
      type: SEND_IMPERSONATE_AUTH_SUCCESS,
      profile
    });

    history.replace('/app');

    return authResponse;
  } catch (error) {
    dispatch({
      type: SEND_IMPERSONATE_AUTH_FAILED
    });

    throw error;
  }
};

const impersonateAbstractLogOut = async () => {
  try {
    await API.auth.impersonateLogOut();

    return true;
  } catch (error) {
    return error;
  }
};

export const impersonateLogOut = () => async (dispatch) => {
  dispatch({
    type: SEND_IMPERSONATE_LOGOUT
  });

  try {
    const authResponse = await impersonateAbstractLogOut();
    const profile = await API.auth.getProfile();

    if (!profile || profile.url) {
      return dispatch({
        type: SEND_IMPERSONATE_LOGOUT_FAILED
      });
    }

    profile.spaceName = 'Super admin';

    if (profile.role.name !== 'SUPER_ADMIN') {
      const agency = await API.agencies.current();

      profile.spaceName = agency.name;
    }

    dispatch({
      type: SEND_IMPERSONATE_LOGOUT_SUCCESS,
      profile
    });

    history.replace('/app/settings/users');

    return authResponse;
  } catch (error) {
    dispatch({
      type: SEND_IMPERSONATE_LOGOUT_FAILED
    });

    throw error;
  }
};

export const logOut = () => async (dispatch) => {
  API.auth.logOut();

  dispatch({
    type: LOGOUT
  });
};

const hasAnyOfRoles = (roles, role) => roles.split(',').map(role => role.trim()).includes(role);

export const checkRoles = (roles = '') => (dispatch, getState) => {
  const profile = getState().auth.profile;

  return profile && hasAnyOfRoles(roles, profile.role.name);
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthorizing: false,
  isAuthorized: false,
  profile: null,
  authorizingError: null
};

const ACTION_HANDLERS = {
  [CHECK_AUTH]: (state, action) => ({
    ...state,
    isAuthorizing: true,
    isAuthorized: false,
    authorizingError: null
  }),
  [CHECK_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: true,
    profile: action.profile
  }),
  [CHECK_AUTH_FAILED]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    authorizingError: action.error
  }),
  [SEND_AUTH]: (state, action) => ({
    ...state,
    isAuthorizing: true,
    isAuthorized: false
  }),
  [SEND_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: true,
    profile: action.profile
  }),
  [SEND_AUTH_FAILED]: (state, action) => ({
    ...state,
    isAuthorizing: false
  }),
  [SEND_IMPERSONATE_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    profile: action.profile
  }),
  [SEND_IMPERSONATE_LOGOUT_SUCCESS]: (state, action) => ({
    ...state,
    profile: action.profile
  }),
  [LOGOUT]: (state, action) => ({
    isAuthorizing: false,
    isAuthorized: false,
    profile: null
  })
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
