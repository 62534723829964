import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import LocalizedMessage, { localizeMessage } from '../../../../components/LocalizedMessage';
import URLFiltersHoc, { Props } from '../../../../components/URLFiltersHoc';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import TableList from '../../../../components/TableList';

import API from '../../../../api';

interface IState {
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

class TargetAudiences extends PureComponent<Props, IState> {
  state: IState = {
    dataList: [],
    dataListTotal: 1,
    isLoading: false
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.targetAudiences.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='targetAudiences' />,
        link: '/app/dictionaries/targetAudiences'
      }
    ];
  }

  render () {
    const {
      onUpdateSort, onUpdatePage, filters,
    } = this.props;
    const {
      dataListTotal,
      isLoading,
      dataList
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.targetAudiences'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={[
                        {
                          label: localizeMessage({ id: 'name' }),
                          sort: 'name'
                        },
                        {
                          label: localizeMessage({ id: 'status' }),
                          sort: 'active'
                        }
                      ]}
                    >
                      {
                        dataList.map((ta, taIndex) => (
                          <tr key={ta.name + '_' + taIndex}>
                            <td>
                              {ta.name}
                            </td>
                            <td>
                              {
                                ta.active
                                  ? <i
                                    className='fa fa-check text-info'
                                    title='Active'
                                  />
                                  : <i
                                    className='fa fa-times text-danger'
                                    title='Not active'
                                  />
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default URLFiltersHoc(TargetAudiences);
