import {connect} from 'react-redux';
import {checkRoles} from '../../../modules/auth';
import Report from '../components/Report';

const mapDispatchToProps = {
  checkRoles
};

const mapStateToProps = (state) => ({
  user: state.auth.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
