import Client from './HTTPClient';
import FilterItems from '../../helpers/filterItems';
import Config from '../../config';

const API = {
  list (request) {
    return Client.post('/api/flowchart/', request);
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/flowchart/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  get (id) {
    return Client.get(`/api/flowchart/${id}`);
  },

  getVersions (id) {
    return Client.get(`/api/flowchart/${id}/version/all`);
  },

  getVersion (versionId) {
    return Client.get(`/api/flowchart/version/${versionId}`);
  },

  revertToVersion (flowchartId, versionId, comment) {
    return Client.put(`/api/flowchart/${flowchartId}/version/revet/${versionId}`, comment, true, {isString: true});
  },

  delete (id) {
    return Client.delete(`/api/flowchart/${id}`);
  },

  save (id, data) {
    if (!id) {
      return Client.post('/api/flowchart/new', data);
    }

    return Client.put(`/api/flowchart/${id}`, data);
  },

  status (year, status) {
    return Client.get(`/api/flowchart/year/${year}/status/${status}`);
  },

  changeStatus (id, newStatus) {
    return Client.put(`/api/flowchart/${id}/setStatus`, newStatus, true, {isString: true});
  },

  clone (id, data) {
    return Client.get(`/api/flowchart/copy/${id}`, data);
  },

  export (id) {
    return Client.get(`${Config.api.host}/api/flowchart/${id}/excel`, {}, true, {
      isJson: false
    });
  }
};

export default API;
