import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocalizedMessage from '../LocalizedMessage';
import LangSwitcher from '../LangSwitcher';
import classes from './Header.module.scss';

class Header extends Component {
  static propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }),
    switchMenu: PropTypes.func.isRequired,
    impersonateLogOut: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired
  };

  logout = e => {
    e.preventDefault();

    const {logOut} = this.props;

    logOut();
  };

  impersonateLogOut = e => {
    e.preventDefault();

    const {impersonateLogOut} = this.props;

    impersonateLogOut();
  };

  render () {
    const {isAuthorized, profile, switchMenu} = this.props;

    if (!isAuthorized) {
      return null;
    }

    return (
      <div className={classes.Container}>
        <span
          className='navbar-minimalize minimalize-styl-2 btn btn-primary'
          onClick={switchMenu}
        >
          <i className='fa fa-bars' />
        </span>
        <span className='agency-name'>
          {profile.spaceName}
        </span>
        {' '}
        {
          profile.impersonate ?
            <button
              className='btn btn-primary ng-scope'
              style={{marginLeft: '20px', marginTop: '-12px'}}
              onClick={this.impersonateLogOut}
            >
              <LocalizedMessage
                id='header.backToSuperAdmin'
              />
            </button>
            : null
        }
        <ul className='nav navbar-top-links navbar-right'>
          {
            profile ?
              <li>
                <div className={classes.Profile}>
                  {profile.firstName} {profile.lastName}
                  {' '}
                  <em>({profile.role.nameTranslation})</em>
                </div>
              </li>
              : null
          }
          {' '}
          <li>
            <LangSwitcher />
          </li>
          {' '}
          <li>
            <button
              className='pseudo-link'
              onClick={this.logout}
            >
              <i className='fa fa-sign-out' />
              <LocalizedMessage
                id='logout'
              />
            </button>
          </li>
          {' '}
          <li>
            <a href='mailto:support@aizek.io?subject=Appeal%20in%20support'>
              <i className='fa fa-question' />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Header;
