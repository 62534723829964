import Client from './HTTPClient';

const currenciesAPI = {
  list (request) {
    return Client.post('/api/currencies/', request);
  },

  create (request) {
    return Client.post('/api/currencies/new', request);
  },

  update (request) {
    return Client.put(`/api/currencies/${request.year}`, request, true, {}, false);
  },

  get (year) {
    return Client.get(`/api/currencies/${year}`);
  }
};

export default currenciesAPI;
