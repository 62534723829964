import {fetchResponse} from './helpers/fakeFetch';

import indoorList from './data/indoorList.json';

const API = {
  list (request) {
    console.log('api.indoor.list(request)');

    return fetchResponse(1000, indoorList);
  },

  upload (data) {
    console.log('api.indoor.upload(data)');

    return fetchResponse(1000, {});
  },

  create (request) {
    console.log('api.indoor.create(request)');

    return fetchResponse(1000, {});
  }
};

export default API;
