import React, { ChangeEvent, PureComponent } from 'react';
import cx from 'classnames';
import Modal from 'react-bootstrap-modal';

import { IOption } from 'types';

import LocalizedMessage from '../LocalizedMessage';
import Select from '../Select';
import { statusOptions } from '../../helpers/statusOptions';

import './SaveModal.scss';

type Props = {
  type: 'save' | 'copy';
  nameValue: string;
  statusValue: IOption<string> | null;
  onChange(e: { target: { value: string | IOption<string>, name: string } }): void;
  onAccept(comment: string): void;
  onClose(): void;
  isActive?: boolean;
}

type State = {
  comment: string;
  errors: {
    title: boolean;
    status: boolean;
    comment: boolean;
  };
}

class SaveModal extends PureComponent<Props, State> {
  state = {
    comment: '',
    errors: {
      title: false,
      status: false,
      comment: false,
    }
  };

  handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e);
    this.setState(prevState => ({
      errors: { ...prevState.errors, title: false },
    }));
  };

  handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    this.setState(prevState => ({
      comment: value,
      errors: { ...prevState.errors, comment: false },
    }));
  };

  handleSelectChange = (option: IOption<string>) => {
    this.setState(prevState => ({
      errors: { ...prevState.errors, status: false },
    }));

    this.props.onChange({
      target: { value: option || '', name: 'status' }
    });
  };

  handleAccept = () => {
    const {
      nameValue,
      statusValue,
      onAccept,
    } = this.props;

    const { comment } = this.state;

    this.setState({
      errors: {
        title: !nameValue,
        status: !statusValue,
        comment: !comment,
      },
    });

    if (
      nameValue &&
      statusValue?.value &&
      comment
    ) {
      onAccept(comment);
    }
  };

  render () {
    const {
      type = 'save',
      isActive = false,
      nameValue = '',
      statusValue,
      onClose,
    } = this.props;

    const { comment, errors } = this.state;

    return (
      <Modal
        show={isActive}
        onHide={onClose}
        aria-labelledby='ModalHeader'
        className='flowchart-save-modal'
      >
        <Modal.Header>
          <h1 id='ModalHeader' className='modal-title'>
            <LocalizedMessage id={`flowchart.${type}`} />?
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className='form'>
            <div className='form-group row text-nowrap'>
              <div className='col-lg-12'>
                <div className='col-lg-3'>
                  <label className='form-control-static'><LocalizedMessage id='flowchart.flowchartName.label' /></label>
                </div>
                <div className='col-lg-9'>
                  <input
                    type='text'
                    className={cx('form-control', {
                      error: errors.title,
                    })}
                    name='flowchartName'
                    value={nameValue}
                    onChange={this.handleTitleChange}
                    autoFocus
                  />
                  <span className={cx('msg', { 'msg_error': errors.title })}>
                    <LocalizedMessage id='flowchart.flowchartName.placeholder' />
                  </span>
                </div>
              </div>
            </div>

            <div className='form-group row text-nowrap'>
              <div className='col-lg-12'>
                <div className='col-lg-3'>
                  <label className='form-control-static'><LocalizedMessage id='flowchart.status.label' /></label>
                </div>
                <div className='col-lg-9'>
                  <Select
                    className={cx('small-selectize', {
                      error: errors.status,
                    })}
                    value={statusValue}
                    onChange={this.handleSelectChange}
                    placeholder={<LocalizedMessage id='flowchart.status.placeholder' />}
                    options={statusOptions}
                    menuPosition={undefined}
                  />
                  <span className={cx('msg', { 'msg_error': errors.status })}>
                    <LocalizedMessage id='flowchart.status.placeholder' />
                  </span>
                </div>
              </div>
            </div>
            <div className='form-group row text-nowrap'>
              <div className='col-lg-12'>
                <div className='col-lg-3'>
                  <label className='form-control-static'><LocalizedMessage id='flowchart.comment.label' /></label>
                </div>
                <div className='col-lg-9'>
                  <textarea
                    className={cx('form-control', {
                      error: errors.comment,
                    })}
                    name='comment'
                    onChange={this.handleCommentChange}
                    value={comment}
                    maxLength={255}
                  />
                  <span className={cx('msg', { 'msg_error': errors.comment })}>
                    <LocalizedMessage id='flowchart.comment.placeholder' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-primary'
            onClick={this.handleAccept}
          >
            <LocalizedMessage id={type} />
          </button>

          <Modal.Dismiss className='btn btn-default'><LocalizedMessage id='cancel' /></Modal.Dismiss>

        </Modal.Footer>
      </Modal>
    );
  }
}

export default SaveModal;
